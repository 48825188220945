


































































import Vue from "vue"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import type { ChargingSite } from "@/datasource/chargers"
import type { InvoiceSettings } from "@/datasource/payments"

export default Vue.extend({
  data() {
    return {
      loading: true,
      loadedTime: new Date(),
      chargingSite: null as ChargingSite | null,
      invoiceSettings: [] as InvoiceSettings[],
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    ...formatters.dateTime,
    ...formatters.generic,

    async loadData() {
      this.loading = true

      const { id } = this.$route.params
      const chargingSiteResult = await datasource.chargers.getChargingSite(id)

      if (chargingSiteResult instanceof Error) {
        console.error(chargingSiteResult)
        return
      } else {
        const invoiceSettingsResult =
          await datasource.payments.listInvoiceSettingsForChargingSite(
            chargingSiteResult as ChargingSite
          )

        if (invoiceSettingsResult instanceof Error) {
          console.error(invoiceSettingsResult)
          return
        }

        this.chargingSite = chargingSiteResult
        this.invoiceSettings = invoiceSettingsResult
      }

      this.loadedTime = new Date()
      this.loading = false
    },
  },
})

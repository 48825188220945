var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{attrs:{"fluid":""}},[_c('VCard',{attrs:{"loading":_vm.loading}},[_c('VCardTitle',[_c('span',[_vm._v("Dynamic Schedules")]),_c('VSpacer'),_c('NewDynamicScheduleDialog',{attrs:{"availableFunctions":_vm.availableFunctions},on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"icon":"","color":"green"}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-plus")])],1)]}}])}),_c('VBtn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.loadData()}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1)],1),_c('VCardSubtitle',{staticClass:"red--text"},[_vm._v("Note: Cron expressions are in UTC")]),_c('VCardText',[(_vm.loading && this.schedules.length == 0)?_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}):_c('VContainer',{attrs:{"fluid":""}},[_c('VTextField',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.schedules,"search":_vm.search,"sort-by":"name","show-expand":"","expanded":_vm.expanded,"disable-pagination":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('EditDynamicScheduleDialog',{attrs:{"schedule":item,"availableFunctions":_vm.availableFunctions},on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-pen")])],1)]}}],null,true)})]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('VMenu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('VList',[_c('VListItem',[_c('ConfirmedButton',{attrs:{"color":"red","text-color":"white"},on:{"click":function($event){return _vm.deleteSchedule(index)}}},[_vm._v("Delete")])],1)],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(_vm._s(_vm.getJobStatistics(item)))])]}}])})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
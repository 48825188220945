var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"flex-fh flex-col flex-grow flex-clip",attrs:{"fluid":""}},[_c('VCard',{staticClass:"flex-grow flex-col flex-clip",attrs:{"loading":_vm.loading}},[_c('VCardTitle',[_c('span',[_vm._v("Admin Users")]),_c('VSpacer'),_c('NewAdminUserDialog',{on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"white--text mr-1",attrs:{"icon":"","color":"green"}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-plus")])],1)]}}])}),_c('VBtn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.loadData()}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1)],1),_c('VCardText',{staticClass:"flex-grow flex-clip"},[(_vm.loading && this.adminUsers.length == 0)?[_c('VContainer',{staticClass:"flex-col",attrs:{"fluid":""}},_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}),1)]:[_c('VContainer',{staticClass:"flex-grow",attrs:{"fluid":""}},[_c('VDataTable',{staticClass:"flex-grow",attrs:{"headers":_vm.headers,"items":_vm.adminUsers,"sort-by":"email","fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.sessionsCount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.authSessions.length))]}},{key:"item.lastLoginAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.dateTime(_vm.lastLoginAt(item))))]}},{key:"item.lastRefreshAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.dateTime(_vm.lastRefreshAt(item))))]}},{key:"item.lastExpiryAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.dateTime(_vm.lastExpiryAt(item))))]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
return [(item.email != _vm.loginAccountEmail)?_c('VMenu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('VList',[(item.email != _vm.loginAccountEmail)?_c('VListItem',{on:{"click":function($event){return _vm.deleteAdminUser(item)}}},[_c('VListItemContent',{staticClass:"red--text"},[_vm._v("Delete")])],1):_vm._e()],1)],1):_vm._e()]}}])})],1)]],2),_c('VSnackbar',{attrs:{"timeout":3000,"color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VBtn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'VBtn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',[_c('VCheckbox',{attrs:{"dense":"","label":"Show short-lived sessions"},model:{value:(_vm.showShortSessions),callback:function ($$v) {_vm.showShortSessions=$$v},expression:"showShortSessions"}}),_c('CollapsibleDataTable',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.rows,"loading":_vm.loading,"options":{ itemsPerPage: 20 },"footer-props":{ 'items-per-page-options': [20, 40] },"namespace":"summary-recent-sessions","fixed-header":""},on:{"click:row":_vm.emitSessionTimestamps},scopedSlots:_vm._u([{key:"item.redirectButton",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VIcon',_vm._g(_vm._b({attrs:{"color":"blue"},on:{"click":_vm.emitSessionTimestamps}},'VIcon',attrs,false),on),[_vm._v("mdi-timeline-clock")])]}}],null,true)},[_c('span',[_vm._v("Go to usage for this session")])])]}},{key:"item.timestamps",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.timestamps))])]}},{key:"item.isSmart",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.isSmart}})]}},{key:"item.isHealthy",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.isHealthy}})]}},{key:"item.authSource",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.authSourceDisplay(item.session)))]),_c('div',[_vm._v(_vm._s(_vm.displayRfidToken(item.session)))])]}},{key:"item.authorizedAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.authorizedAt))])]}},{key:"item.ocppTransactionIds",fn:function(ref){
var item = ref.item;
return _vm._l((item.ocppTransactionIds),function(transactionId){return _c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(transactionId))])})}},{key:"item.vehicleName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.vehicleName))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
















import Vue from "vue"
import formatters from "@/utils/formatters"

export default Vue.extend({
  props: {
    value: {
      type: [Object, String],
      default: null,
    },
  },

  methods: {
    ...formatters.dateTime,
  },
})























import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import { ValidationFailureError } from "@/datasource"
import type { AlertPhoneNumber } from "@/datasource/alerts"
import type { ChargingSite } from "@/datasource/chargers"

export default Vue.extend({
  props: {
    value: {
      type: Array as PropType<AlertPhoneNumber[]>,
      required: true,
    },
    chargingSite: {
      type: Object as PropType<ChargingSite>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      alertPhoneNumbers: this.value,
      errors: {} as Record<string, Array<string | ValidationFailureError>>,
    }
  },

  methods: {
    async addAlertPhoneNumber(phoneNumbers: Array<AlertPhoneNumber | string>) {
      this.loading = true
      const newNumber = phoneNumbers[phoneNumbers.length - 1] as string

      const result = await datasource.alerts.addAlertPhoneNumber(
        this.chargingSite,
        newNumber
      )

      this.alertPhoneNumbers.pop()
      this.loading = false

      if (result instanceof ValidationFailureError) {
        this.errors = { phone_number: ["Invalid phone number"] }
      } else if (result instanceof Error) {
        console.error(result)
      } else if (result) {
        this.errors.phone_number = []
        this.alertPhoneNumbers.push(result)
      }
    },

    async deleteAlertPhoneNumber(item: AlertPhoneNumber) {
      this.loading = true

      const result = await datasource.alerts.deleteAlertPhoneNumber(
        this.chargingSite,
        item
      )

      if (result instanceof Error) {
        console.error(result)
        this.errors = { phone_number: ["Could not delete phone number"] }
      } else {
        this.errors.phone_number = []
        this.alertPhoneNumbers.splice(this.alertPhoneNumbers.indexOf(item), 1)
      }

      this.loading = false
    },
  },
})

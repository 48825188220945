































import Vue from "vue"
import datasource from "@/datasource"
import type { ChargingSite } from "@/datasource/chargers"

import ChargingSiteFields from "./ChargingSiteFields.vue"

function initialData() {
  return {
    showDialog: false,
    loading: false,
    saving: false,
    modifiedChargingSite: {} as ChargingSite,
  }
}

export default Vue.extend({
  components: {
    ChargingSiteFields,
  },

  data: initialData,

  methods: {
    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true
      let isOk = true

      {
        const result = await datasource.chargers.createChargingSite(
          this.modifiedChargingSite
        )
        if (result instanceof Error) {
          console.error(result)
          isOk = false
        }
      }

      this.saving = false

      if (isOk) {
        this.$emit("saved")
        this.close()
      }
    },
  },
})








































































































import Vue, { PropType } from "vue"
import type { EntityType, Entity } from "@/utils"
import ConfirmedButton from "@/components/ConfirmedButton.vue"

export default Vue.extend({
  components: {
    ConfirmedButton,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
    identifier: {
      type: String,
      required: true,
    },
    types: {
      type: Object as PropType<Record<string, EntityType>>,
      required: true,
    },
    readFunction: {
      type: Function,
      required: true,
    },
    createFunction: {
      type: Function,
      default: null,
    },
    blankEntity: {
      type: Object as PropType<EntityType>,
      default: null,
    },
    updateFunction: {
      type: Function,
      default: null,
    },
    deleteFunction: {
      type: Function,
      default: null,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
    disableBulkControl: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      loading: true,
      search: "",
      entities: [] as Entity[],
      selected: [] as Entity[],
    }
  },

  computed: {
    headers(): Entity[] {
      const headers = Object.entries(this.types).map(
        ([key, type]: [string, EntityType]): Entity => {
          return {
            text: type.label as string,
            value: key,
            ...type.opts,
          }
        }
      )

      if (this.deleteFunction) {
        return headers.concat([
          {
            text: "",
            value: "menu",
            width: 0,
            align: "right",
            sortable: false,
          },
        ])
      } else {
        return headers
      }
    },

    elevation(): number {
      return this.nested ? 0 : 1
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true
      const result = await this.readFunction()
      if (result instanceof Error) {
        console.error(result)
        return
      }
      this.entities = result
      this.loading = false
    },

    async deleteEntity(entity: Entity) {
      await this.deleteFunction(entity)
      this.loadData()
    },

    async emit(modifiedEntity: Entity) {
      if (modifiedEntity[this.identifier] && this.updateFunction) {
        this.loading = true

        const result = await this.updateFunction(modifiedEntity)

        if (result instanceof Error) {
          console.error(result)
        }

        this.loading = false
      } else {
        this.$emit("input", modifiedEntity)
      }
    },

    async saveNewEntity(modifiedEntity: Entity) {
      if (!modifiedEntity[this.identifier] && this.createFunction) {
        this.loading = true

        const result = await this.createFunction(modifiedEntity)

        if (result instanceof Error) {
          console.error(result)
        } else {
          this.loading = false
          this.loadData()
        }
      }
    },

    addBlankEntity() {
      this.entities.push(this.blankEntity)
    },
  },
})

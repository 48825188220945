var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"flex-fh flex-col flex-grow flex-clip",attrs:{"fluid":""}},[_c('VCard',{staticClass:"flex-grow flex-col flex-clip",attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"d-flex align-start flex-wrap"},[_c('VCardTitle',[_vm._v("Charging Sessions: Active")]),_c('VSpacer'),_c('div',{staticClass:"flex-grow-0 pa-1 ml-3 d-flex align-center"},[_c('Checkbox',{attrs:{"label":"Hide car-only sessions","filled":""},model:{value:(_vm.onlyKnownChargers),callback:function ($$v) {_vm.onlyKnownChargers=$$v},expression:"onlyKnownChargers"}}),_c('Checkbox',{attrs:{"label":"Only unhealthy","filled":""},model:{value:(_vm.onlyUnhealthy),callback:function ($$v) {_vm.onlyUnhealthy=$$v},expression:"onlyUnhealthy"}}),(!_vm.charger)?_c('ChargingSiteSelect',{staticClass:"mr-2",attrs:{"label":"Charging Site","filled":"","hide-details":"","multiple":""},on:{"input":function($event){return _vm.loadData()}},model:{value:(_vm.selectedChargingSites),callback:function ($$v) {_vm.selectedChargingSites=$$v},expression:"selectedChargingSites"}}):_vm._e(),_c('RegisterChargingSessionDialog',{on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-1 white--text",attrs:{"icon":"","color":"green"}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-plus")])],1)]}}])}),_c('VBtn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.loadData()}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1)],1)],1),_c('VCardSubtitle',[(_vm.charger)?[_vm._v("Charger: "+_vm._s(_vm.charger.displayName))]:_vm._e(),_c('VContainer',{staticClass:"flex-grow-0",attrs:{"fluid":""}},[_c('VTextField',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","label":"Filter: OCCP ID or Charging Site","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],2),_c('VCardText',{staticClass:"flex-grow flex-clip"},[(_vm.loading && this.chargingSessions.length == 0)?[_c('VContainer',{staticClass:"flex-col",attrs:{"fluid":""}},_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}),1)]:[_c('VContainer',{staticClass:"flex-grow flex-col",attrs:{"fluid":""}},[_c('div',{staticClass:"py-2"},[(_vm.selected.length)?_c('VMenu',{staticClass:"px-2",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-2"},'VBtn',attrs,false),on),[_vm._v("View Logs for Selected ("+_vm._s(_vm.selected.length)+")")])]}}],null,false,3570000998)},[_c('VList',[_c('VListItem',{attrs:{"href":_vm.selectedAmqpDashboardUrl,"target":"_blank"}},[_vm._v("AMQP Dashboard")])],1)],1):_vm._e(),(_vm.selected.length)?_c('RouterLink',{staticClass:"px-2",attrs:{"to":{ name: 'ChargerUsage', params: { chargers: _vm.chargersIdString() }, query: { from: _vm.usageFromTime(_vm.selected) } }}},[(_vm.selected.length)?_c('VBtn',[_vm._v("View Usage For Selected ("+_vm._s(_vm.selected.length)+")")]):_vm._e()],1):_vm._e()],1),_c('CollapsibleDataTable',{attrs:{"headers":_vm.headers,"show-select":"","items":_vm.filteredChargingSessions,"item-class":_vm.rowClass,"search":_vm.search,"namespace":"active-sessions","sort-by":"id","fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.car",fn:function(ref){
var item = ref.item;
return [(item.car)?[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(item.car.displayName))])]}}],null,true)},[_vm._v("ID: "+_vm._s(item.car.id))])]:_vm._e()]}},{key:"item.charger",fn:function(ref){
var item = ref.item;
return [(item.charger)?_c('RouterLink',{attrs:{"to":{ name: 'ChargerSummary', query: { ocppId: item.charger.ocppIdentifier } }}},[_vm._v(_vm._s(item.charger.displayName))]):_vm._e()]}},{key:"item.charger.ocppIdentifier",fn:function(ref){
var item = ref.item;
return [(item.charger)?_c('RouterLink',{attrs:{"to":{ name: 'ChargerSummary', query: { ocppId: item.charger.ocppIdentifier } }}},[_vm._v(_vm._s(item.charger.ocppIdentifier))]):_vm._e()]}},{key:"item.charger.status",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.connectorStatus(item)))]}},{key:"item.instantaneousChargingPowerW",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.powerWToKw(item.instantaneousChargingPowerW, 2)))]}},{key:"item.currentChargingPlanStep.maxChargingPowerW",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.powerWToKw(_vm.plannedPower(item))))]}},{key:"item.energyUsageWh",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.powerWToKw(item.energyUsageWh, 2)))]}},{key:"item.cleanedEnergyUsageWh",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.powerWToKw(item.cleanedEnergyUsageWh, 2)))]}},{key:"item.maximumObservedPowerOutputW",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.powerWToKw(item.maximumObservedPowerOutputW, 2)))]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('TimeWithHoverDetail',{attrs:{"value":item.createdAt}})]}},{key:"item.connectedFor",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.dateTimeDiffInWords(item.createdAt, new Date())))]}},{key:"item.chargingPlanLastEnforcedAt",fn:function(ref){
var item = ref.item;
return [_c('TimeWithHoverDetail',{attrs:{"value":item.chargingPlanLastEnforcedAt}})]}},{key:"item.authorized",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.authorized}})]}},{key:"item.authorizationSource",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.authorizationSource))]),_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.rfidToken)?_c('RouterLink',_vm._g(_vm._b({attrs:{"to":{ name: 'AppUsers', params: { appUserId: item.rfidToken.ownerId } }}},'RouterLink',attrs,false),on),[_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(item.rfidToken.secret))])]):_vm._e()]}}],null,true)},[_vm._v("View App User for this token")])]}},{key:"item.authorizedAt",fn:function(ref){
var item = ref.item;
return [_c('TimeWithHoverDetail',{attrs:{"value":item.authorizedAt}})]}},{key:"item.smartChargingEnabled",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.smartChargingEnabled}})]}},{key:"item.instantChargingSessionPricing",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('ChargingSessionPricingDialog',{attrs:{"chargingSessionPricings":item.chargingSessionPricings},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({},'VBtn',attrs,false),on),[_vm._v(_vm._s(_vm.instantChargingSessionPricing(item)))])]}}],null,true)})],1)]}}],null,true)},[_vm._v("Compare pricings for this session")])]}},{key:"item.smartChargingSessionPricing",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('ChargingSessionPricingDialog',{attrs:{"chargingSessionPricings":item.chargingSessionPricings},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({},'VBtn',attrs,false),on),[_vm._v(_vm._s(_vm.smartChargingSessionPricing(item)))])]}}],null,true)})],1)]}}],null,true)},[_vm._v("Compare pricings for this session")])]}},{key:"item.ocppTransactionIds",fn:function(ref){
var item = ref.item;
return _vm._l((item.ocppTransactionIds),function(transactionId){return _c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(transactionId))])})}},{key:"item.estimatedChargingSavings",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.estimatedChargingSavings(item)))])]}},{key:"item.managementStrategy",fn:function(ref){
var item = ref.item;
return [_c('VMenu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(item.managementStrategy))])]}}],null,true)},[_c('VList',_vm._l((_vm.chargingSessionManagementStrategies),function(entry){return _c('VListItem',{key:entry,on:{"click":function($event){return _vm.setManagementStrategy(item, entry)}}},[_vm._v(_vm._s(entry))])}),1)],1)]}},{key:"item.appUserLink",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('span',[_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([(item.charger.appUser)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('RouterLink',{staticClass:"pr-2 no-underline",attrs:{"to":{ name: 'AppUsers', params: { appUserId: item.charger.appUser.id } }}},[_c('VBtn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-account-box-outline")])],1)],1)]}}:null],null,true)},[_c('span',[_vm._v("View AppUser for this session")])])],1)])]}},{key:"item.usageLink",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('span',[_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('RouterLink',{staticClass:"pr-2 no-underline",attrs:{"to":{ name: 'ChargerUsage', params: { charger: item.charger.id }, query: { from: _vm.usageFromTime([item]) } }}},[_c('VBtn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-chart-box-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("See usage for this session")])])],1)])]}},{key:"item.externalLinks",fn:function(ref){
var item = ref.item;
return [_c('ChargingSessionLinkMenu',{attrs:{"charging-session":item,"offset-y":"","left":""}})]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
return [_c('VMenu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('VList',[_c('VListItem',{on:{"click":function($event){return _vm.startCharging(item)}}},[_c('VListItemIcon',[_c('VIcon',{staticClass:"green--text"},[_vm._v("mdi-play-circle")])],1),_c('VListItemContent',[_vm._v("Start Charging")])],1),_c('VListItem',{on:{"click":function($event){return _vm.stopCharging(item)}}},[_c('VListItemIcon',[_c('VIcon',{staticClass:"red--text"},[_vm._v("mdi-stop-circle")])],1),_c('VListItemContent',[_vm._v("Stop Charging")])],1),_c('VListItem',{on:{"click":function($event){return _vm.runOptimization(item)}}},[_c('VListItemIcon',[_c('VIcon',{staticClass:"orange--text"},[_vm._v("mdi-rocket-launch")])],1),_c('VListItemContent',[_vm._v("Build New Charging Plan")])],1),_c('ChargingPlanDialog',{attrs:{"charging-session":item},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItem',_vm._g(_vm._b({attrs:{"disabled":item.chargingPlan == null}},'VListItem',attrs,false),on),[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-format-list-numbered")])],1),_c('VListItemContent',[_vm._v("View Charging Plan...")])],1)]}}],null,true)}),_c('VListItem',[_c('ConfirmedButton',{attrs:{"color":"red","text-color":"white"},on:{"click":function($event){return _vm.clearChargingSession(item)}}},[_vm._v("Clear Session")])],1)],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }








import Vue from "vue"
import helpers from "@/utils/helpers"

export default Vue.extend({
  props: {
    value: {
      type: String,
      required: true,
    },
  },

  computed: {
    chargingSessionLogUrl(): string {
      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_CHARGING_SESSION_LOG_INDEX,
        {
          filters: [`match_phrase:(charging_session_id:${this.value})`],
        }
      )
    },
  },
})

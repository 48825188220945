

















import Vue, { PropType } from "vue"
import type { ChargingSite } from "@/datasource/chargers"
import type { AppUser } from "@/datasource/appUsers"
import datasource from "@/datasource"

export default Vue.extend({
  props: {
    preloadedChargingSites: {
      type: Array as PropType<Array<ChargingSite>>,
      default() {
        return []
      },
    },

    value: {
      type: [String, Object, Array],
      default: null,
    },

    returnId: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: true,
    },

    filter: {
      type: Function,
      default: null,
    },

    appUserId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      chargingSites: this.preloadedChargingSites as ChargingSite[],
      selectedUser: null as AppUser | null,
    }
  },

  computed: {
    modified: {
      get(): ChargingSite {
        return this.value
      },

      set(value: ChargingSite | ChargingSite[] | string | string[]): void {
        this.$emit("input", value)
      },
    },

    filteredSites(): ChargingSite[] {
      return this.filter ? this.filter(this.chargingSites) : this.chargingSites
    },
  },

  watch: {
    appUserId() {
      this.loadData()
    },

    preloadedChargingSites(value) {
      this.chargingSites = value
    },
  },

  created() {
    if (!this.preloadedChargingSites.length) {
      this.loadData()
    }
  },

  methods: {
    async loadData() {
      this.loading = true
      const appUserId = this.$route.params?.appUserId || this.appUserId

      let result
      if (appUserId) {
        const appUserResult = await datasource.appUsers.getAppUser(appUserId)
        if (appUserResult instanceof Error || !appUserResult) {
          console.error(appUserResult)
          return
        }
        this.selectedUser = appUserResult
        result = await datasource.chargers.listChargingSitesForUser(
          this.selectedUser
        )
      } else {
        result = await datasource.chargers.listChargingSites()
      }

      if (result instanceof Error) {
        console.error(result)
        return
      } else {
        this.chargingSites = result
        if (!this.clearable) {
          this.modified ||= this.chargingSites[0]
        }
      }
      this.loading = false
    },

    blur() {
      this.$emit("blur")
    },
  },
})

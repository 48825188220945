var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VEditDialog',{attrs:{"autofocus":"","large":""},on:{"save":function($event){return _vm.emit()},"close":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"input",fn:function(){return [(_vm.estimates.length === 0)?[_c('VTextField',{attrs:{"label":"Battery Capacity (kWh)"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('VFadeTransition',{attrs:{"leave-absolute":""}},[(_vm.loading)?_c('VProgressCircular',{attrs:{"size":"24","color":"info","indeterminate":""}}):_c('VBtn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.estimateBatteryCapacity()}}},[_c('VIcon',[_vm._v("mdi-cog-refresh")])],1)],1)]},proxy:true}],null,false,2336918051),model:{value:(_vm.modifiedValue),callback:function ($$v) {_vm.modifiedValue=_vm._n($$v)},expression:"modifiedValue"}})]:[_c('VSelect',{ref:"estimateSelect",attrs:{"label":"Battery Capacity (kWh)","item-value":"capacityKwh","items":_vm.estimates,"autofocus":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.capacityKwh))]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.confidence === 'high')?[_vm._v(_vm._s(item.capacityKwh))]:[_c('VTooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('span',[_vm._v(_vm._s(item.capacityKwh))]),_c('VIcon',{staticClass:"orange--text mb-1"},[_vm._v("mdi-alert")])],1)]}}],null,true)},[_vm._v("Poor quality data")])]]}}]),model:{value:(_vm.modifiedValue),callback:function ($$v) {_vm.modifiedValue=_vm._n($$v)},expression:"modifiedValue"}})]]},proxy:true}])},[_vm._v(_vm._s(_vm.fixedFloat(_vm.value)))])}
var staticRenderFns = []

export { render, staticRenderFns }
import datasource from "@/datasource"
import { AppUser } from "@/datasource/appUsers"
import { Result } from "@/utils"
import type { Charger } from "@/datasource/chargers"

type Owner = {
  id: string
  displayIdentifier: string | null
}

export enum Confidence {
  Low = "LOW",
  High = "HIGH",
}

export type BatteryCapacityEstimate = {
  capacityKwh: number
  confidence: Confidence
}

export type CarSummary = {
  id: string
  batterySizeWh: number
  displayName: string
  vin: string
  smartChargingEnabled: boolean
  smartSocInferenceEnabled: boolean
  preferenceDepartureTime: string
  preferenceDepartureTimeExpiresAt: string
  preferenceMinCharge: number
  preferenceMaxCharge: number
  preferredChargingSessionManagementStrategy: string | null
  percentageEnergyForecast: number
  appUsers: Owner[]
  onboardChargerCapacityPhasesW: number[] | null
  chargers: Charger[]
  ocppVehicleId: string | null
}

export type Car = CarSummary & {
  batteryCapacityKwh: number
  stateOfChargeTomorrow: number
  stateOfChargeVariance: number | null
  apiData: {
    stateOfChargePercentage: number
    lastRefreshedAt: Date | null
  }
  mlData: {
    batteryCapacityKwh: number
    energyUsagePercentile95: number
    maxChargingPowerKw: number
  }
  integrationType: string
}

export type CarInput = {
  displayName?: string | null
  smartChargingEnabled?: boolean | null
  smartSocInferenceEnabled?: boolean | null
  preferredChargingSessionManagementStrategy?: string | null
  preferenceDepartureTime?: string | null
  onboardChargerCapacityPhasesW?: number[] | null
  baterySizeWh?: number | null
  ocppVehicleId?: string | null
  preferenceDepartureTimeExpiresAt?: string | null
}

const graphQlCarSummaryFields = `
  id
  batterySizeWh
  displayName
  vin
  smartChargingEnabled
  smartSocInferenceEnabled
  preferenceMinCharge
  preferenceMaxCharge
  preferenceDepartureTime
  preferenceDepartureTimeExpiresAt
  preferredChargingSessionManagementStrategy
  percentageEnergyForecast
  onboardChargerCapacityPhasesW
  ocppVehicleId
  integrationType
  appUsers {
    id
    displayIdentifier
  }
  chargers {
    chargingSite {
      id
      name
    }
  }
`

const graphQlCarFields = `
  ${graphQlCarSummaryFields}
  stateOfChargeTomorrow
  stateOfChargeVariance
  apiData {
    stateOfChargePercentage
    lastRefreshedAt
  }
  mlData {
    batteryCapacityKwh
    energyUsagePercentile95
    maxChargingPowerKw
  }
`

const mapCar = (car: Car | undefined | null): Car | null => {
  if (!car) {
    return null
  }

  const lastRefreshedAt =
    car.apiData.lastRefreshedAt && new Date(car.apiData.lastRefreshedAt)

  return {
    ...car,
    apiData: {
      ...car.apiData,
      lastRefreshedAt,
    },
  }
}

const onCarUpdate = (
  operation: string,
  // eslint-disable-next-line
  callbacks: Record<string, (...args: any[]) => void>
): void => {
  datasource.subscribe(operation, {}, callbacks)
}

const cars = {
  async getCar(id: string): Promise<Result<Car | null>> {
    type Response = {
      data?: {
        car?: Car
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        car(id: $id) {
          ${graphQlCarFields}
        }
      }
    `,
      {
        id,
      }
    )
    return Result.map(response, (x) => mapCar(x.data?.car))
  },

  async getUnknownVehiclesForCharger(
    charger: Charger
  ): Promise<Result<CarSummary[]>> {
    type Response = {
      data?: {
        unknownVehicles?: CarSummary[]
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($chargerId: ID!) {
        unknownVehicles(chargerId: $chargerId) {
          ${graphQlCarSummaryFields}
        }
      }
      `,
      { chargerId: charger.id }
    )

    return Result.map(response, (cars) => cars.data?.unknownVehicles || [])
  },

  async listCars(): Promise<Result<Array<Car>>> {
    type Response = {
      data?: {
        cars?: Car[]
      }
    }
    const response: Result<Response> = await datasource.graphql(`
      query {
        cars {
          ${graphQlCarFields}
        }
      }
    `)

    return Result.map(response, function (response: Response) {
      const rawCars = response.data?.cars || []
      return rawCars.flatMap((car) => mapCar(car) || [])
    })
  },

  async listCarSummaries(): Promise<Result<Array<CarSummary>>> {
    type Response = {
      data?: {
        cars?: CarSummary[]
      }
    }

    const response: Result<Response> = await datasource.graphql(`
      query {
        cars {
          ${graphQlCarSummaryFields}
        }
      }
    `)

    return Result.map(response, function (response: Response) {
      const rawCars = response.data?.cars || []
      return rawCars.flatMap((car) => car || [])
    })
  },

  async listCarsForUser(user: AppUser): Promise<Result<Array<Car>>> {
    type Response = {
      data?: {
        appUser?: {
          cars?: Car[]
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        appUser(id: $id) {
          cars {
            ${graphQlCarFields}
          }
        }
      }
    `,
      {
        id: user.id,
      }
    )

    return Result.map(response, (x) => x.data?.appUser?.cars || [])
  },

  async bulkUpdateCars(
    cars: Array<Car | CarSummary>,
    value: Record<string, unknown>
  ): Promise<
    Result<{
      updatedCars: { id: string; displayName: string }[]
      erroredCars: { id: string; displayName: string }[]
    }>
  > {
    type Response = {
      data?: {
        cars?: {
          update?: {
            updatedCars: { id: string; displayName: string }[]
            erroredCars: { id: string; displayName: string }[]
          }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation ($ids: [ID]!, $value: CarInput!) {
        cars(ids: $ids) {
          update(value: $value) {
            updatedCars {
              id
              displayName
            }
            erroredCars {
              id
              displayName
            }
          }
        }
      }
    `,
      {
        ids: cars.map((c) => c.id),
        value: value,
      }
    )

    return Result.map(
      response,
      (x) => x.data?.cars?.update || { updatedCars: [], erroredCars: [] }
    )
  },

  async createCar(input: CarInput): Promise<Result<boolean>> {
    type Response = {
      data?: {
        createCar?: {
          success: boolean
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($value: CarInput!) {
        createCar(value: $value) {
          success
        }
      }
      `,
      {
        value: input,
      }
    )

    return Result.map(response, (r) => !!r.data?.createCar?.success)
  },

  async updateCar(car: Car | CarSummary): Promise<Result<boolean>> {
    type Response = {
      data?: {
        car?: {
          update?: {
            id: string
          }
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation ($id: ID!, $value: CarInput!) {
        car(id: $id) {
          update(value: $value) {
            id
          }
        }
      }
    `,
      {
        id: car.id,
        value: {
          batterySizeWh: car.batterySizeWh,
          displayName: car.displayName,
          smartChargingEnabled: car.smartChargingEnabled,
          smartSocInferenceEnabled: car.smartSocInferenceEnabled,
          preferredChargingSessionManagementStrategy:
            car.preferredChargingSessionManagementStrategy,
          preferenceDepartureTime: car.preferenceDepartureTime,
          onboardChargerCapacityPhasesW: car.onboardChargerCapacityPhasesW,
          ocppVehicleId: car.ocppVehicleId,
        },
      }
    )
    return Result.map(response, (x) => x.data?.car?.update?.id == car.id)
  },

  async deleteCar(car: Car): Promise<Result<boolean>> {
    type Response = {
      data?: {
        car?: {
          delete?: boolean
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation ($id: ID!) {
        car(id: $id) {
          delete
        }
      }
    `,
      {
        id: car.id,
      }
    )
    return Result.map(response, (x) => x.data?.car?.delete || false)
  },

  async deleteVehicles(
    vehicles: Car[] | CarSummary[]
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        vehicles?: {
          delete?: boolean
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation ($ids: [ID]!) {
        vehicles(ids: $ids) {
          delete
        }
      }
    `,
      {
        ids: vehicles.map((vehicle: Car | CarSummary) => vehicle.id),
      }
    )
    return Result.map(response, (x) => x.data?.vehicles?.delete || false)
  },

  async setManualFdtPredictions(
    cars: Car[],
    hour: number
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        setManualFdtPredictions?: boolean
      }
    }
    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($ids: [ID]!, $hour: Int!) {
        setManualFdtPredictions(vehicleIds: $ids, hour: $hour)
      }
    `,
      {
        ids: cars.map((car) => car.id),
        hour: hour,
      }
    )

    return Result.map(response, (x) => x.data?.setManualFdtPredictions || false)
  },

  async setPercentageEnergyForecast(
    cars: Car[],
    percentage: number
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        setPercentageEnergyForecast?: boolean
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation($ids: [ID]!, $percentage: Int!) {
        setPercentageEnergyForecast(vehicleIds: $ids, percentage: $percentage)
      }
    `,
      {
        ids: cars.map((car) => car.id),
        percentage: percentage,
      }
    )

    return Result.map(
      response,
      (x) => x.data?.setPercentageEnergyForecast || false
    )
  },

  async updateChargingPreferences(
    car: Car | CarSummary
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        car?: {
          updateChargingPreferences?: boolean
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation ($id: ID!, $minCharge: Int, $maxCharge: Int) {
        car(id: $id) {
          updateChargingPreferences(
            preferenceMinCharge: $minCharge,
            preferenceMaxCharge: $maxCharge
          )
        }
      }
    `,
      {
        id: car.id,
        minCharge: car.preferenceMinCharge || null,
        maxCharge: car.preferenceMaxCharge || null,
      }
    )
    return Result.map(
      response,
      (x) => x.data?.car?.updateChargingPreferences || false
    )
  },

  async updateMlData(car: Car): Promise<Result<boolean>> {
    type Response = {
      data?: {
        car?: {
          updateMlData?: boolean
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation ($id: ID!, $mlData: VehicleMlDataInput!) {
        car(id: $id) {
          updateMlData(
            vehicleMlData: $mlData
          )
        }
      }
    `,
      {
        id: car.id,
        mlData: car.mlData,
      }
    )
    return Result.map(response, (x) => x.data?.car?.updateMlData || false)
  },

  async estimateBatteryCapacity(
    car: Car
  ): Promise<Result<Array<BatteryCapacityEstimate>>> {
    type Response = {
      data?: {
        estimateBatteryCapacity?: BatteryCapacityEstimate[]
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        estimateBatteryCapacity(vehicleId: $id) {
          capacityKwh
          confidence
        }
      }
    `,
      {
        id: car.id,
      }
    )

    return Result.map(response, (x) => x.data?.estimateBatteryCapacity || [])
  },

  async estimateBatteryCapacities(
    cars: Car[]
  ): Promise<Result<Array<boolean>>> {
    type Response = {
      data?: {
        estimateBatteryCapacities?: boolean[]
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation($ids: [ID]!) {
        estimateBatteryCapacities(vehicleIds: $ids)
      }
    `,
      {
        ids: cars.map((car) => car.id),
      }
    )

    return Result.map(response, (x) => x.data?.estimateBatteryCapacities || [])
  },

  async trainFdtModel(car: Car): Promise<Result<boolean>> {
    type Response = {
      data?: {
        car?: {
          trainFdtModel?: boolean
        }
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($id: ID!) {
        car(id: $id) {
          trainFdtModel
        }
      }
      `,
      {
        id: car.id,
      }
    )

    return Result.map(response, (x) => x.data?.car?.trainFdtModel || false)
  },

  waitForCarUpdate(withCallback: (car: Car) => void): void {
    type Response = {
      data?: {
        all_car_updates?: Car
      }
    }

    const onResult = (report: Response) => {
      const car = report.data?.all_car_updates

      if (car) {
        withCallback(car)
      }
    }

    onCarUpdate(`subscription { all_car_updates { ${graphQlCarFields} } }`, {
      onResult,
    })
  },
}

export default cars

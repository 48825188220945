




































import Vue, { PropType } from "vue"
import datasource, { ValidationFailureError } from "@/datasource"
import type { ValidationError } from "@/datasource"
import type { EmailTemplate } from "@/datasource/emails"

function initialData(this: { emailTemplate: EmailTemplate }) {
  return {
    showDialog: false,
    saving: false,
    modified: { ...this.emailTemplate },
    errors: {} as ValidationError,
  }
}

export default Vue.extend({
  props: {
    emailTemplate: {
      type: Object as PropType<EmailTemplate>,
      required: true,
    },
  },

  data: initialData,

  watch: {
    showDialog(value) {
      if (!value) {
        this.close()
      }
    },
  },

  methods: {
    close() {
      this.showDialog = false
    },

    async save() {
      this.saving = true
      let isOk = true

      try {
        {
          const result = await datasource.emails.updateEmailTemplate(
            this.modified
          )

          if (result instanceof ValidationFailureError) {
            isOk = false
            this.errors = result.errors
          } else if (result instanceof Error) {
            throw result
          }
        }
      } catch (e) {
        console.error(e)
        isOk = false
      }

      if (isOk) {
        this.$emit("saved")
        this.close()
      }

      this.saving = false
    },
  },
})

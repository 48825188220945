

















import Vue, { PropType } from "vue"
import formatters from "@/utils/formatters"
import type { Money } from "@/datasource/payments"

export default Vue.extend({
  props: {
    value: {
      type: Object as PropType<Money>,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modifiedValue: this.value,
    }
  },

  methods: {
    ...formatters.amount,

    emit() {
      this.$emit("save", this.modifiedValue)
    },
  },
})



























































































































































































































































































import Vue, { PropType } from "vue"
import _ from "underscore"
import Big from "big.js"
import type { Charger } from "@/datasource/chargers"
import type { ChargingSessionLogEntry } from "@/datasource/charging"
import type { Money } from "@/datasource/payments"
import formatters from "@/utils/formatters"
import { DateTime, Interval } from "luxon"
import helpers from "@/utils/helpers"
import datasource from "@/datasource"
import ChargerLinksMenu from "@/components/ChargerLinksMenu.vue"
import IsHealthyIcon from "@/components/IsHealthyIcon.vue"
import SmartChargingEnabledIcon from "@/components/SmartChargingEnabledIcon.vue"
import RobotIcon from "@/components/icons/robot.vue"
import CarSmartChargingIcon from "@/components/icons/CarSmartChargingIcon.vue"
import ChargerSmartChargingIcon from "@/components/icons/ChargerSmartChargingIcon.vue"
import ChargingStation from "@/components/icons/chargingStation.vue"
import Funnel from "@/components/icons/Funnel.vue"
import Flash from "@/components/icons/Flash.vue"
import Next from "@/components/icons/Next.vue"
import Unplugged from "@/components/icons/Unplugged.vue"
import Sedan from "@/components/icons/Sedan.vue"
import SpeedLimit from "@/components/icons/SpeedLimit.vue"
import Integration from "@/components/icons/Integration.vue"

export default Vue.extend({
  components: {
    CarSmartChargingIcon,
    ChargerSmartChargingIcon,
    ChargerLinksMenu,
    IsHealthyIcon,
    ChargingStation,
    Flash,
    Funnel,
    Next,
    RobotIcon,
    Unplugged,
    Sedan,
    SpeedLimit,
    SmartChargingEnabledIcon,
    Integration,
  },

  props: {
    chargers: {
      type: Array as PropType<Charger[]>,
      required: true,
    },

    displayChargerInfo: {
      type: Boolean,
      default: true,
    },

    from: {
      type: Date as PropType<Date>,
      required: true,
    },

    to: {
      type: Date as PropType<Date>,
      default: new Date(),
    },

    fluid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const usageRows: { value: string; name: string; display: boolean }[] = [
      { value: "actual", name: "Actual Usage" },
      { value: "planned", name: "Planned Usage" },
      { value: "currentOfferedA", name: "Current Offered (A)" },
      { value: "vehicleSoc", name: "Internal SoC" },
      { value: "ocppSoc", name: "OCPP SoC" },
      { value: "chargingSavings", name: "Savings" },
      { value: "smartChargingEnabled", name: "Smart Charging" },
      { value: "isHealthy", name: "Health" },
      { value: "chargerConnectorStatus", name: "Connector Status" },
      { value: "vehicleDisplayName", name: "Vehicle Name" },
      { value: "energyUsageKwh", name: "Energy Usage (kwh)" },
      { value: "chargingPlanSource", name: "Charging Plan Source" },
      { value: "elapsedTimeH", name: "Elapsed Time (h)" },
      { value: "gridCost", name: "Grid Cost (NOK)" },
      { value: "energyCost", name: "Energy Cost (NOK)" },
      { value: "routeDepartureTime", name: "Route Departure Time" },
      { value: "authorisationSource", name: "Auth Source" },
      { value: "carSmartChargingEnabled", name: "Car Smart Charging" },
      { value: "chargerSmartChargingEnabled", name: "Charger Smart Charging" },
    ].map((row) => {
      return {
        ...row,
        display: ["true", null].includes(
          localStorage.getItem(`chargerUsage-${row.value}`)
        ),
      }
    })

    return {
      usageRows: usageRows,
      loading: false,
      rawSelectedChargingSessionLogs: {} as Record<
        string,
        Record<string, ChargingSessionLogEntry[]>
      >,
      selectedChargingSessionLogs: [] as Record<string, unknown>[],
    }
  },

  computed: {
    displayedRows(): Record<string, boolean> {
      return Object.fromEntries(
        this.usageRows.filter((r) => r.display).map((r) => [r.value, r.display])
      )
    },

    interval(): Record<string, DateTime> {
      const end = this.to
      const begin = this.from

      const hoursInInterval = helpers.hoursInInterval(
        Interval.fromDateTimes(begin, end)
      )

      return Array.from(hoursInInterval).reduce(
        (a, v) => ({ ...a, [v.toUTC().toISO()]: v }),
        {}
      )
    },
  },

  watch: {
    chargers() {
      this.loadData()
    },

    from() {
      this.loadData()
    },

    to() {
      this.loadData()
    },

    usageRows: {
      handler: function (newVal) {
        newVal.forEach((v: { display: boolean; value: string }) =>
          localStorage.setItem(`chargerUsage-${v.value}`, v.display.toString())
        )
      },
      deep: true,
    },
  },

  methods: {
    ...formatters.amount,
    ...formatters.charging,
    ...formatters.dateTime,
    ...formatters.generic,
    ...formatters.number,

    async loadData() {
      this.loading = true

      let rawLogs: Record<
        string,
        Record<string, ChargingSessionLogEntry[]>
      > = {}

      const logPromises = this.chargers.map(async (c: Charger) => {
        const result = await datasource.charging.fetchChargingSessionLogRange(
          c,
          this.from,
          this.to
        )

        if (result instanceof Error) {
          console.error(result)
        }

        return { charger: c, logs: result }
      })

      const chargersLogs = await Promise.all(logPromises)

      chargersLogs.forEach((logs) => {
        const logsByConnector: Record<string, ChargingSessionLogEntry[]> =
          _.groupBy(logs.logs, "chargerConnectorId")

        rawLogs[logs.charger.ocppIdentifier] = logsByConnector
      })

      this.rawSelectedChargingSessionLogs = rawLogs

      this.selectedChargingSessionLogs = this.toProcessedLogs(
        this.chargers,
        rawLogs
      )

      this.loading = false
    },

    toProcessedLogs(
      chargers: Charger[],
      usage: Record<string, Record<string, ChargingSessionLogEntry[]>>
    ): Record<string, unknown>[] {
      return chargers
        .map((charger) => {
          const rawUsage: Record<string, ChargingSessionLogEntry[]> =
            usage[charger.ocppIdentifier] || {}

          const connectorIds = Object.keys(rawUsage)

          if (connectorIds.length) {
            return connectorIds.map((connectorId) => {
              const usage = (rawUsage[connectorId] ||
                []) as ChargingSessionLogEntry[]

              const usageTimeseries = usage.reduce(
                (
                  timeseries: Record<
                    string,
                    Record<
                      string,
                      boolean | string | number | null | undefined | Money | Big
                    >[]
                  >,
                  datapoint: ChargingSessionLogEntry
                ) => {
                  const hour = datapoint.loggedAt
                    .toUTC()
                    .startOf("hour")
                    .toISO()

                  if (this.interval[hour]) {
                    let hourlyUsage = timeseries[hour] || []

                    if (
                      datapoint.plannedChargingPowerW ||
                      datapoint.instantaneousChargingPowerW
                    ) {
                      hourlyUsage.push({
                        planned: datapoint.plannedChargingPowerW,
                        actual: datapoint.instantaneousChargingPowerW,
                        timestamp: datapoint.loggedAt.toLocaleString(
                          DateTime.TIME_24_WITH_SECONDS
                        ),
                        isHealthy: datapoint.isHealthy,
                        smartChargingEnabled: datapoint.smartChargingEnabled,
                        vehicleSoc: datapoint.vehicleSoc,
                        ocppSoc: datapoint.ocppSoc,
                        chargingSavings: datapoint.chargingSavings,
                        vehicleDisplayName: datapoint.vehicleDisplayName,
                        chargerConnectorStatus:
                          datapoint.chargerConnectorStatus,
                        energyUsageKwh: datapoint.energyUsageWh
                          ? Big(datapoint.energyUsageWh).div(1000)
                          : null,
                        chargingPlanSource: datapoint?.chargingPlan?.source,
                        elapsedTimeH:
                          datapoint.elapsedTimeS &&
                          (datapoint.elapsedTimeS / (60 * 60)).toFixed(),
                        gridCost: datapoint.gridCost?.amount,
                        energyCost: datapoint.energyCost?.amount,
                        routeDepartureTime: datapoint.routeDepartureTime
                          ? DateTime.fromISO(
                              datapoint.routeDepartureTime
                            ).toLocaleString(DateTime.TIME_24_SIMPLE)
                          : null,
                        authorisationSource: datapoint.authorizationSource,
                        carSmartChargingEnabled:
                          datapoint.carSmartChargingEnabled,
                        chargerSmartChargingEnabled:
                          datapoint.chargerSmartChargingEnabled,
                        currentOfferedA: datapoint.currentOfferedA,
                      })
                    }

                    return { ...timeseries, [hour]: hourlyUsage }
                  } else {
                    return timeseries
                  }
                },
                {}
              )

              return {
                chargerIdentifier: charger.ocppIdentifier,
                chargerConnectorId: connectorId,
                chargerDisplayName: charger.displayName,
                chargerManagementModule: charger.managementModule,
                chargerId: charger.id,
                usageTimeseries: usageTimeseries,
              }
            })
          } else {
            return {
              chargerIdentifier: charger.ocppIdentifier,
              chargerDisplayName: charger.displayName,
              chargerManagementModule: charger.managementModule,
              chargerId: charger.id,
              usageTimeseries: [],
            }
          }
        })
        .flat()
    },

    chargerDisplay(charger: Charger): string {
      return `${charger.ocppIdentifier} - ${charger.displayName}`
    },

    title(hour: DateTime): string {
      return `Period starting at ${hour.toLocaleString(
        DateTime.DATETIME_SHORT
      )}`
    },

    hourlyUsage(usageTimeseries: Record<string, unknown>, hour: DateTime) {
      return usageTimeseries[hour.toUTC().toISO()] || []
    },

    findCharger(chargerId: string) {
      return this.chargers.find((charger) => charger.id == chargerId)
    },
  },
})

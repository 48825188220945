

























import Vue from "vue"
import VJsonEditor from "v-jsoneditor"

export default Vue.extend({
  components: {
    VJsonEditor,
  },

  props: {
    requestJson: {
      required: true,
      type: Object,
    },
    responseJson: {
      required: true,
      type: Object,
    },
  },
})





















































import Vue, { PropType } from "vue"
import Big from "big.js"
import datasource from "@/datasource"
import type { Car } from "@/datasource/cars"

function initialData() {
  return {
    showDialog: false,
    loading: false,
    saving: false,
    modified: null as Car | null,
    chargingSessionManagementStrategies: [] as string[],
    onboardChargerCapacityInput: "",
  }
}

export default Vue.extend({
  props: {
    car: {
      type: Object as PropType<Car>,
      required: true,
    },
  },

  data: initialData,

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    close() {
      Object.assign(this.$data, initialData())
    },

    onboardChargerCapacityDisplay(phases: number[] | null): string {
      return phases
        ? phases.map((p) => Big(p).div(1000).round(2)).join(",")
        : ""
    },

    onboardChargerCapacityFromDisplay(display: string | null): number[] | null {
      if (display) {
        return display
          .split(",")
          .map((p) => Big(parseFloat(p)).times(1000).toNumber())
      } else {
        return null
      }
    },

    async loadData() {
      this.loading = true

      this.modified = { ...this.car }
      this.onboardChargerCapacityInput = this.onboardChargerCapacityDisplay(
        this.car.onboardChargerCapacityPhasesW
      )

      {
        const result = await datasource.cars.getCar(this.car.id)
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.modified = result
      }

      this.loading = false
    },

    async save() {
      if (!this.modified) {
        return
      }

      this.saving = true

      try {
        {
          this.modified.onboardChargerCapacityPhasesW =
            this.onboardChargerCapacityFromDisplay(
              this.onboardChargerCapacityInput || ""
            )
          const result = await datasource.cars.updateCar(this.modified)
          if (result instanceof Error) {
            throw result
          }
        }

        this.$emit("saved")
        this.close()
      } catch (e) {
        console.error(e)
      }

      this.saving = false
    },

    resetSmartChargingEnabled() {
      if (this.modified) {
        this.modified.smartChargingEnabled = true
      }
    },
  },
})

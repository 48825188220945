





















import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import { ValidationFailureError } from "@/datasource"
import type { ValidationError } from "@/datasource"
import type { AppUser } from "@/datasource/appUsers"
import type { RfidToken } from "@/datasource/chargers"

export default Vue.extend({
  props: {
    value: {
      type: Array as PropType<RfidToken[]>,
      required: true,
    },
    appUser: {
      type: Object as PropType<AppUser>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      rfidTokens: this.value,
      errors: {} as ValidationError | Record<string, Array<string>>,
    }
  },

  methods: {
    async addRfidToken(rfidTokens: Array<RfidToken | string>) {
      this.loading = true
      const newToken = rfidTokens[rfidTokens.length - 1] as string

      const result = await datasource.chargers.addRfidToken(
        this.appUser,
        newToken
      )

      this.rfidTokens.pop()
      this.loading = false

      if (result instanceof ValidationFailureError) {
        this.errors = result.errors
      } else if (result instanceof Error) {
        console.error(result)
      } else if (result) {
        this.errors = {}
        this.rfidTokens.push(result)
      }
    },

    async deleteRfidToken(item: RfidToken) {
      this.loading = true
      const result = await datasource.chargers.deleteRfidToken(
        this.appUser,
        item
      )

      if (result instanceof ValidationFailureError) {
        this.errors = result.errors
      } else if (result instanceof Error) {
        console.error(result)
        this.errors.token = ["Could not delete token"]
      } else {
        this.errors = {}
        this.rfidTokens.splice(this.rfidTokens.indexOf(item), 1)
      }

      this.loading = false
    },
  },
})

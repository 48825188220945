var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"flex-fh flex-col flex-grow flex-clip",attrs:{"fluid":""}},[_c('VCard',{staticClass:"flex-grow flex-col flex-clip",attrs:{"loading":_vm.loading}},[_c('VCardTitle',[_c('span',[_vm._v("Cars: Detail View")]),_c('VSpacer'),_c('VBtn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.loadData()}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1)],1),_c('VCardSubtitle',[(_vm.selectedUser)?[_c('span',[_vm._v("User:"),_c('RouterLink',{attrs:{"to":{ name: 'AppUsers', params: { appUserId: _vm.selectedUser.id } }}},[_vm._v(" "+_vm._s(_vm.selectedUser.displayIdentifier))])],1)]:_vm._e(),_c('VContainer',{staticClass:"flex-grow-0",attrs:{"fluid":""}},[_c('VTextField',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","label":"Filter: Name or VIN","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],2),_c('VCardText',{staticClass:"flex-grow flex-clip"},[(_vm.loading && this.cars.length == 0)?[_c('VContainer',{staticClass:"flex-col",attrs:{"fluid":""}},_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}),1)]:[_c('VContainer',{staticClass:"flex-grow",attrs:{"fluid":""}},[_c('CollapsibleDataTable',{attrs:{"headers":_vm.headers,"items":_vm.cars,"search":_vm.search,"namespace":"car-details","sort-by":"displayName","fixed-header":"","show-select":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.appUsers",fn:function(ref){
var item = ref.item;
return [(item.appUsers.length === 0)?_c('span',[_vm._v("–")]):_vm._l((item.appUsers),function(user){return _c('div',[(!_vm.selectedUser || _vm.selectedUser.id !== user.id)?_c('RouterLink',{attrs:{"to":{ name: 'AppUserCars', params: { appUserId: user.id } }}},[_vm._v(_vm._s(user.displayIdentifier))]):_c('span',[_vm._v(_vm._s(user.displayIdentifier))])],1)})]}},{key:"item.apiData.lastRefreshedAt",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(_vm.dateTimeDiffInWords(item.apiData.lastRefreshedAt, new Date())))])]}}],null,true)},[_vm._v(_vm._s(_vm.dateTime(item.apiData.lastRefreshedAt)))])]}},{key:"item.stateOfChargeVariance",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(_vm.dateTimeDiffInWords(item.stateOfChargeVariance, new Date())))])]}}],null,true)},[_vm._v(_vm._s(_vm.dateTime(item.stateOfChargeVariance)))])]}},{key:"item.chargePreference",fn:function(ref){
var item = ref.item;
return [_c('ChargePreferenceFields',{on:{"input":function($event){return _vm.saveChargePreference(item)}},model:{value:(item),callback:function ($$v) {item=$$v},expression:"item"}})]}},{key:"item.smartChargingEnabled",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.smartChargingEnabled}})]}},{key:"item.stateOfChargeTomorrow",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.fixedFloat(item.stateOfChargeTomorrow)))]}},{key:"header.mlData.batteryCapacityKwh",fn:function(ref){
var header = ref.header;
return [_vm._v(_vm._s(header.text)),_c('VFadeTransition',{attrs:{"leave-absolute":""}},[(_vm.loading)?_c('VProgressCircular',{attrs:{"size":"24","color":"info","indeterminate":""}}):[(_vm.selected.length > 0)?_c('VBtn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.estimateBatteryCapacities()}}},[_c('VIcon',[_vm._v("mdi-cog-refresh")])],1):_vm._e()]],2)]}},{key:"item.batterySizeWh",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.powerWToKw(item.batterySizeWh)))]}},{key:"item.mlData.batteryCapacityKwh",fn:function(ref){
var item = ref.item;
return [_c('BatteryCapacityDialog',{attrs:{"vehicle":item},on:{"input":function($event){return _vm.saveMlData(item)}},model:{value:(item.mlData.batteryCapacityKwh),callback:function ($$v) {_vm.$set(item.mlData, "batteryCapacityKwh", $$v)},expression:"item.mlData.batteryCapacityKwh"}})]}},{key:"item.mlData.maxChargingPowerKw",fn:function(ref){
var item = ref.item;
return [_c('NumberValue',{attrs:{"label":"Max Charging Power (kW)","editable":""},on:{"input":function($event){return _vm.saveMlData(item)}},model:{value:(item.mlData.maxChargingPowerKw),callback:function ($$v) {_vm.$set(item.mlData, "maxChargingPowerKw", $$v)},expression:"item.mlData.maxChargingPowerKw"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('EditCarDialog',{attrs:{"car":item},on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-pen")])],1)]}}],null,true)}),_c('VMenu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('VList',[_c('VListItem',[_c('ConfirmedButton',{attrs:{"color":"red","text-color":"white"},on:{"click":function($event){return _vm.deleteCar(item)}}},[_vm._v("Delete")])],1)],1)],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]],2),_c('VSnackbar',{attrs:{"timeout":3000,"color":_vm.snackColour},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VBtn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'VBtn',attrs,false)),_vm._v("Close")]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(_vm._s(_vm.snackText))]),_c('MachineLearningLog')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
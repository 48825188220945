









import Vue from "vue"

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
})

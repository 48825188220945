export type Result<T> = T | Error

export type EntityType = {
  component: Vue
  label: string
  editable: boolean
  opts: Entity
}

export type Entity = Record<string, unknown>

export type PageInfo = {
  hasNextPage: boolean
  endCursor: string | null
}

export const emptyPageInfo: PageInfo = {
  hasNextPage: false,
  endCursor: null,
}

export const pageInfoFields = `
  hasNextPage
  endCursor
`

export const Result = {
  isOk<T>(result: Result<T>): result is T {
    return !(result instanceof Error)
  },

  map<T, U>(result: Result<T>, func: (from: T) => U): Result<U> {
    if (this.isOk(result)) {
      return func(result)
    } else {
      return result
    }
  },

  toVoid<T>(result: Result<T>): Result<void> {
    return this.map(result, () => {
      /* void */
    })
  },
}

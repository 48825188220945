












import Vue from "vue"

export default Vue.extend({
  props: {
    textColor: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      confirmed: false,
    }
  },

  computed: {
    buttonClass(): string {
      if (this.textColor.length > 0) {
        return `${this.textColor}--text`
      } else {
        return ""
      }
    },
  },

  methods: {
    activate() {
      if (this.confirmed) {
        this.$emit("click")
      }
    },
  },
})



































































import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import InvoiceNotificationResult from "@/components/InvoiceNotificationResult.vue"
import type { EmailTemplate } from "@/datasource/emails"
import type { Invoice } from "@/datasource/payments"

function initialData() {
  return {
    showDialog: false,
    sending: false,
    loading: true,
    step: 1,
    selectedEmailTemplate: null as EmailTemplate | null,
    emailTemplates: [] as EmailTemplate[],
  }
}

export default Vue.extend({
  components: {
    InvoiceNotificationResult,
  },

  props: {
    invoices: {
      type: [] as PropType<Invoice[]>,
      required: true,
    },
  },

  data: initialData,

  computed: {
    isDisabled(): boolean {
      return this.selectedEmailTemplate === null
    },
  },

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    close() {
      Object.assign(this.$data, initialData())
    },

    async loadData() {
      this.loading = true

      const result = await datasource.emails.listEmailTemplates()

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.emailTemplates = result.filter((emailTemplate: EmailTemplate) => {
        return emailTemplate.type === "INVOICE"
      })

      this.loading = false
    },
  },
})















import Vue from "vue"

export default Vue.extend({
  props: {
    value: {
      type: String,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      selected: this.value,
      items: [] as string[],
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      const result = await this.loader()

      if (result instanceof Error) {
        console.error(result)
        return
      } else {
        this.items = result
      }

      this.loading = false
    },

    emit() {
      this.$emit("input", this.selected)
    },
  },
})

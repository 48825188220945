










































import Vue from "vue"
import type { PropType } from "vue"

export default Vue.extend({
  props: {
    value: {
      type: Date as PropType<Date>,
      default: () => new Date(),
    },

    errors: {
      type: [] as PropType<Array<string>>,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "",
    },

    dateOnly: {
      type: Boolean,
      default: false,
    },

    localTime: {
      type: Boolean,
      default: false,
    },

    menuLeft: {
      type: Boolean,
      default: false,
    },

    datePickerType: {
      type: String,
      default: "date",
    },
  },

  data() {
    const timezoneOffsetMinutes = new Date().getTimezoneOffset()
    const timezoneOffsetMs = timezoneOffsetMinutes * 60 * 1000
    const utcValue = new Date()
    utcValue.setTime(this.value.getTime() - timezoneOffsetMs)

    const split = utcValue.toISOString().split("T")

    return {
      isOpen: false,
      date: split[0],
      time: split[1].slice(0, 5),
    }
  },

  computed: {
    displayDateTime(): string {
      if (this.dateOnly) {
        return `${this.date}`
      } else {
        return `${this.date} ${this.time}`
      }
    },
  },

  watch: {
    date() {
      this.emitValue()
    },

    time() {
      this.emitValue()
    },
  },

  methods: {
    close() {
      this.isOpen = false
    },

    emitValue() {
      let newValue = new Date(`${this.date}T${this.time}:00Z`)
      if (this.localTime) {
        const timezoneOffsetMinutes = new Date().getTimezoneOffset()
        const timezoneOffsetMs = timezoneOffsetMinutes * 60 * 1000
        newValue.setTime(newValue.getTime() + timezoneOffsetMs)
      }
      this.$emit("input", newValue)
    },
  },
})















import Vue from "vue"
import datasource from "@/datasource"

import EntityTableCard from "@/components/EntityTableCard.vue"
import NumberValue from "@/components/NumberValue.vue"
import StringValue from "@/components/StringValue.vue"
import EnergyValue from "@/components/EnergyValue.vue"
import DateValue from "@/components/DateValue.vue"
import NewAmsMeterDialog from "./AmsMeters/NewAmsMeterDialog.vue"

export default Vue.extend({
  components: {
    EntityTableCard,
    NewAmsMeterDialog,
  },

  data() {
    return {
      readFunction: datasource.infrastructure.listAmsMeters,
      deleteFunction: datasource.infrastructure.deleteAmsMeter,
      controls: [{ component: NewAmsMeterDialog }],
      types: {
        deviceId: {
          component: StringValue,
          label: "ID",
          props: {
            editable: false,
          },
        },
        displayName: {
          component: StringValue,
          label: "Name",
          props: {
            editable: false,
          },
        },
        voltageCorrectionFactor: {
          component: NumberValue,
          label: "Voltage Correction Factor",
          props: {
            editable: false,
          },
          opts: { align: "right" },
        },
        energyCorrectionFactor: {
          component: NumberValue,
          label: "Energy Correction Factor",
          props: {
            editable: false,
          },
          opts: { align: "right" },
        },
        latestActivePowerW: {
          component: EnergyValue,
          label: "Latest active power (kW)",
          props: {
            editable: false,
          },
          opts: { align: "right" },
        },
        latestReadTime: {
          component: DateValue,
          label: "Latest read time",
          props: {
            editable: false,
          },
        },
      },
    }
  },
})
















































































import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import Big from "big.js"
import type {
  Charger,
  ChargerApiDataConnector,
  ChargerMathematicalModelConnector,
} from "@/datasource/chargers"
import BooleanIcon from "@/components/BooleanIcon.vue"
import formatters from "@/utils/formatters"

type DataRow = {
  charger: Charger
  connector: ChargerMathematicalModelConnector & {
    phase1MaxPowerKw: string
    phase2MaxPowerKw: string
    phase3MaxPowerKw: string
    v2gPhase1MaxPowerKw: string
    v2gPhase2MaxPowerKw: string
    v2gPhase3MaxPowerKw: string
  }
}

export default Vue.extend({
  components: { BooleanIcon },

  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
  },

  computed: {
    dataRows(): DataRow[] {
      return this.charger.mathematicalModel.connectors.map(
        (connector, index) => {
          const apiData = this.charger.apiData?.connectors.find(
            (connector) => connector.order == index + 1
          )
          return {
            charger: this.charger,
            connector: {
              ...connector,
              ...apiData,
              id: index + 1,
              modified: false,
              phase1MaxPowerKw: Big(connector.phase1MaxPowerW)
                .div(1000)
                .toString(),
              phase2MaxPowerKw: Big(connector.phase2MaxPowerW)
                .div(1000)
                .toString(),
              phase3MaxPowerKw: Big(connector.phase3MaxPowerW)
                .div(1000)
                .toString(),
              v2gPhase1MaxPowerKw: connector.v2gPhase1MaxPowerW
                ? Big(connector.v2gPhase1MaxPowerW).div(1000).toString()
                : "0",
              v2gPhase2MaxPowerKw: connector.v2gPhase2MaxPowerW
                ? Big(connector.v2gPhase2MaxPowerW).div(1000).toString()
                : "0",
              v2gPhase3MaxPowerKw: connector.v2gPhase3MaxPowerW
                ? Big(connector.v2gPhase3MaxPowerW).div(1000).toString()
                : "0",
            },
          }
        }
      )
    },
  },

  methods: {
    Big,
    ...formatters.charging,

    outputPowerKwDisplay(connector: ChargerApiDataConnector): string {
      if (connector.instantaneousOutputPowerW) {
        return Big(connector.instantaneousOutputPowerW).div(1000).toString()
      } else {
        return ""
      }
    },

    outputCurrentADisplay(connector: ChargerApiDataConnector): string {
      if (connector.instantaneousOutputCurrentPhasesA) {
        return connector.instantaneousOutputCurrentPhasesA
          .map((p) => p.toString())
          .join(",")
      } else {
        return ""
      }
    },

    async saveRow(row: DataRow) {
      const newConnector = {
        phaseOrder: `${row.connector.phaseOrder.replaceAll("-", "_")}`,
        phase1MaxPowerW: Big(row.connector.phase1MaxPowerKw)
          .times(1000)
          .toNumber(),
        phase2MaxPowerW: Big(row.connector.phase2MaxPowerKw)
          .times(1000)
          .toNumber(),
        phase3MaxPowerW: Big(row.connector.phase3MaxPowerKw)
          .times(1000)
          .toNumber(),
        v2gPhase1MaxPowerW: row.connector.v2gPhase1MaxPowerKw
          ? Big(row.connector.v2gPhase1MaxPowerKw).times(1000).toNumber()
          : null,
        v2gPhase2MaxPowerW: row.connector.v2gPhase2MaxPowerKw
          ? Big(row.connector.v2gPhase2MaxPowerKw).times(1000).toNumber()
          : null,
        v2gPhase3MaxPowerW: row.connector.v2gPhase3MaxPowerKw
          ? Big(row.connector.v2gPhase3MaxPowerKw).times(1000).toNumber()
          : null,
      }

      const newConnectors = [...this.charger.mathematicalModel.connectors]
      // eslint-disable-next-line
      // @ts-ignore
      newConnectors.splice(row.connector.id - 1, 1, newConnector)

      const result = await datasource.chargers.setMathematicalModelForCharger(
        this.charger,
        {
          ...this.charger.mathematicalModel,
          connectors: newConnectors,
        }
      )

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.$emit("saved")
    },

    setRowModified(index: number) {
      const row: DataRow = this.dataRows[index]

      Vue.set(this.dataRows, index, {
        ...row,
        connector: { ...row.connector, modified: true },
      })
    },
  },
})

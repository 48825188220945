






































import Vue from "vue"
import type { PropType } from "vue"
import { ChargingSite } from "@/datasource/chargers"
import DateTimePicker from "@/components/DateTimePicker.vue"
import ChargingTimeline from "./ChargingTimeline.vue"

export default Vue.extend({
  components: {
    ChargingTimeline,
    DateTimePicker,
  },

  props: {
    chargingSite: {
      type: Object as PropType<ChargingSite>,
      required: true,
    },
  },

  data() {
    const fromDate = new Date()
    const day = 1000 * 60 * 60 * 24
    const toDate = new Date(fromDate.getTime() + day)

    return {
      loading: false,
      loadedTime: new Date(),
      fromDate,
      toDate,
    }
  },

  methods: {
    async loadData() {
      this.loadedTime = new Date()
    },
  },
})
















import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import Big from "big.js"
import type { CarSummary } from "@/datasource/cars"

export default Vue.extend({
  props: {
    car: {
      type: Object as PropType<CarSummary>,
      required: true,
    },
  },

  data() {
    return {
      onboardChargerCapacityInput: "",
      edited: false,
    }
  },

  created() {
    this.onboardChargerCapacityInput = this.onboardChargerCapacityKWh(this.car)
  },

  methods: {
    async saveOnboardChargerCapacity() {
      const capacity = this.onboardChargerCapacityFromDisplay(
        this.onboardChargerCapacityInput
      )
      this.car.onboardChargerCapacityPhasesW = capacity

      const result = await datasource.cars.updateCar(this.car)
      if (result instanceof Error) {
        console.error(result)
      }

      this.$emit("saved")
      this.edited = false
    },

    onboardChargerCapacityKWh(car: CarSummary): string {
      if (car.onboardChargerCapacityPhasesW) {
        return car.onboardChargerCapacityPhasesW
          .map((p) => Big(p).div(1000).toString())
          .join(",")
      } else {
        return ""
      }
    },

    markEdited() {
      this.edited = true
    },

    onboardChargerCapacityFromDisplay(display: string | null): number[] | null {
      if (display) {
        return display
          .split(",")
          .map((p) => Big(parseFloat(p)).times(1000).toNumber())
      } else {
        return null
      }
    },
  },
})


















import Vue from "vue"
import type { PropType } from "vue"
import type {
  Charger,
  ConfigurationProfileSummary,
} from "@/datasource/chargers"
import _ from "underscore"

import datasource from "@/datasource"
import ChargerActions from "@/components/ChargerActions.vue"
import ChargerConfigurationProfileSelect from "@/components/ChargerConfigurationProfileSelect.vue"
import ConfigurationDialog from "./ChargerActionsMenu/ConfigurationDialog.vue"
import ConfirmedButton from "@/components/ConfirmedButton.vue"
import DebugDataDialog from "./ChargerActionsMenu/DebugDataDialog.vue"
import OcppDebugger from "./ChargerActionsMenu/OcppDebugger.vue"

function initialData() {
  return {
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    profilesToSet: [],
    showDialog: false,
    configurationSaving: false,
    confirmed: false,
  }
}

export default Vue.extend({
  components: {
    DebugDataDialog,
    ChargerActions,
    ChargerConfigurationProfileSelect,
    ConfigurationDialog,
    ConfirmedButton,
    OcppDebugger,
  },

  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
    multipleChargers: {
      type: Array as PropType<Charger[]>,
      required: false,
      default() {
        return []
      },
    },
  },

  data: initialData,

  methods: {
    async showSnackbar(text: string, color?: string) {
      this.snackbarText = text
      this.snackbarColor = color || "success"
      this.snackbar = true
    },

    async setCableLocked(value: boolean) {
      const result = await datasource.chargers.setChargerCableLocked(
        this.charger,
        1,
        value
      )
      if (result instanceof Error) {
        console.error(result)
        this.showSnackbar("Failed to set cable locked state.", "error")
        return
      }

      this.showSnackbar("Sent command to set cable locked state.")
      this.$emit("change")
    },

    async refreshApiData() {
      const result = await datasource.chargers.refreshChargerApiData(
        this.charger
      )
      if (result instanceof Error) {
        console.error(result)
        this.showSnackbar("Failed to refresh API data.", "error")
        return
      }

      this.showSnackbar("Refreshed API data.")
      this.$emit("change")
    },

    async rebootCharger() {
      const result = await datasource.chargers.rebootCharger(this.charger)
      if (result instanceof Error) {
        console.error(result)
        this.showSnackbar("Failed to reboot charger.", "error")
        return
      }

      this.showSnackbar("Sent reboot command to charger.")
      this.$emit("change")
    },

    async deleteCharger() {
      const result = await datasource.chargers.deleteCharger(this.charger)

      if (result instanceof Error || !result) {
        console.error(result)
        this.showSnackbar("Failed to delete charger.", "error")
        return
      }

      this.$emit("delete")
    },

    async applyConfigurationProfiles() {
      this.configurationSaving = true
      const newProfiles: ConfigurationProfileSummary[] = this.profilesToSet

      const modifiedChargers = this.multipleChargers.map((c) => {
        const currentProfiles = _.get(
          c,
          "directConfigurationProfiles",
          []
        ) as ConfigurationProfileSummary[]

        const modifiedProfiles = _.uniq(
          newProfiles.concat(currentProfiles),
          false,
          (p: ConfigurationProfileSummary) => p.id
        ) as ConfigurationProfileSummary[]

        return {
          ...c,
          directConfigurationProfiles: modifiedProfiles,
        }
      })

      await modifiedChargers.forEach((c) =>
        datasource.chargers.updateCharger(c)
      )

      this.configurationSaving = false
    },

    async overwriteCongfigurationProfiles() {
      this.configurationSaving = true

      const modifiedChargers = this.multipleChargers.map((c) => {
        return {
          ...c,
          directConfigurationProfiles: this.profilesToSet,
        }
      })

      await modifiedChargers.forEach((c) =>
        datasource.chargers.updateCharger(c)
      )

      this.configurationSaving = false
    },

    close() {
      Object.assign(this.$data, initialData())
    },
  },
})










import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type {
  Charger,
  ChargingSiteMathematicalModel,
} from "@/datasource/chargers"
import FuseFields from "@/views/ChargingSites/FuseFields.vue"

export default Vue.extend({
  components: { FuseFields },

  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
  },

  data() {
    return {
      chargingSiteMathematicalModel:
        null as null | ChargingSiteMathematicalModel,
    }
  },

  computed: {
    highlightedFuseId(): string {
      return this.charger?.mathematicalModel?.fuse?.id?.toString() || ""
    },
  },

  watch: {
    charger() {
      this.loadData()
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      if (this.charger?.chargingSite) {
        const result =
          await datasource.chargers.getChargingSiteMathematicalModel(
            this.charger?.chargingSite
          )

        if (result instanceof Error) {
          console.error(result)
          return
        }

        this.chargingSiteMathematicalModel = result
      }
    },
  },
})







































import Vue from "vue"
import datasource from "@/datasource"
import { ValidationFailureError } from "@/datasource"
import type { AmsMeter } from "@/datasource/infrastructure"

import AmsMeterFields from "./AmsMeterFields.vue"

function initialData() {
  return {
    showDialog: false,
    loading: false,
    saving: false,
    isFormValid: false,
    modifiedAmsMeter: {} as AmsMeter,
    modifiedAmsMeterErrors: {},
  }
}

export default Vue.extend({
  components: {
    AmsMeterFields,
  },

  data: initialData,

  methods: {
    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true

      const result = await datasource.infrastructure.createAmsMeter(
        this.modifiedAmsMeter
      )

      if (result instanceof ValidationFailureError) {
        this.modifiedAmsMeterErrors = result.errors
      } else if (result instanceof Error) {
        console.error(result)
      } else {
        this.$emit("saved")
        this.close()
      }

      this.saving = false
    },
  },
})

import datasource from "@/datasource"
import { Result } from "@/utils"

export default {
  async ping(): Promise<Result<void>> {
    type Response = {
      data?: {
        __schema?: {
          queryType?: {
            name: string
          }
        }
      }
    }
    const response = (await datasource.graphql(
      `
      query {
        __schema {
          queryType {
            name
          }
        }
      }
    `
    )) as Response
    return Result.toVoid(response)
  },

  async listEnumOptionsFlexibility(typeName: string): Promise<string[]> {
    type EnumValue = {
      name: string
    }
    type Response = {
      data?: {
        __type?: {
          enumValues?: EnumValue[]
        }
      }
    }
    const response = (await datasource.graphql_flexibility(
      `
      query($name: String!) {
        __type(name: $name) {
          enumValues {
            name
          }
        }
      }
    `,
      {
        name: typeName,
      }
    )) as Response
    const enumValues: EnumValue[] = response.data?.__type?.enumValues || []
    return enumValues.map((value) => value.name)
  },

  async listEnumOptions(typeName: string): Promise<string[]> {
    type EnumValue = {
      name: string
    }
    type Response = {
      data?: {
        __type?: {
          enumValues?: EnumValue[]
        }
      }
    }
    const response = (await datasource.graphql(
      `
      query($name: String!) {
        __type(name: $name) {
          enumValues {
            name
          }
        }
      }
    `,
      {
        name: typeName,
      }
    )) as Response
    const enumValues: EnumValue[] = response.data?.__type?.enumValues || []
    return enumValues.map((value) => value.name)
  },
}

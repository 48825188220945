



















































import Vue from "vue"
import type { PropType } from "vue"
import _ from "underscore"
import datasource from "@/datasource"
import helpers from "@/utils/helpers"
import { DateTime, Duration } from "luxon"
import type {
  ChargingSession,
  FinishedChargingSession,
} from "@/datasource/charging"

function isActiveSession(
  session: ChargingSession | FinishedChargingSession
): session is ChargingSession {
  return (session as FinishedChargingSession).finishedAt === undefined
}

export default Vue.extend({
  props: {
    chargingSession: {
      type: Object as PropType<ChargingSession | FinishedChargingSession>,
      required: true,
    },
    fromDate: {
      type: String,
      required: false,
      default: null,
    },
    toDate: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    dashboardFromTimestamp(): Date | null {
      if (this.fromDate) {
        const fromTimestamp = new Date(this.fromDate)
        fromTimestamp.setHours(0, 0, 0, 0)

        return fromTimestamp
      } else {
        return null
      }
    },

    dashboardToTimestamp(): Date | null {
      if (this.toDate) {
        const toTimestamp = new Date(this.toDate)
        toTimestamp.setHours(23, 59, 59, 999)

        return toTimestamp
      } else {
        return null
      }
    },

    amqpDashboardUrl(): string | void {
      if (!this.chargingSession?.charger?.ocppIdentifier) {
        return
      }

      const options =
        this.dashboardFromTimestamp && this.dashboardToTimestamp
          ? {
              fromDate: this.dashboardFromTimestamp,
              toDate: this.dashboardToTimestamp,
            }
          : undefined

      return helpers.amqpDashboardUrl(
        this.chargingSession.charger.ocppIdentifier,
        options
      )
    },

    ocppDashboardUrl(): string | void {
      if (!this.chargingSession?.charger?.ocppIdentifier) {
        return
      }

      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_OCPP_LOG_INDEX,
        {
          filters: [
            `match_phrase:(charger_identifier.keyword:${this.chargingSession?.charger?.ocppIdentifier})`,
          ],
          time: this.dashboardTimeFilter(),
          columns: [
            "charger_identifier",
            "event",
            "direction",
            "message_type",
            "action",
            "payload",
          ],
        }
      )
    },

    chargingSessionLatestUrl(): string | void {
      if (!this.chargingSession?.charger?.id) {
        return
      }

      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_CHARGING_SESSIONS_2_INDEX,
        {
          filters: [
            `match_phrase:(charging_session_id:${this.chargingSession?.id})`,
          ],
          time: this.chargingSessionTimeFilter(),
          columns: [
            "started_at",
            "charging_session_id",
            "charging_site_id",
            "charging_plan_id",
            "authorized",
            "smart_charging_enabled",
            "is_healthy",
            "session_status",
          ],
        }
      )
    },

    chargingSessionLogsUrl(): string | void {
      if (!this.chargingSession?.charger?.id) {
        return
      }

      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_CHARGING_SESSION_LOG_INDEX,
        {
          filters: [
            `match_phrase:(charging_session_id:${this.chargingSession?.id})`,
          ],
          time: this.chargingSessionTimeFilter(),
          columns: [
            "started_at",
            "charging_session_id",
            "charging_site_id",
            "charging_plan_id",
            "authorized",
            "smart_charging_enabled",
            "is_healthy",
            "session_status",
          ],
        }
      )
    },

    chargerApiDataUrl(): string | void {
      if (!this.chargingSession?.charger?.id) {
        return
      }

      const startedAt =
        (this.chargingSession as ChargingSession).createdAt !== undefined
          ? (this.chargingSession as ChargingSession).createdAt
          : (this.chargingSession as FinishedChargingSession).startedAt

      const startTime = DateTime.fromISO(startedAt)
        .setZone("utc")
        .minus(Duration.fromISO("PT1H"))
      const timeOption = `from:'${startTime.toISO()}',to:now`

      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_CHARGER_API_DATA_INDEX,
        {
          filters: [
            `match_phrase:(ocpp_identifier.keyword:${this.chargingSession?.charger?.ocppIdentifier})`,
          ],
          time: timeOption,
        }
      )
    },

    rfidAuthAttemptsUrl(): string | void {
      if (!this.chargingSession.charger) {
        return
      }
      const ocpp_id = this.chargingSession.charger.ocppIdentifier
      const url = helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_RFID_TOKEN_ATTEMPTS_INDEX,
        {
          filters: [`match_phrase:(ocpp_identifier:${ocpp_id})`],
          time: this.chargingSessionTimeFilter(),
          columns: ["ocpp_identifier", "charger_display_name", "token"],
        }
      )
      return url
    },
  },

  methods: {
    async openEnforcementLog(): Promise<void> {
      const url = await this.getEnforcementLogUrl()
      if (url) {
        window.open(url, "_blank")
      }
    },

    dashboardTimeFilter(): string {
      if (this.dashboardFromTimestamp && this.dashboardToTimestamp) {
        const fromString = this.dashboardFromTimestamp.toISOString()
        const toString = this.dashboardToTimestamp.toISOString()
        return `from:'${fromString}',to:'${toString}'`
      } else {
        return "from:now-7d,to:now"
      }
    },

    chargingSessionTimeFilter(): string {
      const startedAt = isActiveSession(this.chargingSession)
        ? this.chargingSession.createdAt
        : this.chargingSession.startedAt
      const startTime = DateTime.fromISO(startedAt).setZone("utc")
      return `from:'${startTime}',to:'now'`
    },

    async getEnforcementLogUrl(): Promise<string | null> {
      const logEntries = isActiveSession(this.chargingSession)
        ? await datasource.charging.listLogEntriesForChargingSession(
            this.chargingSession
          )
        : await datasource.charging.listLogEntriesForFinishedChargingSession(
            this.chargingSession
          )
      if (logEntries instanceof Error) {
        console.error(logEntries)
        return null
      }
      const chargingPlanIds = _.uniq(
        logEntries
          .map((entry) => entry.chargingPlan?.id)
          .filter((entry) => entry && entry.length > 0)
      )
      if (chargingPlanIds.length > 0) {
        const query = chargingPlanIds.join(" OR ")
        const fromTimeString: string = isActiveSession(this.chargingSession)
          ? this.chargingSession.createdAt
          : this.chargingSession.startedAt
        return helpers.kibanaDiscoverUrl(
          process.env.VUE_APP_ELASTICSEARCH_KERNEL_LOG_INDEX,
          {
            filters: ["match_phrase:(label.keyword:enforce_charging_plan)"],
            time: `from:'${fromTimeString}',to:'now'`,
            query,
            columns: ["message"],
          }
        )
      } else {
        return null
      }
    },
  },
})

































































import Vue from "vue"
import datasource from "@/datasource"

import type { WebUser } from "@/datasource/webUsers"
import type { ChargingSite } from "@/datasource/chargers"

import ConfirmedButton from "@/components/ConfirmedButton.vue"
import WebUserEmail from "@/components/WebUserEmail.vue"
import WebUserSignupDialog from "@/components/WebUserSignupDialog.vue"
import WebUserRoles from "@/components/WebUserRoles.vue"
import WebUserChargingSites from "@/components/WebUserChargingSites.vue"

export default Vue.extend({
  components: {
    ConfirmedButton,
    WebUserChargingSites,
    WebUserEmail,
    WebUserSignupDialog,
    WebUserRoles,
  },

  data() {
    return {
      loading: true,
      webUsers: [] as Array<WebUser>,
      headers: [
        { text: "ID", value: "id" },
        { text: "Email", value: "email", width: "500" },
        { text: "Roles", value: "roles", width: "400" },
        {
          text: "Charging Sites",
          value: "permittedChargingSites",
          width: "700",
        },
        {
          text: "",
          value: "menu",
          width: 0,
          align: "right",
          sortable: false,
        },
      ],
      availableRoles: [] as Array<string>,
      availableChargingSites: [] as Array<ChargingSite>,
    }
  },

  created() {
    this.loadData()
    this.loadAvailableRoles()
    this.loadAvailableChargingSites()
  },

  methods: {
    async loadData() {
      const result = await datasource.webUsers.listWebUsers()

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.webUsers = result
      this.loading = false
    },

    async loadAvailableRoles() {
      const result = await datasource.webUsers.getAvailableRoles()

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.availableRoles = result
    },

    async loadAvailableChargingSites() {
      const result = await datasource.chargers.listChargingSites()

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.availableChargingSites = result
    },

    async deleteWebUser(webUser: WebUser) {
      const result = await datasource.webUsers.deleteWebUser(webUser)

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.loadData()
    },
  },
})





















































































import Vue from "vue"
import datasource from "@/datasource"
import type {
  GridOperator,
  CreateGridOperatorInput,
  EditGridOperatorInput,
} from "@/datasource/chargers"
import InlineSaveInputField from "@/components/InlineSaveInputField.vue"

export default Vue.extend({
  components: { InlineSaveInputField },
  data() {
    return {
      loading: true,
      gridOperators: [] as Array<GridOperator>,
      headers: [
        { text: "Name", width: "10%", value: "name" },
        {
          text: "Marginal Grid Rate (NOK/kw)",
          value: "marginalGridTariffRatePerKw",
          width: "10%",
        },
        { value: "actions", width: "10%", sortable: false },
        {},
      ],
      showCreateDialog: false,
      showEditDialog: false,
      savingGridOperator: false,
      newGridOperator: {} as Record<string, never> | CreateGridOperatorInput,
      modifiedGridOperator: {} as Record<string, never> | EditGridOperatorInput,
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true
      const result = await datasource.chargers.listGridOperators()

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.gridOperators = result
      this.loading = false
    },

    tariffRateInput(operator: GridOperator): string {
      return operator.marginalGridTariffRatePerKw
        ? operator.marginalGridTariffRatePerKw.amount
        : ""
    },

    async saveTariffRate(rate: string, operator: GridOperator) {
      const result = await datasource.chargers.updateGridOperator({
        ...operator,
        marginalGridTariffRatePerKw: { amount: rate, currency: "NOK" },
      })

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.loadData()
    },

    openCreateDialog() {
      this.showCreateDialog = true
    },

    openEditDialog(operator: GridOperator) {
      this.showEditDialog = true
      this.modifiedGridOperator = { ...operator }
    },

    closeCreationDialog() {
      this.showCreateDialog = false
    },

    closeEditDialog() {
      this.showEditDialog = false
      this.modifiedGridOperator = {}
    },

    async saveNewGridOperator(operator: CreateGridOperatorInput) {
      await datasource.chargers.createGridOperator(operator)
      this.showCreateDialog = false
      this.newGridOperator = {}
      this.loadData()
    },

    async updateGridOperator(operator: EditGridOperatorInput) {
      await datasource.chargers.updateGridOperator(operator)
      this.showEditDialog = false
      this.modifiedGridOperator = {}
      this.loadData()
    },
  },
})

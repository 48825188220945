













































































































































































































































































































import Vue from "vue"
import Big from "big.js"
import datasource from "@/datasource"
import helpers from "@/utils/helpers"
import formatters from "@/utils/formatters"
import type {
  Invoice,
  InvoiceLine,
  InvoiceBreakdown,
  AppUserRecipient,
  Money,
} from "@/datasource/payments"
import { InvoiceState } from "@/datasource/payments"
import type { AppUser } from "@/datasource/appUsers"
import type { ChargingSite } from "@/datasource/chargers"

import InvoiceGenerationForm from "@/components/InvoiceGenerationForm.vue"
import DynamicNotificationDialog from "@/views/Invoices/DynamicNotificationDialog.vue"
import ManualInvoiceDialog from "@/components/ManualInvoiceDialog.vue"
import MoneyValue from "@/components/MoneyValue.vue"
import BooleanIcon from "@/components/BooleanIcon.vue"
import ConfirmedButton from "@/components/ConfirmedButton.vue"
import InvoiceLineTable from "@/components/InvoiceLineTable.vue"
import StateNavigationButton from "@/views/Invoices/StateNavigationButton.vue"
import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"

import { stringify as csvStringify } from "csv-stringify"
import FileSaver from "file-saver"

export default Vue.extend({
  components: {
    InvoiceGenerationForm,
    DynamicNotificationDialog,
    ManualInvoiceDialog,
    MoneyValue,
    BooleanIcon,
    ConfirmedButton,
    InvoiceLineTable,
    StateNavigationButton,
    ChargingSiteSelect,
  },

  data() {
    return {
      loading: true,
      paymentMethodLoading: false,
      expanded: [],
      search: "",
      invoices: [] as Invoice[],
      chargingSites: [] as ChargingSite[],
      selected: [] as Invoice[],
      selectedUser: null as AppUser | null,
      state: "" as InvoiceState,
      rawHeaders: [
        { text: "ID", width: 0, value: "id", class: "header-no-wrap" },
        {
          text: "Recipient",
          value: "recipient",
          isAppUserHeader: true,
          class: "header-no-wrap",
        },
        {
          text: "Email",
          value: "recipient.email",
          isAppUserHeader: true,
          class: "header-no-wrap",
        },
        {
          text: "Chargers",
          value: "chargers",
          class: "header-no-wrap",
        },
        {
          text: "Site",
          value: "chargingSite",
          class: "header-no-wrap",
        },
        {
          text: "Flexibility Fee",
          value: "flexibilityFee",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Flexibility Fee Tax",
          value: "flexibilityFeeTax",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Gross Flexibility Fee",
          value: "grossFlexibilityFee",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Processing fee",
          value: "processingFee.amount",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Gross Total",
          value: "grossTotal.amount",
          align: "right",
          class: "header-no-wrap",
        },
        { text: "Sent", value: "sent", class: "header-no-wrap", width: 0 },
        { text: "Paid", value: "paid", class: "header-no-wrap", width: 0 },
        {
          text: "Start Energy (kWh)",
          value: "startMeterReadingKwh",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "End Energy (kWh)",
          value: "endMeterReadingKwh",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Energy Used (kWh)",
          value: "energyUsed",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Energy Cost Sum",
          value: "energyCost",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Offline Charging Usage (kWh)",
          value: "offlineChargingUsageKwh",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Offline Charging Fee",
          value: "offlineChargingFee",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Excess Energy Usage (kWh)",
          value: "excessEnergyUsageKwh",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Excess Energy Fee",
          value: "excessEnergyFee",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Grid Fee",
          value: "gridFee",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Variable Energy Fee (kr/kWh)",
          value: "variableEnergyFee",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Energy Rebate",
          value: "energyRebate",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Authorization ID",
          value: "authorizationRequestId",
          class: "header-no-wrap",
        },
        {
          text: "Authorization ID suffix",
          value: "authorizationRequestIdSuffix",
          class: "header-no-wrap",
        },
        {
          text: "Payment Methods",
          value: "paymentMethods",
          class: "header-no-wrap",
        },
        {
          text: "Tax Systems",
          value: "taxSystems",
          class: "header-no-wrap",
        },
        { text: "Start date", value: "startDate", class: "header-no-wrap" },
        { text: "End date", value: "endDate", class: "header-no-wrap" },
        { text: "Generated On", value: "insertedAt", class: "header-no-wrap" },
        {
          text: "Times Seen",
          value: "seenCount",
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "",
          value: "menu",
          width: 0,
          align: "right",
          sortable: false,
          class: "header-no-wrap",
        },
      ],
    }
  },

  computed: {
    chargingSiteIds(): string[] {
      return this.chargingSites
        .map((site) => site.id)
        .filter(helpers.rejectEmpty)
    },

    shouldLoad(): boolean {
      return (
        (typeof this.selectedUser !== "undefined" &&
          this.selectedUser !== null) ||
        (this.chargingSites && this.chargingSites.length > 0)
      )
    },

    initialTotalsColspan(): number {
      if (this.selectedUser) {
        return 5
      } else {
        return 7
      }
    },

    headers(): Record<string, unknown>[] {
      return this.rawHeaders.filter(
        (header) => !(this.selectedUser && header.isAppUserHeader)
      )
    },

    invoiceTableData(): Record<
      string,
      Invoice[] | Record<string, string | Money>
    > {
      const items = this.invoices.map((i: Invoice) => {
        i.chargingSite = this.chargingSite(i)
        i.chargers = this.chargers(i)
        i.energyUsed = this.fixedFloat(this.energyUsed(i), 1)

        i.startDate = new Date(
          Math.min(...i.lines.map((line) => line.startDate.getTime()))
        )

        i.endDate = new Date(
          Math.max(...i.lines.map((line) => line.endDate.getTime()))
        )

        i.startMeterReadingKwh = i.lines.reduce(
          (total, line) => total + line.startMeterReadingKwh,
          0.0
        )

        i.endMeterReadingKwh = i.lines.reduce(
          (total, line) => total + line.endMeterReadingKwh,
          0.0
        )

        i.energyCost = i.lines.reduce((total, line) => {
          return total
            .plus(line.energyCost.amount)
            .plus(line.energyCostTax.amount)
        }, Big(0))

        i.offlineChargingUsageKwh = this.offlineChargingUsage(i)
        i.excessEnergyUsageKwh = this.excessEnergyUsage(i)

        i.offlineChargingFee = i.lines.reduce((total, line) => {
          return total
            .plus(line.offlineChargingFee.amount)
            .plus(line.offlineChargingFeeTax.amount)
        }, Big(0))

        i.excessEnergyFee = i.lines.reduce((total, line) => {
          return total
            .plus(line.excessEnergyFee.amount)
            .plus(line.excessEnergyFeeTax.amount)
        }, Big(0))

        i.gridFee = i.lines.reduce((total, line) => {
          return total.plus(line.gridFee.amount).plus(line.gridFeeTax.amount)
        }, Big(0))

        i.variableEnergyFee = i.lines.reduce((total, line) => {
          return total.plus(this.variableEnergyFee(line))
        }, Big(0))

        i.energyRebate = i.lines.reduce(
          (total, line) => total.plus(line.energyRebate?.amount || 0),
          Big(0)
        )

        i.flexibilityFee = i.lines.reduce(
          (total, line) => total.plus(line.flexibilityFee.amount),
          Big(0)
        )

        i.flexibilityFeeTax = i.lines.reduce(
          (total, line) => total.plus(line.flexibilityFeeTax.amount),
          Big(0)
        )

        i.authorizationRequestIdSuffix =
          i.paymentRequest?.authorizationRequestId?.split(".")[1] || "—"

        i.authorizationRequestId =
          i.paymentRequest?.authorizationRequestId || "—"

        i.paymentMethods = i.paymentRequest?.paymentMethods || "—"

        i.taxSystems = i.lines
          .map((line) => line.settings.taxSystem)
          .filter(helpers.rejectEmpty)
          .join(", ")

        i.grossFlexibilityFee = i.flexibilityFee.plus(i.flexibilityFeeTax)

        return i
      })

      const totals = items.reduce(
        (totals, i: Invoice) => {
          totals.flexibilityFee = totals.flexibilityFee.plus(
            i.flexibilityFee || Big(0)
          )

          totals.flexibilityFeeTax = totals.flexibilityFeeTax.plus(
            i.flexibilityFeeTax || Big(0)
          )

          totals.grossFlexibilityFee = totals.grossFlexibilityFee.plus(
            i.grossFlexibilityFee || Big(0)
          )

          totals.grossTotal = totals.grossTotal.plus(i.grossTotal.amount)

          totals.processingFee = totals.processingFee.plus(
            i.processingFee.amount
          )

          totals.offlineChargingUsage += this.offlineChargingUsage(i)

          totals.offlineChargingFee = totals.offlineChargingFee.plus(
            i.offlineChargingFee || Big(0)
          )

          totals.excessEnergyUsage += this.excessEnergyUsage(i)

          totals.excessEnergyFee = totals.excessEnergyFee.plus(
            i.excessEnergyFee || Big(0)
          )

          totals.energyUsed += this.energyUsed(i)

          totals.energyCost = totals.energyCost.plus(i.energyCost || Big(0))

          totals.gridFee = totals.gridFee.plus(i.gridFee || Big(0))

          totals.variableEnergyFee = totals.variableEnergyFee.plus(
            i.variableEnergyFee || Big(0)
          )

          totals.energyRebate = totals.energyRebate.plus(
            i.energyRebate || Big(0)
          )

          return totals
        },
        {
          flexibilityFee: Big(0),
          flexibilityFeeTax: Big(0),
          grossFlexibilityFee: Big(0),
          grossTotal: Big(0),
          processingFee: Big(0),
          offlineChargingUsage: 0,
          offlineChargingFee: Big(0),
          excessEnergyUsage: 0,
          excessEnergyFee: Big(0),
          energyUsed: 0,
          energyCost: Big(0),
          gridFee: Big(0),
          variableEnergyFee: Big(0),
          energyRebate: Big(0),
        }
      )

      return {
        items,
        totals: {
          flexibilityFee: totals.flexibilityFee.toFixed(2),
          flexibilityFeeTax: totals.flexibilityFeeTax.toFixed(2),
          grossFlexibilityFee: totals.grossFlexibilityFee.toFixed(2),
          grossTotal: totals.grossTotal.toFixed(2),
          processingFee: totals.processingFee.toFixed(2),
          offlineChargingUsage: this.fixedFloat(totals.offlineChargingUsage, 1),
          offlineChargingFee: totals.offlineChargingFee.toFixed(2),
          excessEnergyUsage: this.fixedFloat(totals.excessEnergyUsage, 1),
          excessEnergyFee: totals.excessEnergyFee.toFixed(2),
          energyUsed: this.fixedFloat(totals.energyUsed, 1),
          energyCost: totals.energyCost.toFixed(2),
          gridFee: totals.gridFee.toFixed(2),
          variableEnergyFee: totals.variableEnergyFee.toFixed(2),
          energyRebate: totals.energyRebate.toFixed(2),
        },
      }
    },
  },

  watch: {
    $route() {
      this.selected = []
      this.loadData()
    },
  },

  created() {
    this.loadData()
  },

  mounted() {
    if (this.$route.query.siteIds) {
      let siteIds = this.$route.query.siteIds
      siteIds = Array.isArray(siteIds) ? siteIds : [siteIds]
      siteIds = siteIds.filter(helpers.rejectEmpty)
      this.setSelectedChargingSites(siteIds as string[])

      this.$nextTick(() => this.loadData())
    }
  },

  methods: {
    ...formatters.number,
    ...formatters.charging,
    ...formatters.amount,
    ...formatters.dateTime,
    ...formatters.generic,

    recipientSearch(search: string | null, item: Invoice) {
      return (
        search &&
        item &&
        (item.recipient.email.toLocaleLowerCase().indexOf(search) !== -1 ||
          this.recipientName(item.recipient)
            .toLocaleLowerCase()
            .indexOf(search) !== -1)
      )
    },

    chargingSiteSearch(search: string | null, item: Invoice) {
      return (
        search && item?.chargingSite?.toLocaleLowerCase().indexOf(search) !== -1
      )
    },

    filterOnlyCapsText(
      value: string | null,
      search: string | null,
      item: Invoice
    ) {
      search = search ? search.toLocaleLowerCase() : search

      return (
        this.recipientSearch(search, item) ||
        this.chargingSiteSearch(search, item) ||
        (value != null &&
          search != null &&
          typeof value !== "boolean" &&
          value.toString().toLocaleLowerCase().indexOf(search) !== -1)
      )
    },

    async setSelectedChargingSites(siteIds: string[]) {
      this.chargingSites = (
        await Promise.all(
          siteIds.map(async (siteId) => {
            const result = await datasource.chargers.getChargingSite(siteId)

            if (result instanceof Error) {
              console.error(result)
              return null
            }

            return result
          })
        )
      ).filter(helpers.rejectEmpty)
    },

    async updateSelectedSites() {
      this.$router.replace({
        path: this.$route.path,
        query: { siteIds: this.chargingSiteIds },
      })

      this.loadData()
    },

    async loadData() {
      const { appUserId, state } = this.$route.params

      this.state = state as InvoiceState
      this.selectedUser = null
      this.loading = true

      if (appUserId) {
        const appUserResult = await datasource.appUsers.getAppUser(appUserId)

        if (appUserResult instanceof Error || !appUserResult) {
          console.error(appUserResult)
          return
        }

        this.selectedUser = appUserResult
      }

      if (this.selectedUser) {
        this.chargingSites = []

        const result = await datasource.payments.listInvoicesForRecipient(
          this.selectedUser,
          state.toUpperCase() as InvoiceState
        )

        if (result instanceof Error) {
          console.error(result)
          return
        }

        this.invoices = result
      } else if (this.chargingSites && this.chargingSites.length > 0) {
        const result = await this.fetchInvoices(
          state.toUpperCase() as InvoiceState
        )

        if (result instanceof Error) {
          console.error(result)
          return
        }

        this.invoices = result
      }

      this.loading = false
    },

    async fetchInvoices(state: InvoiceState) {
      return datasource.payments.listInvoicesForChargingSites(
        this.chargingSiteIds,
        state
      )
    },

    async sendInvoice(item: Invoice) {
      if (this.selected.length > 0) {
        await Promise.all(
          this.selected.map((item) => datasource.payments.sendInvoice(item))
        )
      } else {
        await datasource.payments.sendInvoice(item)
      }

      this.search = ""
      this.selected = []
      this.loadData()
    },

    async emailInvoice(item: Invoice) {
      if (this.selected.length > 0) {
        await Promise.all(
          this.selected.map((item) => datasource.payments.emailInvoice(item))
        )
      } else {
        await datasource.payments.emailInvoice(item)
      }

      this.loadData()
    },

    async moveInvoicesToDrafts(item: Invoice) {
      if (this.selected.length > 0) {
        await datasource.payments.moveInvoicesToDrafts(this.selected)
      } else {
        await datasource.payments.moveInvoicesToDrafts([item])
      }

      this.search = ""
      this.selected = []
      this.loadData()
    },

    async updateCapturedTotals(item: Invoice) {
      if (this.selected.length > 0) {
        await Promise.all(
          this.selected.map((item) =>
            datasource.payments.updateCapturedTotal(item)
          )
        )
      } else {
        await datasource.payments.updateCapturedTotal(item)
      }

      this.search = ""
      this.selected = []
      this.loadData()
    },

    async deleteInvoice(item: Invoice) {
      if (this.selected.length > 0) {
        await Promise.all(
          this.selected.map((item) => datasource.payments.deleteInvoice(item))
        )
      } else {
        await datasource.payments.deleteInvoice(item)
      }

      this.search = ""
      this.selected = []
      this.loadData()
    },

    isPaid(invoice: Invoice) {
      return (
        invoice.grossTotal.display === invoice.paid && invoice.paymentRequest
      )
    },

    recipientName(recipient: AppUser | AppUserRecipient) {
      return [recipient.firstName, recipient.lastName]
        .filter((x) => !!x)
        .join(" ")
    },

    chargers(invoice: Invoice) {
      return invoice.lines.map((line) => line.chargers).join(" ")
    },

    offlineChargingUsage(invoice: Invoice) {
      return invoice.lines.reduce((total, line) => {
        return total + line.offlineChargingUsageKwh
      }, 0)
    },

    excessEnergyUsage(invoice: Invoice) {
      return invoice.lines.reduce((total, line) => {
        return total + line.excessEnergyUsageKwh
      }, 0)
    },

    energyUsed(invoice: Invoice) {
      return invoice.lines.reduce((total, line) => {
        return total + this.lineEnergyUsed(line)
      }, 0)
    },

    lineEnergyUsed(line: InvoiceLine) {
      return (
        line.endMeterReadingKwh -
        line.startMeterReadingKwh +
        line.excessEnergyUsageKwh
      )
    },

    invoiceForBreakdown(
      invoices: Invoice[],
      breakdown: InvoiceBreakdown
    ): Invoice | undefined {
      return invoices.find((invoice) =>
        invoice.lines.some((line) => line.id === breakdown.lineId)
      )
    },

    variableEnergyFee(line: InvoiceLine): Big {
      return Big(line.endMeterReadingKwh)
        .minus(line.startMeterReadingKwh)
        .times(line.settings.chargingSiteFeeKwh.amount)
    },

    chargingSite(item: Invoice) {
      for (let key in item.lines) {
        let name = item.lines[key].chargingSite?.name
        if (name != "") return name
      }
      return "–"
    },

    rowClass() {
      return "clickable-row"
    },

    async updatePaymentMethods(item: Invoice) {
      this.paymentMethodLoading = true
      const result = await datasource.payments.updatePaymentMethods(item)

      if (result instanceof Error) {
        console.error(result)
      } else {
        this.loadData()
      }

      this.paymentMethodLoading = false
    },

    async generateBasicReports(item: Invoice) {
      const invoices = this.selected.length > 0 ? this.selected : [item]
      const headers = [
        "Invoice ID",
        "Charging Site",
        "Recipient",
        "Email",
        "Chargers",
        "Total",
        "Tax *",
        "Paid",
        "Invoice Processing Fee",
        "Flexibility Fee",
        "Flexibility Fee Tax",
        "Gross Flexibility Fee",
        "Grid Fee",
        "Variable Fee (kr/kWh)",
        "Start Energy (kWh)",
        "End Energy (kWh)",
        "Energy Used (kWh)",
        "Energy Cost Sum",
        "Energy Rebate",
        "Offline Charging Usage (kWh)",
        "Offline Charging Fee",
        "Excess Energy Usage (kWh)",
        "Excess Energy Fee",
        "Authorization Request ID",
        "Authorization Request Suffix",
        "Payment Methods",
        "Start Date",
        "End Date",
        "Generated On",
        "Paid On",
      ]

      const data = invoices.flatMap((invoice) => {
        return invoice.lines.map((line) => [
          invoice.id,
          invoice.chargingSite,
          this.recipientName(invoice.recipient),
          invoice.recipient.email,
          line.chargers.join(", "),
          this.localisedAmount(line.total),
          this.localisedAmount(line.tax),
          this.isPaid(invoice) ? "Yes" : "No",
          this.localisedAmount(invoice.processingFee),
          this.localisedAmount(line.flexibilityFee),
          this.localisedAmount(line.flexibilityFeeTax),
          this.localisedAmount(
            Big(line.flexibilityFee.amount).plus(line.flexibilityFeeTax.amount)
          ),
          this.localisedAmount(
            Big(line.gridFee.amount).plus(line.gridFeeTax.amount)
          ),
          this.localisedAmount(this.variableEnergyFee(line)),
          this.localisedAmount(line.startMeterReadingKwh),
          this.localisedAmount(line.endMeterReadingKwh),
          this.localisedAmount(this.lineEnergyUsed(line)),
          this.localisedAmount(
            Big(line.energyCost.amount).plus(line.energyCostTax.amount)
          ),
          this.localisedAmount(line.energyRebate || 0),
          this.localisedAmount(line.offlineChargingUsageKwh),
          this.localisedAmount(
            Big(line.offlineChargingFee.amount).plus(
              line.offlineChargingFeeTax.amount
            )
          ),
          this.localisedAmount(line.excessEnergyUsageKwh),
          this.localisedAmount(
            Big(line.excessEnergyFee.amount).plus(
              line.excessEnergyFeeTax.amount
            )
          ),
          invoice.paymentRequest?.authorizationRequestId,
          invoice.authorizationRequestIdSuffix,
          invoice.paymentMethods,
          this.date(line.startDate),
          this.date(line.endDate),
          this.date(invoice.insertedAt),
          this.date(invoice.paymentRequest?.lastCapturedAt),
        ])
      })

      csvStringify(
        [headers, ...data],
        { delimiter: ";" },
        function (_error, output) {
          if (output) {
            const blob = new Blob([output], { type: "text/csv;charset=utf-8" })
            const fileName = `${formatters.dateTime.dateTime(
              new Date()
            )} - Basic invoice report.csv`
            FileSaver.saveAs(blob, fileName)
          }
        }
      )
    },

    async generateDetailedReports(item: Invoice) {
      const invoices = this.selected.length > 0 ? this.selected : [item]

      const lineIds = invoices.flatMap((invoice) =>
        invoice.lines.map((line) => line.id)
      )

      const breakdownResult = await datasource.payments.listInvoiceBreakdowns(
        lineIds
      )

      if (breakdownResult instanceof Error || !breakdownResult) {
        console.error(breakdownResult)
        return
      }

      const headers = [
        "Invoice ID",
        "Charging Site",
        "Recipient",
        "Email",
        "Charger Display Name",
        "Charger Connector",
        "Connected At",
        "Last Reading At",
        "Initial Reading kWh",
        "Last Reading kWh",
        "Energy Used (kWh)",
        "Cost",
        "Paid On",
      ]

      const data = breakdownResult
        .map((breakdown) => {
          const energyUsed =
            breakdown.lastReadingWh - breakdown.initialReadingWh
          const invoice = this.invoiceForBreakdown(invoices, breakdown)

          if (invoice) {
            return [
              invoice.id,
              invoice.chargingSite,
              this.recipientName(invoice.recipient),
              invoice.recipient.email,
              breakdown.chargerDisplayName,
              breakdown.chargerConnector,
              this.dateTime(breakdown.connectedAt),
              this.dateTime(breakdown.lastReadingAt),
              this.localisedAmount(this.powerWToKw(breakdown.initialReadingWh)),
              this.localisedAmount(this.powerWToKw(breakdown.lastReadingWh)),
              this.localisedAmount(this.powerWToKw(energyUsed)),
              this.localisedAmount(breakdown.totalUsageCost),
              this.date(invoice.paymentRequest?.lastCapturedAt),
            ]
          } else {
            return null
          }
        })
        .filter((invoice) => !!invoice)

      csvStringify(
        [headers, ...data],
        { delimiter: ";" },
        function (_error, output) {
          if (output) {
            const blob = new Blob([output], { type: "text/csv;charset=utf-8" })
            const fileName = `${formatters.dateTime.dateTime(
              new Date()
            )} - Detailed invoice report.csv`
            FileSaver.saveAs(blob, fileName)
          }
        }
      )
    },
  },
})

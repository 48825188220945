










































import Vue from "vue"
import type { Car } from "@/datasource/cars"
import type { Charger } from "@/datasource/chargers"
import datasource from "@/datasource"
import CarSelect from "@/components/CarSelect.vue"
import ChargerSelect from "@/components/ChargerSelect.vue"

function initialData() {
  return {
    showDialog: false,
    saving: false,
    car: {} as Car,
    charger: {} as Charger,
    chargerConnectorId: "1" as string,
  }
}

export default Vue.extend({
  components: {
    CarSelect,
    ChargerSelect,
  },

  data: initialData,

  methods: {
    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true
      const result = await datasource.charging.registerChargingSession({
        car: this.car,
        charger: this.charger,
        chargerConnectorId: parseInt(this.chargerConnectorId),
      })
      if (result instanceof Error) {
        console.error(result)
      } else {
        this.$emit("saved")
        this.close()
      }
      this.saving = false
    },
  },
})






































import Vue from "vue"
import datasource from "@/datasource"
import type { ChargingSite } from "@/datasource/chargers"
import ChargingPlansCard from "./ChargingSite/ChargingPlansCard.vue"
import ChargingTimelineCard from "./ChargingSite/ChargingTimelineCard.vue"

export default Vue.extend({
  components: {
    ChargingPlansCard,
    ChargingTimelineCard,
  },

  data() {
    return {
      loading: true,
      loadedTime: new Date(),
      chargingSite: null as ChargingSite | null,
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      const { id } = this.$route.params
      const result = await datasource.chargers.getChargingSite(id)
      if (result instanceof Error) {
        console.error(result)
        return
      } else {
        this.chargingSite = result
      }

      this.loadedTime = new Date()
      this.loading = false
    },
  },
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"flex-fh flex-col flex-grow flex-clip",attrs:{"fluid":""}},[_c('VCard',{staticClass:"flex-grow flex-col flex-clip",attrs:{"loading":_vm.loading}},[_c('VCardTitle',[_c('span',[_vm._v("Charging Sites")]),_c('VSpacer'),_c('NewChargingSiteDialog',{on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"icon":"","color":"green"}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-plus")])],1)]}}])}),_c('VBtn',{staticClass:"ml-1",attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.loadData()}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1)],1),(_vm.selectedUser)?_c('VCardSubtitle',[_vm._v("User: "+_vm._s(_vm.selectedUser.displayIdentifier))]):_vm._e(),_c('VCardText',{staticClass:"flex-grow flex-clip"},[(_vm.loading)?[_c('VContainer',{staticClass:"flex-grow flex-col",attrs:{"fluid":""}},_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}),1)]:[(!_vm.loading)?_c('CollapsibleDataTable',{attrs:{"headers":_vm.headers,"items":_vm.chargingSites,"sort-by":"name","namespace":"chargingsites","fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: 'ChargingSite', params: { id: item.id } }}},[_vm._v(_vm._s(item.name))])]}},{key:"item.optimizationEnabled",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.mathematicalModel.optimizationEnabled}})]}},{key:"item.alertsWhenAllChargersOffline",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.alertsWhenAllChargersOffline}})]}},{key:"item.environmentTags",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.mathematicalModel.environmentTags.join(', ')))]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.geoPoint(item.mathematicalModel.location)))]}},{key:"item.fuse_voltage",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.voltage(item.mathematicalModel.fuse_voltage)))]}},{key:"item.charger_voltage",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.voltage(item.mathematicalModel.charger_voltage)))]}},{key:"item.mainFuse",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.fuse(item.mathematicalModel.mainFuse)))]}},{key:"item.authorizationSetting",fn:function(ref){
var item = ref.item;
return [_c('VSelect',{attrs:{"items":_vm.authorizationSettings},on:{"change":function($event){return _vm.updateChargingSite(item)}},model:{value:(item.authorizationSetting),callback:function ($$v) {_vm.$set(item, "authorizationSetting", $$v)},expression:"item.authorizationSetting"}})]}},{key:"item.tariffFreePeakLoadLimitW",fn:function(ref){
var item = ref.item;
return [_c('InlineSaveInputField',{attrs:{"input":_vm.tariffLimitDisplayKw(item)},on:{"saved":function (value) { return _vm.saveTariffLimitW(value, item); }}})]}},{key:"item.peakLoadTariffRatePerKw",fn:function(ref){
var item = ref.item;
return [_c('InlineSaveInputField',{attrs:{"input":_vm.peakLoadTariffRateDisplay(item)},on:{"saved":function (value) { return _vm.savePeakLoadTariffRate(value, item); }}})]}},{key:"item.alertPhoneNumbers",fn:function(ref){
var item = ref.item;
return [_c('AlertPhoneNumbers',{attrs:{"chargingSite":item},model:{value:(item.alertPhoneNumbers),callback:function ($$v) {_vm.$set(item, "alertPhoneNumbers", $$v)},expression:"item.alertPhoneNumbers"}})]}},{key:"item.rfidAuthorizedAppUsers",fn:function(ref){
var item = ref.item;
return [_c('RfidAuthorizedAppUsers',{attrs:{"chargingSite":item},on:{"saved":function($event){return _vm.loadData()}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('EditChargingSiteDialog',{attrs:{"chargingSite":item},on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-pen")])],1)]}}],null,true)})]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('VMenu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('VList',[_c('VListItem',{on:{"click":function($event){return _vm.runOptimization(item)}}},[_c('VListItemIcon',[_c('VIcon',{staticClass:"orange--text"},[_vm._v("mdi-rocket-launch")])],1),_c('VListItemContent',[_vm._v("Run Optimization")])],1),_c('VListItem',{on:{"click":function($event){}}},[_c('InvoiceSettingsDialog',{attrs:{"chargingSite":item},on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-file-settings")])],1),_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Invoice Settings")])]}}],null,true)})],1),_c('VListItem',{attrs:{"href":_vm.userActionLogUrl(item),"target":"blank"}},[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-playlist-edit")])],1),_c('VListItemContent',[_vm._v("User Action Logs")])],1),_c('VListItem',[_c('ConfirmedButton',{attrs:{"color":"red","text-color":"white"},on:{"click":function($event){return _vm.deleteChargingSite(index)}}},[_vm._v("Delete")])],1)],1)],1)]}}],null,false,1941568791)}):_vm._e()]],2),_c('VSnackbar',{attrs:{"timeout":3000,"color":_vm.snackColour},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VBtn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'VBtn',attrs,false)),_vm._v("Close")]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(_vm._s(_vm.snackText))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
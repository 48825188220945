


























































































import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import { ValidationFailureError } from "@/datasource"
import type { ValidationError } from "@/datasource"
import type {
  Charger,
  ChargingSite,
  ChargingSiteMathematicalModelFuse,
  ChargerActionRule,
} from "@/datasource/chargers"

import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"
import ConfirmedButton from "@/components/ConfirmedButton.vue"
import ChargerActionsMenu from "@/components/ChargerActionsMenu.vue"
import ChargerLinksMenu from "@/components/ChargerLinksMenu.vue"

import ActionRuleInput from "./EditChargerDialog/ActionRuleInput.vue"
import ChargerFields from "./EditChargerDialog/ChargerFields.vue"

function initialData(charger: Charger) {
  return {
    showDialog: false,
    loading: false,
    saving: false,
    savingFailed: false,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    modifiedCharger: { ...charger } as Charger | null,
    fuse: null as ChargingSiteMathematicalModelFuse | null,
    fuses: [] as ChargingSiteMathematicalModelFuse[],
    actionRules: [] as ChargerActionRule[],
    errors: {} as ValidationError,
  }
}

export default Vue.extend({
  components: {
    ActionRuleInput,
    ChargerFields,
    ChargingSiteSelect,
    ConfirmedButton,
    ChargerActionsMenu,
    ChargerLinksMenu,
  },

  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },

    shouldLoadData: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return initialData(this.charger)
  },

  watch: {
    shouldLoadData(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },

    charger() {
      if (this.shouldLoadData) {
        this.loadData()
      }
    },

    "modifiedCharger.chargingSite": {
      handler: async function (value: ChargingSite) {
        this.fuses = []
        if (value) {
          const result = await datasource.chargers.listFusesForChargingSite(
            value
          )
          if (result instanceof Error) {
            console.error(result)
          } else {
            this.fuses = result
          }
        }
      },
    },
  },

  created() {
    if (this.shouldLoadData) {
      this.loadData()
    } else {
      this.close()
    }
  },

  methods: {
    async loadData() {
      if (!this.charger.id) {
        return
      }

      this.loading = true

      {
        const result = await datasource.chargers.getCharger(this.charger.id)
        if (result instanceof Error) {
          console.error(result)
          return
        } else {
          this.modifiedCharger = result
        }
      }

      this.fuse = this.modifiedCharger?.mathematicalModel?.fuse || null

      {
        const result = await datasource.chargers.listChargerActionRules(
          this.charger
        )
        if (result instanceof Error) {
          console.error(result)
          return
        } else {
          this.actionRules = result
        }
      }

      this.loading = false
    },

    async showSnackbar(text: string, color?: string) {
      this.snackbarText = text
      this.snackbarColor = color || "success"
      this.snackbar = true
    },

    close() {
      Object.assign(this.$data, initialData(this.charger))
      this.$emit("close")
    },

    async save() {
      this.saving = true
      let isOk = true

      if (!this.modifiedCharger) {
        this.saving = false
        return
      }

      // If the charging site has changed, clear the fuse first to avoid a
      // validation error.
      if (
        this.modifiedCharger.chargingSite?.id !== this.charger.chargingSite?.id
      ) {
        await datasource.chargers.setMathematicalModelForCharger(this.charger, {
          ...this.charger.mathematicalModel,
          fuse: null,
        })
      }

      {
        const result = await datasource.chargers.updateCharger(
          this.modifiedCharger
        )
        if (result instanceof ValidationFailureError) {
          this.errors = result.errors
          isOk = false
        } else if (result instanceof Error) {
          console.error(result)
          isOk = false
        }
      }

      {
        const result = await datasource.chargers.setMathematicalModelForCharger(
          this.charger,
          {
            ...this.charger.mathematicalModel,
            fuse: this.fuse,
          }
        )
        if (result instanceof Error) {
          console.error(result)
          isOk = false
        }
      }

      {
        const result = await datasource.chargers.setChargerActionRules(
          this.charger,
          this.actionRules
        )
        if (result instanceof Error) {
          console.error(result)
          isOk = false
        }
      }

      this.saving = false
      this.$emit("saved")
      this.loadData()

      this.savingFailed = !isOk
      if (isOk) {
        this.close()
      } else {
        this.showSnackbar("Failed to save changes.", "error")
      }
    },
  },
})

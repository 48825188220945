var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"flex-fh flex-col flex-grow flex-clip",attrs:{"fluid":""}},[_c('VCard',{staticClass:"flex-grow flex-col flex-clip",attrs:{"loading":_vm.loading}},[_c('VCardTitle',[_c('span',[_vm._v("Email Templates")]),_c('VSpacer'),_c('CreateEmailTemplateDialog',{on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"icon":"","color":"green"}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-plus")])],1)]}}])})],1),_c('div',{staticClass:"d-flex align-start flex-wrap"}),_c('VCardText',{staticClass:"flex-grow flex-clip"},[(_vm.loading)?[_c('VContainer',{staticClass:"flex-col",attrs:{"fluid":""}},_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}),1)]:[_c('VDataTable',{staticClass:"flex-grow",attrs:{"headers":_vm.headers,"items":_vm.emailTemplates,"fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.month",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toMonth(item.month)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('EditEmailTemplateDialog',{attrs:{"emailTemplate":item},on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-pen")])],1)]}}],null,true)}),_c('VMenu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('VList',[_c('VListItem',[_c('ConfirmedButton',{attrs:{"color":"red","text-color":"white"},on:{"click":function($event){return _vm.deleteEmailTemplate(item)}}},[_vm._v("Delete")])],1)],1)],1)],1)]}}])})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }



























































































































































import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type { Charger } from "@/datasource/chargers"
import AppUserSelect from "@/components/AppUserSelect.vue"
import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"
import ChargerConfigurationProfileSelect from "@/components/ChargerConfigurationProfileSelect.vue"

export default Vue.extend({
  components: {
    AppUserSelect,
    ChargingSiteSelect,
    ChargerConfigurationProfileSelect,
  },

  props: {
    value: {
      type: Object as PropType<Charger>,
      required: true,
    },
    errors: {
      type: Object as PropType<Record<string, string[]>>,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: true,
      required: [(v: string) => !!v || "Required"],
      fields: { ...this.value },
      managementModules: [] as string[],
      commandsApis: [] as string[],
      observationsApis: [] as string[],
    }
  },

  computed: {
    managerSettingsToggles: {
      get(): string[] {
        const managerSettings: Record<string, boolean | string | null> =
          this.fields.managerSettings
        const ret = []
        for (const key in managerSettings) {
          if (managerSettings[key] === true) {
            ret.push(key)
          }
        }
        return ret
      },

      set(keys: string[]) {
        const managerSettings: Record<string, boolean | string | null> =
          this.fields.managerSettings

        const toggleKeys = ["useZeroPowerToStop"]
        const newValues: Record<string, boolean> = {}

        toggleKeys.forEach((key) => (newValues[key] = false))
        keys.forEach((key) => (newValues[key] = true))

        for (const key in newValues) {
          managerSettings[key] = newValues[key]
        }

        this.emit()
      },
    },
  },

  watch: {
    value(newValue: Charger): void {
      this.fields = { ...newValue }
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      {
        const result = await datasource.schema.listEnumOptions(
          "ChargerManagementModule"
        )
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.managementModules = result
      }

      {
        const result = await datasource.schema.listEnumOptions("CommandsApi")
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.commandsApis = result
      }

      {
        const result = await datasource.schema.listEnumOptions(
          "ObservationsApi"
        )
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.observationsApis = result
      }

      this.loading = false
    },

    emit(modifiedField?: string) {
      if (modifiedField) {
        this.$emit("update:errors", {
          ...this.errors,
          [modifiedField]: [],
        })
      }
      this.$emit("input", this.fields)
    },
  },
})

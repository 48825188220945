
















import Vue, { PropType } from "vue"

import type { RfidToken } from "@/datasource/chargers"

export default Vue.extend({
  props: {
    selectableRfidTokens: {
      type: Array as PropType<RfidToken[]>,
      required: true,
    },

    value: {
      type: [Object, Array],
      default: null,
    },

    returnId: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    modified: {
      get(): RfidToken {
        return this.value
      },

      set(value: RfidToken | RfidToken[] | string | string[]): void {
        this.$emit("input", value)
      },
    },
  },
})

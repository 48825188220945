










































































import Vue from "vue"
import datasource from "@/datasource"
import type { CarInput } from "@/datasource/cars"

function initialData() {
  return {
    fields: {
      displayName: null,
      smartChargingEnabled: false,
      batterySizeWh: null,
      ocppVehicleId: null,
      onboardChargerCapacityPhasesW: null,
      smartSocInferenceEnabled: false,
    } as CarInput,
    batterySizeKwh: null as null | number,
    saving: false,
    showDialog: false,
    chargerCapacityPhaseOneKw: null as null | number,
    chargerCapacityPhaseTwoKw: null as null | number,
    chargerCapacityPhaseThreeKw: null as null | number,
    error: null as null | string,
  }
}

export default Vue.extend({
  data() {
    return initialData()
  },

  watch: {
    showDialog(value: boolean) {
      if (!value) {
        Object.assign(this.$data, initialData())
      }
    },

    batterySizeKwh(newValue: null | number) {
      if (newValue) {
        Vue.set(this.fields, "batterySizeWh", newValue * 1000)
      }
    },

    chargerCapacityPhaseOneKw() {
      this.toChargerCapacityWInput()
    },

    chargerCapacityPhaseTwoKw() {
      this.toChargerCapacityWInput()
    },

    chargerCapacityPhaseThreeKw() {
      this.toChargerCapacityWInput()
    },
  },

  methods: {
    async save() {
      this.saving = true

      const response = await datasource.cars.createCar(this.fields)

      this.saving = false

      if (response instanceof Error) {
        console.error(response)
        this.error = "Failed to save"

        return
      }

      this.$emit("saved")
      this.showDialog = false
    },

    toChargerCapacityWInput() {
      let p1Kw = this.chargerCapacityPhaseOneKw
      let p2Kw = this.chargerCapacityPhaseTwoKw
      let p3Kw = this.chargerCapacityPhaseThreeKw

      if (!p1Kw) {
        p1Kw = 0
        this.chargerCapacityPhaseOneKw = p1Kw
      }

      if (!p2Kw) {
        p2Kw = 0
        this.chargerCapacityPhaseTwoKw = p2Kw
      }

      if (!p3Kw) {
        p3Kw = 0
        this.chargerCapacityPhaseThreeKw = p3Kw
      }

      Vue.set(
        this.fields,
        "onboardChargerCapacityPhasesW",
        [p1Kw, p2Kw, p3Kw].map((p) => p * 1000)
      )
    },
  },
})

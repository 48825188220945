var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"flex-fh flex-col flex-grow flex-clip",attrs:{"fluid":""}},[_c('VCard',{staticClass:"flex-grow flex-col flex-clip",attrs:{"loading":_vm.loading}},[_c('VCardTitle',[_c('span',[_vm._v("Invoices")]),_c('VSpacer'),_c('ManualInvoiceDialog',{on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"icon":"","color":"green"}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-plus")])],1)]}}])}),_c('VBtn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.loadData()}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1)],1),_c('VCardSubtitle',[(_vm.selectedUser)?_c('span',[_vm._v("Recipient: "+_vm._s(_vm.recipientName(_vm.selectedUser))+" - "+_vm._s(_vm.selectedUser.displayIdentifier))]):_vm._e(),_c('VContainer',{staticClass:"flex-grow",attrs:{"fluid":""}},[(!_vm.selectedUser)?_c('ChargingSiteSelect',{attrs:{"label":"Charging Sites","multiple":""},on:{"blur":function($event){return _vm.updateSelectedSites()}},model:{value:(_vm.chargingSites),callback:function ($$v) {_vm.chargingSites=$$v},expression:"chargingSites"}}):_vm._e(),_c('InvoiceGenerationForm',{attrs:{"chargingSites":_vm.chargingSites},on:{"saved":_vm.loadData,"close":_vm.loadData},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-3",attrs:{"disabled":_vm.chargingSites.length === 0,"color":"primary"}},'VBtn',attrs,false),on),[_vm._v("Generate")])]}}])})],1),(!_vm.loading && _vm.shouldLoad)?_c('VContainer',{staticClass:"flex-grow",attrs:{"fluid":""}},[_c('StateNavigationButton',{staticClass:"flex-grow ma-1",attrs:{"value":'draft',"currentState":_vm.state,"selectedUser":_vm.selectedUser,"label":"Draft"}}),_c('StateNavigationButton',{staticClass:"flex-grow ma-1",attrs:{"value":'unpaid',"currentState":_vm.state,"selectedUser":_vm.selectedUser,"label":"Unpaid"}}),_c('StateNavigationButton',{staticClass:"flex-grow ma-1",attrs:{"value":'paid',"currentState":_vm.state,"selectedUser":_vm.selectedUser,"label":"Paid"}})],1):_vm._e(),(!_vm.loading && _vm.shouldLoad)?_c('VTextField',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),(_vm.shouldLoad)?_c('VCardText',{staticClass:"flex-grow flex-clip"},[(_vm.loading)?[_c('VContainer',{staticClass:"flex-col",attrs:{"fluid":""}},_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}),1)]:_c('VContainer',{staticClass:"flex-grow",attrs:{"fluid":""}},[_c('VDataTable',{staticClass:"flex-grow",attrs:{"headers":_vm.headers,"items":_vm.invoiceTableData.items,"search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"single-expand":true,"expanded":_vm.expanded,"item-class":_vm.rowClass,"show-expand":"","sort-by":"id","fixed-header":"","dense":"","disable-pagination":"","hide-default-footer":"","show-select":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-2",attrs:{"colspan":headers.length}},[_c('InvoiceLineTable',{attrs:{"draft":item.draft},on:{"reload":_vm.loadData},model:{value:(item.lines),callback:function ($$v) {_vm.$set(item, "lines", $$v)},expression:"item.lines"}})],1)]}},{key:"body.append",fn:function(ref){
var items = ref.items;
return [_c('tr',[_c('td',{attrs:{"colspan":_vm.initialTotalsColspan}},[_c('strong',[_vm._v("Totals")])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.flexibilityFee))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.flexibilityFeeTax))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.grossFlexibilityFee))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.processingFee))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.grossTotal))])]),_c('td',{attrs:{"colspan":"4"}}),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.energyUsed))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.energyCost))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.offlineChargingUsage))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.offlineChargingFee))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.excessEnergyUsage))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.excessEnergyFee))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.gridFee))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.variableEnergyFee))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.invoiceTableData.totals.energyRebate))])])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}},{key:"item.recipient",fn:function(ref){
var item = ref.item;
return [(!_vm.selectedUser)?_c('RouterLink',{attrs:{"to":{ name: 'AppUserInvoices', params: { state: _vm.state, appUserId: item.recipient.id } }}},[_vm._v(_vm._s(_vm.recipientName(item.recipient)))]):_c('span',[_vm._v(_vm._s(_vm.recipientName(item.recipient)))])]}},{key:"item.recipient.email",fn:function(ref){
var item = ref.item;
return [(!_vm.selectedUser)?_c('RouterLink',{attrs:{"to":{ name: 'AppUserInvoices', params: { state: _vm.state, appUserId: item.recipient.id } }}},[_vm._v(_vm._s(item.recipient.email))]):_c('span',[_vm._v(_vm._s(item.recipient.email))])]}},{key:"item.chargingSite",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.chargingSite))]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [(_vm.isPaid(item) && item.paymentRequest)?[_vm._v(_vm._s(_vm.date(item.paymentRequest.lastCapturedAt)))]:[_vm._v("No")]]}},{key:"item.sent",fn:function(ref){
var item = ref.item;
return [(item.draft)?[_vm._v("No")]:[_vm._v("Yes")]]}},{key:"item.flexibilityFee",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.flexibilityFee))]}},{key:"item.flexibilityFeeTax",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.flexibilityFeeTax))]}},{key:"item.grossFlexibilityFee",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.grossFlexibilityFee))]}},{key:"item.startMeterReadingKwh",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.fixedFloat(item.startMeterReadingKwh, 1)))]}},{key:"item.endMeterReadingKwh",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.fixedFloat(item.endMeterReadingKwh, 1)))]}},{key:"item.energyCost",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.energyCost))]}},{key:"item.offlineChargingFee",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.offlineChargingFee))]}},{key:"item.offlineChargingUsageKwh",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.fixedFloat(item.offlineChargingUsageKwh, 1)))]}},{key:"item.excessEnergyFee",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.excessEnergyFee))]}},{key:"item.excessEnergyUsageKwh",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.fixedFloat(item.excessEnergyUsageKwh, 1)))]}},{key:"item.gridFee",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.gridFee))]}},{key:"item.variableEnergyFee",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.fixedFloat(item.variableEnergyFee, 2)))]}},{key:"item.energyRebate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.energyRebate || 0))]}},{key:"item.authorizationRequestId",fn:function(ref){
var item = ref.item;
return [(_vm.isPaid(item) && item.paymentRequest)?[_vm._v(_vm._s(item.authorizationRequestId))]:_vm._e()]}},{key:"item.authorizationRequestIdSuffix",fn:function(ref){
var item = ref.item;
return [(_vm.isPaid(item) && item.paymentRequest)?[_vm._v(_vm._s(item.authorizationRequestIdSuffix))]:_vm._e()]}},{key:"item.paymentMethods",fn:function(ref){
var item = ref.item;
return [(_vm.isPaid(item) && item.paymentRequest)?[_vm._v(_vm._s(item.paymentMethods))]:_vm._e(),(_vm.isPaid(item) && !item.paymentRequest.paymentMethods)?[_c('VBtn',{attrs:{"icon":"","x-small":"","disabled":_vm.paymentMethodLoading},on:{"click":function($event){return _vm.updatePaymentMethods(item)}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1)]:_vm._e()]}},{key:"item.taxSystems",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.dashIfNull(item.taxSystems)))]}},{key:"item.insertedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.date(item.insertedAt)))]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.date(item.startDate)))]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.date(item.endDate)))]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('VMenu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('VList',[_c('VListItem',{on:{"click":function($event){return _vm.generateBasicReports(item)}}},[(_vm.selected.length > 0)?_c('VListItemContent',[_vm._v("Generate basic reports")]):_c('VListItemContent',[_vm._v("Generate basic report")])],1),_c('VListItem',{on:{"click":function($event){return _vm.generateDetailedReports(item)}}},[(_vm.selected.length > 0)?_c('VListItemContent',[_vm._v("Generate detailed reports")]):_c('VListItemContent',[_vm._v("Generate detailed report")])],1),_c('VListItem',[_c('VListItemContent',[_c('DynamicNotificationDialog',{attrs:{"invoices":_vm.selected.length > 0 ? _vm.selected : [item]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Email template")])]}}],null,true)})],1)],1),(item.draft)?[_c('VListItem',{on:{"click":function($event){return _vm.sendInvoice(item)}}},[_c('VListItemContent',[(_vm.selected.length > 0)?_c('span',[_vm._v("Send invoices")]):_c('span',[_vm._v("Send invoice")])])],1),_c('VListItem',[_c('ConfirmedButton',{attrs:{"color":"red","text-color":"white"},on:{"click":function($event){return _vm.deleteInvoice(item)}}},[(_vm.selected.length > 0)?_c('span',[_vm._v("Delete all")]):_c('span',[_vm._v("Delete")])])],1)]:[(!_vm.isPaid(item))?[_c('VListItem',{on:{"click":function($event){return _vm.moveInvoicesToDrafts(item)}}},[(_vm.selected.length > 0)?_c('VListItemContent',[_vm._v("Move all to drafts")]):_c('VListItemContent',[_vm._v("Move to drafts")])],1)]:_vm._e(),(!_vm.isPaid(item))?[_c('VListItem',{on:{"click":function($event){return _vm.updateCapturedTotals(item)}}},[(_vm.selected.length > 0)?_c('VListItemContent',[_vm._v("Update captured totals")]):_c('VListItemContent',[_vm._v("Update captured total")])],1)]:_vm._e(),_c('VListItem',{on:{"click":function($event){}}},[_c('VListItemContent',[_vm._v("Renotify user")])],1),_c('VListItem',{on:{"click":function($event){return _vm.emailInvoice(item)}}},[_c('VListItemContent',[_vm._v("Email invoice")])],1)]],2)],1)]}}],null,false,3871329812),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],2):_c('VCardText',{staticClass:"flex-grow flex-clip"},[_vm._v("Please select some charging sites")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import datasource from "@/datasource"
import { Result } from "@/utils"

export type Setting = {
  key: string
  value: string
  source: string
  insertedAt: Date | null
  updatedAt: Date | null
}

export type AdminTask = {
  name: string
  description: string
  exampleInput: string
}

const system = {
  async listSystemSettings(): Promise<Result<Array<Setting>>> {
    type Response = {
      data?: {
        systemSettings?: Array<Setting>
      }
    }

    const response: Result<Response> = await datasource.graphql(`
      query {
        systemSettings {
          key
          value
          source
          insertedAt
          updatedAt
        }
      }
      `)

    return Result.map(response, (x) => x.data?.systemSettings || [])
  },

  async listAdminTasks(): Promise<Result<Array<AdminTask>>> {
    type Response = {
      data?: {
        adminTasks?: AdminTask[]
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(`
      query {
        adminTasks {
          name
          description
          exampleInput
        }
      }
      `)

    return Result.map(response, (r) => r.data?.adminTasks || [])
  },

  async executeAdminTask(
    taskName: string,
    taskArgs: string
  ): Promise<Result<{ success: boolean; message: string }>> {
    type Response = {
      data?: {
        executeAdminTask?: { success: boolean; message: string }
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($taskName: String!, $taskArgs: String!) {
        executeAdminTask(taskName: $taskName,  taskArgs: $taskArgs) {
          success
          message
        }
      }
      `,
      { taskName, taskArgs }
    )

    return Result.map(
      response,
      (r) =>
        r?.data?.executeAdminTask || { success: false, message: "Server Error" }
    )
  },
}

export default system














































import Vue, { PropType } from "vue"
import type { Charger } from "@/datasource/chargers"
import datasource from "@/datasource"
import VJsonEditor from "v-jsoneditor"

function initialData() {
  return {
    showDialog: false,
    loading: false,
    availableOcppActions: [] as string[],
    action: "",
    commandBody: {},
    awaitingCommandResponse: false,
    commandResponse: null as null | string,
  }
}

export default Vue.extend({
  components: { VJsonEditor },

  props: {
    charger: {
      required: true,
      type: Object as PropType<Charger>,
    },
  },

  data: initialData,

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    async loadData() {
      this.loading = true

      {
        const availableActions =
          await datasource.chargers.listAvailableOcppActions(
            this.charger.ocppVersion
          )
        if (availableActions instanceof Error) {
          console.error(availableActions)
          return
        } else {
          this.availableOcppActions = availableActions
        }
      }

      this.loading = false
    },

    async sendCommand() {
      this.awaitingCommandResponse = true

      const commandResponse = await datasource.chargers.sendOcppCommand(
        this.charger,
        this.action,
        JSON.stringify(this.commandBody)
      )

      if (commandResponse instanceof Error) {
        console.error(commandResponse)
      } else {
        this.commandResponse = JSON.parse(commandResponse)
      }

      this.awaitingCommandResponse = false
    },

    close() {
      Object.assign(this.$data, initialData())
    },
  },
})

























import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type { ChargingSite } from "@/datasource/chargers"

function toAuthorizedUsersDisplayString(chargingSite: ChargingSite): string {
  return chargingSite.rfidAuthorizedAppUsers.map((a) => a.email).join(",")
}

export default Vue.extend({
  props: {
    chargingSite: {
      type: Object as PropType<ChargingSite>,
      required: true,
    },
  },

  data() {
    return {
      originalAuthorizedAppUsers: toAuthorizedUsersDisplayString(
        this.chargingSite
      ),
      modifiedAuthorizedAppUsers: toAuthorizedUsersDisplayString(
        this.chargingSite
      ),
      saving: false,
    }
  },

  methods: {
    isEntryModified(): boolean {
      return this.originalAuthorizedAppUsers != this.modifiedAuthorizedAppUsers
    },

    hintText(): string {
      return this.isEntryModified()
        ? "HAS NOT BEEN SAVED"
        : "Accepts comma separated list of emails: foo@foo.foo,bar@bar.bar"
    },

    reset() {
      this.modifiedAuthorizedAppUsers = this.originalAuthorizedAppUsers
    },

    async save() {
      this.saving = true

      const emailAddresses: string[] =
        this.modifiedAuthorizedAppUsers.split(",")

      const result =
        await datasource.chargers.setRfidAuthorizedAppUsersForChargingSiteByEmails(
          this.chargingSite,
          emailAddresses
        )

      if (result instanceof Error) {
        console.error(result)
        return
      } else {
        this.saving = false
        this.$emit("saved")
      }
    },
  },
})

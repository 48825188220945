import datasource from "@/datasource"
import Big from "big.js"
import { BadlyFormattedApiResponse } from "@/datasource"
import { Result } from "@/utils"
import { ChargingSite } from "@/datasource/chargers"

type RequestArgs = {
  timezone: string
  requestDatetime: Date
  stepCount: number
  stepSizeH: number
  includePowerPrices: boolean
  includeMarginalPowerLosses: boolean
  carIds: string[]
  chargerIds: string[]
  chargingSiteId: string | null
}

export type OptimizationRequestLog = {
  requestId: string
  requestedAt: Date
  requestTimezone: string
  requestTriggerCause: string
  requestDepartureHourOffset: number
  chargingPlan?: OptimizationChargingPlanLog
  responseStatusIsOptimal: boolean | null
}

export type OptimizationChargingPlanLog = {
  timestamp: Date
  value: {
    chargerId: string
    powerW: number
  }[]
}[]

export type OptimizationResponseRecord = {
  optimizerRequestId: string
  optimizationTriggerCause: string
  optimizationRequestedAt: Date | null
  chargingSessionId: number
  firstDepartureTime: Date
  optimizationTimezone: string
  chargingPlanTimeseries: {
    timestamp: Date
    plannedPowerPhasesKw: Big[]
    stepSizeH: number
    stateOfChargePercent: number
  }[]
}

type rawOptimizationResponseRecord = {
  optimizerRequestId: string
  optimizationTriggerCause: string
  optimizationRequestedAt: string
  chargingSessionId: number
  firstDepartureTime: string
  optimizationTimezone: string
  chargingPlanTimeseries: {
    timestamp: string
    plannedPowerPhasesKw: Big[]
    stepSizeH: number
    stateOfChargePercent: number
  }[]
}

export default {
  async listEnvironments(): Promise<string[]> {
    return await datasource.schema.listEnumOptionsFlexibility(
      "OptimizationEnvironment"
    )
  },

  async getDefaultRequestArgs(): Promise<Result<RequestArgs>> {
    type ResponseOptimizationRequest = {
      timezone: string
      requestDatetime: string
      stepCount: number
      stepSizeH: number
      includePowerPrices: boolean
      includeMarginalPowerLosses: boolean
      carIds: string[]
      chargerIds: string[]
      chargingSiteId: string | null
    }
    type Response = {
      data?: {
        defaultOptimizationRequest?: ResponseOptimizationRequest
      }
    }
    const response: Result<Response> = await datasource.graphql_flexibility(`
      query {
        defaultOptimizationRequest {
          timezone
          requestDateTime
          stepCount
          stepSizeH
          includePowerPrices
          includeMarginalPowerLosses
          carIds
          chargerIds
        }
      }
    `)
    const optimizationRequest = Result.map(
      response,
      (x) =>
        x.data?.defaultOptimizationRequest || new BadlyFormattedApiResponse(x)
    )
    return Result.map<ResponseOptimizationRequest, RequestArgs>(
      optimizationRequest,
      (x) => ({
        ...x,
        requestDatetime: new Date(x.requestDatetime),
      })
    )
  },

  async buildRequest(args: RequestArgs): Promise<Result<string>> {
    type Response = {
      data?: {
        buildOptimizationRequest?: string
      }
    }
    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($request: OptimizationRequestInput!) {
        buildOptimizationRequest(request: $request)
      }
    `,
      {
        request: args,
      }
    )
    return Result.map(response, (x) =>
      JSON.parse(x.data?.buildOptimizationRequest || "{}")
    )
  },

  async sendRawRequest(
    request: string,
    environment?: string | null
  ): Promise<Result<string>> {
    type Response = {
      data?: {
        sendRawOptimizationRequestV2?: string
      }
    }
    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation(
        $request: Json!,
        $environment: OptimizationEnvironment
      ) {
        sendRawOptimizationRequestV2(
          request: $request,
          environment: $environment
        )
      }
    `,
      {
        request,
        environment,
      }
    )
    return Result.map(
      response,
      (x) => x.data?.sendRawOptimizationRequestV2 || ""
    )
  },

  async listRequestLogsForChargingSite(
    chargingSite: ChargingSite,
    date: Date
  ): Promise<Result<OptimizationRequestLog[]>> {
    type Response = {
      data?: {
        chargingSite?: {
          optimizationRequestLogs?: OptimizationRequestLog[]
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $chargingSiteId: ID!,
        $date: Date
      ) {
        chargingSite(id: $chargingSiteId) {
          optimizationRequestLogs(date: $date) {
            requestId
            requestedAt
            requestTimezone
            requestTriggerCause
            requestDepartureHourOffset
            responseStatusIsOptimal
          }
        }
      }
    `,
      {
        chargingSiteId: chargingSite.id,
        date: date.toISOString().split("T")[0],
      }
    )
    return Result.map(response, function (response: Response) {
      const results = response.data?.chargingSite?.optimizationRequestLogs || []
      return results.map((x) => ({
        ...x,
        requestedAt: new Date(x.requestedAt),
      }))
    })
  },

  async getRequestLogRawRequestAndResponse(
    requestLog: OptimizationRequestLog
  ): Promise<Result<{ requestJson?: string; responseJson?: string }>> {
    type Response = {
      data?: {
        optimizationRequestLog?: {
          requestJson?: string
          responseJson?: string
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $requestId: ID!
      ) {
        optimizationRequestLog(id: $requestId) {
          requestJson
          responseJson
        }
      }
    `,
      {
        requestId: requestLog.requestId,
      }
    )
    return Result.map(response, (x) => x.data?.optimizationRequestLog || {})
  },

  async getRequestLogRawRequestAndResponseByRequestId(
    requestId: string
  ): Promise<Result<{ requestJson?: string; responseJson?: string }>> {
    type Response = {
      data?: {
        optimizationRequestLog?: {
          requestJson?: string
          responseJson?: string
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $requestId: ID!
      ) {
        optimizationRequestLog(id: $requestId) {
          requestJson
          responseJson
        }
      }
    `,
      {
        requestId: requestId,
      }
    )
    return Result.map(response, (x) => x.data?.optimizationRequestLog || {})
  },

  async getChargingPlanLog(
    requestLog: OptimizationRequestLog
  ): Promise<Result<OptimizationChargingPlanLog>> {
    type Response = {
      data?: {
        optimizationRequestLog?: {
          chargingPlan?: string
        }
      }
    }
    type RawDatapoint = {
      timestamp: string
      value: {
        charger_id: string
        power_w: string
      }[]
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $requestId: ID!
      ) {
        optimizationRequestLog(id: $requestId) {
          chargingPlan
        }
      }
    `,
      {
        requestId: requestLog.requestId,
      }
    )
    return Result.map(response, function (response: Response) {
      const json = response.data?.optimizationRequestLog?.chargingPlan || "[]"
      const datapoints = JSON.parse(json)
      return datapoints.map((x: RawDatapoint) => ({
        timestamp: new Date(x.timestamp),
        value: x.value.map((v) => ({
          chargerId: v.charger_id,
          powerW: Number(v.power_w),
        })),
      }))
    })
  },

  async listOptimizationRecordsForRequestId(
    requestId: string
  ): Promise<Result<OptimizationResponseRecord | null>> {
    type Response = {
      data?: {
        optimizerResponse?: rawOptimizationResponseRecord
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($requestId: ID) {
        optimizerResponse(requestId: $requestId) {
          optimizerRequestId
          optimizationTriggerCause
          optimizationRequestedAt
          chargingSessionId
          firstDepartureTime
          optimizationTimezone
          chargingPlanTimeseries {
            timestamp
            plannedPowerPhasesKw
            stepSizeH
            stateOfChargePercent
          }
        }
      }
      `,
      { requestId }
    )

    return Result.map(response, (r) => {
      const optimizerRecord = r.data?.optimizerResponse

      if (optimizerRecord) {
        return {
          ...optimizerRecord,
          optimizationRequestedAt: optimizerRecord.optimizationRequestedAt
            ? new Date(optimizerRecord.optimizationRequestedAt)
            : null,
          firstDepartureTime: new Date(optimizerRecord.firstDepartureTime),
          chargingPlanTimeseries: optimizerRecord.chargingPlanTimeseries.map(
            (t) => {
              return {
                ...t,
                timestamp: new Date(t.timestamp),
                plannedPowerPhasesKw: t.plannedPowerPhasesKw.map((p) => Big(p)),
              }
            }
          ),
        }
      } else {
        return null
      }
    })
  },
}

import * as AbsintheSocket from "@absinthe/socket"
import { Socket as PhoenixSocket } from "phoenix"

import store from "@/store"
import { Result } from "@/utils"

import alerts from "./alerts"
import appUsers from "./appUsers"
import webUsers from "./webUsers"
import authentication from "./authentication"
import authorization from "./authorization"
import backgroundJobs from "./backgroundJobs"
import cars from "./cars"
import chargers from "./chargers"
import charging from "./charging"
import emails from "./emails"
import externalData from "./externalData"
import infrastructure from "./infrastructure"
import machineLearning from "./machineLearning"
import optimization from "./optimization"
import payments from "./payments"
import schema from "./schema"
import system from "./system"

const endpointMain = process.env.VUE_APP_API_ENDPOINT
const endpointFlexibility = process.env.VUE_APP_API_ENDPOINT + "_flexibility"

let absintheSocket: AbsintheSocket.AbsintheSocket | null

export interface ApiResponse {
  data?: Record<string, unknown>
  errors?: Record<string, unknown>[]
}

export class ApiResponseWithErrors extends Error {
  response: ApiResponse

  constructor(response: ApiResponse) {
    const responseJson = JSON.stringify(response, null, 2)
    super(`API response has an error key:\n${responseJson}`)
    this.response = response
  }
}

export type ValidationError = Record<string, (string | ValidationError)[]>

export class ValidationFailureError extends ApiResponseWithErrors {
  errors: ValidationError

  constructor(response: ApiResponse) {
    super(response)
    if (this.response.errors) {
      const errors = this.response.errors[0]?.detail || {}
      this.errors = errors as ValidationError
    } else {
      this.errors = {}
    }
  }
}

export class BadlyFormattedApiResponse extends Error {
  response: ApiResponse

  constructor(response: ApiResponse) {
    super("API response is badly-formatted.")
    this.response = response
  }
}

export type ForecastTime = {
  hour: number | null
  forecastAt: Date
}

function base64UrlEncode(input: Uint8Array): string {
  return btoa(String.fromCharCode(...input))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "")
}

function buildPkceCodeVerifier(): string {
  const verifier = new Uint8Array(128)
  crypto.getRandomValues(verifier)
  return base64UrlEncode(verifier)
}

async function pkceCodeChallengeFromVerifier(
  codeVerifier: string
): Promise<string> {
  const digest = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(codeVerifier)
  )
  return base64UrlEncode(new Uint8Array(digest))
}

function storeAuthTokens(response: {
  access_token?: string
  refresh_token?: string
}): void {
  if (response.access_token) {
    window.localStorage.setItem("authToken", response.access_token)
    store.commit("setLoggedIn", true)
    store.commit("setRefreshingToken", false)
  }
  if (response.refresh_token) {
    window.localStorage.setItem("authRefreshToken", response.refresh_token)
  }
}

function clearAuthTokens(): void {
  window.localStorage.removeItem("authToken")
  window.localStorage.removeItem("authRefreshToken")
}

function getAuthToken(): string {
  return window.localStorage.getItem("authToken") || ""
}

function getAuthRefreshToken(): string {
  return window.localStorage.getItem("authRefreshToken") || ""
}

// This determines which authentication service is used by the kernel to
// authenticate the user.
function getAuthProvider(): string {
  if (window.location.host.split(".")[0] == "circlek") {
    return "circle_k"
  } else {
    return ""
  }
}

let connectAbsintheSocketPromise = null as Promise<void> | null

async function connectAbsintheSocket(): Promise<void> {
  if (connectAbsintheSocketPromise) {
    return connectAbsintheSocketPromise
  } else {
    connectAbsintheSocketPromise = (async function () {
      await connectAbsintheSocketNow()
      connectAbsintheSocketPromise = null
    })()
  }
}

async function connectAbsintheSocketNow(): Promise<void> {
  if (absintheSocket) {
    absintheSocket.phoenixSocket.disconnect()
    absintheSocket = null
  }
  // Run a simple query to ensure the access token is up to date
  let pingOk = false
  try {
    await schema.ping()
    pingOk = true
  } catch {
    // nothing
  }
  if (pingOk) {
    const phoenixSocket = new PhoenixSocket(
      process.env.VUE_APP_WS_ENDPOINT || "",
      {
        params: { auth: getAuthToken() },
      }
    )
    phoenixSocket.onClose(connectAbsintheSocket)
    absintheSocket = AbsintheSocket.create(phoenixSocket)
  } else {
    await new Promise((resolve) => window.setTimeout(resolve, 5000))
    return connectAbsintheSocketNow()
  }
}

function filterApiResponse(response: ApiResponse) {
  if (response.errors) {
    if (response.errors[0]?.type == "validation_failure") {
      return new ValidationFailureError(response)
    } else {
      return new ApiResponseWithErrors(response)
    }
  } else {
    return response
  }
}

const datasource = {
  async startAuthFlow(): Promise<void> {
    clearAuthTokens()
    const codeVerifier = buildPkceCodeVerifier()
    const codeChallenge = await pkceCodeChallengeFromVerifier(codeVerifier)
    window.localStorage.setItem("authPkceCodeVerifier", codeVerifier)
    const params = new URLSearchParams({
      client_id: process.env.VUE_APP_AUTH_CLIENT_ID || "",
      redirect_uri: window.location.origin + "/login",
      code_challenge: codeChallenge,
      code_challenge_method: "S256",
      auth_provider: getAuthProvider(),
    })
    window.location.href = process.env.VUE_APP_AUTH_URL + "?" + params
  },

  async setAuthTokenUsingGrantCode(code: string): Promise<boolean> {
    const tokenUrl = process.env.VUE_APP_AUTH_URL + "/token"
    const codeVerifier =
      window.localStorage.getItem("authPkceCodeVerifier") || ""
    window.localStorage.removeItem("authPkceCodeVerifier")

    const request = await fetch(tokenUrl, {
      method: "POST",
      body: new URLSearchParams({
        grant_type: "authorization_code",
        code,
        code_verifier: codeVerifier,
      }),
    })

    if (request.ok) {
      storeAuthTokens(await request.json())
      return true
    } else {
      console.error("Failed to get auth token from grant code.")
      return false
    }
  },

  async setAuthTokenUsingRefreshToken(): Promise<void> {
    const tokenUrl = process.env.VUE_APP_AUTH_URL + "/token"
    const refreshToken = getAuthRefreshToken()

    if (refreshToken == "") {
      this.startAuthFlow()
      return
    }

    store.commit("setRefreshingToken", true)

    const request = await fetch(tokenUrl, {
      method: "POST",
      body: new URLSearchParams({
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      }),
    })

    if (request.ok) {
      storeAuthTokens(await request.json())
    } else {
      this.startAuthFlow()
    }
  },

  async logOut(): Promise<void> {
    clearAuthTokens()
    this.startAuthFlow()
  },

  async graphql(
    query: string,
    variables?: Record<string, unknown>,
    endpoint?: string
  ): Promise<Result<ApiResponse>> {
    await this.waitUntilLoggedIn()

    endpoint ||= endpointMain
    if (!endpoint) {
      return new Error("Endpoint not defined.")
    }

    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Bearer ${getAuthToken()}`)

    const request = await fetch(endpoint, {
      method: "POST",
      redirect: "manual",
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    })

    if (request.ok) {
      const response: ApiResponse = await request.json()
      return filterApiResponse(response)
    } else if (request.status == 401) {
      let message = "Unauthorized"
      const response: ApiResponse = await request.json()
      if (response.errors && response.errors[0]) {
        const error: { message?: string; detail?: { subtype?: string } } =
          response.errors[0]
        if (error.detail?.subtype == "invalid_token") {
          // Token has expired; commit logged-out status and retry the request
          // (which will wait until we're logged in).
          store.commit("setLoggedIn", false)
          return this.graphql(query, variables, endpoint)
        } else {
          // Unknown error; try to extract a message.
          if (error.message) {
            message = error.message
          }
          if (error.detail?.subtype) {
            message += `: ${error.detail.subtype}`
          }
        }
      }
      return new Error(message)
    } else {
      return new Error("Bad HTTP Status")
    }
  },

  async graphql_flexibility(
    query: string,
    variables?: Record<string, unknown>
  ): Promise<Result<ApiResponse>> {
    return await this.graphql(query, variables, endpointFlexibility)
  },

  async websocket_graphql(
    query: string,
    variables?: Record<string, unknown>
  ): Promise<Result<ApiResponse>> {
    if (!absintheSocket) {
      await connectAbsintheSocket()
    }

    return new Promise((resolve) => {
      if (absintheSocket) {
        const notifier = AbsintheSocket.send(absintheSocket, {
          operation: query,
          variables: variables,
        })

        AbsintheSocket.observe(absintheSocket, notifier, {
          onError: async function () {
            await connectAbsintheSocket()
            resolve(datasource.websocket_graphql(query, variables))
          },
          onResult: function (response: ApiResponse) {
            resolve(filterApiResponse(response))
          },
        })
      }
    })
  },

  subscribe(
    _operation: string,
    _variables: Record<string, unknown>,
    _callbacks: Record<string, unknown>
  ): void {
    // if (!absintheSocket) {
    //   connectAbsintheSocket()
    // }
    // if (absintheSocket) {
    //   const notifier = AbsintheSocket.send(absintheSocket, {
    //     operation,
    //     variables: variables,
    //   })
    //
    //   AbsintheSocket.observe(absintheSocket, notifier, callbacks)
    // }
  },

  async fetchCurrentUserDetails(): Promise<void> {
    type Response = {
      data?: {
        currentUser?: {
          email?: string
          dataOwner?: {
            name?: string
          }
          hasElevatedPrivileges?: boolean
        }
      }
    }
    const response = (await this.graphql(`
      query {
        currentUser {
          email
          dataOwner {
            name
          }
          hasElevatedPrivileges
        }
      }
    `)) as Response
    if (response instanceof Error) {
      console.error(response)
    } else {
      store.commit("setLoginAccountDetails", {
        email: response.data?.currentUser?.email,
        dataOwnerName: response.data?.currentUser?.dataOwner?.name,
        hasElevatedPrivileges:
          response.data?.currentUser?.hasElevatedPrivileges || false,
      })
    }
  },

  async fetchGlobalState(): Promise<void> {
    await this.fetchCurrentUserDetails()
  },

  async waitUntilLoggedIn(): Promise<void> {
    if (store.state.loggedIn) {
      return
    } else {
      return await new Promise((resolve) => {
        const unwatch = store.watch(
          (state) => state.loggedIn,
          function (value) {
            if (value) {
              unwatch()
              resolve()
            }
          }
        )
      })
    }
  },

  alerts,
  appUsers,
  authentication,
  authorization,
  backgroundJobs,
  cars,
  chargers,
  charging,
  emails,
  externalData,
  infrastructure,
  machineLearning,
  optimization,
  payments,
  schema,
  system,
  webUsers,
}

export default datasource

// Try to refresh our auth token if the logged-in state changes.
store.watch(
  (state) => state.loggedIn,
  function (value) {
    if (value === false) {
      datasource.setAuthTokenUsingRefreshToken()
    }
  }
)

// We can assume a logged-in initial state if we have an auth token stored.
if (getAuthToken() != "") {
  store.commit("setLoggedIn", true)
  connectAbsintheSocket()
  datasource.fetchGlobalState()
}

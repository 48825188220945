var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"flex-fh flex-col flex-grow flex-clip",attrs:{"fluid":""}},[_c('VCard',{staticClass:"flex-grow flex-col flex-clip",attrs:{"loading":_vm.loading}},[_c('VCardTitle',[_c('span',[_vm._v("Invoice Settings")]),_c('VSpacer'),_c('VBtn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.loadData()}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1)],1),_c('VCardSubtitle',[_c('VContainer',{staticClass:"flex-grow-0",attrs:{"fluid":""}},[_c('VTextField',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('VCardText',{staticClass:"flex-grow flex-clip"},[(_vm.loading)?[_c('VContainer',{staticClass:"flex-col",attrs:{"fluid":""}},_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}),1)]:[_c('VContainer',{staticClass:"flex-clip",attrs:{"fluid":""}},[(!_vm.loading)?_c('VDataTable',{staticClass:"flex-grow",attrs:{"headers":_vm.headers,"items":_vm.invoiceSettings,"search":_vm.search,"sort-by":"id","fixed-header":"","dense":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.chargingSite",fn:function(ref){
var item = ref.item;
return [(item.chargingSite)?_c('RouterLink',{staticClass:"mr-5",attrs:{"to":{ name: 'ChargingSiteInvoiceSettings', params: { id: item.chargingSite.id } }}},[_vm._v(_vm._s(item.chargingSite.name))]):_vm._e()]}},{key:"item.activeFrom",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.isoDate(item.activeFrom)))]}},{key:"item.chargingSiteFeeKwh",fn:function(ref){
var item = ref.item;
return [_c('MoneyValue',{attrs:{"editable":""},on:{"save":function($event){return _vm.updateInvoiceSettings(item)}},model:{value:(item.chargingSiteFeeKwh),callback:function ($$v) {_vm.$set(item, "chargingSiteFeeKwh", $$v)},expression:"item.chargingSiteFeeKwh"}})]}},{key:"item.gridFee",fn:function(ref){
var item = ref.item;
return [_c('MoneyValue',{attrs:{"editable":""},on:{"save":function($event){return _vm.updateInvoiceSettings(item)}},model:{value:(item.gridFee),callback:function ($$v) {_vm.$set(item, "gridFee", $$v)},expression:"item.gridFee"}})]}},{key:"item.fixedFlexibilityFee",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.amountDisplay(item.fixedFlexibilityFee)))]}},{key:"item.fixedProcessingFee",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.amountDisplay(item.fixedProcessingFee)))]}},{key:"item.excessEnergyFeeKwh",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.amountDisplay(item.excessEnergyFeeKwh)))]}},{key:"item.taxSystem",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.dashIfNull(item.taxSystem)))]}},{key:"item.dummyTax",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.dashIfNull(item.dummyTax)))]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
return [_c('InvoiceSettingsDialog',{attrs:{"chargingSite":item.chargingSite},on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_c('VIcon',[_vm._v("mdi-pen")])],1)]}}],null,true)})]}}],null,false,3102111254)}):_vm._e()],1)]],2),_c('VSnackbar',{attrs:{"timeout":3000,"color":_vm.snackColour},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VBtn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'VBtn',attrs,false)),_vm._v("Close")]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(_vm._s(_vm.snackText))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }















































import Vue, { PropType } from "vue"
import Big from "big.js"
import datasource from "@/datasource"
import type { CarSummary } from "@/datasource/cars"
import BooleanIcon from "@/components/BooleanIcon.vue"
import ChargePreferenceFields from "@/views/Cars/ChargePreferenceFields.vue"
import CarOnboardChargerCapacityInputField from "@/components/CarOnboardChargerCapacityInputField.vue"
import InlineSaveInputField from "@/components/InlineSaveInputField.vue"

export default Vue.extend({
  components: {
    BooleanIcon,
    CarOnboardChargerCapacityInputField,
    ChargePreferenceFields,
    InlineSaveInputField,
  },

  props: {
    vehicles: {
      type: Array as PropType<CarSummary[]>,
      required: true,
    },
  },

  methods: {
    loadData() {
      this.$emit("saved")
    },

    batterySizeKWh(car: CarSummary): string {
      if (car.batterySizeWh) {
        return Big(car.batterySizeWh).div(1000).toString()
      } else {
        return ""
      }
    },

    onboardChargerCapacityKWh(car: CarSummary): string {
      if (car.onboardChargerCapacityPhasesW) {
        return car.onboardChargerCapacityPhasesW
          .map((p) => Big(p).div(1000).toString())
          .join(",")
      } else {
        return ""
      }
    },

    async saveBatterySizeKwh(car: CarSummary, batterySizeKwh: string) {
      const batterySizeWh = Big(batterySizeKwh).times(1000).round().toNumber()

      const updatedCar = { ...car, batterySizeWh: batterySizeWh }

      const result = await datasource.cars.updateCar(updatedCar)

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.loadData()
    },

    async updateCar(car: CarSummary) {
      const result = await datasource.cars.updateCar(car)

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.loadData()
    },

    async saveChargePreference(car: CarSummary) {
      const result = await datasource.cars.updateChargingPreferences(car)
      if (result instanceof Error || !result) {
        console.error(result)
        return
      }

      this.loadData()
    },

    preferenceDepartureTimeDisplay(departureTime: string | null): string {
      /* Just removing the seconds */
      return departureTime ? departureTime.slice(0, -3) : ""
    },

    async savePreferenceDepartureTime(car: CarSummary, departureTime: string) {
      const result = await datasource.cars.updateCar({
        ...car,
        preferenceDepartureTime: departureTime + ":00",
      })

      if (result instanceof Error) {
        console.error(result)
      }
      this.loadData()
    },
  },
})

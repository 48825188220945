













































































import Vue, { PropType } from "vue"
import type { Invoice } from "@/datasource/payments"
import DateTimePicker from "@/components/DateTimePicker.vue"
import InvoiceRecipientSelect from "@/components/InvoiceRecipientSelect.vue"

export default Vue.extend({
  components: {
    DateTimePicker,
    InvoiceRecipientSelect,
  },

  props: {
    value: {
      type: Object as PropType<Invoice>,
      required: true,
    },
    errors: {
      type: Object as PropType<Record<string, string[]>>,
      default: () => [],
    },
  },

  data() {
    return {
      required: [(v: string) => !!v || "Required"],
      minimumZero: [
        (v: string) =>
          (!isNaN(parseFloat(v)) &&
            `${parseFloat(v)}` == `${v}` &&
            parseFloat(v) >= 0) ||
          "Must be a positive number",
      ],
      fields: { ...this.value },
    }
  },

  methods: {
    selected(kind: string) {
      return this.fields.recipient?.kind === kind
    },

    emit(field: string) {
      this.$emit("update:errors", {
        ...this.errors,
        [field]: [],
      })
      this.$emit("input", this.fields)
    },
  },
})































import Vue, { PropType } from "vue"
import type { ChargingSite, GridOperator } from "@/datasource/chargers"
import datasource from "@/datasource"

export default Vue.extend({
  props: {
    value: {
      type: Object as PropType<ChargingSite>,
      required: true,
    },
  },

  data() {
    return {
      fields: { ...this.value },
      powerMarkets: [] as string[],
      gridOperators: [] as GridOperator[],
    }
  },

  watch: {
    value() {
      this.fields = { ...this.value }
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      {
        const result = await datasource.externalData.listPowerMarkets()
        if (result instanceof Error) {
          console.error(result)
        } else {
          this.powerMarkets = result
        }
      }

      {
        const result = await datasource.chargers.listGridOperators()
        if (result instanceof Error) {
          console.error(result)
        } else {
          this.gridOperators = result
        }
      }
    },

    emit() {
      this.$emit("input", this.fields)
    },
  },
})








































































































































































import Vue from "vue"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import type { Car } from "@/datasource/cars"
import type { AppUser } from "@/datasource/appUsers"
import BooleanIcon from "@/components/BooleanIcon.vue"
import ConfirmedButton from "@/components/ConfirmedButton.vue"
import CollapsibleDataTable from "@/components/CollapsibleDataTable.vue"
import EditCarDialog from "./EditCarDialog.vue"
import ChargePreferenceFields from "./ChargePreferenceFields.vue"
import BatteryCapacityDialog from "./BatteryCapacityDialog.vue"
import FdtPredictionModelSelect from "@/components/FdtPredictionModelSelect.vue"
import MachineLearningLog from "@/components/MachineLearningLog.vue"
import TimeForecast from "@/components/TimeForecast.vue"
import NumberValue from "@/components/NumberValue.vue"
export default Vue.extend({
  components: {
    BooleanIcon,
    ConfirmedButton,
    CollapsibleDataTable,
    EditCarDialog,
    ChargePreferenceFields,
    BatteryCapacityDialog,
    FdtPredictionModelSelect,
    MachineLearningLog,
    TimeForecast,
    NumberValue,
  },
  data() {
    return {
      loading: true,
      search: "",
      selectedUser: null as AppUser | null,
      cars: [] as Car[],
      selected: [] as Car[],
      snack: false,
      snackText: "",
      snackColour: "",
      headers: [
        { text: "Name", value: "displayName" },
        { text: "Users", value: "appUsers" },
        { text: "VIN", value: "vin" },
        {
          text: "Battery %",
          value: "apiData.stateOfChargePercentage",
          align: "right",
        },
        {
          text: "Battery Tomorrow %",
          value: "stateOfChargeTomorrow",
          align: "right",
        },
        {
          text: "Time since a change in SOC",
          value: "stateOfChargeVariance",
          align: "right",
        },
        { text: "Min-Max Charge", value: "chargePreference", align: "center" },
        { text: "Last Refreshed", value: "apiData.lastRefreshedAt" },
        {
          text: "Battery Capacity (kWh)",
          value: "batterySizeWh",
          align: "center",
        },
        {
          text: "ML Battery Capacity (kWh)",
          value: "mlData.batteryCapacityKwh",
        },
        {
          text: "ML Max Charging Power (kW)",
          value: "mlData.maxChargingPowerKw",
        },
        {
          text: "Max Charging Power",
          value: "mlData.maxChargingPowerKw",
          align: "center",
        },
        {
          text: "Smart Charging?",
          value: "smartChargingEnabled",
          align: "center",
        },
        {
          text: "Percentage Energy Forecast",
          value: "percentageEnergyForecast",
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          width: 0,
          align: "center",
          sortable: false,
        },
      ],
    }
  },
  watch: {
    $route() {
      this.loadData()
    },
  },
  created() {
    this.loadData()
  },
  mounted() {
    this.streamCars()
  },
  methods: {
    ...formatters.number,
    ...formatters.dateTime,
    ...formatters.charging,

    streamCars() {
      const withCallback = (updatedCar: Car) => {
        const index = this.cars.findIndex((car) => car.id == updatedCar.id)
        if (index !== -1) {
          this.cars.splice(index, 1, updatedCar)
        } else {
          this.cars.push(updatedCar)
        }
      }
      datasource.cars.waitForCarUpdate(withCallback)
    },
    async loadData() {
      this.loading = true
      this.selectedUser = null
      const { appUserId, carId } = this.$route.params

      if (appUserId) {
        const appUserResult = await datasource.appUsers.getAppUser(appUserId)
        if (appUserResult instanceof Error || !appUserResult) {
          console.error(appUserResult)
          return
        }
        this.selectedUser = appUserResult
        const carsResult = await datasource.cars.listCarsForUser(
          this.selectedUser
        )
        if (carsResult instanceof Error) {
          console.error(carsResult)
          return
        }
        this.cars = carsResult
      } else if (carId) {
        const result = await datasource.cars.getCar(carId)
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.cars = result ? [result] : []
      } else {
        const result = await datasource.cars.listCars()
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.cars = result
      }
      this.loading = false
    },
    async saveChargePreference(car: Car) {
      const result = await datasource.cars.updateChargingPreferences(car)
      this.snack = true
      this.loadData()
      if (result instanceof Error || !result) {
        console.error(result)
        this.snackText = "Charge preferences not updated"
        this.snackColour = "error"
        return
      }
      this.snackText = "Charge preferences updated"
      this.snackColour = "success"
    },
    async saveMlData(car: Car) {
      const result = await datasource.cars.updateMlData(car)
      this.snack = true
      this.loadData()
      if (result instanceof Error || !result) {
        console.error(result)
        this.snackText = "Preferences not updated"
        this.snackColour = "error"
        return
      }
      this.snackText = "Preferences updated"
      this.snackColour = "success"
    },
    async estimateBatteryCapacities() {
      this.loading = true
      const result = await datasource.cars.estimateBatteryCapacities(
        this.selected
      )
      if (result instanceof Error) {
        console.error(result)
      }
      this.loadData()
    },
    async deleteCar(car: Car) {
      const result = await datasource.cars.deleteCar(car)
      if (result instanceof Error) {
        console.error(result)
        return
      }
      this.loadData()
    },
  },
})






















import Vue, { PropType } from "vue"
import datasource from "@/datasource"

import type { ChargingSite } from "@/datasource/chargers"
import type { WebUser } from "@/datasource/webUsers"

import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"

export default Vue.extend({
  components: { ChargingSiteSelect },

  props: {
    webUser: {
      type: Object as PropType<WebUser>,
      required: true,
    },

    chargingSites: {
      type: Array as PropType<Array<ChargingSite>>,
      required: true,
    },
  },

  data() {
    return {
      saving: false,
      selectedChargingSites: [...this.webUser.permittedChargingSites],
    }
  },

  methods: {
    isEntryModified(): boolean {
      return (
        this.selectedChargingSites
          .map((x) => x.id || "")
          .concat()
          .sort()
          .toString() !=
        this.webUser.permittedChargingSites
          .map((x) => x.id || "")
          .concat()
          .sort()
          .toString()
      )
    },

    hintText(): string {
      return this.isEntryModified() ? "NOT SAVED" : ""
    },

    async save() {
      this.saving = true

      const result = await datasource.webUsers.updateWebUser(this.webUser, {
        permittedChargingSiteIds: this.selectedChargingSites.map(
          (s) => s.id || ""
        ),
      })

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.saving = false
      this.$emit("saved")
    },
  },
})

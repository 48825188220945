import _ from "underscore"
import datasource from "@/datasource"
import type { AppUser } from "@/datasource/appUsers"
import type { ChargingSession } from "@/datasource/charging"
import type { Money, MoneyInput } from "@/datasource/payments"
import { Result } from "@/utils"
import { graphQlChargingSessionFields } from "@/datasource/charging"
import type { AlertPhoneNumber } from "@/datasource/alerts"

export type Charger = {
  id: string | null
  ocppIdentifier: string
  displayName: string
  status: string
  observationOnly: boolean
  smartChargingEnabled: boolean
  smartChargingSupported: boolean
  vehicleToGridChargingSupported: boolean
  pollMeterValues: boolean
  periodicallyReboot: boolean
  chargingSite: null | {
    id: string
    name: string
  }
  appUser: AppUser | null
  chargingSessions: ChargingSession[]
  mathematicalModel: ChargerMathematicalModel
  managementModule: string
  managementModuleDescription: string | null
  managerSettings: ChargerManagerSettings
  configurationProfiles: ConfigurationProfileSummary[]
  configurationProfilesLastEnforcedAt: string | null
  configurationProfilesEnforcementLastResult: string | null
  configurationProfilesLastSuccessState: boolean | null
  directConfigurationProfiles: ConfigurationProfileSummary[]
  apiData: ChargerApiData | null
  brand: string | null
  model: string | null
  ocppVersion: string
  salePricePerKwh: string | null
  isMeterValuesIncreasing: boolean | null
  optimizerPinningEnabled: boolean | null
  positionLongitude: number | null
  positionLatitude: number | null
}

export type ChargerSkeleton = {
  id: string
  ocppIdentifier: string
  displayName: string
}

export type ChargersDetailedOverviewEntry = {
  id: string | null
  displayName: string
  ocppIdentifier: string
  brand: string
  model: string
  observationOnly: boolean
  smartChargingEnabled: boolean
  appUser: null | {
    displayIdentifier: string | null
  }
  chargingSite: null | {
    name: string
  }
  managerSettings: {
    useZeroPowerToStop: boolean
  }
  mathematicalModel: {
    fuse: {
      id: string
      name: string
      phase1CapacityAmps: string
      phase2CapacityAmps: string
      phase3CapacityAmps: string
    }
  }
  actionRules: ChargerActionRule[]
  configurationProfiles: ConfigurationProfileSummary[]
  unknownVehicle: null | {
    vin: string
    batterySizeWh: number
    startingSoc: number
    mlData: {
      batteryCapacityKwh: number | null
      maxChargingPowerKw: number | null
    }
  }
}

type ChargerInputFields = {
  ocppIdentifier: string
  displayName: string
  appUserId: string | null
  brand: string | null
  model: string | null
  chargingSiteId: string | null
  observationOnly: boolean
  smartChargingEnabled: boolean
  smartChargingSupported: boolean
  vehicleToGridChargingSupported: boolean
  managementModule: string
  managerSettings: ChargerManagerSettings
  directConfigurationProfileIds: string[]
  pollMeterValues: boolean | null
  periodicallyReboot: boolean | null
  salePricePerKwh: string | null
  isMeterValuesIncreasing: boolean | null
  optimizerPinningEnabled: boolean | null
  positionLatitude: number | null
  positionLongitude: number | null
}

export type ChargerMathematicalModel = {
  id?: string | null
  connectors: Array<ChargerMathematicalModelConnector>
  fuse: ChargingSiteMathematicalModelFuse | null
}

export type ChargerMathematicalModelConnector = {
  phaseOrder: string
  phase1MaxPowerW: number
  phase2MaxPowerW: number
  phase3MaxPowerW: number
  v2gPhase1MaxPowerW: number
  v2gPhase2MaxPowerW: number
  v2gPhase3MaxPowerW: number
  longitude: number | null
  latitude: number | null
}

export type ChargerManagerSettings = {
  commandsApi: string
  observationsApi: string
  useZeroPowerToStop: boolean
}

export type ChargerApiData = {
  connectors: ChargerApiDataConnector[]
  meterValueWh: number
  instantaneousOutputPowerW: number
  instantaneousOutputCurrentPhasesA: number[]
  isOnline: boolean
  phaseConfiguration: string
}

export type ChargerApiDataConnector = {
  order: number
  isConnected: boolean
  isCharging: boolean
  isLocked: boolean
  meterValueWh: number
  instantaneousOutputPowerW: number
  instantaneousOutputCurrentPhasesA: number[]
}

export type GeoPoint = {
  latitude: string
  longitude: string
}

export type RfidToken = {
  id: string
  secret: string
}

export type ChargingSite = {
  id?: string | null
  appUserId?: string
  name: string
  powerMarket: string
  mathematicalModel?: ChargingSiteMathematicalModel
  authorizationSetting: string
  alertPhoneNumbers: AlertPhoneNumber[]
  gridOperator: GridOperator | null
  rfidAuthorizedAppUsers: AppUser[]
  alertsWhenAllChargersOffline: boolean
  tariffFreePeakLoadLimitW: number | null
  peakLoadTariffRatePerKw: Money | null
}

export type ChargingSiteCluster = {
  id?: string
  name: string
  autoCurrentLimitingEnabled: boolean
  phaseOneCurrentWarningThresholdA: number | null
  phaseTwoCurrentWarningThresholdA: number | null
  phaseThreeCurrentWarningThresholdA: number | null
  chargingSites: ChargingSite[]
  instantaneousOutputPowerW: number
  instantaneousOutputCurrentPhasesA: number[]
}

export type ChargingSiteClusterInput = {
  name: string
  autoCurrentLimitingEnabled?: boolean
  phaseOneCurrentWarningThreshold?: number
  phaseTwoCurrentWarningThreshold?: number
  phaseThreeCurrentWarningThreshold?: number
  chargingSiteIds: string[]
}

export type ChargingSiteSummary = {
  id: string
  name: string
}

type RawChargingSiteWithMathematicalModel = {
  id: string
  mathematicalModel: {
    optimizationEnabled: boolean
    optimizationMode: string
    optimizationStepSizeH: string
    optimizationStepCount: number
    optimizationPinningTemplate: string[]
    location: GeoPoint | null
    fuse_voltage: string
    charger_voltage: string
    unknownCarBatterySizeWh: number
    unknownCarBatteryLevel: number
    unknownCarOnboardChargerMaxPowerW: number[]
    mainFuse: ChargingSiteMathematicalModelFuse | null
    fuses: ChargingSiteMathematicalModelFuse[]
    environmentTags: string[]
  }
}

export type ChargingSiteMathematicalModel = {
  optimizationEnabled: boolean
  optimizationMode: string
  optimizationStepSizeH: number
  optimizationStepCount: number
  optimizationPinningTemplate: string[]
  location: GeoPoint | null
  fuse_voltage: string
  charger_voltage: string
  unknownCarBatterySizeWh: number
  unknownCarBatteryLevel: number
  unknownCarOnboardChargerMaxPowerW: number[]
  mainFuse: ChargingSiteMathematicalModelFuse | null
  environmentTags: string[]
}

export type ChargingSiteMathematicalModelFuse = {
  id?: number | null
  name: string
  phase1CapacityAmps: string
  phase2CapacityAmps: string
  phase3CapacityAmps: string
  parentId?: number | null
  subFuses: Array<ChargingSiteMathematicalModelFuse>
}

export type ChargerActionRule = {
  id: string | null
  action: "disable_smart_charging"
  daysOfWeek: number[]
  start: string
  finish: string
}

export type TimelineDatapoint = {
  timestamp: Date
  value: number
}

export type Timeline = TimelineDatapoint[]

export type ConfigurationEntry = {
  key: string
  readonly: boolean
  value?: string
  componentName?: string
  type?: string
}

export type ConfigurationProfileEntry = {
  key: string
  value: string
  componentName: string | null
  type: string | null
}

export type ConfigurationProfileSummary = {
  id: string | null
  name: string
}

export type ConfigurationProfile = {
  id: string | null
  name: string
  flex_entries: ConfigurationProfileEntry[]
  ocpp_entries: ConfigurationProfileEntry[]
}

type RegisterChargerError = {
  type: string
}

export type GridOperator = {
  id: string
  name: string
  marginalGridTariffRatePerKw: Money
}

export type CreateGridOperatorInput = {
  name: string
}

export type EditGridOperatorInput = {
  id: string
  name: string | null
  marginalGridTariffRatePerKw: MoneyInput | null
}

const graphQlFuseFields = `
  id
  name
  phase1CapacityAmps
  phase2CapacityAmps
  phase3CapacityAmps
  parentId
`

const graphQlConfigurationProfileSummaryFields = `
  id
  name
`

const graphqlConfigurationProfileEntryFields = `
  key
  value
  type
  componentName
`

const graphQlConfigurationProfileFields = `
  id
  name
  flex_entries {
    ${graphqlConfigurationProfileEntryFields}
  }
  ocpp_entries {
    ${graphqlConfigurationProfileEntryFields}
  }
`

const graphQlChargerFields = `
  id
  displayName
  ocppIdentifier
  ocppVersion
  brand
  model
  status
  observationOnly
  smartChargingEnabled
  smartChargingSupported
  vehicleToGridChargingSupported
  pollMeterValues
  periodicallyReboot
  appUser {
    id
    displayIdentifier
  }
  chargingSite {
    id
    name
  }
  chargingSessions {
    ${graphQlChargingSessionFields}
  }
  mathematicalModel {
    id
    connectors {
      phaseOrder
      phase1MaxPowerW
      phase2MaxPowerW
      phase3MaxPowerW
      v2gPhase1MaxPowerW
      v2gPhase2MaxPowerW
      v2gPhase3MaxPowerW
      longitude
      latitude
    }
    fuse {
      ${graphQlFuseFields}
    }
  }
  managementModule
  managementModuleDescription
  managerSettings {
    commandsApi
    observationsApi
    useZeroPowerToStop
  }
  apiData {
    connectors {
      order
      isLocked
      isConnected
      isCharging
      instantaneousOutputPowerW
      instantaneousOutputCurrentPhasesA
    }
  }
  salePricePerKwh
  configurationProfilesLastEnforcedAt
  configurationProfilesEnforcementLastResult
  configurationProfilesLastSuccessState
  isMeterValuesIncreasing
  optimizerPinningEnabled
  positionLongitude
  positionLatitude
`

const graphQlChargerSkeletonFields = `
  id
  ocppIdentifier
  displayName
`

const graphQlChargerFieldsWithDirectConfigurationProfiles = `
  ${graphQlChargerFields}
  directConfigurationProfiles: configurationProfiles(
    includeInherited: false
  ) {
    ${graphQlConfigurationProfileSummaryFields}
  }
`

export const graphQlChargingSiteClusterFields = `
  id
  name
  autoCurrentLimitingEnabled
  chargingSites {
    id
    name
  }
  instantaneousOutputPowerW
  instantaneousOutputCurrentPhasesA
  phaseOneCurrentWarningThresholdA
  phaseTwoCurrentWarningThresholdA
  phaseThreeCurrentWarningThresholdA
`

export const graphQlChargingSiteFields = `
  id
  name
  powerMarket
  authorizationSetting
  alertPhoneNumbers {
    id
    phoneNumber
  }
  gridOperator {
    name
    id
  }
  rfidAuthorizedAppUsers {
    id
    email
  }
  alertsWhenAllChargersOffline
  tariffFreePeakLoadLimitW
  peakLoadTariffRatePerKw {
    amount
    currency
  }
`

export const graphQlChargingSiteSummaryFields = `
  id
  name
`

const graphQlChargingSiteMathematicalModelFields = `
  optimizationEnabled
  optimizationMode
  optimizationStepSizeH
  optimizationStepCount
  optimizationPinningTemplate
  location {
    latitude
    longitude
  }
  fuse_voltage
  charger_voltage
  unknownCarBatterySizeWh
  unknownCarBatteryLevel
  unknownCarOnboardChargerMaxPowerW
  fuses {
    ${graphQlFuseFields}
  }
  environmentTags
`

const graphQlChargerActionRuleFields = `
  id
  action
  daysOfWeek
  start
  finish
`

export const graphQlGridOperatorFields = `
  id
  name
  marginalGridTariffRatePerKw {
    currency
    amount
  }
`

function mapCharger(charger: Charger | undefined | null): Charger | null {
  if (!charger) {
    return null
  } else {
    return charger
  }
}

function inputFieldsForCharger(charger: Charger): ChargerInputFields {
  const directConfigurationProfileIds: string[] =
    charger?.directConfigurationProfiles
      ?.map((x) => x.id)
      .filter((x) => x !== null) as string[]

  return {
    ocppIdentifier: charger.ocppIdentifier,
    displayName: charger.displayName,
    brand: charger.brand,
    model: charger.model,
    appUserId: charger.appUser?.id || null,
    chargingSiteId: charger.chargingSite?.id || null,
    observationOnly: charger.observationOnly,
    smartChargingEnabled: charger.smartChargingEnabled,
    smartChargingSupported: charger.smartChargingSupported,
    vehicleToGridChargingSupported: charger.vehicleToGridChargingSupported,
    managementModule: charger.managementModule,
    managerSettings: charger.managerSettings,
    directConfigurationProfileIds: directConfigurationProfileIds || [],
    pollMeterValues: charger.pollMeterValues,
    periodicallyReboot: charger.periodicallyReboot,
    salePricePerKwh: charger.salePricePerKwh,
    isMeterValuesIncreasing: charger.isMeterValuesIncreasing,
    optimizerPinningEnabled: charger.optimizerPinningEnabled,
    positionLongitude: charger.positionLongitude,
    positionLatitude: charger.positionLatitude,
  }
}

// Populates a tree structure of fuses and subfuses from a flat list of fuses,
// based on parentIds.
function buildFuseTree(
  fuses: ChargingSiteMathematicalModelFuse[]
): ChargingSiteMathematicalModelFuse {
  const fusesByParentId: Record<
    string,
    ChargingSiteMathematicalModelFuse[] | null
  > = {}
  fuses.forEach(function (fuse) {
    const parentId = fuse?.parentId || ""
    delete fuse.parentId
    const subFuses = (fusesByParentId[parentId] ||= [])
    subFuses.push(fuse)
  })
  const mainFuse = (fusesByParentId[""] || [])[0]
  if (mainFuse) {
    const populateSubFuses = function (
      fuse: ChargingSiteMathematicalModelFuse
    ) {
      fuse.subFuses = fusesByParentId[fuse.id || ""] || []
      fuse.subFuses.forEach(populateSubFuses)
    }
    populateSubFuses(mainFuse)
  }
  return mainFuse
}

function chargingSiteMathematicalModelFromRawChargingSite(
  rawChargingSite: RawChargingSiteWithMathematicalModel
): ChargingSiteMathematicalModel {
  const rawMathematicalModel = rawChargingSite.mathematicalModel
  const mathematicalModel = {
    ...rawMathematicalModel,
    optimizationStepSizeH: parseFloat(
      rawMathematicalModel.optimizationStepSizeH
    ),
  }
  mathematicalModel.mainFuse = buildFuseTree(mathematicalModel.fuses)
  return mathematicalModel
}

const onChargerUpdate = (
  operation: string,
  // eslint-disable-next-line
  callbacks: Record<string, (...args: any[]) => void>
): void => {
  datasource.subscribe(operation, {}, callbacks)
}

const chargers = {
  async listAvailableOcppActions(
    ocppVersion: string
  ): Promise<Result<Array<string>>> {
    type Response = {
      data?: {
        availableOcppCommands?: string[]
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($ocppVersion: String!) {
        availableOcppCommands(ocppVersion: $ocppVersion)
      }
      `,
      { ocppVersion: ocppVersion }
    )

    return Result.map(response, (r) => r.data?.availableOcppCommands || [])
  },

  async sendOcppCommand(
    charger: Charger,
    command: string,
    body: string
  ): Promise<Result<string>> {
    type Response = {
      data?: {
        charger?: {
          sendOcppCommand?: string
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation(
        $id: ID!,
        $command: String!,
        $body: String!,
        $ocppVersion: String!
      ) {
        charger(id: $id) {
          sendOcppCommand(
            command: $command,
            body: $body,
            ocppVersion: $ocppVersion
          )
        }
      }
      `,
      {
        id: charger.id,
        command: command,
        body: body,
        ocppVersion: charger.ocppVersion,
      }
    )

    return Result.map(response, (r) => r.data?.charger?.sendOcppCommand || "")
  },

  async listChargers(opts?: {
    ids?: string[]
    withChargingSite?: boolean
  }): Promise<Result<Array<Charger>>> {
    type Response = {
      data?: {
        chargers?: Array<Charger>
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $ids: [ID!],
        $withChargingSite: Boolean
      ) {
        chargers(
          ids: $ids,
          withChargingSite: $withChargingSite
        ) {
          ${graphQlChargerFields}
        }
      }
    `,
      {
        ids: opts?.ids,
        withChargingSite: opts?.withChargingSite,
      }
    )

    return Result.map(response, function (response: Response) {
      const rawChargers = response.data?.chargers || []
      return rawChargers.flatMap((charger) => mapCharger(charger) || [])
    })
  },

  async listChargerSkeletons(): Promise<Result<Array<ChargerSkeleton>>> {
    type Response = {
      data?: {
        chargers?: Array<ChargerSkeleton>
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query
       {
        chargers
         {
          ${graphQlChargerSkeletonFields}
        }
      }
    `
    )

    return Result.map(response, function (response: Response) {
      return response.data?.chargers || []
    })
  },

  async listChargerDetailedOverviewEntries(
    chargingSites?: ChargingSite[] | null
  ): Promise<Result<Array<ChargersDetailedOverviewEntry>>> {
    type Response = {
      data?: {
        chargers?: Array<ChargersDetailedOverviewEntry>
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($chargingSiteIds: [ID!]) {
        chargers(chargingSiteIds: $chargingSiteIds) {
          id
          displayName
          ocppIdentifier
          observationOnly
          smartChargingEnabled
          appUser {
            displayIdentifier
          }
          chargingSite {
            name
          }
          managerSettings {
            useZeroPowerToStop
          }
          mathematicalModel {
            fuse {
              id
              name
              phase1CapacityAmps
              phase2CapacityAmps
              phase3CapacityAmps
            }
          }
          actionRules {
            ${graphQlChargerActionRuleFields}
          }
          configurationProfiles {
            ${graphQlConfigurationProfileSummaryFields}
          }
          unknownVehicle(connectorId: 1) {
            vin
            batterySizeWh
            startingSoc
            mlData {
              batteryCapacityKwh
              maxChargingPowerKw
            }
          }
        }
      }
    `,
      {
        chargingSiteIds: chargingSites && chargingSites.map((x) => x.id),
      }
    )

    return Result.map(response, (x) => x.data?.chargers || [])
  },

  async listChargersForChargingSite(
    chargingSite: ChargingSite
  ): Promise<Result<Array<Charger>>> {
    type Response = {
      data?: {
        chargingSite?: {
          chargers?: Array<Charger>
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($chargingSiteId: ID!) {
        chargingSite(id: $chargingSiteId) {
          chargers {
            ${graphQlChargerFields}
          }
        }
      }
    `,
      {
        chargingSiteId: chargingSite.id,
      }
    )
    return Result.map(response, (x) => x.data?.chargingSite?.chargers || [])
  },

  async listChargerSkeletonsForChargingSite(chargingSite: {
    id: string
  }): Promise<Result<Array<ChargerSkeleton>>> {
    type Response = {
      data?: {
        chargingSite?: {
          chargers?: Array<ChargerSkeleton>
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($chargingSiteId: ID!) {
        chargingSite(id: $chargingSiteId) {
          chargers {
            ${graphQlChargerSkeletonFields}
          }
        }
      }
    `,
      {
        chargingSiteId: chargingSite.id,
      }
    )
    return Result.map(response, (x) => x.data?.chargingSite?.chargers || [])
  },

  async listChargersForUser(user: AppUser): Promise<Result<Array<Charger>>> {
    type Response = {
      data?: {
        appUser?: {
          chargers?: Charger[]
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        appUser(id: $id) {
          chargers {
            ${graphQlChargerFields}
          }
        }
      }
    `,
      {
        id: user.id,
      }
    )

    return Result.map(response, (x) => x.data?.appUser?.chargers || [])
  },

  async listUnregisteredOcppIds(): Promise<Result<Array<string>>> {
    type Response = {
      data?: {
        unregisteredOcppIds?: Array<string>
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      query {
        unregisteredOcppIds
      }
      `
    )

    return Result.map(
      response,
      (x) => x.data?.unregisteredOcppIds || ([] as string[])
    )
  },

  async listChargingSitesForUser(
    user: AppUser
  ): Promise<Result<Array<ChargingSite>>> {
    type Response = {
      data?: {
        appUser?: {
          chargers: {
            chargingSite: ChargingSite
          }[]
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        appUser(id: $id) {
          chargers {
            chargingSite {
              ${graphQlChargingSiteFields}
            }
          }
        }
      }
    `,
      {
        id: user.id,
      }
    )

    return Result.map(response, (x) => {
      const chargers = x.data?.appUser?.chargers
      const sites = chargers ? chargers.map((c) => c.chargingSite) : []
      return _.uniq(sites, "id")
    })
  },

  async listChargingSiteSummariesForUser(
    user: AppUser
  ): Promise<Result<Array<ChargingSiteSummary>>> {
    type Response = {
      data?: {
        appUser?: {
          chargers: {
            chargingSite: ChargingSiteSummary
          }[]
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        appUser(id: $id) {
          chargers {
            chargingSite {
              ${graphQlChargingSiteSummaryFields}
            }
          }
        }
      }
    `,
      {
        id: user.id,
      }
    )

    return Result.map(response, (x) => {
      const chargers = x.data?.appUser?.chargers
      const sites = chargers ? chargers.map((c) => c.chargingSite) : []
      return _.uniq(sites, "id")
    })
  },

  async getCharger(id: number | string): Promise<Result<Charger | null>> {
    type Response = {
      data?: {
        charger?: Charger
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        charger(id: $id) {
          ${graphQlChargerFieldsWithDirectConfigurationProfiles}
        }
      }
    `,
      {
        id,
      }
    )
    return Result.map(response, (x) => mapCharger(x.data?.charger))
  },

  async getChargerByOcppId(ocppId: string): Promise<Result<Charger | null>> {
    type Response = {
      data?: {
        charger?: Charger
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($ocppId: String) {
        charger(ocppId: $ocppId) {
          ${graphQlChargerFieldsWithDirectConfigurationProfiles}
        }
      }
    `,
      {
        ocppId,
      }
    )
    return Result.map(response, (x) => mapCharger(x.data?.charger))
  },

  async setRfidAuthorizedAppUsersForChargingSiteByEmails(
    chargingSite: ChargingSite,
    emails: string[]
  ): Promise<Result<boolean>> {
    type Response = {
      data?: { chargingSite?: { setRfidAuthorizedAppUsers?: AppUser[] } }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!, $appUserEmails: [String]) {
        chargingSite(id: $id) {
          setRfidAuthorizedAppUsers(appUserEmails: $appUserEmails) {
            id
          }
        }
      }
      `,
      {
        id: chargingSite.id,
        appUserEmails: emails,
      }
    )

    return Result.map(
      response,
      (x) => !!x.data?.chargingSite?.setRfidAuthorizedAppUsers
    )
  },

  async deleteCharger(charger: Charger): Promise<Result<boolean>> {
    type Response = {
      data?: {
        charger?: {
          delete: boolean
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        charger(id: $id) {
          delete
        }
      }
    `,
      {
        id: charger.id,
      }
    )
    return Result.map(response, (x) => x.data?.charger?.delete || false)
  },

  async getChargerDebugData(
    charger: Charger
  ): Promise<Result<Record<string, unknown>>> {
    type Response = {
      data?: {
        charger?: {
          debugData?: string
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        charger(id: $id) {
          debugData
        }
      }
    `,
      {
        id: charger.id,
      }
    )
    return Result.map(response, function (x) {
      try {
        return JSON.parse(x.data?.charger?.debugData || "")
      } catch (e) {
        return ""
      }
    })
  },

  async getChargerWebsocketInfo(charger: Charger): Promise<
    Result<{
      url: string
      connectedAt: Date
      readableConnectedFor: string
    } | null>
  > {
    type Response = {
      data?: {
        charger?: {
          ocppWebsocket?: {
            url: string
            connectedAt: string
            readableConnectedFor: string
          }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        charger(id: $id) {
          ocppWebsocket {
            url
            connectedAt
            readableConnectedFor
          }
        }
      }
      `,
      { id: charger.id }
    )

    return Result.map(response, (r) => {
      const websocket = r.data?.charger?.ocppWebsocket

      return websocket
        ? { ...websocket, connectedAt: new Date(websocket.connectedAt) }
        : null
    })
  },

  async newCharger(): Promise<Result<Charger>> {
    return {
      id: null,
      ocppIdentifier: "",
      ocppVersion: "1.6",
      displayName: "",
      brand: "",
      model: "",
      status: "",
      observationOnly: false,
      smartChargingEnabled: false,
      smartChargingSupported: true,
      vehicleToGridChargingSupported: false,
      chargingSite: null,
      appUser: null,
      chargingSessions: [],
      mathematicalModel: {
        connectors: [],
        fuse: null,
      },
      managementModule: "OCPP",
      managementModuleDescription: null,
      managerSettings: {
        commandsApi: "OCPP",
        observationsApi: "OCPP",
        useZeroPowerToStop: false,
      },
      configurationProfiles: [],
      configurationProfilesLastEnforcedAt: null,
      configurationProfilesEnforcementLastResult: null,
      configurationProfilesLastSuccessState: null,
      directConfigurationProfiles: [],
      apiData: null,
      pollMeterValues: false,
      periodicallyReboot: false,
      salePricePerKwh: null,
      isMeterValuesIncreasing: true,
      optimizerPinningEnabled: false,
      positionLongitude: null,
      positionLatitude: null,
    }
  },

  async createCharger(charger: Charger): Promise<Result<boolean>> {
    type Response = {
      data?: {
        createCharger?: Charger
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($fields: ChargerInput!) {
        createCharger(fields: $fields) {
          id
        }
      }
      `,
      {
        fields: inputFieldsForCharger(charger),
      }
    )

    return Result.map(response, (x) => !!x.data?.createCharger?.id)
  },

  async registerCharger(
    charger: Charger,
    secret: string
  ): Promise<Result<Charger | string>> {
    type Response = {
      data?: {
        chargingSite?: {
          registerChargerV2?: {
            charger: Charger
            errors: RegisterChargerError[]
          }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation(
        $chargingSiteId: ID!,
        $appUserId: Int!,
        $brand: ChargerBrand!,
        $identifier: String!,
        $secret: String
      ) {
        chargingSite(id: $chargingSiteId) {
          registerChargerV2(
            app_user_id: $appUserId,
            brand: $brand,
            identifier: $identifier,
            secret: $secret
          ) {
            charger {
              id
            }
            errors {
              type
            }
          }
        }
      }
      `,
      {
        chargingSiteId: Number(charger.chargingSite?.id),
        appUserId: Number(charger.appUser?.id),
        brand: charger.managementModule,
        identifier: charger.ocppIdentifier,
        secret: secret,
      }
    )

    return Result.map(response, function (result) {
      if (result.data?.chargingSite?.registerChargerV2?.charger) {
        return result.data?.chargingSite?.registerChargerV2?.charger
      }

      if (result.data?.chargingSite?.registerChargerV2?.errors.length) {
        return result.data?.chargingSite?.registerChargerV2?.errors.map(
          (e) => e.type
        )[0]
      }

      return "An unexpected error occurred. Please try again, or contact support."
    })
  },

  async updateCharger(charger: Charger): Promise<Result<void>> {
    type Response = {
      data?: {
        charger?: {
          update?: Charger
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!, $charger: ChargerInput!) {
        charger(id: $id) {
          update(charger: $charger) {
            id
          }
        }
      }
      `,
      {
        id: charger.id,
        charger: inputFieldsForCharger(charger),
      }
    )

    return Result.toVoid(response)
  },

  async synchronizeCharger(charger: Charger): Promise<Result<void>> {
    type Response = {
      data?: {
        synchronize?: boolean
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        charger(id: $id) {
          synchronize
        }
      }
      `,
      {
        id: charger.id,
      }
    )

    return Result.toVoid(response)
  },

  async setMathematicalModelForCharger(
    charger: Charger,
    mathematicalModel: ChargerMathematicalModel
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        charger?: {
          setMathematicalModel?: Charger
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation($chargerId: ID!, $model: ChargerMathematicalModelInput!) {
        charger(id: $chargerId) {
          setMathematicalModel(mathematicalModel: $model) {
            id
          }
        }
      }
      `,
      {
        chargerId: charger.id,
        model: {
          ...mathematicalModel,
          fuse_id: mathematicalModel?.fuse?.id,
          fuse: undefined,
        },
      }
    )
    const returnedId = Result.map(
      response,
      (x) => x.data?.charger?.setMathematicalModel?.id
    )
    return returnedId == charger.id
  },

  async rebootCharger(charger: Charger): Promise<Result<boolean>> {
    type Response = {
      data?: {
        charger?: {
          reboot?: boolean
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        charger(id: $id) {
          reboot
        }
      }
    `,
      {
        id: charger.id,
      }
    )
    return Result.map(response, (x) => x.data?.charger?.reboot || false)
  },

  async refreshChargerApiData(charger: Charger): Promise<Result<boolean>> {
    type Response = {
      data?: {
        charger?: {
          refreshApiData?: boolean
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        charger(id: $id) {
          refreshApiData
        }
      }
    `,
      {
        id: charger.id,
      }
    )
    return Result.map(response, (x) => x.data?.charger?.refreshApiData || false)
  },

  async setChargerCableLocked(
    charger: Charger,
    connectorId: number,
    value: boolean
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        charger?: {
          setCableLocked?: boolean
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation(
        $chargerId: ID!,
        $connectorId: Int,
        $value: Boolean!
      ) {
        charger(id: $chargerId) {
          setCableLocked(
            connectorId: $connectorId,
            value: $value
          )
        }
      }
    `,
      {
        chargerId: charger.id,
        connectorId: connectorId,
        value: value,
      }
    )
    return Result.map(response, (x) => x.data?.charger?.setCableLocked || false)
  },

  async getChargingTimelineForCharger(
    charger: Charger,
    fromDate: Date,
    toDate: Date,
    stepSizeS?: number | null
  ): Promise<Result<Timeline>> {
    type RawTimelineDatapoint = {
      timestamp: string
      value: number
    }
    type Response = {
      data?: {
        charger?: {
          chargingTimeline?: RawTimelineDatapoint[]
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $chargerId: ID!,
        $fromDate: DateTime!,
        $toDate: DateTime!,
        $stepSizeS: Int
      ) {
        charger(id: $chargerId) {
          chargingTimeline(
            interval: {start: $fromDate, end: $toDate},
            stepSizeS: $stepSizeS
          ) {
            timestamp
            value
          }
        }
      }
    `,
      {
        chargerId: charger.id,
        fromDate,
        toDate,
        stepSizeS,
      }
    )
    return Result.map(response, function (response: Response) {
      const rawDatapoints: RawTimelineDatapoint[] =
        response.data?.charger?.chargingTimeline || []
      const datapoints: TimelineDatapoint[] = rawDatapoints.map(function (
        datapoint: RawTimelineDatapoint
      ): TimelineDatapoint {
        return {
          ...datapoint,
          timestamp: new Date(datapoint.timestamp),
        }
      })
      return datapoints
    })
  },

  async listConfigurationForCharger(
    charger: Charger | ChargersDetailedOverviewEntry
  ): Promise<Result<ConfigurationEntry[]>> {
    type Response = {
      data?: {
        charger?: {
          ocppConfiguration?: {
            key: string
            readonly: boolean
            value?: string
            componentName?: string
            type?: string
          }[]
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $id: ID!
      ) {
        charger(id: $id) {
          ocppConfiguration {
            key
            readonly
            value
            componentName
            type
          }
        }
      }
    `,
      {
        id: charger.id,
      }
    )

    return Result.map(response, (x) => x.data?.charger?.ocppConfiguration || [])
  },

  async changeConfigurationForCharger(
    charger: Charger,
    key: string,
    value: string | null,
    componentName: string | null,
    type: string | null
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        charger?: {
          setOcppConfiguration?: string
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation(
        $id: ID!,
        $key: String!,
        $value: String,
        $componentName: String,
        $type: String
      ) {
        charger(id: $id) {
          setOcppConfiguration(key: $key, value: $value, componentName: $componentName, type: $type)
        }
      }
    `,
      {
        id: charger.id,
        key: key,
        value: value,
        componentName: componentName,
        type: type,
      }
    )

    return Result.map(
      response,
      (x) => x.data?.charger?.setOcppConfiguration == "OK"
    )
  },

  async listChargingSites(): Promise<Result<Array<ChargingSite>>> {
    type Response = {
      data?: {
        chargingSites?: Array<ChargingSite>
      }
    }
    const response: Result<Response> = await datasource.graphql(`
      query {
        chargingSites {
          ${graphQlChargingSiteFields}
        }
      }
    `)
    return Result.map(response, (x) => x.data?.chargingSites || [])
  },

  async listChargingSiteSummaries(): Promise<
    Result<Array<ChargingSiteSummary>>
  > {
    type Response = {
      data?: {
        chargingSites?: Array<ChargingSiteSummary>
      }
    }

    const response: Result<Response> = await datasource.graphql(`
      query {
        chargingSites {
          ${graphQlChargingSiteSummaryFields}
        }
      }
    `)

    return Result.map(response, (x) => x.data?.chargingSites || [])
  },

  async getChargingSite(
    id: number | string
  ): Promise<Result<ChargingSite | null>> {
    type Response = {
      data?: {
        chargingSite?: ChargingSite
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        chargingSite(id: $id) {
          ${graphQlChargingSiteFields}
        }
      }
    `,
      {
        id,
      }
    )
    return Result.map(response, (x) => x.data?.chargingSite || null)
  },

  async listChargingSiteClusters(): Promise<Result<ChargingSiteCluster[]>> {
    type Response = {
      data?: {
        chargingSiteClusters?: ChargingSiteCluster[]
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query {
        chargingSiteClusters {
          ${graphQlChargingSiteClusterFields}
        }
      }
      `
    )

    return Result.map(response, (x) => x.data?.chargingSiteClusters || [])
  },

  async listChargingSiteClustersForCharger(
    charger: Charger
  ): Promise<Result<ChargingSiteCluster[]>> {
    type Response = {
      data?: {
        charger?: {
          chargingSite?: {
            chargingSiteClusters?: ChargingSiteCluster[]
          }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        charger(id: $id) {
          chargingSite {
            chargingSiteClusters {
              ${graphQlChargingSiteClusterFields}
            }
          }
        }
      }
      `,
      { id: charger.id }
    )

    return Result.map(
      response,
      (r) => r.data?.charger?.chargingSite?.chargingSiteClusters || []
    )
  },

  async createChargingSiteCluster(
    input: ChargingSiteClusterInput
  ): Promise<Result<string>> {
    type Response = {
      data?: {
        createChargingSiteCluster?: {
          id: string
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($fields: CreateChargingSiteClusterInput!) {
        createChargingSiteCluster(fields: $fields) {
         id
        }
      }
      `,
      {
        fields: input,
      }
    )

    return Result.map(
      response,
      (x) => x.data?.createChargingSiteCluster?.id || ""
    )
  },

  async updateChargingSiteCluster(
    chargingSiteCluster: ChargingSiteCluster
  ): Promise<
    Result<{ success: boolean; chargingSiteCluster?: ChargingSiteCluster }>
  > {
    type Response = {
      data?: {
        chargingSiteCluster?: {
          update?: {
            success: boolean
            chargingSiteCluster?: ChargingSiteCluster
          }
        }
      }
    }

    const fields = {
      name: chargingSiteCluster.name,
      autoCurrentLimitingEnabled:
        chargingSiteCluster.autoCurrentLimitingEnabled,
      chargingSiteIds: chargingSiteCluster.chargingSites.map((s) => s.id),
      phaseOneCurrentWarningThresholdA:
        chargingSiteCluster.phaseOneCurrentWarningThresholdA,
      phaseTwoCurrentWarningThresholdA:
        chargingSiteCluster.phaseTwoCurrentWarningThresholdA,
      phaseThreeCurrentWarningThresholdA:
        chargingSiteCluster.phaseThreeCurrentWarningThresholdA,
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!, $fields: UpdateChargingSiteClusterInput!) {
        chargingSiteCluster(id: $id) {
          update(fields: $fields) {
            success
            chargingSiteCluster {
              ${graphQlChargingSiteClusterFields}
            }
          }
        }
      }
      `,
      {
        fields,
        id: chargingSiteCluster.id,
      }
    )

    return Result.map(
      response,
      (r) => r.data?.chargingSiteCluster?.update || { success: false }
    )
  },

  async deleteChargingSiteCluster(
    chargingSiteCluster: ChargingSiteCluster
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        chargingSiteCluster?: {
          delete?: {
            success: boolean
          }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        chargingSiteCluster(id: $id) {
          delete {
            success
          }
        }
      }
      `,
      {
        id: chargingSiteCluster.id,
      }
    )

    return Result.map(
      response,
      (r) => r.data?.chargingSiteCluster?.delete?.success || false
    )
  },

  async createChargingSite(
    chargingSite: ChargingSite
  ): Promise<Result<string>> {
    type Response = {
      data?: {
        createChargingSite?: ChargingSite
      }
    }

    const fields = _.omit(
      {
        ...chargingSite,
        gridOperatorId: chargingSite.gridOperator?.id,
      },
      ["id", "gridOperator"]
    )

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($fields: ChargingSiteInput!) {
        createChargingSite(fields: $fields) {
          id
        }
      }
      `,
      {
        fields,
      }
    )
    return Result.map(response, (x) => x.data?.createChargingSite?.id || "")
  },

  async updateChargingSite(chargingSite: ChargingSite): Promise<Result<void>> {
    type Response = {
      data?: {
        chargingSite?: {
          update?: ChargingSite
        }
      }
    }

    type ChargingSiteInput = {
      name: string
      powerMarket: string
      authorizationSetting: string
      gridOperatorId?: string
      alertsWhenAllChargersOffline?: boolean
      tariffFreePeakLoadLimitW?: number | null
      peakLoadTariffRatePerKw?: MoneyInput | null
    }

    const fields: ChargingSiteInput = {
      name: chargingSite.name,
      powerMarket: chargingSite.powerMarket,
      authorizationSetting: chargingSite.authorizationSetting,
      gridOperatorId: chargingSite.gridOperator?.id,
      alertsWhenAllChargersOffline: chargingSite.alertsWhenAllChargersOffline,
      tariffFreePeakLoadLimitW: chargingSite.tariffFreePeakLoadLimitW,
      peakLoadTariffRatePerKw: chargingSite.peakLoadTariffRatePerKw,
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!, $fields: ChargingSiteInput!) {
        chargingSite(id: $id) {
          update(fields: $fields) {
            id
          }
        }
      }
      `,
      {
        id: chargingSite.id,
        fields,
      }
    )
    return Result.toVoid(response)
  },

  async deleteChargingSite(chargingSite: ChargingSite): Promise<Result<void>> {
    type Response = {
      data?: {
        chargingSite?: {
          delete?: ChargingSite
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        chargingSite(id: $id) {
          delete {
            id
          }
        }
      }
      `,
      {
        id: chargingSite.id,
      }
    )
    return Result.toVoid(response)
  },

  async getChargingSiteMathematicalModel(chargingSite: {
    id?: string | null
  }): Promise<Result<ChargingSiteMathematicalModel>> {
    type Response = {
      data?: {
        chargingSite?: RawChargingSiteWithMathematicalModel
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($chargingSiteId: ID!) {
        chargingSite(id: $chargingSiteId) {
          id
          mathematicalModel {
            ${graphQlChargingSiteMathematicalModelFields}
          }
        }
      }
    `,
      {
        chargingSiteId: chargingSite.id,
      }
    )
    return Result.map(response, function (response: Response) {
      const chargingSite: RawChargingSiteWithMathematicalModel | null =
        response.data?.chargingSite || null
      if (chargingSite) {
        return chargingSiteMathematicalModelFromRawChargingSite(chargingSite)
      } else {
        return new Error("Failed to parse returned data.")
      }
    })
  },

  async listChargingSiteMathematicalModelsById(): Promise<
    Result<Record<string, ChargingSiteMathematicalModel>>
  > {
    type Response = {
      data?: {
        chargingSites?: RawChargingSiteWithMathematicalModel[]
      }
    }
    const response: Result<Response> = await datasource.graphql(`
      query {
        chargingSites {
          id
          mathematicalModel {
            ${graphQlChargingSiteMathematicalModelFields}
          }
        }
      }
    `)
    return Result.map(response, function (response: Response) {
      const chargingSites: RawChargingSiteWithMathematicalModel[] =
        response.data?.chargingSites || []
      const indexedModels = {} as Record<string, ChargingSiteMathematicalModel>
      chargingSites.forEach((chargingSite) => {
        indexedModels[chargingSite.id] =
          chargingSiteMathematicalModelFromRawChargingSite(chargingSite)
      })
      return indexedModels
    })
  },

  async listChargingSitesMathematicalModelsForUserById(
    user: AppUser
  ): Promise<Result<Record<string, ChargingSiteMathematicalModel>>> {
    type Response = {
      data?: {
        appUser?: {
          chargers: {
            chargingSite: RawChargingSiteWithMathematicalModel
          }[]
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        appUser(id: $id) {
          chargers {
            chargingSite {
              id
              mathematicalModel {
                ${graphQlChargingSiteMathematicalModelFields}
              }
            }
          }
        }
      }
    `,
      {
        id: user.id,
      }
    )

    return Result.map(response, function (response: Response) {
      const chargers = response.data?.appUser?.chargers
      const sites: RawChargingSiteWithMathematicalModel[] = chargers
        ? chargers.map((c) => c.chargingSite)
        : []

      const indexedModels = {} as Record<string, ChargingSiteMathematicalModel>
      sites.forEach((chargingSite) => {
        indexedModels[chargingSite.id] =
          chargingSiteMathematicalModelFromRawChargingSite(chargingSite)
      })
      return indexedModels
    })
  },

  async setMathematicalModelForChargingSite(
    chargingSite: ChargingSite,
    mathematicalModel: ChargingSiteMathematicalModel
  ): Promise<Result<void>> {
    type Response = {
      data?: {
        chargingSite?: {
          setMathematicalModel?: ChargingSite
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation($chargingSiteId: ID!, $model: ChargingSiteMathematicalModelInput!) {
        chargingSite(id: $chargingSiteId) {
          setMathematicalModel(mathematicalModel: $model) {
            id
          }
        }
      }
      `,
      {
        chargingSiteId: chargingSite.id,
        model: {
          ...mathematicalModel,
          fuses: undefined,
        },
      }
    )
    return Result.toVoid(response)
  },

  async setPinningTemplateForChargingSite(
    chargingSite: ChargingSite,
    pinningTemplate: string[]
  ): Promise<Result<void>> {
    type Response = {
      data?: {
        chargingSite?: {
          setMathematicalModel?: ChargingSite
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation($chargingSiteId: ID!, $model: ChargingSiteMathematicalModelInput!) {
        chargingSite(id: $chargingSiteId) {
          setMathematicalModel(mathematicalModel: $model) {
            id
          }
        }
      }
      `,
      {
        chargingSiteId: chargingSite.id,
        model: {
          optimizationPinningTemplate: pinningTemplate,
        },
      }
    )
    return Result.toVoid(response)
  },

  async listFusesForChargingSite(
    chargingSite: ChargingSite
  ): Promise<Result<ChargingSiteMathematicalModelFuse[]>> {
    type RawChargingSite = {
      id: number
      mathematicalModel: ChargingSiteMathematicalModel & {
        fuses: ChargingSiteMathematicalModelFuse[]
      }
    }
    type Response = {
      data?: {
        chargingSite?: RawChargingSite
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        chargingSite(id: $id) {
          mathematicalModel {
            fuses {
              ${graphQlFuseFields}
            }
          }
        }
      }
    `,
      {
        id: chargingSite.id,
      }
    )
    return Result.map(
      response,
      (x) => x.data?.chargingSite?.mathematicalModel?.fuses || []
    )
  },

  async listChargerActionRules(
    charger: Charger
  ): Promise<Result<ChargerActionRule[]>> {
    type Response = {
      data?: {
        charger?: {
          actionRules: ChargerActionRule[]
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $chargerId: ID!,
      ) {
        charger(id: $chargerId) {
          actionRules {
            ${graphQlChargerActionRuleFields}
          }
        }
      }
    `,
      {
        chargerId: charger.id,
      }
    )
    return Result.map(response, (x) => x.data?.charger?.actionRules || [])
  },

  async setChargerActionRules(
    charger: Charger,
    actionRules: ChargerActionRule[]
  ): Promise<Result<void>> {
    type Response = {
      data?: {
        charger?: {
          setActionRules?: boolean
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation(
        $chargerId: ID!,
        $actionRules: [ChargerActionRuleInput!]!
      ) {
        charger(id: $chargerId) {
          setActionRules(actionRules: $actionRules)
        }
      }
      `,
      {
        chargerId: charger.id,
        actionRules,
      }
    )
    return Result.toVoid(response)
  },

  async listRfidTokens(): Promise<Result<RfidToken[]>> {
    type Response = {
      data?: {
        rfidTokens?: RfidToken[]
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query {
        rfidTokens {
          id
          secret
        }
      }
      `
    )

    return Result.map(response, (r) => r.data?.rfidTokens || [])
  },

  async addRfidToken(
    owner: AppUser,
    secret: string
  ): Promise<Result<RfidToken | undefined>> {
    type Response = {
      data?: {
        appUser?: {
          addRfidToken?: RfidToken
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!, $secret: String!) {
        appUser(id: $id) {
          addRfidToken(secret: $secret) {
            id
            secret
          }
        }
      }
      `,
      {
        id: owner.id,
        secret,
      }
    )
    return Result.map(response, (x) => x.data?.appUser?.addRfidToken)
  },

  async deleteRfidToken(
    owner: AppUser,
    rfidToken: RfidToken
  ): Promise<Result<void>> {
    type Response = {
      data?: {
        deleteRfidToken?: string
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!, $tokenId: ID!) {
        appUser(id: $id) {
          deleteRfidToken(id: $tokenId) {
            id
          }
        }
      }
      `,
      {
        id: owner.id,
        tokenId: rfidToken.id,
      }
    )

    return Result.toVoid(response)
  },

  async listConfigurationProfiles(): Promise<
    Result<ConfigurationProfileSummary[]>
  > {
    type Response = {
      data?: {
        configurationProfiles?: ConfigurationProfileSummary[]
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query {
        configurationProfiles {
          ${graphQlConfigurationProfileSummaryFields}
        }
      }
    `
    )

    return Result.map(
      response,
      (x: Response) => x.data?.configurationProfiles || []
    )
  },

  async getConfigurationProfile(
    id: string
  ): Promise<Result<ConfigurationProfile | null>> {
    type Response = {
      data?: {
        configurationProfile?: ConfigurationProfile
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query ($id: ID!) {
        configurationProfile(id: $id) {
          ${graphQlConfigurationProfileFields}
        }
      }
    `,
      {
        id,
      }
    )

    return Result.map(
      response,
      (x: Response) => x.data?.configurationProfile || null
    )
  },

  async createConfigurationProfile(
    profile: ConfigurationProfile
  ): Promise<Result<ConfigurationProfile>> {
    type Response = {
      data?: {
        createConfigurationProfile?: ConfigurationProfile
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation (
        $name: String!,
        $flex_entries: [ConfigurationProfileEntryInput!]
        $ocpp_entries: [ConfigurationProfileEntryInput!]
      ) {
        createConfigurationProfile(
          name: $name,
          flex_entries: $flex_entries
          ocpp_entries: $ocpp_entries
        ) {
          ${graphQlConfigurationProfileFields}
        }
      }
    `,
      {
        name: profile.name,
        flex_entries: profile.flex_entries,
        ocpp_entries: profile.ocpp_entries,
      }
    )

    return Result.map(response, function (x: Response) {
      const result = x.data?.createConfigurationProfile
      return result ? result : new Error("Missing data in response.")
    })
  },

  async updateConfigurationProfile(
    profile: ConfigurationProfile
  ): Promise<Result<ConfigurationProfile>> {
    type Response = {
      data?: {
        configurationProfile?: {
          update: ConfigurationProfile
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation (
        $id: ID!
        $fields: ConfigurationProfileInput!
      ) {
        configurationProfile(id: $id) {
          update(fields: $fields) {
            ${graphQlConfigurationProfileFields}
          }
        }
      }
    `,
      {
        id: profile.id,
        fields: {
          ...profile,
          id: undefined,
        },
      }
    )

    return Result.map(response, function (x: Response) {
      const result = x.data?.configurationProfile?.update
      return result ? result : new Error("Missing data in response.")
    })
  },

  async deleteConfigurationProfile(
    profile: ConfigurationProfile | ConfigurationProfileSummary
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        configurationProfile?: {
          delete?: boolean
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation (
        $id: ID!
      ) {
        configurationProfile(id: $id) {
          delete
        }
      }
    `,
      {
        id: profile.id,
      }
    )

    return Result.map(
      response,
      (x: Response) => x.data?.configurationProfile?.delete || false
    )
  },

  async listConfigurationProfileOcppEntriesForCharger(
    charger: Charger
  ): Promise<Result<ConfigurationProfileEntry[]>> {
    type Response = {
      data?: {
        charger?: {
          configurationProfileOcppEntries?: ConfigurationProfileEntry[]
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $id: ID!
      ) {
        charger(id: $id) {
          configurationProfileOcppEntries {
            ${graphqlConfigurationProfileEntryFields}
          }
        }
      }
    `,
      { id: charger.id }
    )

    return Result.map(
      response,
      (x: Response) => x.data?.charger?.configurationProfileOcppEntries || []
    )
  },

  waitForChargerUpdate(withCallback: (charger: Charger) => void): void {
    type Response = {
      data?: {
        all_charger_updates?: Charger
      }
    }

    const onResult = (report: Response) => {
      const charger = report.data?.all_charger_updates

      if (charger) {
        withCallback(charger)
      }
    }

    onChargerUpdate(
      `subscription { all_charger_updates { ${graphQlChargerFields} } }`,
      { onResult }
    )
  },

  async listEnvironmentTags(): Promise<string[]> {
    return await datasource.schema.listEnumOptionsFlexibility(
      "ChargingSiteEnvironmentTag"
    )
  },

  async listPinningTemplateValueOptions(): Promise<string[]> {
    return await datasource.schema.listEnumOptions("PinningTemplateValue")
  },

  async listGridOperators(): Promise<Result<Array<GridOperator>>> {
    type Response = {
      data?: {
        gridOperators?: Array<GridOperator>
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query {
        gridOperators{
          ${graphQlGridOperatorFields}
        }
      }
      `
    )

    return Result.map(response, (x) => x.data?.gridOperators || [])
  },

  async createGridOperator(
    operator: CreateGridOperatorInput
  ): Promise<Result<void>> {
    type Response = {
      data?: { gridOperator?: { id: string } }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($fields: GridOperatorInput!) {
        createGridOperator(fields: $fields) {
          id
        }
      }
      `,
      {
        fields: operator,
      }
    )

    return Result.toVoid(response)
  },

  async updateGridOperator(
    operator: EditGridOperatorInput
  ): Promise<Result<void>> {
    type Response = {
      data?: { updateGridOperator?: { success?: boolean } }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!, $fields: GridOperatorInput!) {
        gridOperator(id: $id) {
          update(fields: $fields) {
            success
          }
        }
      }
      `,
      {
        fields: _.omit(operator, "id"),
        id: operator.id,
      }
    )

    return Result.toVoid(response)
  },

  async getPowerPricesForCharger(
    charger: Charger,
    from: Date,
    to: Date
  ): Promise<Result<Array<{ timestamp: string; value: string }>>> {
    type Response = {
      data?: {
        charger?: {
          powerPricePerKwhTimeseries?: {
            datapoints: { timestamp: string; value: string }[]
          }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!, $from: DateTime!, $to: DateTime!) {
        charger(id: $id) {
          powerPricePerKwhTimeseries(from: $from, to: $to) {
            currency
            datapoints {
              timestamp
              value
            }
          }
        }
      }
      `,
      {
        id: charger.id,
        from: from,
        to: to,
      }
    )

    return Result.map(response, (r) => {
      return r.data?.charger?.powerPricePerKwhTimeseries?.datapoints || []
    })
  },

  async getGridCostsForCharger(
    charger: Charger,
    from: Date,
    to: Date
  ): Promise<Result<Array<{ timestamp: string; value: string }>>> {
    type Response = {
      data?: {
        charger?: {
          gridCostsPerKwhTimeseries?: {
            datapoints: { timestamp: string; value: string }[]
          }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!, $from: DateTime!, $to: DateTime!) {
        charger(id: $id) {
          gridCostsPerKwhTimeseries(from: $from, to: $to) {
            currency
            datapoints {
              timestamp
              value
            }
          }
        }
      }
      `,
      {
        id: charger.id,
        from: from,
        to: to,
      }
    )

    return Result.map(response, (r) => {
      return r.data?.charger?.gridCostsPerKwhTimeseries?.datapoints || []
    })
  },
}

export default chargers

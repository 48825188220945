import datasource from "@/datasource"

export type Reports = Record<string, string[]>
export type LogEntry = {
  data: string
  runId: string
}

const machineLearningLogSubscription = `subscription { machine_learning_log { data, runId } }`

const onLogChange = (
  operation: string,
  // eslint-disable-next-line
  callbacks: Record<string, (...args: any[]) => void>
): void => {
  datasource.subscribe(operation, {}, callbacks)
}

const machineLearning = {
  waitForNextLogEntry(withCallback: (entry: LogEntry) => void): void {
    type Response = {
      data?: {
        machine_learning_log?: LogEntry
      }
    }

    const onResult = (report: Response) => {
      const entry = report.data?.machine_learning_log

      if (entry && entry.runId && entry.data) {
        withCallback(entry)
      }
    }

    onLogChange(machineLearningLogSubscription, { onResult })
  },
}

export default machineLearning

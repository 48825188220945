var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"flex-fh flex-col flex-grow flex-clip",attrs:{"fluid":""}},[_c('VCard',{staticClass:"flex-grow flex-col flex-grow flex-clip",attrs:{"loading":_vm.loading}},[_c('VCardTitle',[_c('span',[_vm._v("Chargers: Detailed Overview")]),_c('VSpacer'),_c('div',{staticClass:"flex-grow-0 pa-1 ml-3 d-flex align-center"},[(!_vm.charger)?_c('ChargingSiteSelect',{staticClass:"mr-2",attrs:{"label":"Charging Site","multiple":"","filled":"","hide-details":""},on:{"input":function($event){return _vm.loadData()}},model:{value:(_vm.selectedChargingSites),callback:function ($$v) {_vm.selectedChargingSites=$$v},expression:"selectedChargingSites"}}):_vm._e(),_c('VMenu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-menu")])],1)]}}])},[_c('VList',[_c('VListItem',{on:{"click":function($event){return _vm.exportCsv()}}},[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-download")])],1),_c('VListItemContent',[_vm._v("Export CSV")])],1)],1)],1),_c('VBtn',{staticClass:"ml-2",attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.loadData()}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1)],1)],1),_c('VCardText',{staticClass:"flex-grow flex-clip"},[(_vm.loading && _vm.tableRows.length == 0)?[_c('VContainer',{staticClass:"flex-col",attrs:{"fluid":""}},_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}),1)]:[_c('VDataTable',{staticClass:"flex-grow",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableRows,"sort-by":"displayName","fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.observationOnly",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.observationOnly}})]}},{key:"item.smartChargingEnabled",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.smartChargingEnabled}})]}},{key:"item.useZeroPowerToStop",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.useZeroPowerToStop}})]}},{key:"item.fuseCapacity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"no-wrap"},[_vm._v(_vm._s(item.fuseCapacity))])]}},{key:"item.chargerActionRules",fn:function(ref){
var item = ref.item;
return _vm._l((item.chargerActionRules),function(rule){return _c('div',{staticClass:"no-wrap"},[_vm._v(_vm._s(rule))])})}},{key:"item.configurationProfiles",fn:function(ref){
var item = ref.item;
return _vm._l((item.configurationProfiles),function(name){return _c('VChip',{key:name,staticClass:"my-1"},[_vm._v(_vm._s(name))])})}}])})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
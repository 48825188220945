



















































import Vue from "vue"
import datasource from "@/datasource"
import ChargerSelect from "@/components/ChargerSelect.vue"
import ChargingPlanTemplateSelect from "@/components/ChargingPlanTemplateSelect.vue"
import { ValidationFailureError } from "@/datasource"
import type { Charger } from "@/datasource/chargers"
import type { ChargingPlanTemplate } from "@/datasource/charging"

function initialData() {
  return {
    required: [
      (v: Charger | ChargingPlanTemplate | null) => (v && !!v.id) || "Required",
    ],
    minimumZero: [
      (v: string) =>
        (!isNaN(parseFloat(v)) &&
          `${parseFloat(v)}` == `${v}` &&
          parseFloat(v) >= 0) ||
        "Must be a positive number",
    ],
    showDialog: false,
    saving: false,
    isFormValid: false,
    charger: {} as Charger,
    chargingPlanTemplate: {} as ChargingPlanTemplate,
    chargerConnectorId: 1 as number,
    errors: {},
  }
}

export default Vue.extend({
  components: {
    ChargerSelect,
    ChargingPlanTemplateSelect,
  },

  data: initialData,

  methods: {
    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true

      const result = await datasource.charging.triggerChargingPlanTemplate(
        this.chargingPlanTemplate,
        this.charger,
        this.chargerConnectorId
      )

      if (result instanceof ValidationFailureError) {
        this.errors = result.errors
      } else if (result instanceof Error) {
        console.error(result)
      } else {
        this.$emit("saved")
        this.close()
      }

      this.saving = false
    },
  },
})

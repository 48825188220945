var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VCard',{class:_vm.nested && 'nested-table pa-0',attrs:{"loading":_vm.loading,"elevation":_vm.elevation}},[(!_vm.nested)?_c('VCardTitle',[_c('span',[_vm._v(_vm._s(_vm.title))]),_c('VSpacer'),_vm._l((_vm.controls),function(control){return [_c(control.component,_vm._b({tag:"component",on:{"saved":function($event){return _vm.loadData()}}},'component',control.props,false))]}),_c('VBtn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.loadData()}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1)],2):_vm._e(),(_vm.subtitle)?_c('VCardSubtitle',[_vm._v(_vm._s(_vm.subtitle))]):_vm._e(),_c('VCardText',{class:_vm.nested && 'pa-0'},[(_vm.loading && this.entities.length == 0)?_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}):_c('VContainer',{attrs:{"fluid":""}},[(!_vm.disableSearch)?_c('VTextField',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('VDataTable',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.entities,"item-key":_vm.identifier,"show-select":!_vm.disableBulkControl,"disable-pagination":_vm.nested,"hide-default-footer":_vm.nested,"disable-filtering":_vm.nested,"dense":_vm.nested},scopedSlots:_vm._u([(_vm.blankEntity)?{key:"footer",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('VBtn',{staticClass:"ma-1",attrs:{"x-small":""},on:{"click":_vm.addBlankEntity}},[_c('VIcon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true}:null,_vm._l((_vm.headers),function(ref){
var value = ref.value;
return {key:("item." + value),fn:function(ref){
var item = ref.item;
return [_c(_vm.types[value].component,_vm._b({tag:"component",on:{"input":function($event){return _vm.emit(item)}},model:{value:(_vm.types[value].model || item[value]),callback:function ($$v) {_vm.$set(_vm.types[value].model || item, value, $$v)},expression:"types[value].model || item[value]"}},'component',typeof _vm.types[value].props === 'function' ? _vm.types[value].props(item) : _vm.types[value].props,false))]}}}),{key:"item.menu",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('VMenu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-menu")])],1),(!item[_vm.identifier] && _vm.createFunction)?_c('VBtn',{attrs:{"icon":""},on:{"click":function($event){return _vm.saveNewEntity(item)}}},[_c('VIcon',[_vm._v("mdi-content-save-outline")])],1):_vm._e()]}}],null,true)},[_c('VList',[_c('VListItem',[_c('ConfirmedButton',{attrs:{"color":"red","text-color":"white"},on:{"click":function($event){return _vm.deleteEntity(item)}}},[_vm._v("Delete")])],1)],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }












































import Vue from "vue"
import type { PropType } from "vue"
import _ from "underscore"
import formatters from "@/utils/formatters"
import datasource from "@/datasource"
import { ChargingSite, Charger, Timeline } from "@/datasource/chargers"
import ChargingTimelineRow from "./ChargingTimelineRow.vue"

export default Vue.extend({
  components: {
    ChargingTimelineRow,
  },

  props: {
    chargingSite: {
      type: Object as PropType<ChargingSite>,
      required: true,
    },
    fromDate: {
      type: Date as PropType<Date>,
      required: true,
    },
    toDate: {
      type: Date as PropType<Date>,
      required: true,
    },
    stepSizeH: {
      type: Number,
      default: () => 1,
    },
  },

  data() {
    return {
      loading: false,
      loadedTime: new Date(),
      chargers: [] as Charger[],
      timestamps: [] as Date[],
      chargerTotals: {} as { [key: string]: number[] },
      powerPrices: [] as { timestamp: string; value: string }[],
      gridCosts: [] as { timestamp: string; value: string }[],
    }
  },

  computed: {
    sessionsCount(): number {
      const count = this.chargers
        .map((x) => x.chargingSessions.length)
        .reduce((a, b) => a + b, 0)
      return count
    },

    totals(): number[] {
      if (Object.keys(this.chargerTotals).length) {
        return Object.values(this.chargerTotals).reduce((acc, val) => {
          return _.zip(acc, val).map((c) => c[0] + c[1])
        })
      } else {
        return []
      }
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    ...formatters.dateTime,

    async loadData() {
      this.loading = true
      this.totals = []

      {
        const result = await datasource.chargers.listChargersForChargingSite(
          this.chargingSite
        )
        if (result instanceof Error) {
          console.error(result)
          return
        } else {
          this.chargers = result
        }
      }

      {
        if (this.chargers.length) {
          const result = await datasource.chargers.getPowerPricesForCharger(
            this.chargers[0],
            this.fromDate,
            this.toDate
          )

          if (result instanceof Error) {
            console.error(result)
            return
          } else {
            this.powerPrices = result
          }
        }
      }

      {
        if (this.chargers.length) {
          const result = await datasource.chargers.getGridCostsForCharger(
            this.chargers[0],
            this.fromDate,
            this.toDate
          )

          if (result instanceof Error) {
            console.error(result)
            return
          } else {
            this.gridCosts = result
          }
        }
      }

      this.loadedTime = new Date()
      this.loading = false
    },

    processRow(row: { key: string; result: Timeline }) {
      this.timestamps = row.result.map((x) => x.timestamp)

      Vue.set(
        this.chargerTotals,
        row.key,
        row.result.map((r) => r.value)
      )
    },

    hourNumber(timestamp: Date): string {
      const hour = timestamp.getHours()
      const ret = hour == 0 ? 24 : hour
      return ret.toString()
    },

    gridCostAt(timestamp: Date): string {
      const gridCost = _.find(this.gridCosts, (g) => {
        const gridCostDate = new Date(g.timestamp)
        return (
          gridCostDate.getHours() == timestamp.getHours() &&
          gridCostDate.getDay() == timestamp.getDay()
        )
      })

      return gridCost?.value
        ? parseFloat(gridCost.value).toFixed(2).toString()
        : ""
    },

    powerPriceAt(timestamp: Date): string {
      const powerPrice = _.find(this.powerPrices, (g) => {
        const powerPriceDate = new Date(g.timestamp)
        return (
          powerPriceDate.getHours() == timestamp.getHours() &&
          powerPriceDate.getDay() == timestamp.getDay()
        )
      })

      return powerPrice?.value
        ? parseFloat(powerPrice.value).toFixed(2).toString()
        : ""
    },
  },
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"flex-fh flex-col flex-grow flex-clip",attrs:{"fluid":""}},[_c('VCard',{staticClass:"flex-grow flex-col flex-clip",attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"d-flex align-start flex-wrap"},[_c('VCardTitle',[_vm._v("Charger Configuration Profiles")]),_c('VSpacer'),_c('div',{staticClass:"flex-grow-0 pa-1 ml-3 d-flex align-center"},[_c('EditChargerConfigurationProfileDialog',{on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-1 white--text",attrs:{"icon":"","color":"green"}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-plus")])],1)]}}])}),_c('VBtn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.loadData()}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1)],1)],1),_c('VCardText',{staticClass:"flex-grow flex-clip"},[(_vm.loading && _vm.configurationProfiles.length == 0)?[_c('VContainer',{staticClass:"flex-col",attrs:{"fluid":""}},_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}),1)]:(_vm.configurationProfiles.length == 0)?[_c('VCard',{staticClass:"mt-3",attrs:{"outlined":""}},[_c('VCardText',[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('div',{staticClass:"text-subtitle-2 mb-3"},[_vm._v("Create a Configuration Profile")]),_c('EditChargerConfigurationProfileDialog',{on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-1 white--text",attrs:{"icon":"","color":"green"}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-plus")])],1)]}}])})],1)])],1)]:[_c('VDataTable',{staticClass:"flex-grow",attrs:{"headers":_vm.headers,"items":_vm.configurationProfiles,"sort-by":"name","fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('EditChargerConfigurationProfileDialog',{attrs:{"profileId":item.id},on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(item.name))])]}}],null,true)})]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
return [_c('VMenu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('VList',[_c('VListItem',{on:{"click":function($event){return _vm.deleteProfile(item)}}},[_c('VListItemIcon',[_c('VIcon',{staticClass:"red--text"},[_vm._v("mdi-close")])],1),_c('VListItemContent',{staticClass:"red--text"},[_vm._v("Delete")])],1)],1)],1)]}}])})]],2),_c('VSnackbar',{attrs:{"timeout":3000,"color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VBtn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'VBtn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
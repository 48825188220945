
















































































import Vue from "vue"
import { mapState } from "vuex"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import _ from "underscore"
import type { AdminUser, AuthSession } from "@/datasource/authentication"

import NewAdminUserDialog from "./AdminUsers/NewAdminUserDialog.vue"

export default Vue.extend({
  components: {
    NewAdminUserDialog,
  },

  data() {
    return {
      loading: true,
      adminUsers: [] as Array<AdminUser>,
      headers: [
        { text: "Email", value: "email" },
        {
          text: "Sessions",
          value: "sessionsCount",
          align: "right",
          sortable: false,
        },
        {
          text: "Last Login At",
          value: "lastLoginAt",
          sortable: false,
        },
        {
          text: "Last Refresh At",
          value: "lastRefreshAt",
          sortable: false,
        },
        {
          text: "Expires At",
          value: "lastExpiryAt",
          sortable: false,
        },
        { text: "", value: "menu", width: 0, align: "right", sortable: false },
      ],
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
    }
  },

  computed: {
    ...mapState(["loginAccountEmail"]),
  },

  created() {
    this.loadData()
  },

  methods: {
    ...formatters.dateTime,

    async loadData() {
      this.loading = true
      const result = await datasource.authentication.listAdminUsers()
      if (result instanceof Error) {
        console.error(result)
        return
      }
      this.adminUsers = result
      this.loading = false
    },

    lastLoginAt(adminUser: AdminUser): Date | null {
      return this.lastDateTime(
        adminUser,
        (authSession) => authSession.insertedAt
      )
    },

    lastRefreshAt(adminUser: AdminUser): Date | null {
      return this.lastDateTime(
        adminUser,
        (authSession) => authSession.updatedAt
      )
    },

    lastExpiryAt(adminUser: AdminUser): Date | null {
      return this.lastDateTime(
        adminUser,
        (authSession) => authSession.expiresAt
      )
    },

    lastDateTime(
      adminUser: AdminUser,
      mapper: (authSession: AuthSession) => string
    ): Date | null {
      const authSessions = adminUser.authSessions
      return authSessions.length > 0
        ? (_.max(authSessions.map((x) => new Date(mapper(x)))) as Date)
        : null
    },

    async showSnackbar(text: string, color?: string) {
      this.snackbarText = text
      this.snackbarColor = color || "success"
      this.snackbar = true
    },

    async deleteAdminUser(adminUser: AdminUser) {
      const result = await datasource.authentication.deleteAdminUser(adminUser)

      if (result instanceof Error || !result) {
        console.error(result)
        this.showSnackbar("Failed to delete app user.", "error")
        return
      }

      this.showSnackbar(
        `Deleted app user ${adminUser.id} (${adminUser.email}).`
      )

      this.loadData()
    },
  },
})

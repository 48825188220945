import Vue from "vue"

import vuetify from "./plugins/vuetify"
import router from "./router"
import store from "./store"
import "./assets/styles/main.scss"

import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

import App from "./App.vue"

if (process.env.NODE_ENV == "production") {
  Sentry.init({
    Vue,
    dsn: "https://eb50fcbf1f9140e5a8c09172db702042@o352044.ingest.sentry.io/4504338558746624",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          "localhost:4000",
          /^\//,
          "admin.flexibility.ai",
          "circlek.admin.flexibility.ai",
        ],
        tracingOrigins: ["*"],
      }),
    ],
    tracesSampleRate: 1.0,
  })
}

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")






































































































































































































































































































import Vue from "vue"
import _ from "underscore"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import helpers from "@/utils/helpers"
import type { AppUser } from "@/datasource/appUsers"

import BooleanIcon from "@/components/BooleanIcon.vue"
import CollapsibleDataTable from "@/components/CollapsibleDataTable.vue"
import PasswordResetDialog from "./AppUsers/PasswordResetDialog.vue"
import ResendVerificationDialog from "./AppUsers/ResendVerificationDialog.vue"
import LoginQrCodeDialog from "./AppUsers/LoginQrCodeDialog.vue"
import EditAppUserDialog from "./AppUsers/EditAppUserDialog.vue"
import NewAppUserDialog from "./AppUsers/NewAppUserDialog.vue"
import RfidTokens from "@/components/RfidTokens.vue"
import InlineDateInput from "@/components/InlineDateInput.vue"

export default Vue.extend({
  components: {
    BooleanIcon,
    CollapsibleDataTable,
    EditAppUserDialog,
    NewAppUserDialog,
    PasswordResetDialog,
    ResendVerificationDialog,
    LoginQrCodeDialog,
    RfidTokens,
    InlineDateInput,
  },

  data() {
    return {
      loading: true,
      search: "",
      selectedUser: null as AppUser | null,
      showFreeChargingDialog: false,
      bulkSetFreeChargingState: false,
      appUsers: [] as Array<AppUser>,
      selected: [] as Array<AppUser>,
      dummyCarIds: [],
      headers: [
        { text: "Identifier", width: 0, value: "displayIdentifier" },
        { text: "Name", width: 0, value: "name" },
        { text: "Verified", width: 0, value: "emailIsVerified" },
        { text: "Cars", width: 0, value: "carsCount" },
        { text: "Chargers", width: 0, value: "chargersCount" },
        { text: "Charging Sites", width: 0, value: "chargingSiteCount" },
        {
          text: "Optimization",
          width: 0,
          value: "optimizationChargingSiteCount",
        },
        {
          text: "Links",
          width: 0,
          value: "links",
          align: "center",
          sortable: false,
        },
        {
          text: "Unknown Car Battery Size (kWh)",
          width: 0,
          value: "unknownCarBatterySizeWh",
          align: "right",
        },
        {
          text: "Smart Charging?",
          width: 0,
          value: "smartChargingEnabled",
          align: "center",
        },
        {
          text: "Subscription Start",
          width: 160,
          value: "subscriptionStartDate",
        },
        {
          text: "Subscription End",
          width: 160,
          value: "subscriptionEndDate",
        },
        {
          text: "RFID Tokens",
          value: "rfidTokens",
        },
        {
          text: "Unhealthy Session Alerts?",
          width: 0,
          value: "unhealthyChargingSessionAlertsEnabled",
        },
        {
          text: "Free charging?",
          width: 0,
          value: "freeCharging",
        },
        {
          text: "Actions",
          value: "actions",
          width: 0,
          align: "right",
          sortable: false,
        },
        {
          text: "",
          name: "Menu",
          value: "menu",
          width: 0,
          align: "right",
          sortable: false,
        },
        { text: "", value: "spacing" },
      ],
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
    }
  },

  watch: {
    $route() {
      this.loadData()
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    ...formatters.charging,

    getChargingSiteCountFromAppUser(appUser: AppUser): number {
      const siteIDs = appUser.chargers
        .filter((c) => c.chargingSite)
        .map((s) => s.id)

      return _.uniq(siteIDs).length
    },

    name(appUser: AppUser): string {
      return `${appUser.firstName || "—"} ${appUser.lastName || "—"}`
    },

    async loadData() {
      this.loading = true
      this.selectedUser = null

      const { appUserId } = this.$route.params

      if (appUserId) {
        const appUserResult = await datasource.appUsers.getAppUser(appUserId)
        if (appUserResult instanceof Error || !appUserResult) {
          console.error(appUserResult)
          return
        }
        this.selectedUser = appUserResult
        this.appUsers = [appUserResult]
        this.loading = false
      } else {
        const result = await datasource.appUsers.listAppUsers()
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.appUsers = result
        this.loading = false
      }
    },

    async showSnackbar(text: string, color?: string) {
      this.snackbarText = text
      this.snackbarColor = color || "success"
      this.snackbar = true
    },

    async deleteAppUser(appUser: AppUser) {
      const result = await datasource.appUsers.deleteAppUser(appUser)

      if (result instanceof Error || !result) {
        console.error(result)
        this.showSnackbar("Failed to delete app user.", "error")
        return
      }

      this.showSnackbar(`Deleted app user ${appUser.id} (${appUser.email}).`)

      this.loadData()
    },

    async saveSubscriptionStart(start: string, appUser: AppUser) {
      const result = await datasource.appUsers.updateAppUser({
        ...appUser,
        subscriptionStartDate: start,
      })

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.loadData()
    },

    async clearSubscriptionStartDate(appUser: AppUser) {
      const result =
        await datasource.appUsers.clearAppUserSubscriptionStartDate(appUser)

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.loadData()
    },

    async clearSubscriptionEndDate(appUser: AppUser) {
      const result = await datasource.appUsers.clearAppUserSubscriptionEndDate(
        appUser
      )

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.loadData()
    },

    async saveSubscriptionEnd(end: string, appUser: AppUser) {
      const result = await datasource.appUsers.updateAppUser({
        ...appUser,
        subscriptionEndDate: end,
      })

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.loadData()
    },

    async bulkSetFreeCharging() {
      Promise.all(
        this.selected.map((u) => {
          return datasource.appUsers.updateAppUser({
            ...u,
            freeCharging: !!this.bulkSetFreeChargingState,
          })
        })
      ).then(() => {
        this.selected = []
        this.bulkSetFreeChargingState = false
        this.showFreeChargingDialog = false
        this.loadData()
      })
    },

    ocppIdsStringFromUser(user: AppUser): string {
      return user.chargers.map((c) => c.ocppIdentifier.trim()).join(",")
    },

    weeksAgo(n: number): number {
      const d = new Date()
      d.setDate(d.getDate() - 7 * n)
      return d.getTime()
    },

    siteIdsStringFromUser(user: AppUser): string | null {
      const siteIds = _.uniq(user.chargers.map((c) => c.chargingSite?.id)).join(
        ","
      )

      return siteIds?.length ? siteIds : null
    },

    openRfidAuthAttempts(appUser: AppUser): void {
      if (appUser.rfidTokens.length == 0) {
        return
      }

      const rfidTokens = appUser.rfidTokens.map((r) => r.secret)

      const rfidTokenFilters = rfidTokens.map(
        (secret) => `(match_phrase:(token:'${secret}'))`
      )

      const rfidTokensFilter = `
        bool:(
          minimum_should_match:1,
          should:!(${rfidTokenFilters.join(",")})
        )
      `.replaceAll(/\s+/g, "")
      const url = helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_RFID_TOKEN_ATTEMPTS_INDEX,
        {
          filters: [rfidTokensFilter],
          time: "from:now-1M,to:now",
          columns: ["ocpp_identifier", "charger_display_name", "token"],
        }
      )
      window.open(url, "_blank")
    },

    userActionLogUrl(user: AppUser): string {
      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_USER_ACTION_LOG_INDEX,
        {
          filters: [
            `match_phrase:(user_type.keyword:app)`,
            `match_phrase:(user_id:${user.id})`,
          ],
          time: "from:now-7d,to:now",
          columns: [
            "user_type",
            "user_email",
            "action",
            "entity_details",
            "entity_type",
            "entity_id",
          ],
        }
      )
    },
  },
})

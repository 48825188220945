





















































































import Vue from "vue"
import datasource from "@/datasource"
import AppUserSelect from "@/components/AppUserSelect.vue"
import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"
import type { Charger, ChargingSite } from "@/datasource/chargers"

function initialData() {
  return {
    showDialog: false,
    loading: false,
    saving: false,
    isFormValid: false,
    required: [(v: string) => !!v || "Required"],
    chargerBrands: [] as string[],
    useExistingChargingSite: true,
    powerMarkets: [] as string[],
    fields: {
      charger: { appUser: {} } as Charger,
      chargingSite: {} as ChargingSite,
      secret: "",
    },
    error: null as string | null,
  }
}

export default Vue.extend({
  components: {
    AppUserSelect,
    ChargingSiteSelect,
  },

  data: initialData,

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    async loadData() {
      this.loading = true

      {
        const result = await datasource.schema.listEnumOptions("ChargerBrand")
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.chargerBrands = result
      }

      {
        const result = await datasource.externalData.listPowerMarkets()
        if (result instanceof Error) {
          console.error(result)
        } else {
          this.powerMarkets = result
        }
      }

      // override the brand since only
      // easee is currently supported
      this.fields.charger.managementModule = "EASEE"
      this.loading = false
    },

    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true

      if (!this.useExistingChargingSite) {
        if (!this.fields.charger.appUser?.id) {
          return
        }

        {
          const response = await datasource.chargers.createChargingSite({
            ...this.fields.chargingSite,
            appUserId: this.fields.charger.appUser.id,
          })

          if (response instanceof Error) {
            return
          }

          const chargingSite = await datasource.chargers.getChargingSite(
            response
          )

          if (chargingSite instanceof Error) {
            return
          }

          if (chargingSite) {
            this.fields.charger.chargingSite = {
              id: chargingSite.id || "",
              name: chargingSite.name,
            }
          }
        }
      }

      const response = await datasource.chargers.registerCharger(
        this.fields.charger,
        this.fields.secret
      )

      if (typeof response === "string") {
        const message = (response as string).toLowerCase().replace(/_/, " ")
        this.error = `Error: ${message}`
      } else {
        this.$emit("saved")
        this.close()
      }

      this.saving = false
    },
  },
})

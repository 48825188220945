




























import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type { Charger } from "@/datasource/chargers"
import { DateTime } from "luxon"

export default Vue.extend({
  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      activeWebsocket: true,
      websocketData: {} as Record<string, string | Date> | null,
    }
  },

  watch: {
    charger() {
      this.loadData()
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      const websocketData = await datasource.chargers.getChargerWebsocketInfo(
        this.charger
      )

      if (websocketData instanceof Error) {
        console.error(websocketData)
        return
      }

      this.activeWebsocket = !!websocketData
      this.websocketData = websocketData
      this.loading = false
    },

    formattedDate(date: Date): string {
      return DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_MED)
    },
  },
})

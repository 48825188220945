

















import Vue from "vue"
import datasource from "@/datasource"

export default Vue.extend({
  data() {
    return {
      loading: false,
      unregisteredOcppIds: [] as Array<string>,
      items: [] as { ocppId: string }[],
      headers: [{ text: "OCPP ID", value: "ocppId" }],
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      const result = await datasource.chargers.listUnregisteredOcppIds()

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.unregisteredOcppIds = result
      this.items = result.map((ocppId) => {
        return { ocppId: ocppId }
      })
    },
  },
})






















import Vue, { PropType } from "vue"
import type { ForecastTime } from "@/datasource"
import formatters from "@/utils/formatters"
import NumberValue from "@/components/NumberValue.vue"

export default Vue.extend({
  components: {
    NumberValue,
  },

  props: {
    value: {
      type: Object as PropType<ForecastTime>,
      default: { hour: null },
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      forecast: this.value || { hour: null },
    }
  },

  computed: {
    hour(): string {
      return formatters.number.fixedFloat(this.forecast?.hour, 0)
    },
  },

  watch: {
    value: function (newForecast) {
      Object.assign(this.forecast, newForecast || { hour: null })
    },
  },

  methods: {
    ...formatters.dateTime,

    outdatedForecast(forecast: ForecastTime): boolean {
      if (!forecast.forecastAt) {
        return false
      }

      const now = new Date().getTime()

      return (now - forecast.forecastAt.getTime()) / 3600000 > 24
    },

    emit(forecast: ForecastTime) {
      const hour = parseInt(`${forecast.hour}`)
      forecast.hour = hour
      Object.assign(this.forecast, forecast)
      this.$emit("input", forecast)
    },
  },
})





































import Vue, { PropType } from "vue"
import type { AmsMeter } from "@/datasource/infrastructure"

export default Vue.extend({
  props: {
    value: {
      type: Object as PropType<AmsMeter>,
      required: true,
    },
    errors: {
      type: Object as PropType<Record<string, string[]>>,
      default: () => ({}),
    },
  },

  data() {
    return {
      required: [(v: string) => !!v || "Required"],
      numberRule: [(v: string) => !isNaN(parseFloat(v)) || "Must be a number"],
      fields: { ...this.value },
    }
  },

  methods: {
    emit(field: string) {
      this.errors[field] = []
      this.$emit("input", this.fields)
    },
  },
})


















import Vue from "vue"
import type { Car } from "@/datasource/cars"
import datasource from "@/datasource"

export default Vue.extend({
  props: {
    value: {
      type: [String, Object, Array],
      default: null,
    },

    returnId: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: true,
      cars: [] as Car[],
    }
  },

  computed: {
    modified: {
      get(): Car {
        return this.value
      },

      set(value: Car | Car[] | string | string[]): void {
        this.$emit("input", value)
      },
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true
      const result = await datasource.cars.listCars()
      if (result instanceof Error) {
        console.error(result)
        return
      } else {
        this.cars = result
        if (!this.clearable) {
          this.modified ||= this.cars[0]
        }
      }
      this.loading = false
    },
  },
})






























































import Vue from "vue"
import type { PropType } from "vue"
import type {
  Charger,
  ChargingSiteMathematicalModelFuse,
} from "@/datasource/chargers"
import ChargerSelect from "@/components/ChargerSelect.vue"

export default Vue.extend({
  name: "Fuse",

  components: { ChargerSelect },

  props: {
    value: {
      type: Object as PropType<ChargingSiteMathematicalModelFuse>,
      required: true,
    },

    availableChargers: {
      type: Array as PropType<Charger[]>,
      required: true,
    },

    chargersByFuseId: {
      type: Object as PropType<Record<string, Charger>>,
      required: true,
    },
  },

  computed: {
    allocatableChargers(): Charger[] {
      if (this.value.id) {
        return this.availableChargers.concat(
          this.chargersByFuseId[this.value.id]
        )
      } else {
        return []
      }
    },
  },

  methods: {
    addFuse() {
      this.value.subFuses.push({
        name: "",
        phase1CapacityAmps: "",
        phase2CapacityAmps: "",
        phase3CapacityAmps: "",
        subFuses: [],
      })
    },

    removeFuse(index: number) {
      this.value.subFuses.splice(index, 1)
    },

    removeThisFuse() {
      this.$emit("remove")
    },
  },
})

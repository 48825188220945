














import Vue from "vue"
import OptimizationOverviewCard from "./Home/OptimizationOverviewCard.vue"
import datasource from "@/datasource"
import type { ChargingSite } from "@/datasource/chargers"

export default Vue.extend({
  components: {
    OptimizationOverviewCard,
  },

  data() {
    return {
      loading: true,
      chargingSite: null as ChargingSite | null,
    }
  },

  watch: {
    chargingSite() {
      const id = this.chargingSite?.id
      if (this.$route.query.chargingSite !== id) {
        this.$router.push({ query: { chargingSite: this.chargingSite?.id } })
      }
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      const chargingSiteId = this.$route.query.chargingSite
      if (chargingSiteId) {
        const result = await datasource.chargers.getChargingSite(
          chargingSiteId.toString()
        )
        if (result instanceof Error) {
          console.error(result)
        } else {
          this.chargingSite = result
        }
      }

      this.loading = false
    },
  },
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"flex-fh flex-col flex-grow flex-clip",attrs:{"fluid":""}},[_c('VCard',{staticClass:"flex-grow flex-col flex-clip",attrs:{"loading":_vm.loading}},[_c('VCardTitle',[_c('span',[_vm._v("Web Users")]),_c('VSpacer'),_c('WebUserSignupDialog',{on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"white--text mr-1",attrs:{"icon":"","color":"green"}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-plus")])],1)]}}])})],1),_c('VCardText',{staticClass:"flex-clip"},[_c('VDataTable',{staticClass:"overflow-auto",attrs:{"headers":_vm.headers,"items":_vm.webUsers,"sort-by":"email","fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('WebUserEmail',{attrs:{"webUser":item},on:{"saved":function($event){return _vm.loadData()}}})]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('WebUserRoles',{attrs:{"webUser":item,"availableRoles":_vm.availableRoles},on:{"saved":function($event){return _vm.loadData()}}})]}},{key:"item.permittedChargingSites",fn:function(ref){
var item = ref.item;
return [_c('WebUserChargingSites',{attrs:{"webUser":item,"chargingSites":_vm.availableChargingSites},on:{"saved":function($event){return _vm.loadData()}}})]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
return [_c('VMenu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('VList',[_c('VListItem',[_c('VListItemContent',[_c('ConfirmedButton',{attrs:{"color":"red","text-color":"white"},on:{"click":function($event){return _vm.deleteWebUser(item)}}},[_vm._v("Delete")])],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
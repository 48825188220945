import datasource from "@/datasource"
import { Result } from "@/utils"

type RawSchedule = {
  id: number
  name: string
  function: string
  schedule: string
  arguments: string
  quantumJob: {
    state: string
  }
}

export type Schedule = {
  id: number | null
  name: string
  function: string
  schedule: string
  arguments: string
  state: string
}

export type JobStatistics = {
  worker: string
  state: string
  count: number
  dynamicJobId: number | null
  lastAttemptedAt: Date | null
}

export type CreateScheduleResponse = {
  data?: {
    createDynamicJob?: Schedule
  }
}

const backgroundJobs = {
  async jobStatistics(): Promise<Result<Array<JobStatistics>>> {
    type Response = {
      data?: {
        jobStatistics?: Array<JobStatistics>
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(`
      query {
        jobStatistics {
          worker
          state
          count
          dynamicJobId
          lastAttemptedAt
        }
      }
      `)

    return Result.map(response, (x) => x.data?.jobStatistics || [])
  },

  async listJobFunctions(): Promise<Result<Array<string>>> {
    type Response = {
      data?: {
        availableDynamicJobFunctions?: Array<string>
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(`
      query {
        availableDynamicJobFunctions
      }
    `)

    return Result.map(
      response,
      (x) => x.data?.availableDynamicJobFunctions || []
    )
  },

  async listSchedules(): Promise<Result<Array<Schedule>>> {
    type Response = {
      data?: {
        dynamicJobs?: RawSchedule[]
      }
    }
    const response: Result<Response> = await datasource.graphql_flexibility(`
      query {
        dynamicJobs {
          id
          name
          function
          schedule
          arguments
          quantumJob {
            state
          }
        }
      }
    `)

    return Result.map(response, function (response: Response) {
      if (response.data?.dynamicJobs) {
        return response.data.dynamicJobs.map(function (schedule: RawSchedule) {
          return {
            ...schedule,
            state: schedule.quantumJob.state,
          }
        })
      } else {
        return []
      }
    })
  },

  async createDynamicSchedule(
    schedule: Schedule
  ): Promise<Result<CreateScheduleResponse>> {
    const response: Result<CreateScheduleResponse> =
      await datasource.graphql_flexibility(
        `
      mutation($name: String!, $function: String!, $schedule: String!, $arguments: Json) {
        createDynamicJob(name: $name, function: $function, schedule: $schedule, arguments: $arguments) {
          id
        }
      }
      `,
        {
          name: schedule.name,
          function: schedule.function,
          schedule: schedule.schedule,
          arguments: schedule.arguments,
        }
      )

    return response
  },

  async updateDynamicSchedule(schedule: Schedule): Promise<Result<void>> {
    type Response = {
      data?: {
        dynamicJob?: {
          updateDynamicJob?: Schedule
        }
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($id: ID!, $name: String!, $function: String!, $schedule: String!, $arguments: Json) {
        dynamicJob(id: $id) {
          updateDynamicJob(name: $name, function: $function, schedule: $schedule, arguments: $arguments) {
            id
          }
        }
      }
      `,
      {
        id: schedule.id,
        name: schedule.name,
        function: schedule.function,
        schedule: schedule.schedule,
        arguments: schedule.arguments,
      }
    )

    return Result.toVoid(response)
  },

  async deleteDynamicSchedule(schedule: Schedule): Promise<Result<void>> {
    type Response = {
      data?: {
        dynamicJob?: {
          id?: number
        }
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($id: ID!) {
        dynamicJob(id: $id) {
          delete {
            id
          }
        }
      }
      `,
      {
        id: schedule.id,
      }
    )

    return Result.toVoid(response)
  },
}

export default backgroundJobs

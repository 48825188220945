


















import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import { ApiResponseWithErrors } from "@/datasource"
import type { ChargingSite } from "@/datasource/chargers"
import type { BillingPeriod } from "@/datasource/payments"

export default Vue.extend({
  props: {
    chargingSite: {
      type: Object as PropType<ChargingSite>,
      required: true,
    },

    date: {
      type: Date as PropType<Date>,
      required: true,
    },

    shouldLoad: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      billingPeriodResponse: "" as BillingPeriod | string,
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      if (this.chargingSite.id && this.shouldLoad) {
        const result = await datasource.payments.getBillingPeriod(
          this.chargingSite.id,
          this.date
        )

        if (result instanceof ApiResponseWithErrors) {
          console.error(result)
          const response = result.response

          if (
            response.errors &&
            response.errors[0] &&
            response.errors[0].message
          ) {
            this.billingPeriodResponse =
              (response.errors[0].message as string) || "an error occurred"
          } else {
            this.billingPeriodResponse = "an error occurred"
          }
        } else if (result instanceof Error) {
          console.error(result)
          this.billingPeriodResponse = "an error occurred"
        } else {
          this.billingPeriodResponse = result
        }
      }

      this.loading = false
    },

    blur() {
      this.$emit("blur")
    },
  },
})

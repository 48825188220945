






























import Vue, { PropType } from "vue"
import _ from "underscore"
import { DateTime } from "luxon"
import Big from "big.js"
import type { Charger } from "@/datasource/chargers"
import type { ChargingSession } from "@/datasource/charging"
import BooleanIcon from "@/components/BooleanIcon.vue"
import CollapsibleDataTable from "@/components/CollapsibleDataTable.vue"

type Row = {
  started: string
  isSmart: boolean
  authSource: string
  authdAt: string
  energyTransferred: string
  maximumObservedPowerOutputKw: Big | null
  currentOutputPhasesAAtMaximumPowerOutputW: number[] | null
  isHealthy: boolean
  instantCost: string
  smartCost: string
  savings: string
  connector: number
  ocppSoc: number | null
  ocppVehicleId: string | null
  currentOfferedA: number | null
  ocppTransactionIds: string[]
  session: ChargingSession
  vehicleName: string
  routeId: string | null
  driverId: string | null
  routeDepartureTime: string | null
}

export default Vue.extend({
  components: { BooleanIcon, CollapsibleDataTable },

  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
  },

  data() {
    return {
      chargingSessions: [] as ChargingSession[],
      rows: [] as Row[],
      headers: [
        {
          text: "Started (UTC)",
          value: "started",
          width: "0",
          align: "center",
        },
        { text: "Smart?", value: "isSmart", width: "0" },
        { text: "Auth Source", value: "authSource", width: "0" },
        { text: "Authd At", value: "authdAt", width: "0" },
        { text: "Connector", value: "connector", width: 0, align: "right" },
        { text: "OCPP SoC", value: "ocppSoc", width: 0, align: "right" },
        {
          text: "Current Offered (A)",
          value: "currentOfferedA",
          width: 0,
          align: "right",
        },
        {
          text: "Energy (kWh)",
          value: "energyTransferred",
          width: "0",
          align: "right",
        },
        {
          text: "Max Power (kw)",
          value: "maximumObservedPowerOutputKw",
          width: "0",
          align: "right",
        },
        {
          text: "Output at Max Power (A)",
          value: "currentOutputPhasesAAtMaximumPowerOutputW",
          width: "0",
        },
        { text: "Health", value: "isHealthy", width: "0" },
        { text: "Vehicle Name", value: "vehicleName", align: "left" },
        { text: "OCPP VID", value: "ocppVehicleId", width: 0 },
        {
          text: "OCPP Transactions",
          value: "ocppTransactionIds",
          width: 0,
          align: "right",
        },
        {
          text: "Route ID",
          value: "routeId",
          align: "left",
        },
        {
          text: "Driver ID",
          value: "driverId",
          align: "left",
        },
        {
          text: "Route Departure Time",
          value: "routeDepartureTime",
          align: "left",
        },
        { text: "Instant Cost Estimate", value: "instantCost", width: "0" },
        { text: "Smart Cost Estimate", value: "smartCost", width: "0" },
        { text: "Savings Estimate", value: "savings", width: "0" },
        { text: "", value: "spacing" },
      ],
    }
  },

  computed: {
    instantCostHeader(): string {
      let currencyStr = ""

      if (
        this.chargingSessions.length &&
        this.chargingSessions[0].chargingSessionPricings.length
      ) {
        currencyStr = ` (${this.chargingSessions[0].chargingSessionPricings[0].cost.currency})`
      }

      return `Instant Cost Estimate${currencyStr}`
    },

    smartCostHeader(): string {
      let currencyStr = ""

      if (
        this.chargingSessions.length &&
        this.chargingSessions[0].chargingSessionPricings.length
      ) {
        currencyStr = ` (${this.chargingSessions[0].chargingSessionPricings[0].cost.currency})`
      }

      return `Smart Cost Estimate${currencyStr}`
    },
  },

  watch: {
    charger() {
      this.rows = this.charger.chargingSessions.map((s) => this.toRow(s))
    },
  },

  created() {
    this.rows = this.charger.chargingSessions.map((s) => this.toRow(s))
  },

  methods: {
    toRow(session: ChargingSession): Row {
      return {
        started: this.startedAtDisplay(session),
        isSmart: session.smartChargingEnabled,
        authSource: this.authSourceDisplay(session),
        authdAt: this.dateDisplay(session.authorizedAt),
        energyTransferred: this.energyUsageKWHDisplay(session),
        maximumObservedPowerOutputKw: session.maximumObservedPowerOutputW
          ? Big(session.maximumObservedPowerOutputW).div(1000).round(2)
          : null,
        currentOutputPhasesAAtMaximumPowerOutputW:
          session.currentOutputPhasesAAtMaximumPowerOutputW,
        isHealthy: session.isHealthy,
        instantCost: this.instantChargingSessionPricing(session),
        smartCost: this.smartChargingSessionPricing(session),
        savings: this.estimatedChargingSavings(session),
        ocppSoc: session.ocppSoc,
        currentOfferedA: session.currentOfferedA,
        connector: session.chargerConnectorId,
        ocppVehicleId: session.ocppVehicleId,
        ocppTransactionIds: session.ocppTransactionIds,
        session: session,
        vehicleName: session.car?.displayName || "",
        routeId: session.routeId,
        driverId: session.routeDriverId,
        routeDepartureTime:
          session.routeDepartureTime &&
          DateTime.fromISO(session.routeDepartureTime).toLocaleString(
            DateTime.TIME_24_SIMPLE
          ),
      }
    },

    energyUsageKWHDisplay(session: ChargingSession): string {
      return Big(session.energyUsageWh).div(1000).round(1).toString()
    },

    instantChargingSessionPricing(session: ChargingSession): string {
      const instantPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "instant"
      )
      return instantPricing?.cost?.amount || ""
    },

    smartChargingSessionPricing(session: ChargingSession): string {
      const smartPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "smart"
      )
      return smartPricing?.cost?.amount || ""
    },

    estimatedChargingSavings(session: ChargingSession): string {
      const instantPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "instant"
      )

      const smartPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "smart"
      )

      const savings =
        instantPricing && smartPricing
          ? Big(instantPricing.cost.amount)
              .minus(Big(smartPricing.cost.amount))
              .toString()
          : ""

      return savings ? `${instantPricing?.cost?.currency} ${savings}` : ""
    },

    authSourceDisplay(session: ChargingSession): string {
      switch (session.authorizationSource) {
        case "AUTH_NOT_REQUIRED":
          return "N/A"
        default:
          return session.authorizationSource || ""
      }
    },

    displayRfidToken(session: ChargingSession): string {
      return (
        session.rfidToken?.secret || session.rfidToken?.externalReference || ""
      )
    },

    startedAtDisplay(session: ChargingSession): string {
      const startedAt = DateTime.fromISO(session.createdAt)
      const delta = Math.floor(startedAt.diffNow("hours").hours * -1)

      return `${this.dateDisplay(session.createdAt)} (${delta} hours ago)`
    },

    dateDisplay(dtString: string | null): string {
      if (dtString) {
        return DateTime.fromISO(dtString).toFormat("d LLL T")
      } else {
        return ""
      }
    },
  },
})










































































































































































































































































import Vue from "vue"
import Big from "big.js"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import type { Car, CarSummary } from "@/datasource/cars"
import type { AppUser } from "@/datasource/appUsers"
import type { Charger } from "@/datasource/chargers"

import BooleanIcon from "@/components/BooleanIcon.vue"
import ConfirmedButton from "@/components/ConfirmedButton.vue"
import CollapsibleDataTable from "@/components/CollapsibleDataTable.vue"
import CreateVehicleDialog from "@/components/CreateVehicleDialog.vue"
import EditCarDialog from "./EditCarDialog.vue"
import ChargePreferenceFields from "./ChargePreferenceFields.vue"
import BatteryCapacityDialog from "./BatteryCapacityDialog.vue"
import SetPercentageEnergyForecastDialog from "@/components/SetPercentageEnergyForecastDialog.vue"
import SetCarsAppUsers from "@/components/Cars/SetCarsAppUsers.vue"
import FdtPredictionModelSelect from "@/components/FdtPredictionModelSelect.vue"
import MachineLearningLog from "@/components/MachineLearningLog.vue"
import TimeForecast from "@/components/TimeForecast.vue"
import NumberValue from "@/components/NumberValue.vue"

export default Vue.extend({
  components: {
    BooleanIcon,
    ConfirmedButton,
    CollapsibleDataTable,
    CreateVehicleDialog,
    EditCarDialog,
    ChargePreferenceFields,
    BatteryCapacityDialog,
    SetPercentageEnergyForecastDialog,
    SetCarsAppUsers,
    FdtPredictionModelSelect,
    MachineLearningLog,
    TimeForecast,
    NumberValue,
  },

  data() {
    return {
      loading: true,
      search: "",
      error: null as null | string,
      selectedUser: null as AppUser | null,
      cars: [] as CarSummary[],
      selected: [] as CarSummary[],
      snack: false,
      snackText: "",
      snackColour: "",
      showDepartureTimeDialog: false,
      showOnboardChargerCapacityDialogue: false,
      showBatterySizeDialog: false,
      settingBatterySize: false,
      bulkAppUserDialog: false,
      bulkEditBatterySize: null,
      bulkDepartureTime: null,
      bulkSettingDepartureTimes: false,
      bulkSettingOnboardChargerCapacity: false,
      bulkOnboardChargerCapacityPhases: "",
      bulkSettingOnboardChargerCapacityDialogue: false,
      showBulkSmartChargeDialog: false,
      bulkSettingSmartCharge: false,
      bulkSetSmartChargeValue: null,
      headers: [
        { text: "Name", value: "displayName" },
        { text: "Users", value: "appUsers" },
        { text: "VIN", value: "vin" },
        {
          text: "Charging Site",
          value: "chargers",
          sort: (a: Charger[], b: Charger[]) => {
            const siteA =
              (a.length && (a[0]?.chargingSite?.name as string)) || ""
            const siteB =
              (b.length && (b[0]?.chargingSite?.name as string)) || ""

            return siteA.localeCompare(siteB)
          },
        },
        {
          text: "Battery %",
          value: "apiData.stateOfChargePercentage",
          align: "right",
        },
        {
          text: "Battery size Kwh",
          value: "batterySizeWh",
          align: "center",
        },
        { text: "Min-Max Charge", value: "chargePreference", align: "center" },
        {
          text: "Smart Charging?",
          value: "smartChargingEnabled",
          align: "center",
        },
        {
          text: "Infer fully charged?",
          value: "smartSocInferenceEnabled",
          align: "center",
        },
        {
          text: "OCPP VID",
          value: "ocppVehicleId",
        },
        {
          text: "Preferred departure time",
          value: "preferenceDepartureTime",
          align: "center",
        },
        {
          text: "Percentage Energy Forecast",
          value: "percentageEnergyForecast",
          align: "center",
        },
        {
          text: "Onboard Charger Capacity Kw",
          value: "onboardChargerCapacityPhasesW",
          align: "center",
        },
        {
          text: "Edit",
          value: "edit",
          width: "0",
          align: "center",
          sortable: false,
        },
        {
          text: "Menu",
          value: "menu",
          width: "0",
          align: "center",
          sortable: false,
        },
      ],
    }
  },

  watch: {
    $route() {
      this.loadData()
    },
  },

  created() {
    this.loadData()
  },

  mounted() {
    this.streamCars()
  },

  methods: {
    ...formatters.number,
    ...formatters.dateTime,

    async bulkSetPreferredDepartureTime() {
      this.bulkSettingDepartureTimes = true
      const departureTime = this.bulkDepartureTime + ":00"

      Promise.all(
        this.selected.map((c: CarSummary) => {
          datasource.cars.updateCar({
            ...c,
            preferenceDepartureTime: departureTime,
          })
        })
      ).then(() => {
        this.selected = []
        this.bulkDepartureTime = null
        this.bulkSettingDepartureTimes = false
        this.showDepartureTimeDialog = false
        this.loadData()
      })
    },

    async bulkSetOnboardChargerCapacity() {
      this.bulkSettingOnboardChargerCapacity = true

      const chargerCapacityPhasesW = this.bulkOnboardChargerCapacityPhases
        .split(",")
        .map((p) => Big(parseFloat(p)).times(1000).toNumber())

      Promise.all(
        this.selected.map((c: CarSummary) => {
          datasource.cars.updateCar({
            ...c,
            onboardChargerCapacityPhasesW: chargerCapacityPhasesW,
          })
        })
      ).then(() => {
        this.selected = []
        this.bulkOnboardChargerCapacityPhases = ""
        this.bulkSettingOnboardChargerCapacity = false
        this.showOnboardChargerCapacityDialogue = false
        this.loadData()
      })
    },

    async bulkSetSmartCharge() {
      this.bulkSettingSmartCharge = true

      await Promise.all(
        this.selected.map((c: CarSummary) => {
          datasource.cars.updateCar({
            ...c,
            smartChargingEnabled: !!this.bulkSetSmartChargeValue,
          })
        })
      ).then(() => {
        this.selected = []
        this.bulkSetSmartChargeValue = null
        this.bulkSettingSmartCharge = false
        this.showBulkSmartChargeDialog = false
      })

      this.loadData()
    },

    async bulkUpdateBatterySizeKwh() {
      this.settingBatterySize = true
      const batterySizeWh = this.bulkEditBatterySize
        ? Big(this.bulkEditBatterySize).times(1000).toNumber()
        : null

      const result = await datasource.cars.bulkUpdateCars(this.selected, {
        batterySizeWh: batterySizeWh,
      })

      if (result instanceof Error) {
        console.error(result)
      } else {
        this.selected = []
        this.showBatterySizeDialog = false
        this.settingBatterySize = false
        this.bulkEditBatterySize = null

        if (result.erroredCars.length) {
          this.error = `Failed to update cars ${result.erroredCars.map(
            (c) => c.displayName
          )}`
        } else {
          this.error = null
        }

        this.loadData()
      }
    },

    expiryTextForDepartureTime(car: Car) {
      const departureTimeExpiry = new Date(car.preferenceDepartureTimeExpiresAt)
      const now = new Date()

      return now < departureTimeExpiry
        ? `Expires at ${departureTimeExpiry}`
        : `Expired at ${departureTimeExpiry}`
    },

    preferenceDepartureTimeDisplay(departureTime: string | null): string {
      /* Just removing the seconds */
      return departureTime ? departureTime.slice(0, -3) : ""
    },

    onboardChargerCapacityDisplay(phases: number[] | null): string {
      return phases
        ? phases.map((p) => Big(p).div(1000).round(2)).join(", ")
        : ""
    },

    batterySizeDisplay(car: Car): string {
      return car.batterySizeWh
        ? Big(car.batterySizeWh).div(1000).round(2).toString()
        : ""
    },

    streamCars() {
      const withCallback = (updatedCar: Car) => {
        const index = this.cars.findIndex((car) => car.id == updatedCar.id)

        if (index !== -1) {
          this.cars.splice(index, 1, updatedCar)
        } else {
          this.cars.push(updatedCar)
        }
      }

      datasource.cars.waitForCarUpdate(withCallback)
    },

    async loadData() {
      this.loading = true
      const { appUserId } = this.$route.params

      if (appUserId) {
        const appUserResult = await datasource.appUsers.getAppUser(appUserId)
        if (appUserResult instanceof Error || !appUserResult) {
          console.error(appUserResult)
          return
        }
        this.selectedUser = appUserResult

        const carsResult = await datasource.cars.listCarsForUser(
          this.selectedUser
        )
        if (carsResult instanceof Error) {
          console.error(carsResult)
          return
        }
        this.cars = carsResult
      } else {
        this.selectedUser = null

        const result = await datasource.cars.listCarSummaries()
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.cars = result
      }

      this.loading = false
    },

    async deleteCar(car: Car) {
      const result = await datasource.cars.deleteCar(car)
      if (result instanceof Error) {
        console.error(result)
        return
      }
      this.loadData()
    },

    async deleteVehicles() {
      const result = await datasource.cars.deleteVehicles(this.selected)
      if (result instanceof Error) {
        console.error(result)
        return
      }
      this.loadData()
    },

    async saveChargePreference(car: Car) {
      const result = await datasource.cars.updateChargingPreferences(car)
      this.snack = true
      this.loadData()
      if (result instanceof Error || !result) {
        console.error(result)
        this.snackText = "Charge preferences not updated"
        this.snackColour = "error"
        return
      }
      this.snackText = "Charge preferences updated"
      this.snackColour = "success"
    },

    chargingSiteDisplay(car: Car): string {
      if (!!car.chargers.length && !!car.chargers[0].chargingSite) {
        return car.chargers[0].chargingSite.name
      } else {
        return ""
      }
    },

    onBulkAppUserSave() {
      this.bulkAppUserDialog = false
      this.loadData()
    },
  },
})

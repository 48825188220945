






















import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type { WebUser } from "@/datasource/webUsers"

export default Vue.extend({
  props: {
    webUser: {
      type: Object as PropType<WebUser>,
      required: true,
    },
  },

  data() {
    return {
      originalEmail: this.webUser.email as string,
      modifiedEmail: this.webUser.email as string,
      saving: false,
    }
  },

  watch: {
    webUser(value) {
      this.originalEmail = value.email
      this.modifiedEmail = value.email
    },
  },

  methods: {
    isEntryModified(): boolean {
      return this.originalEmail != this.modifiedEmail
    },

    reset() {
      this.modifiedEmail = this.originalEmail
    },

    hintText(): string {
      return this.isEntryModified() ? "NOT SAVED" : ""
    },

    async save() {
      this.saving = true
      const result = datasource.webUsers.updateWebUser(this.webUser, {
        email: this.modifiedEmail,
      })

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.saving = false
      this.$emit("saved")
      this.modifiedEmail = this.originalEmail
    },
  },
})

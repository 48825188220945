



























































































import Vue from "vue"
import datasource from "@/datasource"
import EditChargerConfigurationProfileDialog from "@/components/ChargerConfigurationProfiles/EditChargerConfigurationProfileDialog.vue"
import type { ConfigurationProfileSummary } from "@/datasource/chargers"

export default Vue.extend({
  components: {
    EditChargerConfigurationProfileDialog,
  },

  data() {
    return {
      loading: true,
      configurationProfiles: [] as ConfigurationProfileSummary[],
      headers: [
        { text: "Name", value: "name" },
        { text: "", value: "menu", width: 0, align: "right", sortable: false },
      ],
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      {
        const result = await datasource.chargers.listConfigurationProfiles()
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.configurationProfiles = result
      }

      this.loading = false
    },

    async showSnackbar(text: string, color?: string) {
      this.snackbarText = text
      this.snackbarColor = color || "success"
      this.snackbar = true
    },

    async deleteProfile(profile: ConfigurationProfileSummary) {
      const result = await datasource.chargers.deleteConfigurationProfile(
        profile
      )
      if (result instanceof Error) {
        console.error(result)
        this.showSnackbar("Failed to delete profile.", "error")
      } else {
        this.showSnackbar(`Deleted profile ${profile.id} (${profile.name})`)
        this.loadData()
      }
    },
  },
})

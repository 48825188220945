




















import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type { AppUser } from "@/datasource/appUsers"

export default Vue.extend({
  props: {
    appUser: {
      type: Object as PropType<AppUser>,
      required: true,
    },
  },

  data() {
    return {
      showDialog: false,
      loading: true,
      loginQrCodeSvg: "",
    }
  },

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      }
    },
  },

  methods: {
    async loadData() {
      const result = await datasource.appUsers.getLoginQrCode(this.appUser)
      if (result instanceof Error) {
        console.error(result)
        return
      }
      this.loginQrCodeSvg = result
      this.loading = false
    },
  },
})



























































































































import Vue from "vue"
import datasource from "@/datasource"
import type {
  VariableGridCost,
  CreateVariableGridCostInput,
  EditVariableGridCostInput,
} from "@/datasource/payments"

import type { GridOperator } from "@/datasource/chargers"

import ConfirmedButton from "@/components/ConfirmedButton.vue"
import DateTimePicker from "@/components/DateTimePicker.vue"

export default Vue.extend({
  components: { DateTimePicker, ConfirmedButton },
  data() {
    return {
      loading: true,
      gridCosts: [] as Array<VariableGridCost>,
      gridOperators: [] as Array<GridOperator>,
      headers: [
        { text: "Valid From", value: "validFrom" },
        { text: "Grid Operator", value: "gridOperator.name" },
        { text: "Day Rate", value: "peakRate.display" },
        { text: "Evening/Weekend Rate", value: "offpeakRate.display" },
        { value: "actions" },
      ],
      newGridCost: datasource.payments.defaultCreateVariableGridCostInput(),
      modifiedGridCost: datasource.payments.defaultEditVariableGridCostInput(),
      showCreateDialog: false,
      showEditDialog: false,
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true
      const gridCosts = await datasource.payments.listVariableGridCosts()

      if (gridCosts instanceof Error) {
        console.error(gridCosts)
        return
      }

      this.gridCosts = gridCosts

      const gridOperators = await datasource.chargers.listGridOperators()

      if (gridOperators instanceof Error) {
        console.error(gridOperators)
        return
      }

      this.gridOperators = gridOperators
      this.loading = false
    },

    openCreateDialog() {
      this.showCreateDialog = true
    },

    openEditDialog(gridCost: VariableGridCost) {
      this.modifiedGridCost = datasource.payments.variableGridCostToInput({
        ...gridCost,
      }) as EditVariableGridCostInput
      this.showEditDialog = true
    },

    closeCreationDialog() {
      this.showCreateDialog = false
      this.newGridCost =
        datasource.payments.defaultCreateVariableGridCostInput()
    },

    closeEditDialog() {
      this.showEditDialog = false
      this.modifiedGridCost =
        datasource.payments.defaultEditVariableGridCostInput()
    },

    saveNew(gridCost: CreateVariableGridCostInput) {
      datasource.payments.createVariableGridCost(gridCost).then(() => {
        this.showCreateDialog = false
        this.newGridCost =
          datasource.payments.defaultCreateVariableGridCostInput()
        this.loadData()
      })
    },

    updateVariableGridCost(gridCost: EditVariableGridCostInput) {
      datasource.payments.updateVariableGridCost(gridCost).then(() => {
        this.showEditDialog = false
        this.modifiedGridCost =
          datasource.payments.defaultEditVariableGridCostInput()
        this.loadData()
      })
    },

    deleteVariableGridCost(gridCost: EditVariableGridCostInput) {
      datasource.payments.deleteVariableGridCost(gridCost).then(() => {
        this.showEditDialog = false
        this.modifiedGridCost =
          datasource.payments.defaultEditVariableGridCostInput()
        this.loadData()
      })
    },
  },
})

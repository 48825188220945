







































































import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type { Charger } from "@/datasource/chargers"

type ConnectorInput = {
  phaseOrder: string
  phase1MaxPowerW: string
  phase2MaxPowerW: string
  phase3MaxPowerW: string
}

function initialData() {
  return {
    showDialog: false,
    saving: false,
    connectors: [] as Array<ConnectorInput>,
    phaseOrders: [
      { value: "PHASE_1_2_3", text: "1-2-3" },
      { value: "PHASE_2_3_1", text: "2-3-1" },
      { value: "PHASE_3_1_2", text: "3-1-2" },
    ],
  }
}

export default Vue.extend({
  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
  },

  data: initialData,

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      }
    },
  },

  methods: {
    async loadData() {
      const formatPower = function (power: number): string {
        return (power / 1000).toString()
      }
      this.connectors = this.charger.mathematicalModel.connectors.map((x) => ({
        phaseOrder: x.phaseOrder,
        phase1MaxPowerW: formatPower(x.phase1MaxPowerW),
        phase2MaxPowerW: formatPower(x.phase2MaxPowerW),
        phase3MaxPowerW: formatPower(x.phase3MaxPowerW),
      }))
    },

    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true

      let newModel = { ...this.charger.mathematicalModel }
      Object.assign(newModel, {
        connectors: this.connectors.map((x) => ({
          phaseOrder: x.phaseOrder,
          phase1MaxPowerW: Number.parseFloat(x.phase1MaxPowerW) * 1000,
          phase2MaxPowerW: Number.parseFloat(x.phase2MaxPowerW) * 1000,
          phase3MaxPowerW: Number.parseFloat(x.phase3MaxPowerW) * 1000,
        })),
      })

      await datasource.chargers.setMathematicalModelForCharger(
        this.charger,
        newModel
      )

      this.saving = false
      this.$emit("saved")
      this.close()
    },

    addConnector() {
      const newConnector = { ...this.connectors[this.connectors.length - 1] }
      this.connectors.push(newConnector)
    },

    removeConnector(index: number) {
      this.connectors.splice(index, 1)
    },
  },
})















import Vue from "vue"
import datasource from "@/datasource"

import EntityTableCard from "@/components/EntityTableCard.vue"
import StringValue from "@/components/StringValue.vue"
import ChargingPlanStepsValue from "@/components/ChargingPlanStepsValue.vue"
import ChargingSessionLogLink from "@/components/ChargingSessionLogLink.vue"
import NewChargingPlanTemplateRunDialog from "@/components/NewChargingPlanTemplateRunDialog.vue"

export default Vue.extend({
  components: {
    EntityTableCard,
    NewChargingPlanTemplateRunDialog,
  },

  data() {
    return {
      readFunction: datasource.charging.listChargingPlanTemplateRuns,
      controls: [{ component: NewChargingPlanTemplateRunDialog }],
      types: {
        id: {
          component: StringValue,
          label: "ID",
          props: {
            editable: false,
          },
        },
        chargingSessionId: {
          component: ChargingSessionLogLink,
          label: "Links",
        },
        steps: {
          component: ChargingPlanStepsValue,
          label: "Steps",
          props: {
            editable: false,
          },
          opts: {
            align: "center",
            sortable: false,
          },
        },
      },
    }
  },
})

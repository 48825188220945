




















import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type {
  Charger,
  ChargingSite,
  ChargingSiteMathematicalModel,
} from "@/datasource/chargers"

export default Vue.extend({
  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
  },

  data() {
    return {
      chargingSite: null as ChargingSite | null,
      chargingSiteMathematicalModel:
        null as ChargingSiteMathematicalModel | null,
      loading: false,
    }
  },

  watch: {
    charger() {
      this.loadData()
    },

    chargingSite(value) {
      if (value) {
        this.loadChargingSiteMathematicalModel(value)
      }
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      if (this.charger.chargingSite?.id) {
        this.loading = true

        const result = await datasource.chargers.getChargingSite(
          this.charger.chargingSite.id
        )

        if (result instanceof Error) {
          console.error(result)
          this.loading = false
          return
        }

        this.loading = false
        this.chargingSite = result
      }
    },

    async loadChargingSiteMathematicalModel(chargingSite: ChargingSite) {
      const result = await datasource.chargers.getChargingSiteMathematicalModel(
        chargingSite
      )

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.chargingSiteMathematicalModel = result
    },

    gridOperatorDisplay(chargingSite: ChargingSite): string {
      return chargingSite.gridOperator?.name || ""
    },

    tagsDisplay(model: null | ChargingSiteMathematicalModel): string {
      if (model?.environmentTags) {
        return model.environmentTags.join(", ")
      } else {
        return ""
      }
    },
  },
})




































import Vue from "vue"
import _ from "underscore"
import datasource from "@/datasource"

import type {
  Charger,
  ChargingSite,
  ChargingSiteSummary,
  ChargingSiteMathematicalModel,
} from "@/datasource/chargers"

import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"
import Fuse from "@/components/Fuse.vue"

export default Vue.extend({
  components: { ChargingSiteSelect, Fuse },

  data() {
    return {
      loading: false,
      chargingSiteSummary: null as null | ChargingSiteSummary,
      chargingSite: null as null | ChargingSite,
      mathematicalModel: null as null | ChargingSiteMathematicalModel,
      chargers: [] as Charger[],
      chargersByFuseId: {} as Record<number, Charger[]>,
    }
  },

  computed: {
    unallocatedChargers(): Charger[] {
      const allocatedChargers = Object.values(this.chargersByFuseId).flat()
      return _.difference(this.chargers, allocatedChargers)
    },

    unallocatedChargerDisplay(): string {
      return this.unallocatedChargers.map((c) => c.displayName).join(", ")
    },
  },

  methods: {
    async loadChargingSiteData() {
      if (this.chargingSiteSummary) {
        this.loading = true
        const siteResult = await datasource.chargers.getChargingSite(
          this.chargingSiteSummary.id
        )

        if (siteResult instanceof Error || !siteResult) {
          console.error(siteResult)
          return
        }

        this.chargingSite = siteResult

        const modelResult =
          await datasource.chargers.getChargingSiteMathematicalModel(siteResult)

        if (modelResult instanceof Error) {
          console.error(modelResult)
          return
        }

        this.mathematicalModel = { ...modelResult }

        const chargersResult =
          await datasource.chargers.listChargersForChargingSite(siteResult)

        if (chargersResult instanceof Error) {
          console.error(chargersResult)
          return
        }

        this.chargers = chargersResult
        this.chargersByFuseId = this.indexChargersByFuseId(chargersResult)

        this.loading = false
      }
    },

    async save() {
      if (this.chargingSite && this.mathematicalModel) {
        await datasource.chargers.setMathematicalModelForChargingSite(
          this.chargingSite,
          this.mathematicalModel
        )

        this.chargers.forEach((c) => {
          const fuseId = _.findKey(this.chargersByFuseId, (chargers) =>
            _.contains(chargers, c)
          )
          const chargerModel = {
            ...c.mathematicalModel,
            fuse: {
              id: fuseId,
              // The rest of these fields aren't actually used in the request
              name: "",
              phase1CapacityAmps: "",
              phase2CapacityAmps: "",
              phase3CapacityAmps: "",
              subFuses: [],
            },
          }

          datasource.chargers.setMathematicalModelForCharger(c, chargerModel)
        })

        this.loadChargingSiteData()
      }
    },

    indexChargersByFuseId(chargers: Charger[]): Record<number, Charger[]> {
      return chargers.reduce((acc: Record<number, Charger[]>, val) => {
        const fuseId = val.mathematicalModel?.fuse?.id

        if (fuseId) {
          acc[fuseId] = acc[fuseId] || []
          acc[fuseId].push(val)
        }

        return acc
      }, {})
    },

    subtitle(): string {
      return this.chargingSiteSummary ? this.chargingSiteSummary.name : ""
    },
  },
})

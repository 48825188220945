import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    refreshingToken: false,
    loginAccountEmail: "",
    loginAccountDataOwnerName: "",
    hasElevatedPrivileges: false,
  },

  mutations: {
    setLoggedIn(state, value) {
      state.loggedIn = value
    },

    setRefreshingToken(state, value) {
      state.refreshingToken = value
    },

    setLoginAccountDetails(
      state,
      { email, dataOwnerName, hasElevatedPrivileges }
    ) {
      state.loginAccountEmail = email
      state.loginAccountDataOwnerName = dataOwnerName
      state.hasElevatedPrivileges = hasElevatedPrivileges
    },
  },

  actions: {},

  modules: {},
})

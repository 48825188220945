





























import Vue from "vue"
import type { PropType } from "vue"
import type { ChargingSiteMathematicalModelFuse } from "@/datasource/chargers"
import FuseFields from "./FuseFields.vue"

export default Vue.extend({
  components: {
    FuseFields,
  },

  props: {
    value: {
      type: Object as PropType<ChargingSiteMathematicalModelFuse>,
      required: true,
    },
  },

  data() {
    return {
      showDialog: false,
      fuse: { ...this.value },
    }
  },

  methods: {
    emit() {
      this.$emit("input", this.fuse)
    },

    close() {
      this.showDialog = false
    },
  },
})





































































import Vue from "vue"
import type { PropType } from "vue"
import type { ChargingSiteMathematicalModelFuse } from "@/datasource/chargers"

export default Vue.extend({
  name: "FuseFields",

  props: {
    value: {
      type: Object as PropType<ChargingSiteMathematicalModelFuse>,
      required: true,
    },

    canRemove: {
      type: Boolean,
    },

    editable: {
      type: Boolean,
      default: true,
    },

    highlightedFuseId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      fields: {
        ...this.value,
        subFuses: this.value.subFuses.map((x) => ({ ...x })),
      },
    }
  },

  computed: {
    outlined() {
      return this.highlightedFuseId == this.value?.id?.toString()
    },

    backgroundColor() {
      return this.highlightedFuseId == this.value?.id?.toString()
        ? "green lighten-4"
        : null
    },
  },

  methods: {
    emit() {
      this.$emit("input", this.fields)
    },

    addFuse() {
      this.fields.subFuses.push({
        name: "",
        phase1CapacityAmps: "",
        phase2CapacityAmps: "",
        phase3CapacityAmps: "",
        subFuses: [],
      })
    },

    removeThisFuse() {
      this.$emit("remove")
    },

    removeFuse(index: number) {
      this.fields.subFuses.splice(index, 1)
      this.emit()
    },
  },
})

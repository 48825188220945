

























































import Vue, { PropType } from "vue"
import _ from "underscore"

type Header = { text: string; value: string }

export default Vue.extend({
  props: {
    headers: {
      type: Array as PropType<Header[]>,
      required: true,
    },

    namespace: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      hiddenHeaders: [] as Header[],
    }
  },

  computed: {
    filteredHeaders(): Header[] {
      return this.headers.filter((h: Header) => !this.hiddenHeaders.includes(h))
    },
  },

  created() {
    this.hiddenHeaders = this.headers.filter(
      (h) => localStorage.getItem(`${this.namespace}-${h.value}`) == "hidden"
    )
  },

  methods: {
    hideColumn(header: Header) {
      this.hiddenHeaders.splice(0, 0, header)
      localStorage.setItem(`${this.namespace}-${header.value}`, "hidden")
    },

    showAllColumns() {
      this.hiddenHeaders.forEach((h) => this.showColumn(h))
    },

    showColumn(header: Header) {
      this.hiddenHeaders = _.without(this.hiddenHeaders, header)
      localStorage.removeItem(`${this.namespace}-${header.value}`)
    },
  },
})

























































import Vue, { PropType } from "vue"
import formatters from "@/utils/formatters"
import helpers from "@/utils/helpers"
import datasource from "@/datasource"
import type { Car, BatteryCapacityEstimate } from "@/datasource/cars"

export default Vue.extend({
  props: {
    value: {
      required: true,
      type: Number,
    },
    vehicle: {
      type: Object as PropType<Car>,
      required: true,
    },
  },

  data() {
    return {
      modifiedValue: this.value,
      loading: false,
      estimates: [] as BatteryCapacityEstimate[],
    }
  },

  methods: {
    ...formatters.number,

    emit() {
      this.$emit("input", this.modifiedValue)
    },

    close() {
      this.estimates = []
    },

    async estimateBatteryCapacity() {
      this.loading = true
      const result = await datasource.cars.estimateBatteryCapacity(this.vehicle)

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.estimates = result

      helpers.focusSelect(this, "estimateSelect")

      this.loading = false
    },
  },
})













import Vue from "vue"
import formatters from "@/utils/formatters"

import type { PropType } from "vue"
import type { ChargerActionRule } from "@/datasource/chargers"

const valueToString = formatters.chargers.chargerActionRule

export default Vue.extend({
  props: {
    value: {
      type: Object as PropType<ChargerActionRule>,
      default: null,
    },
  },

  data() {
    return {
      modified: valueToString(this.value),
      formatIsBad: false,
    }
  },

  watch: {
    value: {
      deep: true,
      handler: function (newValue: ChargerActionRule) {
        this.modified = valueToString(newValue)
      },
    },
  },

  methods: {
    emitValue() {
      if (this.modified.length == 0) {
        this.$emit("delete")
      } else {
        const newValue = this.stringToValue(this.modified)
        if (newValue) {
          this.formatIsBad = false
          this.$emit("input", newValue)
        } else {
          this.formatIsBad = true
        }
      }
    },

    stringToValue(string: string): ChargerActionRule | null {
      const matches =
        /^\s*(\d+)\s*-\s*(\d+)\s+([1-7](?:\s*,\s*[1-7])*)\s*$/.exec(string)
      if (!matches) {
        return null
      }

      const formatTime = function (hourString: string): string | null {
        const hour = Number(hourString)
        if (hour < 0 || hour > 24) {
          return null
        } else {
          return hour.toString().padStart(2, "0") + ":00:00"
        }
      }

      const start = formatTime(matches[1])
      const finish = formatTime(matches[2])

      if (!start || !finish) {
        return null
      }

      const daysOfWeek = matches[3]
        .split(",")
        .map(Number)
        .filter((x) => !isNaN(x))

      return {
        id: this.value?.id,
        action: this.value?.action || "disable_smart_charging",
        daysOfWeek: daysOfWeek,
        start: start,
        finish: finish,
      }
    },
  },
})


























































































import Vue from "vue"
import datasource from "@/datasource"
import { ValidationFailureError } from "@/datasource"
import CarSelect from "@/components/CarSelect.vue"
import ChargerSelect from "@/components/ChargerSelect.vue"
import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"
import DateTimePicker from "@/components/DateTimePicker.vue"

export default Vue.extend({
  components: {
    CarSelect,
    ChargerSelect,
    ChargingSiteSelect,
    DateTimePicker,
  },

  data() {
    return {
      showDialog: false,
      loading: true,
      saving: false,
      errors: {},
      request: {
        timezone: "",
        requestDatetime: new Date(),
        stepCount: "",
        stepSizeH: "",
        includePowerPrices: true,
        includeMarginalPowerLosses: true,
        carIds: [] as string[],
        chargerIds: [] as string[],
        chargingSiteId: null as string | null,
      },
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      const result = await datasource.optimization.getDefaultRequestArgs()
      if (result instanceof Error) {
        console.error(result)
        return
      } else {
        this.request = {
          ...result,
          stepCount: result.stepCount.toString(),
          stepSizeH: result.stepSizeH.toString(),
        }
      }

      this.loading = false
    },

    close() {
      this.showDialog = false
    },

    async save() {
      this.saving = true
      const result = await datasource.optimization.buildRequest({
        ...this.request,
        stepCount: Number.parseInt(this.request.stepCount),
        stepSizeH: Number.parseFloat(this.request.stepSizeH),
      })
      this.errors = {}
      if (result instanceof ValidationFailureError) {
        this.errors = result.errors
      } else if (result instanceof Error) {
        console.error(result)
      } else {
        this.$emit("save", result)
        this.showDialog = false
      }
      this.saving = false
    },
  },
})

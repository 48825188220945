





























































































































































import Vue from "vue"
import goTo from "vuetify/lib/services/goto"
import { DateTime } from "luxon"
import router from "@/router"
import datasource from "@/datasource"
import { ValidationFailureError } from "@/datasource"
import type { ValidationError } from "@/datasource"
import ChargerEdit from "@/components/ChargerEdit.vue"
import ChargerActions from "@/components/ChargerActions.vue"
import ChargerActiveSessions from "@/components/ChargerActiveSessions.vue"
import ChargerChargingSite from "@/components/ChargerChargingSite.vue"
import ChargerChargingSiteClusters from "@/components/ChargerChargingSiteClusters.vue"
import ChargerFuses from "@/components/ChargerFuses.vue"
import ChargerSelect from "@/components/ChargerSelect.vue"
import ChargerConnectorSummary from "@/components/ChargerConnectorSummary.vue"
import ChargerFields from "@/components/EditChargerDialog/ChargerFields.vue"
import ChargerLinks from "@/components/ChargerLinks.vue"
import ChargerManagementModuleSummary from "@/components/ChargerManagementModuleSummary.vue"
import ChargerRecentSessions from "@/components/ChargerRecentSessions.vue"
import ChargerUsage from "@/components/ChargerUsage.vue"
import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"
import ChargerVehicles from "@/components/ChargerVehicles.vue"
import ChargerWebsocket from "@/components/ChargerWebsocket.vue"
import DateTimePicker from "@/components/DateTimePicker.vue"
import { AppUser } from "@/datasource/appUsers"
import type { CarSummary } from "@/datasource/cars"
import type {
  Charger,
  ChargerSkeleton,
  ChargingSite,
} from "@/datasource/chargers"

export default Vue.extend({
  components: {
    ChargerActions,
    ChargerActiveSessions,
    ChargerChargingSite,
    ChargerChargingSiteClusters,
    ChargerConnectorSummary,
    ChargerEdit,
    ChargerFields,
    ChargerFuses,
    ChargerLinks,
    ChargerRecentSessions,
    ChargerSelect,
    ChargerVehicles,
    ChargerManagementModuleSummary,
    ChargerUsage,
    ChargingSiteSelect,
    ChargerWebsocket,
    DateTimePicker,
  },

  data() {
    return {
      loading: false,
      saving: false,
      errors: {} as ValidationError,
      ocppId: this.$route.query.ocppId,
      charger: null as null | Charger,
      selectableChargers: [] as Array<ChargerSkeleton>,
      selectedOcppIdentifier: null as null | string,
      chargingSite: null as null | ChargingSite | { id: string; name: string },
      modifiedCharger: null as null | Charger,
      showEditSnackbar: false,
      editSnackBarText: "",
      chargerUsageTo: new Date(),
      chargerUsageFrom: new Date(new Date().getTime() - 1000 * 60 * 60 * 4),
      chargerUnknownVehicles: [] as CarSummary[],
      chargerAppUserKnownVehicles: [] as CarSummary[],
    }
  },

  watch: {
    selectedOcppIdentifier(value) {
      if (value) {
        router.push({ path: "summary", query: { ocppId: value } })
        this.loadChargerData(value)
      }
    },

    chargingSite(value) {
      if (value) {
        this.loadSelectableChargers()
      }
    },
  },

  created() {
    this.loadData()
    this.loadSelectableChargers()
  },

  methods: {
    async loadData() {
      if (this.$route.query.ocppId) {
        this.loading = true
        this.selectedOcppIdentifier = this.$route.query.ocppId as string
        this.loadChargerData(this.selectedOcppIdentifier)
      }
    },

    async loadSelectableChargers() {
      let result: Error | Array<ChargerSkeleton> = []

      if (this.chargingSite) {
        result = await datasource.chargers.listChargerSkeletonsForChargingSite(
          this.chargingSite as { id: string }
        )
      } else {
        result = await datasource.chargers.listChargerSkeletons()
      }

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.selectableChargers = result
    },

    async loadChargerData(ocppId: string) {
      this.loading = true

      {
        const result = await datasource.chargers.getChargerByOcppId(ocppId)

        if (result instanceof Error) {
          console.error(result)
          return
        }

        this.charger = result
        this.chargingSite = result?.chargingSite || null
        this.modifiedCharger = this.charger ? { ...this.charger } : null

        if (result) {
          this.loadChargerUnknownVehicles(result)
        }

        if (result?.appUser) {
          this.loadAppUserKnownVehicles(result.appUser)
        }

        if (result?.chargingSessions?.length) {
          const usageFrom = DateTime.fromISO(
            result.chargingSessions[0].createdAt
          ).toJSDate()

          this.chargerUsageFrom = usageFrom
        } else {
          this.chargerUsageFrom = new Date(
            new Date().getTime() - 1000 * 60 * 60 * 4
          )
        }

        this.loading = false
      }
    },

    async loadChargerUnknownVehicles(charger: Charger) {
      const result = await datasource.cars.getUnknownVehiclesForCharger(charger)

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.chargerUnknownVehicles = result
    },

    async loadAppUserKnownVehicles(appUser: AppUser) {
      const result = await datasource.cars.listCarsForUser(appUser)

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.chargerAppUserKnownVehicles = result
        .filter((c) => c.integrationType != "NONE")
        .slice(0, 2)
    },

    async save() {
      this.saving = true

      if (!this.modifiedCharger) {
        this.saving = false
        return
      }

      {
        const result = await datasource.chargers.updateCharger(
          this.modifiedCharger
        )

        if (result instanceof ValidationFailureError) {
          this.errors = result.errors
          this.editSnackBarText = "Failed to save changes to charger"
          this.showEditSnackbar = true
          this.saving = false
          return
        } else if (result instanceof Error) {
          console.error(result)
        }

        this.saving = false
        this.loadData()
      }
    },

    chargerItemText(charger: ChargerSkeleton): string {
      if (charger) {
        return `${charger.displayName} (${charger.ocppIdentifier})`
      } else {
        return ""
      }
    },

    setUsageTimestamps(timestamps: { startedAt: Date; finishedAt: Date }) {
      this.chargerUsageFrom = new Date(timestamps.startedAt)
      this.chargerUsageTo = new Date(timestamps.finishedAt)

      const usageRef = this.$refs.charerUsageCard as HTMLFormElement

      if (usageRef) {
        goTo(usageRef)
      }
    },
  },
})

import datasource from "@/datasource"
import { Result } from "@/utils"

export type CreateResponse = {
  data?: {
    createAmsMeter?: AmsMeter
  }
}

export type AmsMeter = {
  deviceId: string
  displayName: string
  voltageCorrectionFactor: number
  energyCorrectionFactor: number
  latestActivePowerW: number | null
  latestReadTime: Date | null
}

const amsMeters = {
  async listAmsMeters(): Promise<Result<Array<AmsMeter>>> {
    type RawAmsMeter = {
      deviceId: string
      displayName: string
      voltageCorrectionFactor: number
      energyCorrectionFactor: number
      latestActivePowerW: number | null
      latestReadTime: string | null
    }
    type Response = {
      data?: {
        amsMeters?: RawAmsMeter[]
      }
    }
    const response: Result<Response> = await datasource.graphql_flexibility(`
      query {
        amsMeters {
          deviceId
          displayName
          voltageCorrectionFactor
          energyCorrectionFactor
          latestActivePowerW
          latestReadTime
        }
      }
    `)
    return Result.map(response, function (response: Response) {
      const rawAmsMeters = response.data?.amsMeters || []
      const amsMeters: AmsMeter[] = rawAmsMeters.map(function (
        amsMeter: RawAmsMeter
      ): AmsMeter {
        let latestReadTime = null
        if (amsMeter.latestReadTime) {
          latestReadTime = new Date(amsMeter.latestReadTime)
        }
        return {
          ...amsMeter,
          latestReadTime: latestReadTime,
        }
      })
      return amsMeters
    })
  },

  async deleteAmsMeter(amsMeter: AmsMeter): Promise<Result<void>> {
    type Response = {
      data?: {
        amsMeter?: {
          deviceId?: AmsMeter
        }
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($id: ID!) {
        deleteAmsMeter(deviceId: $id) {
          deviceId
        }
      }
      `,
      {
        id: amsMeter.deviceId,
      }
    )
    return Result.toVoid(response)
  },

  async createAmsMeter(amsMeter: AmsMeter): Promise<Result<CreateResponse>> {
    const fields = { ...amsMeter }

    const response: Result<CreateResponse> =
      await datasource.graphql_flexibility(
        `
      mutation($fields: AmsMeterInput!) {
        createAmsMeter(fields: $fields) {
          deviceId
          displayName
          voltageCorrectionFactor
          energyCorrectionFactor
          latestActivePowerW
          latestReadTime
        }
      }
      `,
        {
          fields,
        }
      )

    return response
  },
}

export default amsMeters

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"flex-fh flex-col flex-grow flex-clip",attrs:{"fluid":""}},[_c('VCard',{staticClass:"flex-grow flex-col flex-clip",attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"d-flex align-start flex-wrap"},[_c('VCardTitle',[_vm._v("Chargers: Overview")]),_c('VSpacer'),_c('div',{staticClass:"flex-grow-0 pa-1 ml-3 d-flex align-center"},[(!_vm.selectedUser)?_c('ChargingSiteSelect',{staticClass:"mr-1",attrs:{"label":"Charging Site","filled":"","hide-details":""},on:{"input":function($event){return _vm.loadData()}},model:{value:(_vm.selectedChargingSite),callback:function ($$v) {_vm.selectedChargingSite=$$v},expression:"selectedChargingSite"}}):_vm._e(),_c('ChargerCreateMenu',{on:{"saved":function($event){return _vm.loadData()}}}),_c('VBtn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.loadData()}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1)],1)],1),(_vm.selectedUser)?_c('VCardSubtitle',[_c('span',[_vm._v("User:"),_c('RouterLink',{attrs:{"to":{ name: 'AppUsers', params: { appUserId: _vm.selectedUser.id } }}},[_vm._v(" "+_vm._s(_vm.selectedUser.displayIdentifier))])],1)]):_vm._e(),_c('VCardText',{staticClass:"flex-grow flex-clip"},[(_vm.loading && _vm.chargers.length == 0)?[_c('VContainer',{staticClass:"flex-col",attrs:{"fluid":""}},_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}),1)]:[_c('VContainer',{staticClass:"flex-grow flex-col",attrs:{"fluid":""}},[_c('div',{staticClass:"py-2"},[(_vm.selected.length)?_c('VMenu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-2"},'VBtn',attrs,false),on),[_vm._v("Edit Selected ("+_vm._s(_vm.selected.length)+")")])]}}],null,false,2643761435)},[_c('VList',[_c('VTooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItem',_vm._g({directives:[{name:"attrs",rawName:"v-attrs",value:(attrs),expression:"attrs"}],attrs:{"loading":_vm.synchronizingChargers},on:{"click":function($event){return _vm.enforceSelectedConfigurationProfiles()}}},on),[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-lightning-bolt")])],1),_c('VListItemContent',[_vm._v("Enforce Configuration Profiles")])],1)]}}],null,false,4000762941)},[_c('span',[_vm._v("Send message to physical chargers applying configuration profiles")])]),_c('VListItem',[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-map-marker")])],1),_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Set Charging Site")])]}}],null,false,2324365769),model:{value:(_vm.showBulkChargingSiteDialog),callback:function ($$v) {_vm.showBulkChargingSiteDialog=$$v},expression:"showBulkChargingSiteDialog"}},[_c('VCard',{attrs:{"loading":_vm.bulkSettingChargingSite}},[_c('VCardTitle',[_vm._v("Set Charging Site")]),_c('VCardText',[_c('ChargingSiteSelect',{attrs:{"label":"Charging Site"},model:{value:(_vm.multiSetChargingSite),callback:function ($$v) {_vm.multiSetChargingSite=$$v},expression:"multiSetChargingSite"}}),_c('VBtn',{on:{"click":function($event){return _vm.bulkSetChargingSite()}}},[_vm._v("Apply To Selected ("+_vm._s(_vm.selected.length)+")")])],1)],1)],1)],1),_c('VListItem',[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-tag")])],1),_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Set Charger Brand")])]}}],null,false,1622965710),model:{value:(_vm.showBulkBrandDialog),callback:function ($$v) {_vm.showBulkBrandDialog=$$v},expression:"showBulkBrandDialog"}},[_c('VCard',{attrs:{"loading":_vm.bulkSettingChargerBrands}},[_c('VCardTitle',[_vm._v("Set Charger Brand")]),_c('VCardText',[_c('VTextField',{staticClass:"px-2",attrs:{"label":"Brand Name"},model:{value:(_vm.multiSetChargerBrand),callback:function ($$v) {_vm.multiSetChargerBrand=$$v},expression:"multiSetChargerBrand"}}),_c('VBtn',{on:{"click":function($event){return _vm.bulkSetChargerBrand()}}},[_vm._v("Apply To Selected ("+_vm._s(_vm.selected.length)+")")])],1)],1)],1)],1),_c('VListItem',[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-ev-plug-type1")])],1),_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Set Charger Model")])]}}],null,false,2059696410),model:{value:(_vm.showBulkModelDialog),callback:function ($$v) {_vm.showBulkModelDialog=$$v},expression:"showBulkModelDialog"}},[_c('VCard',{attrs:{"loading":_vm.bulkSettingChargerModels}},[_c('VCardTitle',[_vm._v("Set Charger Model")]),_c('VCardText',[_c('VTextField',{staticClass:"px-2",attrs:{"label":"Model"},model:{value:(_vm.multiSetChargerModel),callback:function ($$v) {_vm.multiSetChargerModel=$$v},expression:"multiSetChargerModel"}}),_c('VBtn',{on:{"click":function($event){return _vm.bulkSetChargerModel()}}},[_vm._v("Apply To Selected ("+_vm._s(_vm.selected.length)+")")])],1)],1)],1)],1),_c('VListItem',[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-brain")])],1),_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Toggle Smart Charging Enabled")])]}}],null,false,3109549070),model:{value:(_vm.showBulkSmartChargeDialog),callback:function ($$v) {_vm.showBulkSmartChargeDialog=$$v},expression:"showBulkSmartChargeDialog"}},[_c('VCard',{attrs:{"loading":_vm.bulkSettingSmartCharge}},[_c('VCardTitle',[_vm._v("Toggle Smart Charging Enabled")]),_c('VCardText',[_c('VSwitch',{model:{value:(_vm.bulkSetSmartChargeValue),callback:function ($$v) {_vm.bulkSetSmartChargeValue=$$v},expression:"bulkSetSmartChargeValue"}}),_c('VBtn',{on:{"click":function($event){return _vm.bulkSetSmartCharge()}}},[_vm._v("Apply To Selected ("+_vm._s(_vm.selected.length)+")")])],1)],1)],1)],1),_c('VListItem',[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-robot-confused-outline")])],1),_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Toggle Smart Charging Supported")])]}}],null,false,3552393893),model:{value:(_vm.showBulkSmartChargeSupportedDialog),callback:function ($$v) {_vm.showBulkSmartChargeSupportedDialog=$$v},expression:"showBulkSmartChargeSupportedDialog"}},[_c('VCard',{attrs:{"loading":_vm.bulkSettingSmartChargeSupported}},[_c('VCardTitle',[_vm._v("Toggle Smart Charging Supported")]),_c('VCardText',[_c('div',{staticClass:"flex flex-row"},[_c('span',{staticClass:"px-2 mt-6"},[_vm._v("OFF")]),_c('VSwitch',{model:{value:(_vm.bulkSetSmartChargeSupportedValue),callback:function ($$v) {_vm.bulkSetSmartChargeSupportedValue=$$v},expression:"bulkSetSmartChargeSupportedValue"}}),_c('span',{staticClass:"px-2 mt-6"},[_vm._v("ON")])],1),_c('VBtn',{on:{"click":function($event){return _vm.bulkSetSmartChargeSupported()}}},[_vm._v("Apply To Selected ("+_vm._s(_vm.selected.length)+")")])],1)],1)],1)],1),_c('VListItem',[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-restart-alert")])],1),_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Toggle Meter Values Increasing?")])]}}],null,false,1435875424),model:{value:(_vm.showBulkIncreasingDialog),callback:function ($$v) {_vm.showBulkIncreasingDialog=$$v},expression:"showBulkIncreasingDialog"}},[_c('VCard',{attrs:{"loading":_vm.bulkSettingMeterValuesIncreasing}},[_c('VCardTitle',[_vm._v("Meter Values Increasing?")]),_c('VCardSubtitle',{staticClass:"red--text"},[_vm._v("Note: Disabling this setting will recreate clean meter readings for these chargers")]),_c('VCardText',[_c('VSwitch',{model:{value:(_vm.bulkSetMeterValuesIncreasingValue),callback:function ($$v) {_vm.bulkSetMeterValuesIncreasingValue=$$v},expression:"bulkSetMeterValuesIncreasingValue"}}),_c('VBtn',{on:{"click":function($event){return _vm.bulkSetMeterValuesIncreasing()}}},[_vm._v("Apply To Selected ("+_vm._s(_vm.selected.length)+")")])],1)],1)],1)],1),_c('VListItem',[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-meter-electric-outline")])],1),_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Set Manual Meter Value Triggering")])]}}],null,false,4138152831),model:{value:(_vm.showBulkTriggerMeterValuesDialog),callback:function ($$v) {_vm.showBulkTriggerMeterValuesDialog=$$v},expression:"showBulkTriggerMeterValuesDialog"}},[_c('VCard',{attrs:{"loading":_vm.bulkSettingPollMeterValues}},[_c('VCardTitle',[_vm._v("Set Manual Meter Value Triggering")]),_c('VCardText',[_c('VSwitch',{model:{value:(_vm.bulkSetPollMeterValuesState),callback:function ($$v) {_vm.bulkSetPollMeterValuesState=$$v},expression:"bulkSetPollMeterValuesState"}}),_c('VBtn',{on:{"click":function($event){return _vm.bulkSetPollMeterValues()}}},[_vm._v("Apply To Selected ("+_vm._s(_vm.selected.length)+")")])],1)],1)],1)],1),_c('VListItem',[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-pin")])],1),_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Set Optimizer Pinning")])]}}],null,false,2728018095),model:{value:(_vm.showBulkOptimizerPinDialog),callback:function ($$v) {_vm.showBulkOptimizerPinDialog=$$v},expression:"showBulkOptimizerPinDialog"}},[_c('VCard',{attrs:{"loading":_vm.bulkSettingOptimizerPin}},[_c('VCardTitle',[_vm._v("Toggle Optimizer Pinning Enabled")]),_c('VCardText',[_c('VSwitch',{model:{value:(_vm.bulkOptimizerPinningState),callback:function ($$v) {_vm.bulkOptimizerPinningState=$$v},expression:"bulkOptimizerPinningState"}}),_c('VBtn',{on:{"click":function($event){return _vm.bulkSetOptimizerPinning()}}},[_vm._v("Apply To Selected ("+_vm._s(_vm.selected.length)+")")])],1)],1)],1)],1),_c('VListItem',[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-meter-electric-outline")])],1),_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Toggle Periodic Rebooting")])]}}],null,false,213691939),model:{value:(_vm.showBulkPeriodicRebootDialog),callback:function ($$v) {_vm.showBulkPeriodicRebootDialog=$$v},expression:"showBulkPeriodicRebootDialog"}},[_c('VCard',{attrs:{"loading":_vm.bulkSettingPeriodicRebooting}},[_c('VCardTitle',[_vm._v("Toggle Periodic Rebooting")]),_c('VCardText',[_c('VSwitch',{model:{value:(_vm.bulkSetPeriodicallyRebootState),callback:function ($$v) {_vm.bulkSetPeriodicallyRebootState=$$v},expression:"bulkSetPeriodicallyRebootState"}}),_c('VBtn',{on:{"click":function($event){return _vm.bulkSetPeriodicallyReboot()}}},[_vm._v("Apply To Selected ("+_vm._s(_vm.selected.length)+")")])],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.selected.length)?_c('VMenu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-2"},'VBtn',attrs,false),on),[_vm._v("View Logs for Selected ("+_vm._s(_vm.selected.length)+")")])]}}],null,false,3570000998)},[_c('VList',[_c('VListItem',{attrs:{"href":_vm.selectedAmqpDashboardUrl,"target":"_blank"}},[_vm._v("AMQP Dashboard")])],1)],1):_vm._e()],1),_c('CollapsibleDataTable',{attrs:{"headers":_vm.headers,"items":_vm.chargers,"namespace":"chargers","sort-by":"displayName","fixed-header":"","disable-pagination":"","hide-default-footer":"","show-select":""},scopedSlots:_vm._u([{key:"item.observationOnly",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.observationOnly}})]}},{key:"item.smartChargingEnabled",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.smartChargingEnabled}})]}},{key:"item.smartChargingSupported",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.smartChargingSupported}})]}},{key:"item.vehicleToGridChargingSupported",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.vehicleToGridChargingSupported}})]}},{key:"item.pollMeterValues",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":!!item.pollMeterValues}})]}},{key:"item.periodicallyReboot",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":!!item.periodicallyReboot}})]}},{key:"item.isMeterValuesIncreasing",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":!!item.isMeterValuesIncreasing}})]}},{key:"item.optimizerPinningEnabled",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":!!item.optimizerPinningEnabled}})]}},{key:"item.managerSettings.useZeroPowerToStop",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.managerSettings.useZeroPowerToStop}})]}},{key:"item.displayName",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: 'ChargerSummary', query: { ocppId: item.ocppIdentifier } }}},[_vm._v(_vm._s(item.displayName))])]}},{key:"item.chargingSite",fn:function(ref){
var item = ref.item;
return [(item.chargingSite)?_c('RouterLink',{attrs:{"to":{ name: 'ChargingSite', params: { id: item.chargingSite.id } }}},[_vm._v(_vm._s(item.chargingSite.name))]):_vm._e()]}},{key:"item.appUser",fn:function(ref){
var item = ref.item;
return [(item.appUser)?_c('RouterLink',{attrs:{"to":{ name: 'AppUserChargers', params: { appUserId: item.appUser.id } }}},[_vm._v(_vm._s(item.appUser.displayIdentifier))]):_c('span',[_vm._v("–")])]}},{key:"item.connectors",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: 'ChargerConnectors', params: { chargerId: item.id } }}},[_vm._v(_vm._s(item.mathematicalModel.connectors.length))])]}},{key:"item.historicChargingSessions",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: 'HistoricChargingSessions', query: { ocppIds: item.ocppIdentifier, from: _vm.historicSessionLinkFromTimestamp() } }}},[_c('VBtn',{attrs:{"icon":""}},[_c('VIcon',[_vm._v("mdi-link")])],1)],1)]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.positionDisplay(item)))])]}},{key:"item.chargingSessions",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: 'ChargerChargingSessions', params: { chargerId: item.id } }}},[_vm._v(_vm._s(item.chargingSessions.length))])]}},{key:"item.configurationProfilesLastEnforcedAt",fn:function(ref){
var item = ref.item;
return [_c('VDialog',{attrs:{"width":"20%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:item.configurationProfilesLastSuccessState ? 'green--text' : 'red--text'},'div',attrs,false),on),[_vm._v(_vm._s(_vm.configurationProfilesTimestampDisplay(item)))]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.configurationProfilesTimeDiffDisplay(item)))])]}}],null,true),model:{value:(_vm.showConfigurationProfileDebugDialog),callback:function ($$v) {_vm.showConfigurationProfileDebugDialog=$$v},expression:"showConfigurationProfileDebugDialog"}},[_c('VCard',[_c('VCardText',[_c('VJsonEditor',{attrs:{"value":_vm.configurationProfileResultJson(item)}})],1)],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('EditChargerDialog',{attrs:{"charger":item},on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-pen")])],1)]}}],null,true)})]}},{key:"item.actions-menu",fn:function(ref){
var item = ref.item;
return [_c('ChargerActionsMenu',{attrs:{"charger":item,"multipleChargers":_vm.selected,"offset-y":"","left":""},on:{"change":function($event){return _vm.loadData()},"delete":function($event){_vm.showSnackbar(("Deleted charger " + (item.id) + " (" + (item.ocppIdentifier) + ").")) && _vm.loadData()}}})]}},{key:"item.links-menu",fn:function(ref){
var item = ref.item;
return [_c('ChargerLinksMenu',{attrs:{"charger":item},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-link")])],1)]}}],null,true)})]}},{key:"item.salePricePerKwh",fn:function(ref){
var item = ref.item;
return [_c('StringValue',{attrs:{"editable":""},on:{"input":function($event){return _vm.updateCharger(item)}},model:{value:(item.salePricePerKwh),callback:function ($$v) {_vm.$set(item, "salePricePerKwh", $$v)},expression:"item.salePricePerKwh"}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]],2),_c('VSnackbar',{attrs:{"timeout":3000,"color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VBtn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'VBtn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText))]),_c('MachineLearningLog')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
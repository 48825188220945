














































import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type {
  FinishedChargingSession,
  EstimatedSavingsBreakdown,
} from "@/datasource/charging"
import { DateTime } from "luxon"

export default Vue.extend({
  props: {
    finishedChargingSession: {
      type: Object as PropType<FinishedChargingSession>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      showDialog: false,
      estimatedSavings: null as null | EstimatedSavingsBreakdown,
    }
  },

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      }
    },
  },

  methods: {
    async loadData() {
      this.loading = true

      const savingsBreakdown =
        await datasource.charging.getEstimatedSavingsForFinishedChargingSession(
          this.finishedChargingSession
        )

      if (savingsBreakdown instanceof Error) {
        console.error(savingsBreakdown)
        return
      }

      this.estimatedSavings = savingsBreakdown
      this.loading = false
    },

    formattedDate(d: Date): string {
      return DateTime.fromJSDate(d).toLocaleString(DateTime.DATETIME_MED)
    },
  },
})























import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type { AppUserSummary } from "@/datasource/appUsers"
import type { Car } from "@/datasource/cars"
import AppUserSelect from "@/components/AppUserSelect.vue"

export default Vue.extend({
  components: { AppUserSelect },

  props: {
    cars: {
      type: Array as PropType<Car[]>,
      required: true,
    },
  },

  data() {
    return {
      saving: false,
      appUsers: [] as AppUserSummary[],
    }
  },

  methods: {
    async save() {
      this.saving = true

      const result = await datasource.cars.bulkUpdateCars(this.cars, {
        appUserIds: this.appUsers.map((u) => u.id),
      })

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.saving = false
      this.appUsers = []
      this.$emit("saved")
    },
  },
})

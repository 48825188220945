

































































import Vue from "vue"
import datasource from "@/datasource"
import AppUserSelect from "@/components/AppUserSelect.vue"
import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"
import type { Charger } from "@/datasource/chargers"

function initialData() {
  return {
    showDialog: false,
    loading: true,
    saving: false,
    isFormValid: false,
    required: [(v: string) => !!v || "Required"],
    chargerBrands: [] as string[],
    fields: {
      charger: {} as Charger,
      secret: "",
    },
    error: null as string | null,
  }
}

export default Vue.extend({
  components: {
    AppUserSelect,
    ChargingSiteSelect,
  },

  data: initialData,

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    async loadData() {
      this.loading = true

      {
        const result = await datasource.schema.listEnumOptions("ChargerBrand")
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.chargerBrands = result
      }

      // override the brand since only
      // easee is currently supported
      this.fields.charger.managementModule = "EASEE"

      this.loading = false
    },

    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true

      const result = await datasource.chargers.registerCharger(
        this.fields.charger,
        this.fields.secret
      )

      if (typeof result === "string") {
        const message = (result as string).toLowerCase().replace(/_/, " ")
        this.error = `Error: ${message}`
      } else {
        this.$emit("saved")
        this.close()
      }

      this.saving = false
    },
  },
})





































































































import Vue, { PropType } from "vue"
import _ from "underscore"
import store from "@/store"
import { DateTime } from "luxon"
import Big from "big.js"
import VJsonEditor from "v-jsoneditor"
import type {
  FinishedChargingSession,
  PostFactSavings,
  PostFactSavingsBreakdownLine,
} from "@/datasource/charging"
import type { OptimizationResponseRecord } from "@/datasource/optimization"
import datasource from "@/datasource"

export default Vue.extend({
  components: { VJsonEditor },

  props: {
    finishedChargingSession: {
      type: Object as PropType<FinishedChargingSession>,
      required: true,
    },
  },

  data() {
    return {
      generating: false,
      generated: false,
      failedToGenerate: false,
      showDialog: false,
      loading: false,
      showOptimizerRequestAndResponse: false,
      loadingOptimizerRequestAndResponse: false,
      optimizerResponseRecord: null as null | OptimizationResponseRecord,
      requestJson: null as string | null,
      responseJson: null as string | null,
      postFactSavings: null as PostFactSavings | null,
      postFactSavingsAmount: (this.finishedChargingSession.postFactSavingsAmount
        ? Big(
            this.finishedChargingSession.postFactSavingsAmount.toString()
          ).round(2)
        : null) as Big | null,
    }
  },

  computed: {
    showOptimizerDebugging(): boolean {
      return this.canDebug() && !!this.postFactSavings?.optimizationRequestId
    },

    timestamps(): Date[] {
      if (this.postFactSavings?.breakdownLines.length) {
        const firstTimestamp = this.postFactSavings.breakdownLines[0]
          .stopTime as Date

        return _.range(24).map(
          (h) => new Date(firstTimestamp.getTime() + h * 60 * 60 * 1000)
        )
      } else {
        return []
      }
    },
  },

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      }
    },

    showOptimizerRequestAndResponse(value) {
      if (value && !this.requestJson) {
        this.loadOptimizerRequestAndResponse()
      }
    },
  },

  methods: {
    Big,
    async loadData() {
      this.loading = true

      const result =
        await datasource.charging.listPostFactPricingsForFinishedChargingSession(
          this.finishedChargingSession
        )

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.postFactSavings = result[0]

      if (this.postFactSavings?.optimizationRequestId) {
        const optimizerResponseResult =
          await datasource.optimization.listOptimizationRecordsForRequestId(
            this.postFactSavings.optimizationRequestId
          )

        if (optimizerResponseResult instanceof Error) {
          console.error(optimizerResponseResult)
          return
        }

        this.optimizerResponseRecord = optimizerResponseResult
      }

      this.loading = false
    },

    async generatePostFactSavings() {
      this.generating = true

      const result =
        await datasource.charging.generatePostFactSavingsForFinishedChargingSessionId(
          this.finishedChargingSession.id as string | number
        )

      if (result instanceof Error) {
        console.error(result)
        return
      }

      if (result) {
        this.generated = true
        this.postFactSavings = result
        this.postFactSavingsAmount = Big(result.postFactSavings.amount).round(2)
      } else {
        this.failedToGenerate = true
      }

      this.generating = false
    },

    async loadOptimizerRequestAndResponse() {
      if (this.postFactSavings?.optimizationRequestId) {
        this.loadingOptimizerRequestAndResponse = true

        const result =
          await datasource.optimization.getRequestLogRawRequestAndResponseByRequestId(
            this.postFactSavings.optimizationRequestId
          )

        if (result instanceof Error) {
          console.error(result)
          return
        }

        this.requestJson = result.requestJson
          ? JSON.parse(result.requestJson)
          : ""
        this.responseJson = result.responseJson
          ? JSON.parse(result.responseJson)
          : ""

        this.loadingOptimizerRequestAndResponse = false
      }
    },

    formattedDate(d: Date): string {
      return DateTime.fromJSDate(d).toLocaleString(DateTime.DATETIME_MED)
    },

    canDebug(): boolean {
      return store.state.hasElevatedPrivileges
    },

    energyPriceForSavings(timestamp: Date): Big | string {
      const line = this.lineAtTimestamp(timestamp)
      return line ? Big(line.energyPricePerKwh.amount).round(2) : ""
    },

    energyUsageForSavings(timestamp: Date): Big | string {
      const line = this.lineAtTimestamp(timestamp)
      return line ? line.actualEnergyUsageKwh.round(2) : "0"
    },

    energyUsageForPlan(timestamp: Date): Big | string {
      const line = this.lineAtTimestamp(timestamp)
      return line ? line.postFactPlanEnergyUsageKwh.round(2) : "0"
    },

    aggregatedCostForTimestamp(timestamp: Date): Big | string {
      const line = this.lineAtTimestamp(timestamp)
      return line ? Big(line.actualAggregatedCost.amount).round(2) : "0"
    },

    planCostForTimestamp(timestamp: Date): Big | string {
      const line = this.lineAtTimestamp(timestamp)
      return line ? Big(line.postFactPlanAggregatedCost.amount).round(2) : "0"
    },

    lineAtTimestamp(timestamp: Date): PostFactSavingsBreakdownLine | null {
      if (this.postFactSavings) {
        const line = this.postFactSavings.breakdownLines.find(
          (l) => l.stopTime.getHours() == timestamp.getHours()
        )

        return line || null
      } else {
        return null
      }
    },

    optimizerPlanKw(timestamp: Date): Big | string {
      if (this.optimizerResponseRecord) {
        const timeseriesEntry =
          this.optimizerResponseRecord.chargingPlanTimeseries.find(
            (r) => (r.timestamp.getHours() + 1) % 24 == timestamp.getHours()
          )

        return timeseriesEntry
          ? timeseriesEntry.plannedPowerPhasesKw
              .reduce((a, b) => a.add(b))
              .round(2)
          : ""
      } else {
        return ""
      }
    },

    optimizerPlanSoc(timestamp: Date): string {
      if (this.optimizerResponseRecord) {
        const timeseriesEntry =
          this.optimizerResponseRecord.chargingPlanTimeseries.find(
            (r) => (r.timestamp.getHours() + 1) % 24 == timestamp.getHours()
          )

        return timeseriesEntry
          ? timeseriesEntry.stateOfChargePercent.toFixed(1)
          : ""
      } else {
        return ""
      }
    },

    nextHour(timestamp: Date) {
      const dt = new Date(timestamp.getTime() + 1000 * 60 * 60)
      dt.setMinutes(0, 0, 0)
      return dt
    },
  },
})





























































































import Vue from "vue"
import type { PropType } from "vue"
import type { AppUser } from "@/datasource/appUsers"
import type { ValidationError } from "@/datasource"
import ChargingSessionManagementStrategySelect from "@/components/ChargingSessionManagementStrategySelect.vue"

export default Vue.extend({
  components: {
    ChargingSessionManagementStrategySelect,
  },

  props: {
    value: {
      type: Object as PropType<AppUser>,
      required: true,
    },
    errors: {
      type: Object as PropType<ValidationError>,
      default: {},
    },
  },

  data() {
    return {
      fields: { ...this.value },
      modifiedErrors: {
        unknownCarBatterySizeKwh: [],
      } as ValidationError,
    }
  },

  computed: {
    mergedErrors(): ValidationError {
      return {
        ...this.errors,
        ...this.modifiedErrors,
      }
    },

    unknownCarBatterySizeKwh: {
      get(): number {
        return this.fields.unknownCarBatterySizeWh / 1000
      },

      set(value: number) {
        if (isNaN(value)) {
          this.modifiedErrors.unknownCarBatterySizeKwh = ["Must be a number."]
        } else {
          this.modifiedErrors.unknownCarBatterySizeKwh = []
          this.fields.unknownCarBatterySizeWh = value * 1000
        }
      },
    },
  },

  methods: {
    emit() {
      this.$emit("input", { ...this.fields })
    },

    resetSmartChargingEnabled() {
      this.fields.smartChargingEnabled = true
      this.emit()
    },
  },
})































































import Vue from "vue"
import datasource from "@/datasource"
import type { RebateRateInput } from "@/datasource/payments"

import DateTimePicker from "@/components/DateTimePicker.vue"

function initialData() {
  return {
    showDialog: false,
    saving: false,
    modified: {
      month: new Date(),
      rebatePercentage: null,
      rebateThreshold: null,
      no1RatePerKwh: null,
      no2RatePerKwh: null,
      no3RatePerKwh: null,
      no4RatePerKwh: null,
      no5RatePerKwh: null,
    } as RebateRateInput,
    rebatePercentage: null as string | null,
    rebateThreshold: null as string | null,
    no1RateAmount: null as string | null,
    no2RateAmount: null as string | null,
    no3RateAmount: null as string | null,
    no4RateAmount: null as string | null,
    no5RateAmount: null as string | null,
  }
}

export default Vue.extend({
  components: { DateTimePicker },

  data: initialData,

  watch: {
    showDialog(value) {
      if (!value) {
        this.close()
      }
    },

    rebatePercentage(newVal) {
      this.modified.rebatePercentage = newVal
    },

    rebateThreshold(newVal) {
      this.modified.rebateThreshold = newVal
    },

    no1RateAmount(newVal) {
      this.modified.no1RatePerKwh = newVal
        ? { amount: newVal, currency: "NOK" }
        : null
    },

    no2RateAmount(newVal) {
      this.modified.no2RatePerKwh = newVal
        ? { amount: newVal, currency: "NOK" }
        : null
    },

    no3RateAmount(newVal) {
      this.modified.no3RatePerKwh = newVal
        ? { amount: newVal, currency: "NOK" }
        : null
    },

    no4RateAmount(newVal) {
      this.modified.no4RatePerKwh = newVal
        ? { amount: newVal, currency: "NOK" }
        : null
    },

    no5RateAmount(newVal) {
      this.modified.no5RatePerKwh = newVal
        ? { amount: newVal, currency: "NOK" }
        : null
    },
  },

  methods: {
    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true

      try {
        {
          const result = await datasource.payments.createRebateRate(
            this.modified
          )
          if (result instanceof Error) {
            throw result
          }
        }

        this.$emit("saved")
        this.close()
      } catch (e) {
        console.error(e)
      }

      this.saving = false
    },
  },
})

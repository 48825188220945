import _ from "underscore"
import datasource, { ApiResponseWithErrors } from "@/datasource"
import { Result } from "@/utils"
import { AppUser } from "@/datasource/appUsers"
import { ChargingSite, graphQlGridOperatorFields } from "@/datasource/chargers"

import Big from "big.js"
import type { GridOperator } from "@/datasource/chargers"
import { EmailTemplate } from "./emails"

export type PaymentRequest = {
  lastCapturedAt: Date
  authorizationRequestId: string
  paymentMethods: string
}

export type Money = {
  amount: string
  currency: string
  display: string
}

export type MoneyInput = {
  amount: string
  currency: string
}

export type BillingPeriod = {
  start: string
  finish: string
}

export type Invoice = {
  id: string
  recipient: AppUserRecipient
  tax: Money
  grossTotal: Money
  processingFee: Money
  paid: string
  seenCount: number
  insertedAt: Date
  draft: boolean
  paymentRequest?: PaymentRequest
  lines: InvoiceLine[]
  chargingSite?: string
  chargers?: string
  energyUsed?: string
  startDate?: Date
  endDate?: Date
  startMeterReadingKwh?: number
  endMeterReadingKwh?: number
  offlineChargingUsageKwh?: number
  excessEnergyUsageKwh?: number
  flexibilityFee?: Big
  flexibilityFeeTax?: Big
  grossFlexibilityFee?: Big
  energyCost?: Big
  offlineChargingFee?: Big
  excessEnergyFee?: Big
  gridFee?: Big
  variableEnergyFee?: Big
  energyRebate?: Big
  paymentMethods?: string
  authorizationRequestId?: string
  authorizationRequestIdSuffix?: string
  taxSystems?: string
}

export type InvoiceInput = {
  startDate: Date
  endDate: Date
  meterReadingKwh: number
  energyCost: number
  gridFee: number
  flexibilityFee: number
  chargingSite: ChargingSiteRecipient
  recipient: AppUserRecipient
  chargerDisplayName: string
  chargerIdentifier: string
}

export type InvoiceLine = {
  id: string
  energyCost: Money
  energyCostTax: Money
  flexibilityFee: Money
  flexibilityFeeTax: Money
  gridFee: Money
  gridFeeTax: Money
  tax: Money
  total: Money
  startMeterReadingKwh: number
  endMeterReadingKwh: number
  excessEnergyUsageKwh: number
  excessEnergyFee: Money
  excessEnergyFeeTax: Money
  offlineChargingUsageKwh: number
  offlineChargingFee: Money
  offlineChargingFeeTax: Money
  energyRebate?: Money
  startDate: Date
  endDate: Date
  settings: InvoiceSettings
  chargers: string[]
  chargingSite: InvoiceChargingSite
}

export type InvoiceBreakdown = {
  id: string
  lineId: string
  initialReadingWh: number
  lastReadingWh: number
  connectedAt: Date
  lastReadingAt: Date
  chargerConnector: string
  chargerDisplayName: string
  usageTimeseries: InvoiceUsageTimeseriesEntry[]
  totalUsageCost: number | null
}

export type InvoiceUsageTimeseriesEntry = {
  startTime: Date
  usageWh: number
  initialReadingWh: number
  rebate: number
}

type InvoiceSettingsInput = {
  type: InvoiceType
  chargingSiteFeeKwh: string
  gridFee: string
  schedule: InvoiceSchedule
  dayOfSchedule: number
  gracePeriodDays: number
  taxPercentage: number
  chargingSite: InvoiceChargingSite
  fixedFlexibilityFee: string
  percentageFlexibilityFee: number
  fixedProcessingFee: string
  percentageProcessingFee: number
  excessEnergyFeeKwh: string
  useRebate: boolean
  activeFrom: Date
  dataSource: InvoiceDataSource
  taxSystem: string | null
  dummyTax: number | null
}

export type InvoiceSettings = InvoiceSettingsInput & {
  id?: string | null
  gridFee: Money
  fixedFlexibilityFee: Money
  fixedProcessingFee: Money
  chargingSiteFeeKwh: Money
  excessEnergyFeeKwh: Money
}

export interface AppUserRecipient {
  kind: "AppUser"
  id: string
  email: string
  firstName: string
  lastName: string
}

export interface ChargingSiteRecipient {
  kind: "ChargingSite"
  id: string
  name: string
}

export type Recipient = AppUserRecipient | ChargingSiteRecipient

type RawVariableGridCost = {
  id: string
  gridOperator: GridOperator
  validFrom: Date
  weekHourlyRatesKwh: Array<Array<Money>>
}

export type VariableGridCost = {
  id: string
  gridOperator: GridOperator
  validFrom: Date
  peakRate: Money
  offpeakRate: Money
}

export type CreateVariableGridCostInput = {
  gridOperatorId: string
  validFrom: Date
  peakRate: MoneyInput
  offpeakRate: MoneyInput
}

export type EditVariableGridCostInput = {
  id: string
  gridOperatorId: string
  validFrom: Date
  peakRate: MoneyInput
  offpeakRate: MoneyInput
}

type InvoiceChargingSite = {
  id?: string | null
  name: string
}

export enum InvoiceType {
  Fixed = "FIXED",
  Floating = "FLOATING",
}

export enum InvoiceSchedule {
  Quarterly = "QUARTERLY",
  Monthly = "MONTHLY",
  OneOff = "ONE_OFF",
  PerUserMonthly = "PER_USER_MONTHLY",
  None = "NONE",
}

export enum InvoiceState {
  Draft = "DRAFT",
  Unpaid = "UNPAID",
  Paid = "PAID",
}

export enum InvoiceDataSource {
  CleanMeterReadings = "CLEAN_METER_READINGS",
  Amqp = "AMQP",
}

export type RebateRate = {
  id: string
  month: Date
  no1RatePerKwh: Money | null
  no2RatePerKwh: Money | null
  no3RatePerKwh: Money | null
  no4RatePerKwh: Money | null
  no5RatePerKwh: Money | null
  rebatePercentage: number | null
  rebateThreshold: number | null
}

export type RebateRateInput = {
  id?: string
  month: Date
  no1RatePerKwh: MoneyInput | null
  no2RatePerKwh: MoneyInput | null
  no3RatePerKwh: MoneyInput | null
  no4RatePerKwh: MoneyInput | null
  no5RatePerKwh: MoneyInput | null
  rebatePercentage: number | null
  rebateThreshold: number | null
}

const graphQlMoneyFields = `
  amount
  currency
  display
`

const graphQlChargingSiteFields = `
  id
  name
`

const graphQlInvoiceSettingsFields = `
  id
  type
  chargingSiteFeeKwh {
    ${graphQlMoneyFields}
  }
  gridFee {
    ${graphQlMoneyFields}
  }
  schedule
  dayOfSchedule
  gracePeriodDays
  taxPercentage
  taxSystem
  dummyTax
  fixedFlexibilityFee {
    ${graphQlMoneyFields}
  }
  percentageFlexibilityFee
  fixedProcessingFee {
    ${graphQlMoneyFields}
  }
  percentageProcessingFee
  chargingSite {
    ${graphQlChargingSiteFields}
  }
  activeFrom
  useRebate
  dataSource
  excessEnergyFeeKwh {
    ${graphQlMoneyFields}
  }
`

const graphQlInvoiceFields = `
  id
  tax {
    ${graphQlMoneyFields}
  }
  grossTotal {
    ${graphQlMoneyFields}
  }
  processingFee {
    ${graphQlMoneyFields}
  }
  paid
  draft
  seenCount
  insertedAt
  recipient {
    id
    email
    firstName
    lastName
  }
  paymentRequest {
    lastCapturedAt
    authorizationRequestId
    paymentMethods
  }
  lines {
    id
    chargingSite {
      ${graphQlChargingSiteFields}
    }
    energyCost {
      ${graphQlMoneyFields}
    }
    energyCostTax {
      ${graphQlMoneyFields}
    }
    energyRebate {
      ${graphQlMoneyFields}
    }
    flexibilityFee {
      ${graphQlMoneyFields}
    }
    flexibilityFeeTax {
      ${graphQlMoneyFields}
    }
    gridFee {
      ${graphQlMoneyFields}
    }
    gridFeeTax {
      ${graphQlMoneyFields}
    }
    tax {
      ${graphQlMoneyFields}
    }
    total {
      ${graphQlMoneyFields}
    }
    startMeterReadingKwh
    endMeterReadingKwh
    excessEnergyUsageKwh
    excessEnergyFee {
      ${graphQlMoneyFields}
    }
    excessEnergyFeeTax {
      ${graphQlMoneyFields}
    }
    offlineChargingUsageKwh
    offlineChargingFee {
      ${graphQlMoneyFields}
    }
    offlineChargingFeeTax {
      ${graphQlMoneyFields}
    }
    startDate
    endDate
    settings {
      ${graphQlInvoiceSettingsFields}
    }
    chargers
  }
`

const graphQlInvoiceBreakdownFields = `
  id
  lineId
  initialReadingWh
  lastReadingWh
  connectedAt
  lastReadingAt
  chargerConnector
  chargerDisplayName
  totalUsageCost
  usageTimeseries {
    startTime
    usageWh
    initialReadingWh
  }
`

const graphQlRebateRateFields = `
  id
  month
  rebatePercentage
  rebateThreshold
  no1RatePerKwh {
    ${graphQlMoneyFields}
  }
  no2RatePerKwh {
    ${graphQlMoneyFields}
  }
  no3RatePerKwh {
    ${graphQlMoneyFields}
  }
  no4RatePerKwh {
    ${graphQlMoneyFields}
  }
  no5RatePerKwh {
    ${graphQlMoneyFields}
  }
`

const graphQlVariableGridCostFields = `
  id
  validFrom
  weekHourlyRatesKwh {
    amount
    currency
    display
  }
  gridOperator {
    ${graphQlGridOperatorFields}
  }
`

function mappedInvoices(rawInvoices: Array<Invoice>): Array<Invoice> {
  return rawInvoices.map(function (invoice: Invoice): Invoice {
    const insertedAt = new Date(invoice.insertedAt)
    const lines = invoice.lines.map(function (line: InvoiceLine) {
      const startDate = new Date(line.startDate)
      const endDate = new Date(line.endDate)
      const startMeterReadingKwh = parseFloat(`${line.startMeterReadingKwh}`)
      const endMeterReadingKwh = parseFloat(`${line.endMeterReadingKwh}`)
      const excessEnergyUsageKwh = parseFloat(`${line.excessEnergyUsageKwh}`)
      const offlineChargingUsageKwh = parseFloat(
        `${line.offlineChargingUsageKwh}`
      )

      return {
        ...line,
        startDate,
        endDate,
        startMeterReadingKwh,
        endMeterReadingKwh,
        excessEnergyUsageKwh,
        offlineChargingUsageKwh,
      }
    })

    if (invoice.paymentRequest?.lastCapturedAt) {
      const paidAt = new Date(invoice.paymentRequest.lastCapturedAt)
      invoice.paymentRequest.lastCapturedAt = paidAt
    }

    return {
      ...invoice,
      lines,
      insertedAt,
    }
  })
}

function mappedInvoiceBreakdowns(
  rawBreakdowns: Array<InvoiceBreakdown>
): Array<InvoiceBreakdown> {
  return rawBreakdowns.map(function (
    breakdown: InvoiceBreakdown
  ): InvoiceBreakdown {
    const totalUsageCost = parseFloat(`${breakdown.totalUsageCost}`)

    return {
      ...breakdown,
      totalUsageCost,
    }
  })
}

function mappedSettings(
  rawSettings: Array<InvoiceSettings>
): Array<InvoiceSettings> {
  return rawSettings.map(function (settings: InvoiceSettings): InvoiceSettings {
    const activeFrom = new Date(settings.activeFrom)

    return { ...settings, activeFrom }
  })
}

// The backend expects a grid rate for each hour of the week.
// In Norway, there are only two rates: "Peak" (0600 - 2200 Mon-Fri)
// and "Offpeak" (everything else). Here we convert rates specified by these two values
// into the hourly resolution the backend expects.
function ratesToGridCostArray(
  peakRate: MoneyInput,
  offpeakRate: MoneyInput
): Array<Array<MoneyInput>> {
  const weekday = Array.from(new Array(24), (x, i) =>
    i > 5 && i < 22 ? peakRate : offpeakRate
  )

  const weekend = Array.from(new Array(24), () => offpeakRate)

  // Week starts on Monday
  const week = Array.from(new Array(7), (x, i) =>
    i < 5 ? [...weekday] : [...weekend]
  )

  return week
}

// This function does the inverse of toGridCostArray.
// It takes an array of rates, selects a known "peak" hour as the peakRate
// and a known "offpeak" hour as the offpeakRate.
function gridCostArrayToRates(rates: Array<Array<Money>>): {
  peakRate: Money
  offpeakRate: Money
} {
  return {
    offpeakRate: rates[0][0],
    peakRate: rates[0][12],
  }
}

const payments = {
  moneyToMoneyInput(money: Money): MoneyInput {
    return _.omit(money, "display")
  },

  async getBillingPeriod(
    chargingSiteId: string,
    date: Date
  ): Promise<Result<BillingPeriod>> {
    type Response = {
      data?: {
        getBillingPeriod?: BillingPeriod
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($chargingSiteId: ID!, $date: DateTime!) {
        getBillingPeriod(chargingSiteId: $chargingSiteId, date: $date) {
          start
          finish
        }
      }
    `,
      {
        chargingSiteId: chargingSiteId,
        date: date,
      }
    )

    return Result.map(
      response,
      (x) => x.data?.getBillingPeriod || new ApiResponseWithErrors(x)
    )
  },

  async listInvoicesForChargingSites(
    siteIds: string[],
    state: InvoiceState
  ): Promise<Result<Array<Invoice>>> {
    type Response = {
      data?: {
        invoicesForChargingSites?: Array<Invoice>
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($siteIds: [ID!]!, $state: InvoiceState!) {
        invoicesForChargingSites(chargingSiteIds: $siteIds, state: $state) {
          ${graphQlInvoiceFields}
        }
      }
    `,
      {
        siteIds: siteIds,
        state: state,
      }
    )

    return Result.map(response, function (response: Response) {
      return mappedInvoices(response.data?.invoicesForChargingSites || [])
    })
  },

  async listInvoicesForRecipient(
    appUser: AppUser,
    state: InvoiceState
  ): Promise<Result<Array<Invoice>>> {
    type Response = {
      data?: {
        appUser?: {
          invoices?: Array<Invoice>
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!, $state: InvoiceState!) {
        appUser(id: $id) {
          invoices(state: $state) {
            ${graphQlInvoiceFields}
          }
        }
      }
    `,
      {
        id: appUser.id,
        state: state,
      }
    )

    return Result.map(response, function (response: Response) {
      return mappedInvoices(response.data?.appUser?.invoices || [])
    })
  },

  async listInvoiceBreakdowns(
    lineIds: string[]
  ): Promise<Result<Array<InvoiceBreakdown>>> {
    type Response = {
      data?: {
        invoiceBreakdowns?: Array<InvoiceBreakdown>
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($lineIds: [ID!]!) {
        invoiceBreakdowns(lineIds: $lineIds) {
          ${graphQlInvoiceBreakdownFields}
        }
      }
    `,
      {
        lineIds: lineIds,
      }
    )

    return Result.map(response, function (response: Response) {
      return mappedInvoiceBreakdowns(response.data?.invoiceBreakdowns || [])
    })
  },

  async deleteInvoice(invoice: Invoice): Promise<Result<void>> {
    type Response = {
      data?: {
        invoice?: {
          delete?: Invoice
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        invoice(id: $id) {
          delete {
            id
          }
        }
      }
      `,
      {
        id: invoice.id,
      }
    )

    return Result.toVoid(response)
  },

  async updatePaymentMethods(invoice: Invoice): Promise<Result<void>> {
    type Response = {
      data?: {
        invoice?: {
          updatePaymentMethods?: Invoice
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        invoice(id: $id) {
          updatePaymentMethods
        }
      }
      `,
      {
        id: invoice.id,
      }
    )

    return Result.toVoid(response)
  },

  async updateInvoiceLine(line: InvoiceLine): Promise<Result<void>> {
    type Response = {
      data?: {
        invoiceLine?: {
          update?: InvoiceLine
        }
      }
    }

    type InvoiceLineUpdateFields = {
      gridFee: MoneyInput
      flexibilityFee: MoneyInput
      offlineChargingFee: MoneyInput
      excessEnergyUsageKwh: number
    }

    // eslint-disable-next-line
    const { display: gridDisplay, ...gridFee } = line.gridFee
    // eslint-disable-next-line
    const { display: flexibilityFeeDisplay, ...flexibilityFee } =
      line.flexibilityFee
    // eslint-disable-next-line
    const { display: offlineChargingFeeDisplay, ...offlineChargingFee } =
      line.offlineChargingFee

    const fields: InvoiceLineUpdateFields = {
      gridFee,
      flexibilityFee,
      offlineChargingFee,
      excessEnergyUsageKwh: line.excessEnergyUsageKwh,
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!, $fields: InvoiceLineUpdateInput!) {
        invoiceLine(id: $id) {
          update(fields: $fields) {
            id
          }
        }
      }
      `,
      {
        id: line.id,
        fields,
      }
    )

    return Result.toVoid(response)
  },

  async createManualInvoice(fields: InvoiceInput): Promise<Result<void>> {
    type Response = {
      data?: {
        createManualInvoice?: Invoice
      }
    }

    fields = {
      ...fields,
      startDate: new Date(fields.startDate),
      endDate: new Date(fields.endDate),
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($fields: ManualInvoiceInput!) {
        createManualInvoice(fields: $fields) {
          id
        }
      }
      `,
      {
        fields,
      }
    )

    return Result.toVoid(response)
  },

  async sendInvoice(invoice: Invoice): Promise<Result<boolean>> {
    type Response = {
      data?: {
        invoice?: {
          send?: Invoice
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        invoice(id: $id) {
          send {
            id
          }
        }
      }
      `,
      {
        id: invoice.id,
      }
    )

    return Result.map(response, (x) => !!x.data?.invoice?.send?.id)
  },

  async emailInvoice(invoice: Invoice): Promise<Result<boolean>> {
    type Response = {
      data?: {
        invoice?: {
          emailPdf?: boolean
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        invoice(id: $id) {
          emailPdf
        }
      }
      `,
      {
        id: invoice.id,
      }
    )

    return Result.map(response, (x) => x.data?.invoice?.emailPdf || false)
  },

  async updateCapturedTotal(invoice: Invoice): Promise<Result<boolean>> {
    type Response = {
      data?: {
        invoice?: {
          updateCapturedTotal?: boolean
        }
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($id: ID!) {
        invoice(id: $id) {
          updateCapturedTotal
        }
      }
      `,
      {
        id: invoice.id,
      }
    )

    return Result.map(
      response,
      (x) => x.data?.invoice?.updateCapturedTotal || false
    )
  },

  async moveInvoicesToDrafts(invoices: Invoice[]): Promise<Result<boolean>> {
    type Response = {
      data?: {
        moveInvoicesToDrafts?: boolean
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($ids: [ID]!) {
        moveInvoicesToDrafts(invoiceIds: $ids)
      }
      `,
      {
        ids: invoices.map((invoice) => invoice.id),
      }
    )

    return Result.map(response, (x) => x.data?.moveInvoicesToDrafts || false)
  },

  async listInvoiceSettings(): Promise<Result<Array<InvoiceSettings>>> {
    type Response = {
      data?: {
        invoiceSettings?: Array<InvoiceSettings>
      }
    }
    const response: Result<Response> = await datasource.graphql(`
      query {
        invoiceSettings {
          ${graphQlInvoiceSettingsFields}
        }
      }
    `)

    return Result.map(response, function (response: Response) {
      return mappedSettings(response.data?.invoiceSettings || [])
    })
  },

  async listInvoiceSettingsForChargingSite(
    chargingSite: ChargingSite
  ): Promise<Result<Array<InvoiceSettings>>> {
    type Response = {
      data?: {
        invoiceSettingsForChargingSite?: Array<InvoiceSettings>
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($chargingSiteId: ID!) {
        invoiceSettingsForChargingSite(chargingSiteId: $chargingSiteId) {
          ${graphQlInvoiceSettingsFields}
        }
      }
    `,
      {
        chargingSiteId: chargingSite.id,
      }
    )

    return Result.map(response, function (response: Response) {
      return mappedSettings(response.data?.invoiceSettingsForChargingSite || [])
    })
  },

  async getInvoiceSettings(
    chargingSite: InvoiceChargingSite
  ): Promise<Result<InvoiceSettings | null>> {
    type Response = {
      data?: {
        chargingSite?: {
          invoiceSettings?: InvoiceSettings
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($chargingSiteId: ID!) {
        chargingSite(id: $chargingSiteId) {
          invoiceSettings {
            ${graphQlInvoiceSettingsFields}
          }
        }
      }
    `,
      {
        chargingSiteId: chargingSite.id,
      }
    )

    return Result.map(response, function (response: Response) {
      const settings = response.data?.chargingSite?.invoiceSettings || null

      if (settings) {
        return mappedSettings([settings])[0]
      } else {
        return null
      }
    })
  },

  async listRebateRates(): Promise<Result<Array<RebateRate>>> {
    type Response = {
      data?: {
        rebateRates?: Array<RebateRate>
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      query {
        rebateRates {
          ${graphQlRebateRateFields}
        }
      }
      `
    )

    return Result.map(response, (r) => {
      const rebateRates = r.data?.rebateRates

      return rebateRates
        ? rebateRates.map((x) => {
            return { ...x, month: new Date(x.month) }
          })
        : []
    })
  },

  async createRebateRate(
    rebateRateInput: RebateRateInput
  ): Promise<Result<void>> {
    type Response = {
      data?: {
        rebateRate?: {
          update?: { success: boolean }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($fields: RebateRateInput!) {
        createRebateRate(fields: $fields) {
            id
        }
      }
      `,
      {
        fields: rebateRateInput,
      }
    )

    return Result.toVoid(response)
  },

  async updateRebateRate(rebateRate: RebateRateInput): Promise<Result<void>> {
    type Response = {
      data?: {
        rebateRate?: {
          update?: { success: boolean }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($id: ID!, $fields: RebateRateInput!) {
        rebateRate(id: $id) {
          update(fields: $fields) {
            success
          }
        }
      }
      `,
      {
        id: rebateRate.id,
        fields: _.omit(rebateRate, ["id"]),
      }
    )

    return Result.toVoid(response)
  },

  async deleteRebateRate(rebateRate: RebateRate): Promise<Result<void>> {
    type Response = {
      data?: {
        rebateRate?: {
          delete?: { success: boolean }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($id: ID!) {
        rebateRate(id: $id) {
          delete {
            success
          }
        }
      }
      `,
      {
        id: rebateRate.id,
      }
    )

    return Result.toVoid(response)
  },

  async setInvoiceSettings(
    settings: InvoiceSettings,
    createNew: boolean
  ): Promise<Result<boolean>> {
    const fields: InvoiceSettingsInput = {
      type: settings.type,
      chargingSiteFeeKwh: settings.chargingSiteFeeKwh.amount,
      gridFee: settings.gridFee.amount,
      fixedFlexibilityFee: settings.fixedFlexibilityFee.amount,
      percentageFlexibilityFee: settings.percentageFlexibilityFee,
      fixedProcessingFee: settings.fixedProcessingFee.amount,
      percentageProcessingFee: settings.percentageProcessingFee,
      schedule: settings.schedule,
      dayOfSchedule: settings.dayOfSchedule,
      gracePeriodDays: settings.gracePeriodDays,
      taxPercentage: settings.taxPercentage,
      chargingSite: settings.chargingSite,
      excessEnergyFeeKwh: settings.excessEnergyFeeKwh.amount,
      activeFrom: settings.activeFrom,
      useRebate: settings.useRebate,
      dataSource: settings.dataSource,
      taxSystem: settings.taxSystem,
      dummyTax: settings.dummyTax,
    }

    type Response = {
      data?: {
        setInvoiceSettings?: InvoiceSettings
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID, $fields: InvoiceSettingsInput!, $createNew: Boolean!) {
        setInvoiceSettings(id: $id, fields: $fields, createNew: $createNew) {
          id
        }
      }
      `,
      {
        id: settings.id,
        fields,
        createNew,
      }
    )

    return Result.map(
      response,
      (x) => typeof x.data?.setInvoiceSettings?.id == "string"
    )
  },

  async generateInvoiceFor(
    chargingSite: ChargingSite,
    date: Date
  ): Promise<Result<Array<string>>> {
    type Response = {
      data?: {
        chargingSite?: {
          generateInvoiceFor?: Array<Invoice>
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($siteId: ID!, $date: DateTime!) {
        chargingSite(id: $siteId) {
          generateInvoiceFor(date: $date) {
            id
          }
        }
      }
      `,
      {
        siteId: chargingSite.id,
        date: date,
      }
    )

    return Result.map(response, (x) =>
      (x.data?.chargingSite?.generateInvoiceFor || []).map(
        (result) => result.id
      )
    )
  },

  async sendInvoiceNotification(
    invoice: Invoice,
    emailTemplate: EmailTemplate
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        invoice?: {
          dynamicNotification?: boolean
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($invoiceId: ID!, $emailTemplateId: ID!) {
        invoice(id: $invoiceId) {
          dynamicNotification(emailTemplateId: $emailTemplateId)
        }
      }
      `,
      {
        invoiceId: invoice.id,
        emailTemplateId: emailTemplate.id,
      }
    )

    return Result.map(
      response,
      (x) => x.data?.invoice?.dynamicNotification || false
    )
  },

  async listVariableGridCosts(): Promise<Result<Array<VariableGridCost>>> {
    type Response = {
      data?: {
        variableGridRates?: Array<RawVariableGridCost>
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query {
        variableGridRates{
          ${graphQlVariableGridCostFields}
        }
      }
      `
    )

    return Result.map(response, (x) => {
      const gridCosts = x.data?.variableGridRates
      return gridCosts
        ? gridCosts.map((c: RawVariableGridCost) => {
            return {
              ..._.omit(c, "weekHourlyRatesKwh"),
              ...gridCostArrayToRates(c.weekHourlyRatesKwh),
              validFrom: new Date(c.validFrom),
            }
          })
        : []
    })
  },

  async createVariableGridCost(
    input: CreateVariableGridCostInput
  ): Promise<Result<void>> {
    const weekHourlyRatesKwh = ratesToGridCostArray(
      input.peakRate,
      input.offpeakRate
    )

    const fields = {
      ..._.omit(input, ["peakRate", "offpeakRate"]),
      weekHourlyRatesKwh,
    }

    type Response = { data?: { VariableGridCost?: { id: string } } }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($fields: VariableGridRateInput!) {
        createVariableGridRate(fields: $fields) {
          id
        }
      }
      `,
      {
        fields,
      }
    )

    return Result.toVoid(response)
  },

  async updateVariableGridCost(
    input: EditVariableGridCostInput
  ): Promise<Result<void>> {
    const weekHourlyRatesKwh = ratesToGridCostArray(
      input.peakRate,
      input.offpeakRate
    )

    const fields = {
      validFrom: input.validFrom,
      gridOperatorId: input.gridOperatorId,
      weekHourlyRatesKwh,
    }

    type Response = { data?: { variableGridRate?: { success: boolean } } }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!, $fields: VariableGridRateInput!) {
        variableGridRate(id: $id) {
          update(fields: $fields) {
            success
          }
        }
      }
      `,
      {
        fields,
        id: input.id,
      }
    )

    return Result.toVoid(response)
  },

  async deleteVariableGridCost(
    input: EditVariableGridCostInput
  ): Promise<Result<void>> {
    type Response = { data?: { variableGridRate?: { success: boolean } } }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        variableGridRate(id: $id) {
          delete {
            success
          }
        }
      }
      `,
      {
        id: input.id,
      }
    )

    return Result.toVoid(response)
  },

  variableGridCostToInput(
    gridCost: VariableGridCost
  ): CreateVariableGridCostInput | EditVariableGridCostInput {
    return {
      ..._.omit(gridCost, ["gridOperator", "peakRate", "offPeakRate"]),
      gridOperatorId: gridCost?.gridOperator.id,
      peakRate: datasource.payments.moneyToMoneyInput(gridCost.peakRate),
      offpeakRate: datasource.payments.moneyToMoneyInput(gridCost.offpeakRate),
    }
  },

  defaultCreateVariableGridCostInput(): CreateVariableGridCostInput {
    return {
      peakRate: { currency: "NOK", amount: "0" },
      offpeakRate: { currency: "NOK", amount: "0" },
      gridOperatorId: "",
      validFrom: new Date(),
    }
  },

  defaultEditVariableGridCostInput(): EditVariableGridCostInput {
    return {
      id: "",
      peakRate: { currency: "NOK", amount: "0" },
      offpeakRate: { currency: "NOK", amount: "0" },
      gridOperatorId: "",
      validFrom: new Date(),
    }
  },
}

export default payments

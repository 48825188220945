


































































import Vue from "vue"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import type { InvoiceLine } from "@/datasource/payments"

import MoneyValue from "@/components/MoneyValue.vue"
import NumberValue from "@/components/NumberValue.vue"
import ConfirmedButton from "@/components/ConfirmedButton.vue"

export default Vue.extend({
  components: {
    MoneyValue,
    NumberValue,
    ConfirmedButton,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },
    draft: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      lines: [] as InvoiceLine[],
      headers: [
        { text: "Charging Site", value: "chargingSite" },
        { text: "Chargers", value: "chargers", align: "right" },
        { text: "Variable Energy cost", value: "energyCost", align: "right" },
        { text: "Flexibility Fee", value: "flexibilityFee", align: "right" },
        {
          text: "Flexibility Fee Tax",
          value: "flexibilityFeeTax",
          align: "right",
        },
        { text: "Grid Fee", value: "gridFee", align: "right" },
        {
          text: "Offline Charging Usage (kWh)",
          value: "offlineChargingUsageKwh",
          align: "right",
        },
        {
          text: "Offline Charging Fee",
          value: "offlineChargingFee",
          align: "right",
        },
        { text: "Energy Rebate", value: "energyRebate", align: "right" },
        { text: "Tax *", value: "tax", align: "right" },
        { text: "Total", value: "total", align: "right" },
        {
          text: "Excess Energy Usage (kWh)",
          value: "excessEnergyUsageKwh",
          align: "right",
        },
        { text: "Energy Used (kWh)", value: "energyUsed", align: "right" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        { text: "Schedule", value: "settings.schedule", align: "right" },
      ],
    }
  },

  methods: {
    ...formatters.number,
    ...formatters.dateTime,

    async updateInvoiceLine(item: InvoiceLine) {
      if (!this.draft) {
        return
      }

      await datasource.payments.updateInvoiceLine(item)
      this.$emit("reload")
    },
  },
})

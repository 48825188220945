



































































import Vue from "vue"
import datasource from "@/datasource"
import type { RebateRate } from "@/datasource/payments"

import ConfirmedButton from "@/components/ConfirmedButton.vue"
import EditEnergyRebateDialog from "./EditEnergyRebateDialog.vue"
import CreateEnergyRebateDialog from "./CreateEnergyRebateDialog.vue"

export default Vue.extend({
  components: {
    ConfirmedButton,
    CreateEnergyRebateDialog,
    EditEnergyRebateDialog,
  },

  data() {
    return {
      loading: true,
      energyRebates: [] as Array<RebateRate>,
      headers: [
        { text: "Month", value: "month" },
        {
          text: "Rebate Percentage",
          value: "rebatePercentage",
        },
        {
          text: "Rebate Threshold inc. VAT (NOK/kWh)",
          value: "rebateThreshold",
        },
        {
          text: "NO1 Rate (kWh)",
          value: "no1RatePerKwh.display",
        },
        {
          text: "NO2 Rate (kWh)",
          value: "no2RatePerKwh.display",
        },
        {
          text: "NO3 Rate (kWh)",
          value: "no3RatePerKwh.display",
        },
        {
          text: "NO4 Rate (kWh)",
          value: "no4RatePerKwh.display",
        },
        {
          text: "NO5 Rate (kWh)",
          value: "no5RatePerKwh.display",
        },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true
      const rebatesResult = await datasource.payments.listRebateRates()

      if (rebatesResult instanceof Error) {
        console.error(rebatesResult)
        return
      }

      this.energyRebates = rebatesResult
      this.loading = false
    },

    toMonth(dt: Date) {
      return `${dt.toLocaleString("default", {
        month: "short",
      })} ${dt.getFullYear()}`
    },

    async deleteEnergyRebate(rebate: RebateRate) {
      const result = await datasource.payments.deleteRebateRate(rebate)
      if (result instanceof Error) {
        console.error(result)
        return
      }
      this.loadData()
    },
  },
})

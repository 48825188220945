







































































import Vue from "vue"
import type { PropType } from "vue"
import helpers from "@/utils/helpers"
import formatters from "@/utils/formatters"
import datasource from "@/datasource"
import type { ChargingPlan, ChargingSession } from "@/datasource/charging"

function initialData() {
  return {
    showDialog: false,
    loading: false,
    chargingPlan: null as ChargingPlan | null,
  }
}

export default Vue.extend({
  props: {
    chargingSession: {
      type: Object as PropType<ChargingSession>,
      required: true,
    },
  },

  data: initialData,

  computed: {
    sourceIsOptimization(): boolean {
      if (this.chargingPlan) {
        return this.chargingPlan.source == "OPTIMIZER"
      } else {
        return false
      }
    },

    stateOfChargeTomorrow(): number | null {
      return this.chargingSession.car?.stateOfChargeTomorrow || null
    },

    optimizationRequestUrl(): string {
      const requestId =
        this.chargingPlan?.sourceMetadata?.optimization_request_id

      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_OPTIMIZATION_REQUESTS_INDEX,
        {
          filters: [`match_phrase:(request.id:'${requestId}')`],
          time: "from:now-30d,to:now%2B30d",
          columns: [
            "request_json",
            "request.departure_hour_offset",
            "response_status.is_optimal",
          ],
        }
      )
    },

    optimizationChargingPlanUrl(): string {
      const requestId =
        this.chargingPlan?.sourceMetadata?.optimization_request_id

      return helpers.kibanaDiscoverUrl(
        process.env
          .VUE_APP_ELASTICSEARCH_OPTIMIZATION_LOGS_CHARGING_PLANS_INDEX,
        {
          filters: [`match_phrase:(request_id:'${requestId}')`],
          time: "from:now-7d,to:now",
          columns: ["charger", "phase_1", "phase_2", "phase_3", "timestamp"],
        }
      )
    },
  },

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      }
    },
  },

  methods: {
    ...formatters.charging,
    ...formatters.dateTime,
    ...formatters.number,

    close() {
      Object.assign(this.$data, initialData())
    },

    async loadData() {
      this.loading = true
      const result = await datasource.charging.getChargingPlan(
        this.chargingSession
      )
      if (result instanceof Error) {
        console.error(result)
      } else {
        this.chargingPlan = result
      }
      this.loading = false
    },
  },
})

import Big from "big.js"
import type { ChargerMathematicalModelConnector } from "@/datasource/chargers"
import type { ChargerActionRule } from "@/datasource/chargers"
import type { Money } from "@/datasource/payments"
import { DateTime } from "luxon"
import type { DurationUnits } from "luxon"

interface ConvertableToString<Type> {
  toString: (this: Type) => string
}

const dash = "—"

const generic = {
  dashIfNull<
    Type extends ConvertableToString<Type>,
    Mapped extends ConvertableToString<Mapped>
  >(value: Type | null | undefined, mapper?: (value: Type) => Mapped): string {
    if (value === null || value === undefined) {
      // em-dash for visual weight
      return dash
    }
    if (mapper) {
      return mapper(value).toString()
    }
    return value.toString()
  },
}

const amount = {
  moneyValue(amount: Money | null): string | number {
    if (amount) {
      return amount.amount
    } else {
      return dash
    }
  },

  localisedAmount(amount: Big | Money | number | string | null): string {
    const localised = (amount: string) => {
      return parseFloat(amount)
        .toLocaleString([], {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .replace(/\s/g, "")
    }

    if (amount === dash) {
      return "0"
    } else if (amount && typeof amount === "object" && "amount" in amount) {
      return localised(amount.amount)
    } else if (amount && typeof amount === "object") {
      return localised(amount.toFixed(2))
    } else if (amount && typeof amount === "number") {
      return localised(`${amount}`)
    } else if (amount && typeof amount === "string") {
      return localised(amount)
    } else {
      return "0"
    }
  },

  amountDisplay(amount: Money | null): string {
    if (amount && typeof amount === "object" && amount.display) {
      return amount.display
    } else if (amount && typeof amount === "string") {
      return amount
    } else {
      return dash
    }
  },
}

const chargers = {
  chargerActionRule: function (value: ChargerActionRule | null): string {
    if (value) {
      const start = value.start.split(":")[0]
      const finish = value.finish.split(":")[0]
      const daysOfWeek = value.daysOfWeek.join(",")
      return `${start}-${finish} ${daysOfWeek}`
    } else {
      return ""
    }
  },
}

const charging = {
  phaseOrder(connector: ChargerMathematicalModelConnector): string {
    switch (connector.phaseOrder) {
      case "PHASE_1_2_3":
        return "1-2-3"
      case "PHASE_2_3_1":
        return "2-3-1"
      case "PHASE_3-1-2":
        return "3-1-2"
      default:
        return connector.phaseOrder
    }
  },

  powerWToKw(
    watts: number | null | undefined,
    decimalPrecision: number | null = null
  ): string {
    return generic.dashIfNull(watts, (x) =>
      decimalPrecision ? (x / 1000).toFixed(decimalPrecision) : x / 1000
    )
  },
}

function ensureIsDate(date: Date | string): Date {
  if (typeof date == "string") {
    return new Date(date)
  } else {
    return date
  }
}

const dateTime = {
  dateTime(date: Date | string | null, timezone?: string): string {
    if (date) {
      return ensureIsDate(date).toLocaleString([], {
        timeZoneName: "short",
        timeZone: timezone,
      })
    } else {
      return dash
    }
  },

  isoDate(date: Date | string | null): string {
    if (date) {
      return ensureIsDate(date).toISOString().split("T")[0]
    } else {
      return dash
    }
  },

  date(date: Date | string | null | undefined): string {
    if (date) {
      return ensureIsDate(date).toLocaleDateString([])
    } else {
      return dash
    }
  },

  time(date: Date | string | null): string {
    if (date) {
      return ensureIsDate(date).toLocaleString([], {
        timeStyle: "medium",
      })
    } else {
      return dash
    }
  },

  dateTimeDiffInWords(
    date1: Date | string | null,
    date2: Date | string | null
  ): string {
    if (!date1 || !date2) {
      return dash
    }
    const dateTime1 = DateTime.fromJSDate(ensureIsDate(date1))
    const dateTime2 = DateTime.fromJSDate(ensureIsDate(date2))
    const units: DurationUnits = ["years", "months", "days", "hours", "minutes"]
    let duration = dateTime2.diff(dateTime1, units)
    for (let i = 0; i < units.length; i++) {
      if (duration.get(units[i]) == 0) {
        duration = duration.shiftTo(...units.slice(i + 1))
      } else {
        break
      }
    }
    duration = duration.set({ minutes: Math.round(duration.minutes) })
    return duration.toHuman({ unitDisplay: "narrow" })
  },
}

const number = {
  fixedFloat(
    number: number | null | undefined,
    precision: string | number | null
  ): string {
    let precisionNum = parseInt(`${precision}`)
    if (isNaN(precisionNum)) {
      precisionNum = 1
    }

    return generic.dashIfNull(number, (x) => x.toFixed(precisionNum))
  },
}

export default {
  generic,
  amount,
  chargers,
  charging,
  dateTime,
  number,
}

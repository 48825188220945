
















import Vue from "vue"
import formatters from "@/utils/formatters"

export default Vue.extend({
  props: {
    value: {
      required: true,
      type: Number,
    },
    label: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: false,
    },
    precision: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      modifiedValue: this.value,
    }
  },

  methods: {
    ...formatters.number,

    emit() {
      this.$emit("input", this.modifiedValue)
    },
  },
})































































import Vue, { PropType } from "vue"
import Big from "big.js"
import type {
  ChargingSessionPricing,
  SessionPricingBreakdownLine,
} from "@/datasource/charging"
import _ from "underscore"

export default Vue.extend({
  props: {
    chargingSessionPricings: {
      type: Array as PropType<ChargingSessionPricing[]>,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      instantPricings: [] as ChargingSessionPricing[],
      smartPricings: [] as Array<ChargingSessionPricing>,
      selectedInstantPricing: null as ChargingSessionPricing | null,
      selectedSmartPricing: null as ChargingSessionPricing | null,
    }
  },
  computed: {
    rows(): {
      stepFinish: string
      energyPrice: string
      smartPowerOutput: Big
      instantPowerOutput: Big
      smartCost: string
      instantCost: string
    }[] {
      if (this.selectedInstantPricing || this.selectedSmartPricing) {
        const instantTimestamps = this.selectedInstantPricing
          ? this.selectedInstantPricing.breakdownLines.map(
              (l: SessionPricingBreakdownLine) => l.stopTime
            )
          : []

        const smartTimestamps = this.selectedSmartPricing
          ? this.selectedSmartPricing.breakdownLines.map(
              (l: SessionPricingBreakdownLine) => l.stopTime
            )
          : []

        const sortedTimestamps = instantTimestamps.concat(smartTimestamps)

        sortedTimestamps.sort(
          (d1: Date, d2: Date) => d1.getTime() - d2.getTime()
        )

        sortedTimestamps.forEach((d: Date) => d.setMinutes(0, 0, 0))

        const uniqTimestamps = sortedTimestamps
          .map((t) => t.getTime())
          .filter((t, i, arr) => arr.indexOf(t) === i)
          .map((t) => new Date(t))

        const rows = uniqTimestamps.map((t) => {
          const smartBreakdownLine = this.selectedSmartPricing
            ? _.find(
                this.selectedSmartPricing.breakdownLines,
                (l) => l.stopTime.getHours() == t.getHours()
              )
            : null

          const instantBreakdownLine = this.selectedInstantPricing
            ? _.find(
                this.selectedInstantPricing.breakdownLines,
                (l) => l.stopTime.getHours() == t.getHours()
              )
            : null

          const sumPhasePowers: (l: SessionPricingBreakdownLine) => Big = (
            l
          ) => {
            return [
              l.maxChargingPowerPhase1Kw,
              l.maxChargingPowerPhase2Kw,
              l.maxChargingPowerPhase3Kw,
            ].reduce((acc: Big, val) => (val ? acc.add(val) : acc), Big("0"))
          }

          const energyPrice =
            instantBreakdownLine?.energyPriceKwh.display ||
            (smartBreakdownLine?.energyPriceKwh.display as string)

          return {
            stepFinish: t.toISOString(),
            energyPrice: energyPrice,
            smartPowerOutput: smartBreakdownLine
              ? sumPhasePowers(smartBreakdownLine)
              : Big("0"),
            instantPowerOutput: instantBreakdownLine
              ? sumPhasePowers(instantBreakdownLine)
              : Big("0"),
            smartCost: smartBreakdownLine
              ? smartBreakdownLine.cost.display
              : "NOK 0.00",
            instantCost: instantBreakdownLine
              ? instantBreakdownLine.cost.display
              : "NOK 0.00",
          }
        })

        return rows
      } else {
        return []
      }
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const partitionedPricings = _.partition(
        this.chargingSessionPricings,
        (p) => p.type == "instant"
      )

      this.instantPricings = partitionedPricings[0]
      this.smartPricings = partitionedPricings[1]
      this.selectedInstantPricing = this.instantPricings[0]
      this.selectedSmartPricing = this.smartPricings[0]
    },

    pricingDisplayName(pricing: ChargingSessionPricing): string {
      return `(${pricing.type}) ${pricing.updatedAt}`
    },
  },
})















import Vue from "vue"
import type { PropType } from "vue"
import type { Charger } from "@/datasource/chargers"
import EditChargerDialog from "@/components/EditChargerDialog.vue"

export default Vue.extend({
  components: {
    EditChargerDialog,
  },

  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
  },
})









































































































































































































































































































import Vue from "vue"
import Big from "big.js"
import _ from "underscore"
import helpers from "@/utils/helpers"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import { ChargingState } from "@/datasource/charging"
import { ChargingSite } from "@/datasource/chargers"
import type { ChargingSession } from "@/datasource/charging"
import type { Charger } from "@/datasource/chargers"
import BooleanIcon from "@/components/BooleanIcon.vue"
import Checkbox from "@/components/Checkbox.vue"
import CollapsibleDataTable from "@/components/CollapsibleDataTable.vue"
import ConfirmedButton from "@/components/ConfirmedButton.vue"
import ChargingSessionLinkMenu from "@/components/ChargingSessionLinkMenu.vue"
import ChargingSessionPricingDialog from "@/components/ChargingSessionPricingDialog.vue"
import ChargerLink from "@/components/ChargerLink.vue"
import TimeWithHoverDetail from "@/components/TimeWithHoverDetail.vue"
import ChargingPlanDialog from "./ChargingPlanDialog.vue"
import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"
import RegisterChargingSessionDialog from "./RegisterChargingSessionDialog.vue"

function sortBy(
  field: string
): (a: Record<string, string>, b: Record<string, string>) => number {
  return function (a: Record<string, string>, b: Record<string, string>) {
    const aField = (a && a[field]) || ""
    const bField = (b && b[field]) || ""
    if (aField < bField) {
      return -1
    } else if (aField > bField) {
      return 1
    }
    return 0
  }
}

export default Vue.extend({
  components: {
    BooleanIcon,
    Checkbox,
    ChargerLink,
    ChargingPlanDialog,
    ChargingSessionPricingDialog,
    CollapsibleDataTable,
    ConfirmedButton,
    ChargingSessionLinkMenu,
    ChargingSiteSelect,
    RegisterChargingSessionDialog,
    TimeWithHoverDetail,
  },

  data() {
    return {
      loading: true,
      charger: null as Charger | null,
      selectedChargingSites: [] as ChargingSite[],
      chargingSessions: [] as ChargingSession[],
      onlyKnownChargers: true,
      onlyUnhealthy: false,
      search: "",
      selected: [] as ChargingSession[],
      headers: [
        { text: "ID", width: 0, value: "id" },
        { text: "Car", width: 0, value: "car", sort: sortBy("displayName") },
        {
          text: "Charger",
          width: 0,
          value: "charger",
          sort: sortBy("displayName"),
        },
        { text: "OCPP ID", width: 0, value: "charger.ocppIdentifier" },
        { text: "Charging Site", width: 0, value: "charger.chargingSite.name" },
        {
          text: "Connector",
          width: 0,
          value: "chargerConnectorId",
          align: "right",
        },
        { text: "Connector Status", width: 0, value: "charger.status" },
        { text: "Authorized", width: 0, value: "authorized", align: "center" },
        { text: "Authorized At", width: 0, value: "authorizedAt" },
        {
          text: "Authorization Source",
          width: 0,
          value: "authorizationSource",
        },
        {
          text: "Smart Charging",
          value: "smartChargingEnabled",
          width: 0,
          align: "center",
        },
        {
          text: "Current Power (kW)",
          width: 0,
          value: "instantaneousChargingPowerW",
          align: "right",
        },
        {
          text: "Planned Power (kW)",
          width: 0,
          value: "currentChargingPlanStep.maxChargingPowerW",
          align: "right",
        },
        {
          text: "Offered Current (A)",
          value: "currentOfferedA",
          width: 0,
          align: "right",
        },
        {
          text: "Energy Transferred (kWh)",
          value: "energyUsageWh",
          width: 0,
          align: "right",
        },
        {
          text: "Cleaned Energy Transferred (kWh)",
          value: "cleanedEnergyUsageWh",
          width: 0,
          align: "right",
        },
        {
          text: "Max Power (kw)",
          value: "maximumObservedPowerOutputW",
          width: 0,
          align: "right",
          class: "header-no-wrap",
        },
        {
          text: "Output at Max Power (A)",
          value: "currentOutputPhasesAAtMaximumPowerOutputW",
          width: 0,
          align: "center",
          class: "header-no-wrap",
        },
        {
          text: "Internal SoC",
          value: "vehicleSoc",
          width: 0,
          align: "right",
        },
        {
          text: "OCPP Soc",
          value: "ocppSoc",
          width: 0,
          align: "right",
        },
        {
          text: "OCPP Transactions",
          value: "ocppTransactionIds",
          width: 0,
          align: "right",
        },
        {
          text: "OCPP VID",
          value: "ocppVehicleId",
        },
        {
          text: "Session Status",
          value: "sessionStatus",
          width: 0,
          align: "center",
        },
        { text: "Connected At", value: "createdAt", width: 0 },
        { text: "Connected For", value: "connectedFor", width: 0 },
        {
          text: "Last Successful Enforcement",
          value: "chargingPlanLastEnforcedAt",
          width: 0,
        },
        {
          text: "Enforcement Warnings",
          value: "chargingPlanEnforcementFailureCount",
          width: 0,
          align: "right",
        },
        {
          text: "Charging Savings",
          value: "chargingPlan.chargingSavings.display",
          width: 0,
        },
        {
          text: "Commands API",
          width: 0,
          value: "charger.managerSettings.commandsApi",
        },
        {
          text: "Observations API",
          width: 0,
          value: "charger.managerSettings.observationsApi",
        },
        {
          text: "Ongoing Energy Cost",
          value: "energyCost.display",
          width: 0,
        },
        {
          text: "Ongoing Variable Grid Cost",
          value: "gridCost.display",
          width: 0,
        },
        {
          text: "Estimated Instant Charging Cost",
          value: "instantChargingSessionPricing",
          width: 0,
        },
        {
          text: "Estimated Smart Charging Cost",
          value: "smartChargingSessionPricing",
          width: 0,
        },
        {
          text: "Estimated Savings",
          width: 0,
          value: "estimatedChargingSavings",
        },
        {
          text: "Route Departure time",
          value: "routeDepartureTime",
          width: 0,
        },
        {
          text: "Route ID",
          value: "routeId",
          width: 0,
        },
        {
          text: "Driver ID",
          value: "routeDriverId",
          width: 0,
        },
        {
          text: "App User",
          class: "header-no-wrap",
          value: "appUserLink",
          width: 0,
          align: "center",
          sortable: false,
        },
        {
          text: "Usage",
          value: "usageLink",
          width: 0,
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "externalLinks",
          name: "Links",
          width: 0,
          align: "right",
          sortable: false,
        },
        {
          text: "",
          name: "Menu",
          value: "menu",
          width: 0,
          align: "right",
          sortable: false,
        },
        {
          text: "",
          value: "spacing",
        },
      ],
      loadDataIntervalId: null as number | null,
      chargingSessionManagementStrategies: [] as string[],
    }
  },

  computed: {
    filteredChargingSessions(): ChargingSession[] {
      let ret = this.chargingSessions
      if (this.onlyKnownChargers) {
        ret = ret.filter((session) => session.charger !== null)
      }
      if (this.onlyUnhealthy) {
        ret = ret.filter((session) => !session.isHealthy)
      }
      return ret
    },

    selectedAmqpDashboardUrl(): string {
      const ocppIds = _.map(
        this.selected,
        (session) => session.charger?.ocppIdentifier || ""
      )
      return helpers.bulkAmqpDashboardUrl(ocppIds)
    },
  },

  watch: {
    $route() {
      this.loadData()
    },
  },

  created() {
    this.loadData()
    this.loadDataIntervalId = window.setInterval(this.loadData, 10_000)
  },

  beforeDestroy() {
    if (this.loadDataIntervalId) {
      clearInterval(this.loadDataIntervalId)
    }
  },

  methods: {
    ...formatters.charging,
    ...formatters.dateTime,

    async loadData() {
      this.loading = true

      const { chargerId } = this.$route.params
      let chargingSessions: ChargingSession[] = []

      if (chargerId) {
        const result = await datasource.chargers.getCharger(chargerId)
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.charger = result
        chargingSessions = result?.chargingSessions || []
      } else {
        this.charger = null

        let result
        if (this.selectedChargingSites.length) {
          result = (await Promise.all(
            this.selectedChargingSites.map((site) =>
              datasource.charging.listChargingSessionsForChargingSite(site)
            )
          ).then((res) =>
            res.filter((s) => !(s instanceof Error)).flat()
          )) as ChargingSession[]
        } else {
          result = await datasource.charging.listChargingSessions()
        }
        if (result instanceof Error) {
          console.error(result)
          return
        }
        chargingSessions = result
      }

      if (this.chargingSessionManagementStrategies.length == 0) {
        const result =
          await datasource.charging.listChargingSessionManagementStrategies()
        if (result instanceof Error) {
          console.error(result)
        }
        this.chargingSessionManagementStrategies = result
      }

      this.chargingSessions = chargingSessions
      this.loading = false
    },

    async clearChargingSession(chargingSession: ChargingSession) {
      const result = await datasource.charging.clearChargingSession(
        chargingSession
      )
      if (result instanceof Error) {
        console.error(result)
      } else {
        this.loadData()
      }
    },

    async startCharging(chargingSession: ChargingSession) {
      return await this.setChargingState(
        chargingSession,
        ChargingState.Charging
      )
    },

    async stopCharging(chargingSession: ChargingSession) {
      return await this.setChargingState(chargingSession, ChargingState.Stopped)
    },

    async runOptimization(chargingSession: ChargingSession) {
      const result = await datasource.charging.optimizeChargingSession(
        chargingSession
      )
      if (result instanceof Error) {
        console.error(result)
      }
    },

    async setChargingState(
      chargingSession: ChargingSession,
      chargingState: ChargingState
    ) {
      const result = await datasource.charging.setChargingState(
        chargingSession,
        chargingState
      )
      if (result instanceof Error) {
        console.error(result)
      }
      return result
    },

    connectorStatus(chargingSession: ChargingSession): string {
      const chargerStatus = chargingSession.charger?.status
      const connector = chargingSession?.charger?.apiData?.connectors
        ? _.find(
            chargingSession.charger.apiData.connectors,
            (connector) => connector.order == chargingSession.chargerConnectorId
          )
        : null

      if (
        chargerStatus &&
        _.contains(["Errored", "Offline", "Pending"], chargerStatus)
      ) {
        return chargerStatus
      } else if (connector) {
        return connector.status
      } else {
        return "Pending"
      }
    },

    async setManagementStrategy(
      chargingSession: ChargingSession,
      managementStrategy: string
    ) {
      chargingSession.managementStrategy = managementStrategy
      const result =
        await datasource.charging.setChargingSessionManagementStrategy(
          chargingSession
        )
      if (result instanceof Error) {
        console.error(result)
      }
      Object.assign(chargingSession, result)
    },

    plannedPower(session: ChargingSession): number {
      return session.currentChargingPlanStep?.maxChargingPowerW || 0
    },

    rowClass(session: ChargingSession): string {
      if (!session.isHealthy) {
        return "is-unhealthy"
      }
      return ""
    },

    displayRfidToken(session: ChargingSession): string {
      return (
        session.rfidToken?.secret || session.rfidToken?.externalReference || ""
      )
    },

    instantChargingSessionPricing(session: ChargingSession): string {
      const instantPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "instant"
      )
      return instantPricing?.cost?.display || ""
    },

    smartChargingSessionPricing(session: ChargingSession): string {
      const smartPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "smart"
      )
      return smartPricing?.cost?.display || ""
    },

    estimatedChargingSavings(session: ChargingSession): string {
      const instantPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "instant"
      )

      const smartPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "smart"
      )

      const savings =
        instantPricing && smartPricing
          ? Big(instantPricing.cost.amount)
              .minus(Big(smartPricing.cost.amount))
              .toString()
          : ""

      return savings ? `${instantPricing?.cost?.currency} ${savings}` : ""
    },

    chargersIdString(): string {
      const ids = _.uniq(this.selected.map((s) => s.charger?.id))
      return _.compact(ids).join(",")
    },

    usageFromTime(sessions: ChargingSession[]): string | null {
      const dates = sessions.map((s) => new Date(s.createdAt).getTime())

      dates.sort((a: number, b: number) => a - b)

      if (dates.length) {
        return dates[0].toString()
      } else {
        return null
      }
    },
  },
})





































import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import { ValidationFailureError } from "@/datasource"
import type {
  ChargingSite,
  ChargingSiteMathematicalModel,
} from "@/datasource/chargers"

import ChargingSiteFields from "./ChargingSiteFields.vue"
import MathematicalModelFields from "./MathematicalModelFields.vue"

function initialData() {
  return {
    showDialog: false,
    loading: true,
    saving: false,
    modifiedChargingSite: {} as ChargingSite,
    modifiedMathematicalModel: {} as ChargingSiteMathematicalModel,
    mathematicalModelErrors: {},
  }
}

export default Vue.extend({
  components: {
    ChargingSiteFields,
    MathematicalModelFields,
  },

  props: {
    chargingSite: {
      type: Object as PropType<ChargingSite>,
      required: true,
    },
  },

  data: initialData,

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    async loadData() {
      this.loading = true

      // Temporarily populate our fields from the charging site provided in the
      // prop.
      this.modifiedChargingSite = { ...this.chargingSite }
      delete this.modifiedChargingSite.mathematicalModel

      if (this.chargingSite.mathematicalModel) {
        const mathematicalModel = {
          ...this.chargingSite.mathematicalModel,
        } as ChargingSiteMathematicalModel
        this.modifiedMathematicalModel = mathematicalModel
      }

      // And now get fresh data in case it's changed since the page loaded.
      if (!this.chargingSite.id) {
        this.close()
        return
      }

      {
        const result = await datasource.chargers.getChargingSite(
          this.chargingSite.id
        )
        if (result instanceof Error) {
          console.error(result)
          return
        }
        if (!result) {
          this.close()
          return
        }
        this.modifiedChargingSite = result
      }

      {
        const result =
          await datasource.chargers.getChargingSiteMathematicalModel(
            this.modifiedChargingSite
          )
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.modifiedMathematicalModel = result
      }

      this.loading = false
    },

    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true
      let isOk = true

      {
        const result = await datasource.chargers.updateChargingSite(
          this.modifiedChargingSite
        )
        if (result instanceof Error) {
          console.error(result)
          isOk = false
        }
      }

      const fields = this.modifiedMathematicalModel
      if (fields.location) {
        if (fields.location.latitude == "" || fields.location.longitude == "") {
          fields.location = null
        }
      }
      if (fields.mainFuse) {
        if (
          fields.mainFuse.phase1CapacityAmps.length <= 0 ||
          fields.mainFuse.phase2CapacityAmps.length <= 0 ||
          fields.mainFuse.phase3CapacityAmps.length <= 0
        ) {
          fields.mainFuse = null
        }
      }

      {
        const result =
          await datasource.chargers.setMathematicalModelForChargingSite(
            this.chargingSite,
            fields
          )
        if (result instanceof ValidationFailureError) {
          this.mathematicalModelErrors = result.errors
        } else if (result instanceof Error) {
          console.error(result)
          isOk = false
        }
      }

      if (isOk) {
        this.$emit("saved")
        this.close()
      }

      this.saving = false
    },
  },
})





































import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import { ValidationFailureError } from "@/datasource"
import type { Schedule } from "@/datasource/backgroundJobs"

import DynamicScheduleFields from "./DynamicScheduleFields.vue"

function initialData(schedule: Schedule) {
  return {
    showDialog: false,
    loading: false,
    saving: false,
    isFormValid: false,
    modifiedDynamicSchedule: { ...schedule },
    modifiedDynamicScheduleErrors: {} as Record<string, unknown>,
  }
}

export default Vue.extend({
  components: {
    DynamicScheduleFields,
  },

  props: {
    schedule: {
      type: Object as PropType<Schedule>,
      required: true,
    },

    availableFunctions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return initialData(this.schedule)
  },

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    async loadData() {
      this.loading = true

      this.modifiedDynamicSchedule = { ...this.schedule }

      this.loading = false
    },

    close() {
      Object.assign(this.$data, initialData(this.schedule))
    },

    async save() {
      this.saving = true

      const result = await datasource.backgroundJobs.updateDynamicSchedule(
        this.modifiedDynamicSchedule
      )

      if (result instanceof ValidationFailureError) {
        this.modifiedDynamicScheduleErrors = result.errors
      } else if (result instanceof Error) {
        console.error(result)
      } else {
        this.$emit("saved")
        this.close()
      }

      this.saving = false
    },
  },
})

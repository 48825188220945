















import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import { ApiResponseWithErrors } from "@/datasource"
import type { ChargingSite } from "@/datasource/chargers"

export default Vue.extend({
  props: {
    chargingSite: {
      type: Object as PropType<ChargingSite>,
      required: true,
    },

    date: {
      type: Date as PropType<Date>,
      required: true,
    },

    shouldLoad: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      invoiceGenerationResponse: [] as unknown[] | Record<string, unknown>,
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      if (this.chargingSite.id && this.shouldLoad) {
        const result = await datasource.payments.generateInvoiceFor(
          this.chargingSite,
          this.date
        )

        if (result instanceof ApiResponseWithErrors) {
          const errors = result.response.errors || []
          this.invoiceGenerationResponse = errors.map((e) => e.message)
        } else if (result instanceof Error) {
          console.error(result)
          this.invoiceGenerationResponse = ["internal server error"]
        } else {
          this.$emit("saved")
          this.invoiceGenerationResponse = result
        }
      }

      this.loading = false
    },

    blur() {
      this.$emit("blur")
    },
  },
})

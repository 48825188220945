





































































































































































































import Vue from "vue"
import _ from "underscore"
import { DateTime } from "luxon"

import store from "@/store"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import helpers from "@/utils/helpers"
import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"
import DateTimePicker from "@/components/DateTimePicker.vue"
import ChargerLink from "@/components/ChargerLink.vue"
import BooleanIcon from "@/components/BooleanIcon.vue"
import RequestAndResponse from "./RequestAndResponse.vue"
import SmartChargingEnabledIcon from "@/components/SmartChargingEnabledIcon.vue"
import type { PropType } from "vue"
import type { AppUser } from "@/datasource/appUsers"
import type { ChargingSite, Charger } from "@/datasource/chargers"
import type {
  OptimizationRequestLog,
  OptimizationChargingPlanLog,
} from "@/datasource/optimization"

function jsonParseOrRawString(json: string) {
  try {
    return JSON.parse(json)
  } catch (e) {
    return json
  }
}

export default Vue.extend({
  components: {
    BooleanIcon,
    ChargerLink,
    ChargingSiteSelect,
    DateTimePicker,
    RequestAndResponse,
    SmartChargingEnabledIcon,
  },

  props: {
    chargingSite: {
      type: Object as PropType<ChargingSite>,
      required: true,
    },
  },

  data() {
    const selectedDate = new Date()

    return {
      chargers: [] as Charger[],
      chargersHistoricStates: {} as {
        [chargerId: string]: {
          smartChargingEnabled: boolean
          sessionStatus: string
          planSource: string
          chargerConnectorStatus: string
        }
      },
      loadingAppUser: false,
      loadingRequestChargingPlan: false,
      loadingRequestLogs: false,
      modifiedChargingSite: this.chargingSite,
      optimizationRequest: null as OptimizationRequestLog | null,
      optimizationRequestLogs: [] as OptimizationRequestLog[],
      // This field is a workaround for a wild bug where Vuetify is incrementing the date by 1 day
      optimizationRequestLogsRequestedAt: {} as {
        requestId: string
        requestedAt: Date
      }[],
      requestChargingPlanLog: null as OptimizationChargingPlanLog | null,
      selectedDate,
      selectedUser: null as AppUser | null,
      showRawRequestAndResponse: false,
      requestJson: {} as
        | Record<string, never>
        | {
            data: {
              power_prices: Array<number>
              car_data?: {
                first_departure_time_user_set: number
                charger_id: string
                first_departure_time_forecast: number
                first_departure_time_date: Date
              }[]
            }
          },
      responseJson: {},
      powerPrices: [] as number[],
      powerPriceOffset: 0,
    }
  },

  computed: {
    loading(): boolean {
      return (
        this.loadingRequestLogs ||
        this.loadingRequestChargingPlan ||
        this.loadingAppUser
      )
    },

    timestamps(): Date[] {
      if (this.requestChargingPlanLog) {
        return this.requestChargingPlanLog.map((x) => x.timestamp)
      } else {
        return []
      }
    },

    powerPricesHourOffset(): number {
      if (this.optimizationRequest) {
        // The first powerPrice in the optimizer request corresponds to the hour after
        // the optimization happened. Here we find the index of that hour to use as an offset.
        return (
          parseInt(this.hourNumber(this.optimizationRequest.requestedAt)) - 1
        )
      } else {
        return 0
      }
    },

    departureTimes(): {
      [chargerId: string]: {
        day: Date
        userSetHour: number
        forecastHour: number
      }
    } {
      if (this.requestJson?.data?.car_data) {
        const departureTimes = this.requestJson.data.car_data.map((carData) => {
          return [
            carData.charger_id.split("_")[0],
            {
              day: new Date(carData.first_departure_time_date),
              userSetHour: carData.first_departure_time_user_set - 1,
              forecastHour: carData.first_departure_time_forecast - 1,
            },
          ]
        })

        return Object.fromEntries(departureTimes)
      } else {
        return {}
      }
    },

    canDebug(): boolean {
      return store.state.hasElevatedPrivileges
    },

    optimizationRequestUrl(): string {
      const requestId = this.optimizationRequest?.requestId

      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_OPTIMIZATION_REQUESTS_INDEX,
        {
          filters: [`match_phrase:(request.id:'${requestId}')`],
          time: "from:now-30d,to:now%2B30d",
        }
      )
    },

    optimizationChargingPlanUrl(): string {
      const requestId = this.optimizationRequest?.requestId

      return helpers.kibanaDiscoverUrl(
        process.env
          .VUE_APP_ELASTICSEARCH_OPTIMIZATION_LOGS_CHARGING_PLANS_INDEX,
        {
          filters: [`match_phrase:(request_id:'${requestId}')`],
          time: "from:now-7d,to:now",
          columns: ["charger", "phase_1", "phase_2", "phase_3", "timestamp"],
        }
      )
    },

    kibanaVisualizationUrl(): string {
      const requestId = this.optimizationRequest?.requestId

      const fromTime = this.optimizationRequest?.requestedAt
      const fromTimeStamp = fromTime ? fromTime.toISOString() : ""

      const toTime = this.optimizationRequest?.requestedAt
      toTime?.setDate(toTime.getDate() + 1)
      const toTimeStamp = toTime ? toTime.toISOString() : ""

      return helpers.kibanaDashboardUrl(
        "701a1340-d69d-11ed-9536-8f1e389eaaca",
        {
          time: `from:'${fromTimeStamp}',to:'${toTimeStamp}'`,
          filters: [`match_phrase:(request_id:'${requestId}')`],
        }
      )
    },
  },

  watch: {
    $route() {
      this.setSelectedDate()
      this.loadRequestLogs()
      this.loadAppUser()
    },
  },

  created() {
    this.setSelectedDate()
    this.loadRequestLogs()
    this.loadAppUser()
  },

  methods: {
    async loadAppUser() {
      const { appUserId } = this.$route.params
      this.selectedUser = null

      if (appUserId) {
        this.loadingAppUser = true
        const result = await datasource.appUsers.getAppUser(appUserId)

        if (result instanceof Error) {
          console.error(result)
          return
        }

        this.selectedUser = result
        this.loadingAppUser = false
      }
    },

    async loadRequestLogs() {
      this.loadingRequestLogs = true
      this.requestChargingPlanLog = null

      if (this.modifiedChargingSite) {
        this.$emit("update:chargingSite", this.modifiedChargingSite)

        const result =
          await datasource.optimization.listRequestLogsForChargingSite(
            this.modifiedChargingSite,
            this.selectedDate
          )
        if (result instanceof Error) {
          console.error(result)
          return
        } else {
          this.optimizationRequestLogs = result

          this.optimizationRequestLogsRequestedAt = result.map((l) => {
            return {
              requestId: l.requestId,
              requestedAt: new Date(l.requestedAt.getTime()),
            }
          })

          if (this.optimizationRequestLogs.length > 0) {
            this.optimizationRequest =
              this.optimizationRequestLogs[
                this.optimizationRequestLogs.length - 1
              ]
            this.loadRequestChargingPlan()
            this.loadRequestAndResponse()
          }
        }
      }

      this.loadingRequestLogs = false
    },

    async loadChargersHistoricStates() {
      if (this.requestChargingPlanLog?.length && this.optimizationRequest) {
        const to = this.requestLogRequestedAt(this.optimizationRequest)
        const from = DateTime.fromJSDate(to).minus({ hours: 24 }).toJSDate()

        this.chargers.forEach((c) => this.loadChargerHistoricState(c, from, to))
      }
    },

    async loadChargerHistoricState(charger: Charger, from: Date, to: Date) {
      const logs = await datasource.charging.fetchChargingSessionLogRange(
        charger,
        from,
        to
      )

      if (logs instanceof Error) {
        console.error(logs)
        return
      }

      const log = _.last(logs)
      if (log?.chargerIdentifier) {
        Vue.set(this.chargersHistoricStates, log.chargerIdentifier, {
          smartChargingEnabled: log.smartChargingEnabled,
          sessionStatus: log.sessionStatus,
          planSource: log.chargingPlan?.source || "",
          chargerConnectorStatus: log.chargerConnectorStatus,
        })
      }
    },

    async loadRequestAndResponse() {
      if (this.optimizationRequest) {
        const result =
          await datasource.optimization.getRequestLogRawRequestAndResponse(
            this.optimizationRequest
          )

        if (result instanceof Error) {
          console.error(result)
          return
        }

        this.requestJson = jsonParseOrRawString(result.requestJson || "")
        this.responseJson = jsonParseOrRawString(result.responseJson || "")
        this.powerPrices = this.requestJson?.data?.power_prices || []
      }
    },

    async loadRequestChargingPlan() {
      if (!this.optimizationRequest) {
        return
      }

      this.loadingRequestChargingPlan = true

      {
        const result = await datasource.optimization.getChargingPlanLog(
          this.optimizationRequest
        )
        if (result instanceof Error) {
          console.error(result)
          return
        } else {
          this.requestChargingPlanLog = result
        }
      }

      {
        this.chargers = []
        const firstDatapoint = this.requestChargingPlanLog[0]
        const chargerIds = firstDatapoint
          ? firstDatapoint.value.map((x) => x.chargerId)
          : []
        const result = await datasource.chargers.listChargers({
          ids: chargerIds,
        })
        if (result instanceof Error) {
          console.error(result)
          return
        } else {
          this.chargers = _.sortBy(result, "displayName")
          this.loadChargersHistoricStates()
        }
      }

      this.loadingRequestChargingPlan = false
    },

    setSelectedDate() {
      let selectedDate

      if (typeof this.$route.query.selectedDate === "string") {
        selectedDate = new Date(this.$route.query.selectedDate)
      }

      if (selectedDate instanceof Date && !isNaN(selectedDate.getTime())) {
        this.selectedDate = selectedDate
      }
    },

    requestLogDisplay(requestLog: OptimizationRequestLog) {
      const timezone = this.optimizationRequest?.requestTimezone
      const requestedAt = this.requestLogRequestedAt(requestLog)

      return [
        formatters.dateTime.dateTime(requestedAt, timezone),
        `(${requestLog.requestTriggerCause})`,
      ].join(" ")
    },

    requestLocalDateTime(timestamp: Date): string {
      return formatters.dateTime.dateTime(
        timestamp,
        this.optimizationRequest?.requestTimezone
      )
    },

    hourNumber(timestamp: Date): string {
      const timezone = this.optimizationRequest?.requestTimezone
      if (!timezone) {
        return ""
      }

      const hourString = timestamp
        .toLocaleString([], { timeZone: timezone })
        .split(" ")[1]
        .split(":")[0]
      const hour = Number(hourString) + 1
      const ret = hour == 0 ? 24 : hour
      return ret.toString()
    },

    powerKw(powerW: number): string {
      if (powerW !== 0) {
        return (powerW / 1000).toFixed(1).toString()
      } else {
        return ""
      }
    },

    powerPriceDisplay(index: number): string {
      if (this.powerPricesHourOffset && this.powerPrices) {
        return index - this.powerPricesHourOffset >= 0
          ? this.powerPrices[index - this.powerPricesHourOffset]
              ?.toFixed(2)
              ?.toString() || ""
          : ""
      } else {
        return ""
      }
    },

    departureType(
      charger: Charger,
      datapoint: { timestamp: Date }
    ): string | null {
      const departureTime = charger.id && this.departureTimes[charger.id]

      const requestTimestamp = this.optimizationRequestLogsRequestedAt.find(
        (l) => l.requestId == this.optimizationRequest?.requestId
      )

      const inPast = requestTimestamp
        ? datapoint.timestamp < requestTimestamp.requestedAt
        : true

      if (
        departureTime &&
        datapoint.timestamp.getDay() == departureTime.day.getDay() &&
        !inPast
      ) {
        if (departureTime.userSetHour == datapoint.timestamp.getHours()) {
          return "userSet"
        } else if (
          departureTime.forecastHour == datapoint.timestamp.getHours()
        ) {
          return "forecast"
        } else {
          return null
        }
      } else {
        return null
      }
    },

    sumDatapoint(datapoint: { value: { powerW: number }[] }): string {
      const sum = datapoint.value
        .map((d) => parseFloat(this.powerKw(d.powerW)) || 0)
        .reduce((x, y) => x + y, 0)

      return sum == 0 ? "" : sum.toFixed(1)
    },

    chargerRowSum(
      datapoints: OptimizationChargingPlanLog | null,
      charger: Charger
    ): string {
      if (datapoints) {
        const sum = datapoints.reduce((acc, datapoint) => {
          let val = 0

          const data = this.datapointForCharger(datapoint.value, charger)

          if (data) {
            val = data.powerW
          }

          return val + acc
        }, 0)

        return this.powerKw(sum)
      }

      return "0"
    },

    datapointForCharger(
      datapoint: { chargerId: string; powerW: number }[],
      charger: Charger
    ) {
      return _.find(datapoint, (d) => d.chargerId == charger.id)
    },

    requestLogRequestedAt(log: OptimizationRequestLog): Date {
      const logData = _.find(
        this.optimizationRequestLogsRequestedAt,
        (d) => log.requestId == d.requestId
      )

      return logData ? logData.requestedAt : new Date()
    },

    sessionStatusDisplay(charger: Charger): string {
      const status =
        this.chargersHistoricStates[
          charger.ocppIdentifier
        ]?.sessionStatus?.toUpperCase()

      if (status && status != "COMPLETED") {
        return status
      } else {
        return ""
      }
    },
  },
})







































































import Vue from "vue"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"

import { stringify as csvStringify } from "csv-stringify"
import FileSaver from "file-saver"

import type {
  ChargersDetailedOverviewEntry,
  ConfigurationEntry,
  ChargingSite,
} from "@/datasource/chargers"

import BooleanIcon from "@/components/BooleanIcon.vue"
import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"

const baseTableHeaders = [
  { text: "Name", value: "displayName" },
  { text: "OCPP ID", value: "ocppIdentifier" },
  { text: "Observation Only?", value: "observationOnly" },
  { text: "Smart Charging?", value: "smartChargingEnabled", align: "center" },
  { text: "Site", value: "chargingSiteName" },
  { text: "0W Stop?", value: "useZeroPowerToStop" },
  { text: "App User", value: "appUserDisplayIdentifier" },
  { text: "Fuse", value: "fuseName" },
  { text: "Fuse Capacity", value: "fuseCapacity" },
  { text: "Suspend Smart Charging", value: "chargerActionRules" },
  { text: "Configuration Profiles", value: "configurationProfiles" },
  { text: "Unknown Vehicle VIN", value: "unknownVehicleVin" },
  {
    text: "Unknown Vehicle Battery Capacity (kWh)",
    value: "unknownVehicleBatteryCapacityKwh",
  },
  {
    text: "Unknown Vehicle ML Battery Capacity (kWh)",
    value: "unknownVehicleMlBatteryCapacityKwh",
  },
  {
    text: "Unknown Vehicle ML Max Charging Power (kW)",
    value: "unknownVehicleMlMaxChargingPowerKw",
  },
  {
    text: "Unknown Vehicle Starting SoC",
    value: "unknownVehicleStartingSoc",
  },
]

type TableHeader = typeof baseTableHeaders[number]

type TableRow = {
  [index: string]: string | string[] | boolean | Record<string, string>
  displayName: string
  ocppIdentifier: string
  observationOnly: boolean
  smartChargingEnabled: boolean
  chargingSiteName: string
  useZeroPowerToStop: boolean
  appUserDisplayIdentifier: string
  fuseName: string
  fuseCapacity: string
  chargerActionRules: string[]
  configurationProfiles: string[]
  unknownVehicleVin: string
  unknownVehicleBatteryCapacityKwh: string
  unknownVehicleMlBatteryCapacityKwh: string
  unknownVehicleMlMaxChargingPowerKw: string
  unknownVehicleStartingSoc: string
  ocppConfiguration: Record<string, string>
}

export default Vue.extend({
  components: {
    BooleanIcon,
    ChargingSiteSelect,
  },

  data() {
    return {
      loading: false,
      selectedChargingSites: [] as ChargingSite[],
      chargers: [] as ChargersDetailedOverviewEntry[],
      ocppConfiguration: {} as Record<string, ConfigurationEntry[]>,
      ocppConfigurationKeys: new Set() as Set<string>,
    }
  },

  computed: {
    tableHeaders(): TableHeader[] {
      return baseTableHeaders.concat(this.ocppTableHeaders)
    },

    ocppTableHeaders(): TableHeader[] {
      return Array.from(this.ocppConfigurationKeys.values()).map(
        (key: string) => ({
          text: `OCPP ${key}`,
          value: `ocppConfiguration.${key}`,
        })
      )
    },

    tableRows(): TableRow[] {
      const mapOcppConfiguration = function (entries: ConfigurationEntry[]) {
        const ret = {} as Record<string, string>
        entries.forEach((entry) => (ret[entry.key] = entry.value || ""))
        return ret
      }

      return this.chargers.map((charger) => ({
        displayName: charger.displayName,
        ocppIdentifier: charger.ocppIdentifier,
        observationOnly: charger.observationOnly,
        smartChargingEnabled: charger.smartChargingEnabled,
        chargingSiteName: charger.chargingSite?.name || "",
        useZeroPowerToStop: charger.managerSettings.useZeroPowerToStop,
        appUserDisplayIdentifier: charger.appUser?.displayIdentifier || "",
        fuseName: this.fuseName(charger),
        fuseCapacity: this.fuseCapacity(charger),
        chargerActionRules: charger.actionRules.map(this.chargerActionRule),
        configurationProfiles: charger.configurationProfiles.map(
          (profile) => profile.name
        ),
        unknownVehicleVin: charger.unknownVehicle?.vin || "",
        unknownVehicleBatteryCapacityKwh: formatters.charging.powerWToKw(
          charger.unknownVehicle?.batterySizeWh
        ),
        unknownVehicleMlBatteryCapacityKwh: formatters.generic.dashIfNull(
          charger.unknownVehicle?.mlData.batteryCapacityKwh
        ),
        unknownVehicleMlMaxChargingPowerKw: formatters.number.fixedFloat(
          charger.unknownVehicle?.mlData.maxChargingPowerKw,
          2
        ),
        unknownVehicleStartingSoc: formatters.generic.dashIfNull(
          charger.unknownVehicle?.startingSoc
        ),
        ocppConfiguration: mapOcppConfiguration(
          this.ocppConfiguration[charger.id || ""] || []
        ),
      }))
    },
  },

  methods: {
    async loadData() {
      this.loading = true
      this.ocppConfigurationKeys = new Set()

      {
        const chargingSiteFilter =
          this.selectedChargingSites.length > 0
            ? this.selectedChargingSites
            : null
        const result =
          await datasource.chargers.listChargerDetailedOverviewEntries(
            chargingSiteFilter
          )
        if (result instanceof Error) {
          console.error(result)
          return
        } else {
          this.chargers = result
        }
      }

      await Promise.all(
        this.chargers.map(async (charger: ChargersDetailedOverviewEntry) => {
          const result = await datasource.chargers.listConfigurationForCharger(
            charger
          )
          if (result instanceof Error) {
            console.error(result)
          } else {
            const entries = result.filter((entry) => !entry.readonly)

            const newOcppConfiguration = { ...this.ocppConfiguration }
            newOcppConfiguration[charger.id || ""] = entries
            this.ocppConfiguration = newOcppConfiguration

            const newOcppConfigurationKeys = new Set(this.ocppConfigurationKeys)
            entries.forEach((entry) => {
              newOcppConfigurationKeys.add(entry.key)
            })
            this.ocppConfigurationKeys = newOcppConfigurationKeys
          }
        })
      )

      this.loading = false
    },

    fuseName(charger: ChargersDetailedOverviewEntry): string {
      const fuse = charger?.mathematicalModel?.fuse
      if (fuse) {
        if (fuse.name.length > 0) {
          return fuse.name
        } else {
          return fuse.id?.toString() || ""
        }
      } else {
        return ""
      }
    },

    fuseCapacity(charger: ChargersDetailedOverviewEntry): string {
      const fuse = charger?.mathematicalModel?.fuse
      if (fuse) {
        return [
          fuse.phase1CapacityAmps,
          fuse.phase2CapacityAmps,
          fuse.phase3CapacityAmps,
        ].join(", ")
      } else {
        return ""
      }
    },

    chargerActionRule: formatters.chargers.chargerActionRule,

    async exportCsv() {
      const headers = this.tableHeaders.map((header) => header.text)
      const mapValue = function (value: TableRow[string]) {
        if (value === true) {
          return "true"
        } else if (value === false) {
          return "false"
        } else if (value instanceof Array) {
          return value.join("\n")
        } else {
          return value
        }
      }
      const ocppTableHeaders = this.ocppTableHeaders
      const data = this.tableRows.map(function (row) {
        const chargerFields = baseTableHeaders.map((header) =>
          mapValue(row[header.value])
        )
        const ocppConfigurationFields = ocppTableHeaders.map((header) =>
          mapValue(row.ocppConfiguration[header.value.slice(18)])
        )
        return [...chargerFields, ...ocppConfigurationFields]
      })
      csvStringify([headers, ...data], function (_error, output) {
        const blob = new Blob([output], { type: "text/csv;charset=utf-8" })
        const fileName = "Charger Details.csv"
        FileSaver.saveAs(blob, fileName)
      })
    },
  },
})

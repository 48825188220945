
















import Vue from "vue"

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      modifiedValue: this.value,
    }
  },

  methods: {
    emit() {
      this.$emit("input", this.modifiedValue)
    },
  },
})



















































































































































































import Vue, { PropType } from "vue"
import _ from "underscore"
import datasource from "@/datasource"
import type {
  Charger,
  ConfigurationEntry,
  ConfigurationProfileSummary,
} from "@/datasource/chargers"
import ChargerSelect from "@/components/ChargerSelect.vue"
import ChargerConfigurationProfileSelect from "@/components/ChargerConfigurationProfileSelect.vue"

import { stringify as csvStringify } from "csv-stringify"
import FileSaver from "file-saver"

type ConfigEntry = {
  name: string
  readonly: boolean
  originalValue: string
  value: string
  componentName?: string | null
  type?: string
  status: null | "loading" | "saved" | "error"
}

type CopyFromState = null | "loading" | "success" | "error"

function initialData() {
  return {
    showDialog: false,
    loading: true,
    loadingFailed: false,
    saving: false,
    savingFailed: false,
    ocppConfigEntries: [] as ConfigEntry[],
    copyFromCharger: null as Charger | null,
    copyFromChargerState: null as CopyFromState,
    copyFromProfile: null as CopyFromState,
    copyFromProfileState: null as CopyFromState,
    copyFromAssociatedProfilesState: null as CopyFromState,
  }
}

export default Vue.extend({
  components: {
    ChargerSelect,
    ChargerConfigurationProfileSelect,
  },

  props: {
    charger: {
      required: true,
      type: Object as PropType<Charger>,
    },
  },

  data() {
    return initialData()
  },

  computed: {
    displayConfigEntries(): ConfigEntry[] {
      return _(this.ocppConfigEntries).filter((x: ConfigEntry) => !x.readonly)
    },

    copyFromChargerError(): string | null {
      return this.copyFromChargerState == "error"
        ? "Failed to copy configuration from charger."
        : null
    },

    copyFromProfileError(): string | null {
      return this.copyFromProfileState == "error"
        ? "Failed to copy configuration from profile."
        : null
    },
  },

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    async loadData() {
      this.loading = true
      this.loadingFailed = false
      this.ocppConfigEntries = []

      {
        const result = await datasource.chargers.listConfigurationForCharger(
          this.charger
        )
        if (result instanceof Error) {
          console.error(result)
          this.loadingFailed = true
        } else {
          this.ocppConfigEntries = _.chain(result)
            .map((x: ConfigurationEntry) => ({
              name: x.key,
              readonly: x.readonly,
              originalValue: x.value || "",
              value: x.value || "",
              componentName: x.componentName,
              type: x.type,
              status: null,
            }))
            .sortBy((x) => x.name.toLowerCase())
            .value()
        }
      }

      this.loading = false
    },

    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true
      this.savingFailed = false

      const saveEntry = this.saveEntry

      const promises = _.chain(this.ocppConfigEntries)
        .filter(this.isEntryModified)
        .map(async function (entry) {
          entry.status = "loading"
          const success = await saveEntry(
            entry.name,
            entry.value,
            entry.componentName || null,
            entry.type || null
          )
          entry.status = success ? "saved" : "error"
          return success
        })
        .value()

      const result = await Promise.all(promises)
      const success = result.findIndex((x) => x == false) == -1

      this.saving = false

      if (success) {
        this.close()
      } else {
        this.savingFailed = true
      }
    },

    async saveEntry(
      key: string,
      value: string | null,
      componentName: string | null,
      type: string | null
    ): Promise<boolean> {
      const result = await datasource.chargers.changeConfigurationForCharger(
        this.charger,
        key,
        value,
        componentName,
        type
      )

      if (result instanceof Error) {
        console.error(result)
        return false
      } else {
        return true
      }
    },

    isEntryModified(entry: ConfigEntry): boolean {
      return entry.originalValue != entry.value
    },

    resetEntry(entry: ConfigEntry) {
      entry.value = entry.originalValue
      entry.status = null
    },

    resetCopyStates() {
      this.copyFromChargerState = null
      this.copyFromProfileState = null
      this.copyFromAssociatedProfilesState = null
    },

    async copyConfigurationFromCharger(charger: Charger) {
      this.resetCopyStates()
      this.copyFromChargerState = "loading"

      const result = await datasource.chargers.listConfigurationForCharger(
        charger
      )

      if (result instanceof Error) {
        console.error(result)
        this.copyFromChargerState = "error"
      } else {
        const newValuesByKey: Record<string, string> = {}
        result.forEach(function (x: ConfigurationEntry) {
          if (!x.readonly) {
            newValuesByKey[x.key] = x.value || ""
          }
        })

        this.setNewValues(newValuesByKey)
        this.copyFromChargerState = "success"
      }
    },

    async copyConfigurationFromProfile(profile: ConfigurationProfileSummary) {
      this.resetCopyStates()
      this.copyFromProfileState = "loading"

      let result

      if (profile.id) {
        result = await datasource.chargers.getConfigurationProfile(profile.id)
      } else {
        result = new Error("Bad profile ID")
      }

      if (result instanceof Error || !result) {
        console.error(result)
        this.copyFromProfileState = "error"
      } else {
        const newValuesByKey: Record<string, string> = {}
        result.ocpp_entries.forEach(function (entry) {
          newValuesByKey[entry.key] = entry.value
        })

        this.setNewValues(newValuesByKey)
        this.copyFromProfileState = "success"
      }
    },

    async copyOcppConfigurationFromAssociatedProfiles() {
      this.resetCopyStates()
      this.copyFromAssociatedProfilesState = "loading"

      let result

      result =
        await datasource.chargers.listConfigurationProfileOcppEntriesForCharger(
          this.charger
        )

      if (result instanceof Error) {
        console.error(result)
        this.copyFromAssociatedProfilesState = "error"
      } else {
        const newValuesByKey: Record<string, string> = {}
        result.forEach(function (entry) {
          newValuesByKey[entry.key] = entry.value
        })

        this.setNewValues(newValuesByKey)
        this.copyFromAssociatedProfilesState = "success"
      }
    },

    setNewValues(values: Record<string, string>) {
      this.ocppConfigEntries.forEach(function (entry) {
        const newValue = values[entry.name]
        if (newValue) {
          entry.value = newValue
          entry.status = null
        }
      })
    },

    async exportCsv() {
      const charger = this.charger
      const headers = ["Key", "Value", "ReadOnly?"]
      const data = this.ocppConfigEntries.map((entry) => [
        entry.name,
        entry.originalValue,
        entry.readonly,
      ])
      csvStringify([headers, ...data], function (_error, output) {
        if (output) {
          const blob = new Blob([output], { type: "text/csv;charset=utf-8" })
          const fileName =
            "OCPP Configuration " +
            `(${charger.ocppIdentifier} - ${charger.displayName}).csv`
          FileSaver.saveAs(blob, fileName)
        }
      })
    },
  },
})

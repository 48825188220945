















import Vue from "vue"
import _ from "underscore"
import type { ChargingSiteSummary } from "@/datasource/chargers"
import type { AppUser } from "@/datasource/appUsers"
import datasource from "@/datasource"

export default Vue.extend({
  props: {
    value: {
      type: [String, Object, Array],
      default: null,
    },

    returnId: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: true,
    },

    filter: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      loading: true,
      chargingSiteNameGroups: [] as { name: string; ids: string[] }[],
      selectedUser: null as AppUser | null,
    }
  },

  computed: {
    modified: {
      get(): { name: string; ids: string[] } {
        return this.value
      },

      set(
        value: ChargingSiteSummary | ChargingSiteSummary[] | string | string[]
      ): void {
        this.$emit("input", value)
      },
    },

    filteredSites(): ChargingSiteSummary[] {
      return this.filter
        ? this.filter(this.chargingSiteNameGroups)
        : this.chargingSiteNameGroups
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    groupChargingSiteIdsByName(sites: ChargingSiteSummary[]) {
      const idsMapping = _.groupBy(sites, "name")

      return Object.entries(idsMapping).map((x) => {
        return { name: x[0], ids: x[1].map((y) => y.id) }
      })
    },

    async loadData() {
      this.loading = true
      const { appUserId } = this.$route.params

      let result

      if (appUserId) {
        const appUserResult = await datasource.appUsers.getAppUser(appUserId)
        if (appUserResult instanceof Error || !appUserResult) {
          console.error(appUserResult)
          return
        }
        this.selectedUser = appUserResult
        result = await datasource.chargers.listChargingSiteSummariesForUser(
          this.selectedUser
        )
      } else {
        result = await datasource.chargers.listChargingSiteSummaries()
      }

      if (result instanceof Error) {
        console.error(result)
        return
      } else {
        this.chargingSiteNameGroups = this.groupChargingSiteIdsByName(result)
        if (!this.clearable) {
          this.modified ||= this.chargingSiteNameGroups[0]
        }
      }
      this.loading = false
    },

    blur() {
      this.$emit("blur")
    },
  },
})















import Vue, { PropType } from "vue"

import type { ChargingPlanStep } from "@/datasource/charging"
import EntityTableCard from "@/components/EntityTableCard.vue"
import DateValue from "@/components/DateValue.vue"
import EnergyValue from "@/components/EnergyValue.vue"
import StringValue from "@/components/StringValue.vue"

export default Vue.extend({
  components: {
    EntityTableCard,
  },

  props: {
    value: {
      type: Array as PropType<ChargingPlanStep[]>,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },

    editable: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      readFunction: () => this.value,
      types: {
        startTime: {
          component: DateValue,
          label: "Start Time",
          props: {
            editable: false,
          },
        },
        chargingState: {
          component: StringValue,
          label: "ChargingState",
          props: {
            editable: false,
          },
        },
        maxChargingPowerPhaseW: {
          component: EnergyValue,
          label: "Max Charging Power (kW)",
          props: {
            editable: false,
          },
        },
        maxChargingPowerPhase1W: {
          component: EnergyValue,
          label: "Max Charging Power Phase 1 (kW)",
          props: {
            editable: false,
          },
        },
        maxChargingPowerPhase2W: {
          component: EnergyValue,
          label: "Max Charging Power Phase 2 (kW)",
          props: {
            editable: false,
          },
        },
        maxChargingPowerPhase3W: {
          component: EnergyValue,
          label: "Max Charging Power Phase 3 (kW)",
          props: {
            editable: false,
          },
        },
      },
    }
  },

  methods: {
    async emit() {
      this.$emit("input", this.value)
    },
  },
})






import Vue, { PropType } from "vue"
import type { Charger } from "@/datasource/chargers"

export default Vue.extend({
  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
  },
})




















import Vue, { PropType } from "vue"
import Big from "big.js"
import datasource from "@/datasource"
import type { Charger, ChargingSiteCluster } from "@/datasource/chargers"
import BooleanIcon from "@/components/BooleanIcon.vue"

export default Vue.extend({
  components: { BooleanIcon },

  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
  },

  data() {
    return {
      chargingSiteClusters: [] as ChargingSiteCluster[],
      loading: false,
    }
  },

  watch: {
    charger() {
      this.loadData()
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      const result =
        await datasource.chargers.listChargingSiteClustersForCharger(
          this.charger
        )

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.chargingSiteClusters = result
      this.loading = false
    },

    powerDisplayKW(cluster: ChargingSiteCluster): string {
      return Big(cluster.instantaneousOutputPowerW).div(1000).toString()
    },

    currentDisplayA(cluster: ChargingSiteCluster): string {
      return cluster.instantaneousOutputCurrentPhasesA
        .map((a) => a.toString())
        .join(",")
    },

    warningThresholdsDisplay(cluster: ChargingSiteCluster): string {
      return [
        cluster.phaseOneCurrentWarningThresholdA,
        cluster.phaseTwoCurrentWarningThresholdA,
        cluster.phaseThreeCurrentWarningThresholdA,
      ]
        .map((t) => (t ? t.toString() : ""))
        .join(",")
    },
  },
})









































import Vue from "vue"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import type { JobStatistics } from "@/datasource/backgroundJobs"

export default Vue.extend({
  data() {
    return {
      loading: true,
      search: "",
      jobStatistics: [] as JobStatistics[],
      headers: [
        { text: "Worker", value: "worker" },
        { text: "State", value: "state" },
        { text: "Dynamic Schedule ID", value: "dynamicJobId" },
        { text: "Count", value: "count", groupable: false },
        { text: "Last Attempt", value: "lastAttemptedAt", groupable: false },
        {
          text: "",
          value: "menu",
          width: 0,
          align: "right",
          sortable: false,
          groupable: false,
        },
      ],
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    ...formatters.dateTime,

    async loadData() {
      this.loading = true

      const result = await datasource.backgroundJobs.jobStatistics()
      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.jobStatistics = result

      this.loading = false
    },
  },
})

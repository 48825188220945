import datasource from "@/datasource"
import { Result } from "@/utils"

import type { ChargingSite } from "@/datasource/chargers"

export class WebUser {
  id?: string
  email: string | null = ""
  roles: Array<string> = []
  permittedChargingSites: Array<ChargingSite> = []
}

export class WebUserInput {
  email?: string
  roles?: Array<string>
  permittedChargingSiteIds?: Array<string>
}

const graphQlWebUserFields = `
  id
  email
  roles
  permittedChargingSites {
    id
    name
  }
`

const webUsers = {
  async getWebUser(id: string): Promise<Result<WebUser | null>> {
    type Response = {
      data?: {
        webUser?: WebUser
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        webUser(id: $id) {
          ${graphQlWebUserFields}
        }
      }
      `,
      { id: id }
    )

    return Result.map(response, (x) => x.data?.webUser || null)
  },

  async listWebUsers(): Promise<Result<Array<WebUser>>> {
    type Response = {
      data?: {
        webUsers?: Array<WebUser>
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query {
        webUsers {
          ${graphQlWebUserFields}
        }
      }
      `
    )

    return Result.map(response, (x) => x.data?.webUsers || [])
  },

  async updateWebUser(
    webUser: WebUser,
    fields: WebUserInput
  ): Promise<Result<WebUser | null>> {
    type Response = {
      data?: {
        webUser?: {
          update?: {
            webUser?: WebUser
          }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!, $fields: WebUserInput!) {
        webUser(id: $id) {
          update(fields: $fields) {
            webUser {
              ${graphQlWebUserFields}
            }
          }
        }
      }
      `,
      { id: webUser.id, fields: fields }
    )

    return Result.map(
      response,
      (x) => x?.data?.webUser?.update?.webUser || null
    )
  },

  async signUpWebUser(fields: WebUserInput): Promise<Result<WebUser | null>> {
    type Response = {
      data?: {
        signUpWebUser?: {
          webUser?: WebUser
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($fields: WebUserInput!) {
        signUpWebUser(fields: $fields) {
          webUser {
            ${graphQlWebUserFields}
          }
        }
      }
      `,
      { fields }
    )

    return Result.map(response, (x) => x?.data?.signUpWebUser?.webUser || null)
  },

  async deleteWebUser(webUser: WebUser): Promise<Result<WebUser | null>> {
    type Response = {
      data?: {
        webUser?: {
          delete?: {
            webUser?: WebUser
          }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        webUser(id: $id) {
          delete {
            webUser {
              ${graphQlWebUserFields}
            }
          }
        }
      }
      `,
      { id: webUser.id }
    )

    return Result.map(
      response,
      (x) => x?.data?.webUser?.delete?.webUser || null
    )
  },

  async getAvailableRoles(): Promise<Result<Array<string>>> {
    type Response = {
      data?: {
        webUserRoles?: Array<string>
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query {
        webUserRoles
      }
      `
    )

    return Result.map(response, (x) => x?.data?.webUserRoles || [])
  },
}

export default webUsers

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VMenu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-1 white--text",attrs:{"icon":"","color":"green"}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-plus")])],1)]}}])},[_c('VList',[_c('VListItem',{on:{"click":function($event){}}},[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-toy-brick-search")])],1),_c('RegisterChargerDialog',{on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g({attrs:{"v-bind":attrs}},on),[_vm._v("Migrate Charger for AppUser")])]}}])})],1),_c('VListItem',{on:{"click":function($event){}}},[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-toy-brick-plus")])],1),_c('NewChargerDialog',{on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g({attrs:{"v-bind":attrs}},on),[_vm._v("Create New Charger")])]}}])})],1),_c('VListItem',{on:{"click":function($event){}}},[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-toy-brick-search")])],1),_c('RegisterChargerAndCreateSiteDialog',{on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g({attrs:{"v-bind":attrs}},on),[_vm._v("Create Charging Site and Register Charger for Circle K")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
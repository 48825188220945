




import Vue from "vue"
import formatters from "@/utils/formatters"

export default Vue.extend({
  props: {
    value: {
      type: [Number, String],
      required: true,
    },

    label: {
      type: String,
      default: null,
    },
  },

  methods: {
    ...formatters.charging,
  },
})

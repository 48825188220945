











































































import Vue from "vue"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import helpers from "@/utils/helpers"
import ChargerLinksMenu from "@/components/ChargerLinksMenu.vue"
import DateTimePicker from "@/components/DateTimePicker.vue"
import DateValue from "@/components/DateValue.vue"
import IsHealthyIcon from "@/components/IsHealthyIcon.vue"
import SmartChargingEnabledIcon from "@/components/SmartChargingEnabledIcon.vue"
import ChargerUsage from "@/components/ChargerUsage.vue"
import RobotIcon from "@/components/icons/robot.vue"
import ChargingStation from "@/components/icons/chargingStation.vue"
import Funnel from "@/components/icons/Funnel.vue"
import Flash from "@/components/icons/Flash.vue"
import Next from "@/components/icons/Next.vue"
import Unplugged from "@/components/icons/Unplugged.vue"
import Sedan from "@/components/icons/Sedan.vue"
import SpeedLimit from "@/components/icons/SpeedLimit.vue"
import type { Charger } from "@/datasource/chargers"
import type { ChargingSessionLogEntry } from "@/datasource/charging"
import type { Money } from "@/datasource/payments"
import { DateTime, Interval } from "luxon"

export default Vue.extend({
  components: {
    ChargerLinksMenu,
    ChargerUsage,
    ChargingStation,
    DateTimePicker,
    DateValue,
    Flash,
    Funnel,
    Next,
    IsHealthyIcon,
    RobotIcon,
    Unplugged,
    Sedan,
    SpeedLimit,
    SmartChargingEnabledIcon,
  },

  data() {
    const day = 1000 * 60 * 60 * 24

    const now = new Date()

    const toDate =
      typeof this.$route.query.to === "string"
        ? new Date(parseInt(this.$route.query.to))
        : now

    const fromDate =
      typeof this.$route.query.from === "string"
        ? new Date(parseInt(this.$route.query.from))
        : new Date(toDate.getTime() - day)

    return {
      loading: true,
      logLoading: true,
      toDate,
      fromDate,
      search: "",
      chargers: [] as Charger[],
      selectedChargers: [] as Charger[],
      rawSelectedChargingSessionLogs: {} as Record<
        string,
        ChargingSessionLogEntry[]
      >,
      rawHeaders: [
        { text: "OCPP Identifier", value: "chargerIdentifier" },
        { text: "Display Name", value: "chargerDisplayName" },
      ],
    }
  },

  computed: {
    interval(): Record<string, DateTime> {
      const end = this.toDate
      const begin = this.fromDate

      const hoursInInterval = helpers.hoursInInterval(
        Interval.fromDateTimes(begin, end)
      )

      return Array.from(hoursInInterval).reduce(
        (a, v) => ({ ...a, [v.toUTC().toISO()]: v }),
        {}
      )
    },

    selectedChargingSessionLogs(): Record<string, unknown>[] {
      return this.selectedChargers.map((charger) => {
        const rawUsage =
          this.rawSelectedChargingSessionLogs[charger.ocppIdentifier] || []

        const usageTimeseries = rawUsage.reduce(
          (
            timeseries: Record<
              string,
              Record<string, boolean | string | number | null | Money>[]
            >,
            datapoint: ChargingSessionLogEntry
          ) => {
            const hour = datapoint.loggedAt.toUTC().startOf("hour").toISO()

            if (this.interval[hour]) {
              let hourlyUsage = timeseries[hour] || []

              if (
                datapoint.plannedChargingPowerW ||
                datapoint.instantaneousChargingPowerW
              ) {
                hourlyUsage.push({
                  planned: datapoint.plannedChargingPowerW,
                  actual: datapoint.instantaneousChargingPowerW,
                  timestamp: datapoint.loggedAt.toLocaleString(
                    DateTime.TIME_24_WITH_SECONDS
                  ),
                  isHealthy: datapoint.isHealthy,
                  smartChargingEnabled: datapoint.smartChargingEnabled,
                  vehicleSoc: datapoint.vehicleSoc,
                  chargingSavings: datapoint.chargingSavings,
                })
              }

              return { ...timeseries, [hour]: hourlyUsage }
            } else {
              return timeseries
            }
          },
          {}
        )

        return {
          chargerIdentifier: charger.ocppIdentifier,
          chargerDisplayName: charger.displayName,
          chargerManagementModule: charger.managementModule,
          chargerId: charger.id,
          usageTimeseries: usageTimeseries,
        }
      })
    },
  },

  watch: {
    selectedChargers(newSelected, oldSelected) {
      const [actuallyNew] = newSelected.filter(
        (x: Charger) => !oldSelected.includes(x)
      )
      const [actuallyOld] = oldSelected.filter(
        (x: Charger) => !newSelected.includes(x)
      )

      if (actuallyOld) {
        Vue.delete(
          this.rawSelectedChargingSessionLogs,
          actuallyOld.ocppIdentifier
        )
      }

      if (actuallyNew) {
        this.loadLogEntries(actuallyNew)
      }
    },
    fromDate() {
      this.selectedChargers.forEach((charger) => this.loadLogEntries(charger))
    },
    toDate() {
      this.selectedChargers.forEach((charger) => this.loadLogEntries(charger))
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    ...formatters.amount,
    ...formatters.charging,
    ...formatters.dateTime,
    ...formatters.generic,
    ...formatters.number,

    async loadData() {
      let chargerIds: string[] = []

      if (this.$route.params.charger) {
        chargerIds = [this.$route.params.charger]
      } else if (this.$route.params.chargers) {
        chargerIds = this.$route.params.chargers.split(",")
      }

      const appUserId = this.$route.query.appUser as string | null

      this.loading = true
      this.logLoading = true

      const result = await datasource.chargers.listChargers()
      if (result instanceof Error) {
        console.error(result)
        return
      }
      this.chargers = result

      if (chargerIds.length) {
        const result = await datasource.chargers.listChargers({
          ids: chargerIds,
        })
        if (result instanceof Error) {
          console.error(result)
        } else {
          this.selectedChargers = result
        }
      }

      if (appUserId) {
        const appUser = await datasource.appUsers.getAppUser(appUserId)
        if (appUser instanceof Error || !appUser) {
          console.error(appUser)
          return
        }
        const result = await datasource.chargers.listChargersForUser(appUser)
        if (result instanceof Error) {
          console.error(result)
        } else {
          this.chargers = result
          this.selectedChargers = result
        }
      }

      this.loading = false
      this.logLoading = false
    },

    async loadLogEntries(charger: Charger) {
      this.logLoading = true

      const result = await datasource.charging.fetchChargingSessionLogRange(
        charger,
        this.fromDate,
        this.toDate
      )

      if (result instanceof Error) {
        console.error(result)
        return
      }

      Vue.set(
        this.rawSelectedChargingSessionLogs,
        charger.ocppIdentifier,
        result
      )

      this.logLoading = false
    },

    findCharger(chargerId: string) {
      return this.chargers.find((charger) => charger.id == chargerId)
    },

    filterCharger(item: Charger, queryText: string) {
      return (
        item.ocppIdentifier
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.displayName
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },

    chargerDisplay(charger: Charger): string {
      return `${charger.ocppIdentifier} - ${charger.displayName}`
    },

    title(hour: DateTime): string {
      return `Period starting at ${hour.toLocaleString(
        DateTime.DATETIME_SHORT
      )}`
    },

    hourlyUsage(usageTimeseries: Record<string, unknown>, hour: DateTime) {
      return usageTimeseries[hour.toUTC().toISO()] || []
    },

    removeCharger(item: Charger) {
      const index = this.selectedChargers.indexOf(item)

      if (index >= 0) {
        this.selectedChargers.splice(index, 1)
      }
    },
  },
})

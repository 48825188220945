


































































































































































































































































































import Vue from "vue"
import Big from "big.js"
import _ from "underscore"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import type { ChargingSiteSummary, RfidToken } from "@/datasource/chargers"
import type {
  FinishedChargingSession,
  FinishedChargingSessionPage,
} from "@/datasource/charging"
import type { AppUserSummary } from "@/datasource/appUsers"
import AppUserSelect from "@/components/AppUserSelect.vue"
import Checkbox from "@/components/Checkbox.vue"
import ChargingSessionLinkMenu from "@/components/ChargingSessionLinkMenu.vue"
import ChargingSiteNameSelect from "@/components/ChargingSiteNameSelect.vue"
import CollapsibleDataTable from "@/components/CollapsibleDataTable.vue"
import RfidTokenSelect from "@/components/RfidTokenSelect.vue"
import BooleanIcon from "@/components/BooleanIcon.vue"
import DateTimePicker from "@/components/DateTimePicker.vue"
import DateValue from "@/components/DateValue.vue"
import SimulatedSavingsDialog from "@/components/SimulatedSavingsDialog.vue"
import PostFactSavingsButton from "@/components/PostFactSavingsButton.vue"

import { stringify as csvStringify } from "csv-stringify"
import FileSaver from "file-saver"

function sortBy(
  field: string
): (a: Record<string, string>, b: Record<string, string>) => number {
  return function (a: Record<string, string>, b: Record<string, string>) {
    const aField = (a && a[field]) || ""
    const bField = (b && b[field]) || ""
    if (aField < bField) {
      return -1
    } else if (aField > bField) {
      return 1
    }
    return 0
  }
}

function commaSeparatedOrNullToList(val: string | null): string[] {
  return val == null ? [] : val.split(",")
}

export default Vue.extend({
  components: {
    AppUserSelect,
    Checkbox,
    ChargingSessionLinkMenu,
    ChargingSiteNameSelect,
    CollapsibleDataTable,
    RfidTokenSelect,
    BooleanIcon,
    DateTimePicker,
    DateValue,
    SimulatedSavingsDialog,
    PostFactSavingsButton,
  },

  data() {
    const toQuery = this.$route.query.to as string
    const toDate = toQuery == null ? new Date() : new Date(parseInt(toQuery))

    const fromQuery = this.$route.query.from as string

    let yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    const fromDate =
      fromQuery == null ? yesterday : new Date(parseInt(fromQuery))

    const siteIdsQuery = this.$route.query.siteIds as string
    const siteIdsFilter = siteIdsQuery == null ? [] : siteIdsQuery.split(",")

    return {
      loading: true,
      toDate,
      fromDate,
      ocppIds: commaSeparatedOrNullToList(
        this.$route.query.ocppIds as string | null
      ) as string[],
      onlyKnownChargers: false,
      smartChargingEnabled: null,
      smartChargingIndeterminate: true,
      minimumEnergyTransferred: null,
      maximumEnergyTransferred: null,
      minimumDurationHr: null,
      maximumDurationHr: null,
      vehicleDisplayName: null,
      chargingSessions: [] as FinishedChargingSession[],
      selected: [] as FinishedChargingSession[],
      chargingSessionIdsFilter: [] as string[],
      chargingSites: [] as ChargingSiteSummary[],
      siteIdsFilter,
      chargingSiteIdGroups: [] as string[][],
      loadingChargingSites: true,
      limit: 50,
      rfidTokens: [] as RfidToken[],
      rfidTokenIds: [] as string[],
      appUserSummaries: [] as AppUserSummary[],
      headers: [
        { text: "ID", width: 0, value: "id" },
        { text: "Charging Site", width: 0, value: "charger.chargingSite.name" },
        {
          text: "Charger",
          value: "charger.displayName",
          width: 0,
          sort: sortBy("displayName"),
        },
        { text: "OCPP ID", width: 0, value: "charger.ocppIdentifier" },
        { text: "Car", value: "car", width: 0, sort: sortBy("displayName") },
        { text: "Duration", width: 0, value: "connectedFor" },
        { text: "Started At", width: 0, value: "startedAt" },
        { text: "Finished At", width: 0, value: "finishedAt" },
        {
          text: "Energy Transferred (kWh)",
          value: "energyUsageWh",
          align: "right",
          width: 0,
          class: "header-no-wrap",
        },
        {
          text: "Cleaned Energy Transferred (kWh)",
          value: "cleanedEnergyUsageWh",
          align: "right",
          width: 0,
          class: "header-no-wrap",
        },
        {
          text: "Max Power Output (kw)",
          value: "maximumObservedPowerOutputW",
          align: "right",
          width: 0,
          class: "header-no-wrap",
        },
        {
          text: "Output Phases at Max Power (A)",
          value: "currentOutputPhasesAAtMaximumPowerOutputW",
          align: "right",
          width: 0,
          class: "header-no-wrap",
        },
        {
          text: "Internal SoC %",
          width: 0,
          value: "vehicleSoc",
          align: "right",
        },
        {
          text: "OCPP SoC %",
          width: 0,
          value: "ocppSoc",
          align: "right",
        },
        {
          text: "Current Offered (A)",
          width: 0,
          value: "currentOfferedA",
          align: "right",
        },
        {
          text: "Session Status",
          width: 0,
          value: "sessionStatus",
          align: "center",
        },
        {
          text: "OCPP Transactions",
          value: "ocppTransactionIds",
          width: 0,
          align: "right",
        },
        {
          text: "Smart Charging Enabled",
          width: 0,
          value: "smartChargingEnabled",
          align: "center",
        },
        {
          text: "Healthy?",
          width: 0,
          value: "isHealthy",
          align: "center",
        },
        {
          text: "Enforcement Warnings",
          width: 0,
          value: "chargingPlanEnforcementFailureCount",
          align: "right",
        },
        { text: "Connector", width: 0, value: "chargerConnectorId" },
        { text: "Authorized", width: 0, value: "authorized", align: "center" },
        { text: "Authorized At", width: 0, value: "authorizedAt" },
        {
          text: "Authorization Source",
          width: 0,
          value: "authorizationSource",
        },
        {
          text: "OCPP VID",
          value: "ocppVehicleId",
        },
        {
          text: "Energy Cost (NOK)",
          width: 0,
          value: "energyCost.amount",
        },
        {
          text: "Variable Grid Cost (NOK)",
          width: 0,
          value: "gridCost.amount",
        },
        {
          text: "Charging Savings (Legacy) (NOK)",
          width: 0,
          value: "chargingPlan.chargingSavings.amount",
        },
        {
          text: "Estimated Initial Instant Charging Cost",
          width: 0,
          value: "instantChargingCost",
        },
        {
          text: "Estimated Initial Smart Charging Cost",
          width: 0,
          value: "smartChargingCost",
        },
        {
          text: "Estimated Charging Cost Savings",
          width: 0,
          value: "estimatedChargingSavings",
        },
        {
          text: "Post Fact Savings",
          width: 0,
          value: "postFactSavingsAmount",
        },
        {
          text: "Simulated Savings",
          width: 0,
          value: "estimatedSavings",
        },
        {
          text: "Route Departure time",
          value: "routeDepartureTime",
          width: 0,
        },
        {
          text: "Route ID",
          value: "routeId",
          width: 0,
        },
        {
          text: "Driver ID",
          value: "routeDriverId",
          width: 0,
        },
        {
          text: "App User",
          class: "header-no-wrap",
          value: "appUserLink",
          width: 0,
          align: "center",
          sortable: false,
        },
        {
          text: "Usage",
          value: "usageLink",
          width: 0,
          align: "center",
          sortable: false,
        },
        {
          text: "",
          name: "Links",
          value: "externalLinks",
          width: 0,
          align: "right",
          sortable: false,
        },
        {
          text: "",
          value: "spacing",
        },
      ] as { text: string; value: string }[],
      chargingSessionManagementStrategies: [] as string[],
    }
  },

  computed: {
    filteredChargingSessions(): FinishedChargingSession[] {
      let ret = this.chargingSessions
      if (this.onlyKnownChargers) {
        ret = ret.filter((session) => session.charger !== null)
      }
      return ret
    },
  },

  watch: {
    $route() {
      this.loadData()
    },

    smartChargingEnabled() {
      this.smartChargingIndeterminate = false
    },

    chargingSiteIdGroups(newValue) {
      this.siteIdsFilter = _.flatten(newValue)
      const query = this.$route.query

      this.$router.push({
        name: "HistoricChargingSessions",
        query: _.omit(query, ["siteIds"]),
      })
    },

    async appUserSummaries(appUserSummaries: AppUserSummary[]) {
      const appUserIds = appUserSummaries
        .filter((a) => a.id)
        .map((a) => a.id) as string[]

      const appUsersResult = await datasource.appUsers.listAppUsersByIds(
        appUserIds
      )

      if (appUsersResult instanceof Error) {
        console.error(appUsersResult)
        return
      }

      const appUserTokenIds = appUsersResult
        .map((a) => a.rfidTokens.map((r) => r.id))
        .flat()

      this.rfidTokenIds = _.uniq(this.rfidTokenIds.concat(appUserTokenIds))
    },
  },

  created() {
    this.loadData()
    this.loadRfidTokens()
  },

  methods: {
    ...formatters.charging,
    ...formatters.dateTime,
    ...formatters.generic,
    ...formatters.amount,

    async loadData() {
      this.loading = true
      this.chargingSessions = []

      let page: FinishedChargingSessionPage
      let hasNextPage = true
      let cursor = null

      while (hasNextPage) {
        const ocppIds = this.ocppIds?.length ? this.ocppIds : null

        const vehicleDisplayName =
          this.vehicleDisplayName == "" ? null : this.vehicleDisplayName

        const minimumDurationS =
          this.minimumDurationHr == null
            ? null
            : Math.round(parseFloat(this.minimumDurationHr) * (60 * 60))

        const maximumDurationS =
          this.maximumDurationHr == null
            ? null
            : Math.round(parseFloat(this.maximumDurationHr) * (60 * 60))

        const chargingSessionIds = this.chargingSessionIdsFilter?.length
          ? this.chargingSessionIdsFilter
          : null

        const chargingSiteIds = this.siteIdsFilter?.length
          ? this.siteIdsFilter
          : null

        const minimumEnergyTransferred =
          this.minimumEnergyTransferred == null
            ? null
            : parseFloat(this.minimumEnergyTransferred) * 1000

        const maximumEnergyTransferred =
          this.maximumEnergyTransferred == null
            ? null
            : parseFloat(this.maximumEnergyTransferred) * 1000

        const rfidTokenIds = this.rfidTokenIds?.length
          ? this.rfidTokenIds
          : null

        const result = await datasource.charging.listFinishedChargingSessions(
          cursor,
          this.limit,
          {
            fromDate: this.fromDate,
            toDate: this.toDate,
            ocppIds: ocppIds,
            chargingSessionIds: chargingSessionIds,
            chargingSiteIds: chargingSiteIds,
            rfidTokenIds: rfidTokenIds,
            smartChargingEnabled: this.smartChargingEnabled,
            minimumEnergyTransferred: minimumEnergyTransferred,
            maximumEnergyTransferred: maximumEnergyTransferred,
            minimumDuration: minimumDurationS,
            maximumDuration: maximumDurationS,
            vehicleDisplayName: vehicleDisplayName,
          }
        )

        if (result instanceof Error) {
          return
        }

        page = result
        this.chargingSessions = this.chargingSessions.concat(page.items)
        hasNextPage = page.pageInfo.hasNextPage
        cursor = page.pageInfo.endCursor
      }

      this.loading = false
    },

    async loadRfidTokens() {
      const result = await datasource.chargers.listRfidTokens()

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.rfidTokens = result
    },

    applyOcppFilterValue(value: string) {
      this.ocppIds = _.uniq([...this.ocppIds, value])
    },

    resetSmartChargingEnabled() {
      this.smartChargingIndeterminate = true
      this.smartChargingEnabled = null
    },

    displayRfidToken(session: FinishedChargingSession): string {
      return (
        session.rfidToken?.secret || session.rfidToken?.externalReference || ""
      )
    },

    instantChargingSessionPricing(session: FinishedChargingSession): string {
      const instantPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "instant"
      )
      return instantPricing?.cost?.amount || ""
    },

    smartChargingSessionPricing(session: FinishedChargingSession): string {
      const smartPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "smart"
      )
      return smartPricing?.cost?.amount || ""
    },

    estimatedChargingSavings(session: FinishedChargingSession): string {
      const instantPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "instant"
      )

      const smartPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "smart"
      )

      const savings =
        instantPricing && smartPricing
          ? Big(instantPricing.cost.amount)
              .minus(Big(smartPricing.cost.amount))
              .toString()
          : ""

      return savings ? `${savings}` : ""
    },

    usageTimeStamp(timestamp: string | null): string {
      if (timestamp) {
        return new Date(timestamp).getTime().toString()
      } else {
        return ""
      }
    },

    generateReport() {
      const sessions: FinishedChargingSession[] = this.selected
      const extraneousHeaders = [
        "usageLink",
        "appUserLink",
        "externalLinks",
        "spacing",
        "connectedFor",
      ]

      const csvColumns = this.headers.filter(
        (h) => !extraneousHeaders.includes(h.value)
      )

      const csvColumnValues: {
        [index: string]: (
          s: FinishedChargingSession
        ) => string | boolean | number | null | undefined
      } = {
        car: (session) => session.car?.displayName,
        smartChargingEnabled: (session) => !!session.smartChargingEnabled,
        isHealthy: (session) => !!session.isHealthy,
        instantChargingCost: (session) =>
          this.instantChargingSessionPricing(session)
            ? this.localisedAmount(this.instantChargingSessionPricing(session))
            : null,
        smartChargingCost: (session) =>
          this.smartChargingSessionPricing(session)
            ? this.localisedAmount(this.smartChargingSessionPricing(session))
            : null,
        estimatedChargingSavings: (session) =>
          this.estimatedChargingSavings(session)
            ? this.localisedAmount(this.estimatedChargingSavings(session))
            : null,
        energyUsageWh: (session) =>
          session.energyUsageWh
            ? this.localisedAmount(
                this.powerWToKw(parseFloat(session.energyUsageWh))
              )
            : null,
        cleanedEnergyUsageWh: (session) =>
          session.cleanedEnergyUsageWh
            ? this.localisedAmount(
                this.powerWToKw(parseFloat(session.cleanedEnergyUsageWh))
              )
            : null,
        authorizationSource: (session) =>
          `${session.authorizationSource} ${this.displayRfidToken(session)} `,
        maximumObservedPowerOutputW: (session) =>
          this.powerWToKw(session.maximumObservedPowerOutputW, 2),
        "energyCost.amount": (session) =>
          session.energyCost
            ? this.localisedAmount(session.energyCost.amount)
            : null,
        "gridCost.amount": (session) =>
          session.gridCost
            ? this.localisedAmount(session.gridCost.amount)
            : null,
      }

      const csvData = sessions.map((session) =>
        csvColumns.map((h) => {
          const propKey = h.value.split(".")
          const valueFunc = csvColumnValues[h.value]

          return valueFunc ? valueFunc(session) : _.get(session, propKey)
        })
      )

      csvStringify(
        [csvColumns.map((h) => h.text), ...csvData],
        { delimiter: ";" },
        function (_error, output) {
          if (output) {
            const blob = new Blob([output], { type: "text/csv;charset=utf-8" })
            const fileName = `${formatters.dateTime.dateTime(
              new Date()
            )} - Finished Charging Session Report.csv`
            FileSaver.saveAs(blob, fileName)
          }
        }
      )
    },
  },
})

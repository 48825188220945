












































import Vue from "vue"
import NewChargerDialog from "@/views/Chargers/NewChargerDialog.vue"
import RegisterChargerDialog from "@/views/Chargers/RegisterChargerDialog.vue"
import RegisterChargerAndCreateSiteDialog from "@/views/Chargers/RegisterChargerAndCreateSiteDialog.vue"

export default Vue.extend({
  components: {
    NewChargerDialog,
    RegisterChargerDialog,
    RegisterChargerAndCreateSiteDialog,
  },

  methods: {
    loadData() {
      this.$emit("saved")
    },
  },
})

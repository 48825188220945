






























import Vue from "vue"
import DateTimePicker from "@/components/DateTimePicker.vue"

export default Vue.extend({
  components: { DateTimePicker },

  props: {
    value: {
      required: false,
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isOpen: false,
      input: this.value as string | null,
      edited: false,
    }
  },

  watch: {
    value(newVal: string) {
      if (!this.edited) {
        this.input = newVal
      }
    },
  },

  methods: {
    save() {
      this.$emit("saved", this.input)
      this.edited = false
    },

    clear() {
      this.$emit("clear")
    },

    markChanged() {
      this.edited = true
    },
  },
})
































































































































































































































































import Vue from "vue"
import _ from "underscore"
import datasource, { ValidationFailureError } from "@/datasource"
import ChargerSelect from "@/components/ChargerSelect.vue"
import type {
  Charger,
  ChargerManagerSettings,
  ConfigurationEntry,
  ConfigurationProfileEntry,
} from "@/datasource/chargers"
import type { ValidationError } from "@/datasource"

type ConfigEntry = {
  included: boolean
  name: string
  originalValue: string | null
  value: string
  componentName: string | null
  type: string | null
}

function initialData() {
  return {
    showDialog: false,
    loading: true,
    saving: false,
    savingFailed: false,
    validationErrors: {} as ValidationError,
    name: "My Profile",
    flexConfigEntries: [] as ConfigEntry[],
    ocppConfigEntries: [] as ConfigEntry[],
    newEntryKey: "",
    newEntryComponentName: null as string | null,
    newEntryType: null as string | null,
    newEntryValue: "",
    copyFromCharger: null as Charger | null,
    copyFromChargerLoading: false,
    copyFromChargerSuccess: false,
    copyFromChargerError: null as string | null,
    commandsApis: [] as string[],
    observationsApis: [] as string[],
    defaultFlexConfigEntries: [
      {
        name: "smartChargingEnabled",
        originalValue: false,
        value: "False",
        included: false,
      },
      {
        name: "useZeroPowerToStop",
        originalValue: false,
        value: "False",
        included: false,
      },
      {
        name: "observationOnly",
        originalValue: false,
        value: "False",
        included: false,
      },
      {
        name: "commandsApi",
        originalValue: "OCPP",
        value: "OCPP",
        included: false,
      },
      {
        name: "observationsApi",
        originalValue: "OCPP",
        value: "OCPP",
        included: false,
      },
    ] as ConfigEntry[],
  }
}

export default Vue.extend({
  components: {
    ChargerSelect,
  },

  props: {
    profileId: {
      type: String,
      default: null,
    },
  },

  data() {
    return initialData()
  },

  computed: {
    flexConfigEntriesByName(): Record<string, ConfigEntry> {
      return _(this.flexConfigEntries).indexBy((x: ConfigEntry) => x.name)
    },

    ocppConfigEntriesByName(): Record<string, ConfigEntry> {
      return _(this.ocppConfigEntries).indexBy((x: ConfigEntry) => x.name)
    },

    sortedOcppConfigEntries(): ConfigEntry[] {
      return _(this.ocppConfigEntries).sortBy((x: ConfigEntry) =>
        x.name.toLowerCase()
      )
    },
  },

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    async loadData() {
      this.loading = true
      this.copyFromChargerSuccess = false
      this.savingFailed = false
      this.ocppConfigEntries = []
      this.flexConfigEntries = this.defaultFlexConfigEntries.map(
        (o: ConfigEntry) => ({ ...o })
      )

      if (this.profileId) {
        const result = await datasource.chargers.getConfigurationProfile(
          this.profileId
        )
        if (result instanceof Error || !result) {
          console.error(result)
          return
        }
        this.name = result.name
        this.flexConfigEntries = this.defaultFlexConfigEntries.map(
          (entry: ConfigEntry) => {
            let stored = result.flex_entries?.find(
              (o: ConfigurationProfileEntry) => o.key === entry.name
            )
            return {
              included: !!stored,
              name: entry.name,
              originalValue: entry.value,
              value: (stored && stored.value) || entry.value,
              componentName: entry.componentName,
              type: entry.type,
            }
          }
        )
        this.ocppConfigEntries = result.ocpp_entries.map(
          (entry: ConfigurationProfileEntry) => ({
            included: true,
            name: entry.key,
            originalValue: entry.value,
            value: entry.value,
            type: entry.type,
            componentName: entry.componentName,
          })
        )
      }

      {
        const result = await datasource.schema.listEnumOptions("CommandsApi")
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.commandsApis = result
      }

      {
        const result = await datasource.schema.listEnumOptions(
          "ObservationsApi"
        )
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.observationsApis = result
      }

      this.loading = false
    },

    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true
      this.savingFailed = false
      this.validationErrors = {}

      let success = false

      const flex_entries = this.flexConfigEntries
        .filter((x: ConfigEntry) => x.included)
        .map((x: ConfigEntry) => ({
          key: x.name,
          value: x.value,
          componentName: x.componentName && null,
          type: x.type && null,
        }))
      const ocpp_entries = this.ocppConfigEntries
        .filter((x: ConfigEntry) => x.included)
        .map((x: ConfigEntry) => ({
          key: x.name,
          value: x.value,
          componentName: x.componentName,
          type: x.type,
        }))
      const modifiedProfile = {
        id: this.profileId,
        name: this.name,
        flex_entries,
        ocpp_entries,
      }

      let result
      if (modifiedProfile.id) {
        result = await datasource.chargers.updateConfigurationProfile(
          modifiedProfile
        )
      } else {
        result = await datasource.chargers.createConfigurationProfile(
          modifiedProfile
        )
      }

      if (result instanceof Error) {
        console.error(result)
        if (result instanceof ValidationFailureError) {
          this.validationErrors = result.errors
        }
      } else {
        success = true
      }

      this.saving = false

      if (success) {
        this.$emit("saved")
        this.close()
      } else {
        this.savingFailed = true
      }
    },

    isEntryModified(entry: ConfigEntry): boolean {
      return entry.included && entry.originalValue != entry.value
    },

    resetEntry(entry: ConfigEntry) {
      if (entry.originalValue) {
        entry.value = entry.originalValue
      } else {
        entry.value = ""
        entry.included = false
      }
    },

    addFlexEntry(name: string) {
      if (!this.flexConfigEntriesByName[name]) {
        this.flexConfigEntries.push({
          included: true,
          name: name,
          originalValue: null,
          value: name,
          componentName: null,
          type: null,
        })
      }
    },

    addOcppEntry() {
      if (this.newEntryKey.trim().length > 0) {
        this.ocppConfigEntries.push({
          included: true,
          name: this.newEntryKey,
          originalValue: null,
          value: this.newEntryValue,
          componentName: this.newEntryComponentName,
          type: this.newEntryType,
        })
        this.newEntryKey = ""
        this.newEntryValue = ""
        this.newEntryComponentName = null
        this.newEntryType = null
        const nextFocusElement = this.$refs
          .newEntryKeyTextField as HTMLElement | null
        nextFocusElement?.focus()
      }
    },

    booleanToCamelCase(value: boolean): string {
      return value.toString()[0].toUpperCase() + value.toString().substring(1)
    },

    async copyConfigurationFromCharger(charger: Charger) {
      this.copyFromChargerSuccess = false
      this.copyFromChargerError = null
      this.copyFromChargerLoading = true

      const result = await datasource.chargers.listConfigurationForCharger(
        charger
      )

      if (result instanceof Error) {
        console.error(result)
        this.copyFromChargerError = "Failed to get configuration from charger."
      } else {
        this.flexConfigEntries = this.defaultFlexConfigEntries.map(
          (entry: ConfigEntry) => {
            let chargerSetting = charger[entry.name as keyof Charger]
            let chargerManagerSetting =
              charger.managerSettings[
                entry.name as keyof ChargerManagerSettings
              ]
            let value =
              // "string" values for the select boxes
              (typeof chargerManagerSetting === "string"
                ? chargerManagerSetting
                : null) ||
              // boolean values must be converted to strings
              (typeof chargerSetting === "boolean"
                ? this.booleanToCamelCase(chargerSetting)
                : null) ||
              // or use the original value
              entry.value

            return {
              included: true,
              name: entry.name,
              originalValue: entry.value,
              value: value,
              componentName: entry.componentName,
              type: entry.type,
            }
          }
        )

        const newOcppValuesByKey: Record<string, string> = {}
        result.forEach(function (x: ConfigurationEntry) {
          if (!x.readonly) {
            newOcppValuesByKey[x.key] = x.value || ""
          }
        })

        for (const key in newOcppValuesByKey) {
          const entry = this.ocppConfigEntriesByName[key]
          if (entry) {
            entry.value = newOcppValuesByKey[key]
          } else {
            this.ocppConfigEntries.push({
              included: !this.profileId,
              name: key,
              originalValue: null,
              value: newOcppValuesByKey[key],
              componentName: null,
              type: null,
            })
          }
        }

        this.copyFromChargerSuccess = true
      }

      this.copyFromChargerLoading = false
    },
  },
})






import Vue from "vue"
import formatters from "@/utils/formatters"

export default Vue.extend({
  props: {
    value: {
      type: [Date, String],
      default: null,
    },

    label: {
      type: String,
      default: null,
    },
  },

  methods: {
    ...formatters.dateTime,
  },
})






































import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type { AppUser } from "@/datasource/appUsers"

function initialData() {
  return {
    showDialog: false,
    loading: false,
    sendingPasswordResetEmail: false,
    sentPasswordResetEmail: false,
    passwordResetSuccess: true,
  }
}

export default Vue.extend({
  props: {
    appUser: {
      type: Object as PropType<AppUser>,
      required: true,
    },
  },

  data: initialData,

  watch: {
    showDialog(value) {
      if (!value) {
        this.close()
      }
    },
  },

  methods: {
    close() {
      Object.assign(this.$data, initialData())
    },

    async sendPasswordResetEmail() {
      this.sentPasswordResetEmail = false
      this.sendingPasswordResetEmail = true
      const result = await datasource.appUsers.sendPasswordResetEmail(
        this.appUser
      )
      if (result instanceof Error) {
        console.error(result)
        this.passwordResetSuccess = false
      } else {
        this.passwordResetSuccess = result
      }
      this.sendingPasswordResetEmail = false
      this.sentPasswordResetEmail = true
    },
  },
})





















































































































































































































































































































































































import Vue from "vue"
import _ from "underscore"
import helpers from "@/utils/helpers"
import datasource from "@/datasource"
import VJsonEditor from "v-jsoneditor"
import type { Charger, ChargingSite } from "@/datasource/chargers"
import type { AppUser } from "@/datasource/appUsers"
import { SelfBuildingSquareSpinner } from "epic-spinners"

import BooleanIcon from "@/components/BooleanIcon.vue"
import CollapsibleDataTable from "@/components/CollapsibleDataTable.vue"
import EditChargerDialog from "@/components/EditChargerDialog.vue"
import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"
import ChargerCreateMenu from "@/components/ChargerCreateMenu.vue"
import NewChargerDialog from "./Chargers/NewChargerDialog.vue"
import FdtPredictionModelSelect from "@/components/FdtPredictionModelSelect.vue"
import MachineLearningLog from "@/components/MachineLearningLog.vue"
import TimeForecast from "@/components/TimeForecast.vue"
import ChargerLink from "@/components/ChargerLink.vue"
import ChargerActionsMenu from "@/components/ChargerActionsMenu.vue"
import ChargerLinksMenu from "@/components/ChargerLinksMenu.vue"
import StringValue from "@/components/StringValue.vue"

function sortBy(
  field: string
): (a: Record<string, string>, b: Record<string, string>) => number {
  return function (a: Record<string, string>, b: Record<string, string>) {
    const aField = (a && a[field]) || ""
    const bField = (b && b[field]) || ""
    if (aField < bField) {
      return -1
    } else if (aField > bField) {
      return 1
    }
    return 0
  }
}

const rawHeaders = [
  { text: "Name", value: "displayName", width: 0 },
  { text: "OCPP ID", value: "ocppIdentifier", width: 0 },
  { text: "Status", value: "status", width: 0 },
  {
    text: "Observation Only?",
    value: "observationOnly",
    align: "center",
    width: 0,
  },
  {
    text: "Smart Charging Enabled?",
    value: "smartChargingEnabled",
    align: "center",
    width: 0,
  },
  {
    text: "Smart Charging Supported?",
    value: "smartChargingSupported",
    align: "center",
    width: 0,
  },
  {
    text: "V2G?",
    value: "vehicleToGridChargingSupported",
    width: 0,
  },
  {
    text: "Trigger meter values?",
    value: "pollMeterValues",
    align: "center",
    width: 0,
  },
  {
    text: "Periodic Reboot?",
    value: "periodicallyReboot",
    align: "center",
    width: 0,
  },
  {
    text: "Meter Values Increasing?",
    value: "isMeterValuesIncreasing",
    align: "center",
    width: 0,
  },
  {
    text: "Optimizer Pinning?",
    value: "optimizerPinningEnabled",
    align: "center",
    width: 0,
  },
  { text: "Site", value: "chargingSite", sort: sortBy("name"), width: 0 },
  {
    text: "Owner",
    value: "appUser",
    isAppUserHeader: true,
    sort: sortBy("displayIdentifier"),
    width: 0,
  },
  { text: "Connectors", value: "connectors", align: "center", width: 0 },
  {
    text: "Charging Sessions",
    value: "chargingSessions",
    align: "center",
    width: 0,
  },
  {
    text: "Last Configuration Profile Enforcement",
    width: 0,
    value: "configurationProfilesLastEnforcedAt",
  },
  {
    text: "Historic Sessions",
    width: 0,
    value: "historicChargingSessions",
    align: "center",
  },
  {
    text: "Geo Location (Lat/Long)",
    width: 0,
    value: "location",
    align: "right",
  },
  {
    text: "Management Module",
    width: 0,
    value: "managementModule",
  },
  {
    text: "Commands API",
    width: 0,
    value: "managerSettings.commandsApi",
  },
  {
    text: "Observations API",
    width: 0,
    value: "managerSettings.observationsApi",
  },
  {
    text: "0W Stop?",
    width: 0,
    value: "managerSettings.useZeroPowerToStop",
  },
  {
    text: "Brand",
    width: 0,
    value: "brand",
  },
  {
    text: "Model",
    width: 0,
    value: "model",
  },
  {
    text: "OCPP Version",
    width: 0,
    value: "ocppVersion",
    align: "center",
  },
  {
    text: "Sale price (NOK/kWh), ex VAT",
    width: 0,
    value: "salePricePerKwh",
  },
  {
    text: "",
    name: "Edit",
    value: "actions",
    width: 0,
    align: "right",
    sortable: false,
  },
  {
    text: "",
    name: "Links",
    value: "links-menu",
    width: 0,
    align: "right",
    sortable: false,
  },
  {
    text: "",
    name: "Actions",
    value: "actions-menu",
    width: 0,
    align: "right",
    sortable: false,
  },
  { text: "", value: "spacing" },
]

export default Vue.extend({
  components: {
    SelfBuildingSquareSpinner,
    BooleanIcon,
    CollapsibleDataTable,
    ChargingSiteSelect,
    ChargerCreateMenu,
    NewChargerDialog,
    EditChargerDialog,
    FdtPredictionModelSelect,
    MachineLearningLog,
    TimeForecast,
    ChargerLink,
    ChargerActionsMenu,
    ChargerLinksMenu,
    StringValue,
    VJsonEditor,
  },

  data() {
    return {
      tab: null,
      loading: true,
      selectedUser: null as AppUser | null,
      selectedChargingSite: null as ChargingSite | null,
      chargers: [] as Charger[] | [],
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      selected: [] as Charger[],
      synchronizingChargers: false,
      multiSetChargingSite: null,
      multiSetChargerBrand: null,
      multiSetChargerModel: null,
      bulkSettingOptimizerPin: false,
      bulkSettingChargingSite: false,
      bulkSettingChargerBrands: false,
      bulkSettingChargerModels: false,
      bulkSettingPollMeterValues: false,
      bulkSettingPeriodicRebooting: false,
      bulkSettingSmartCharge: false,
      bulkSettingSmartChargeSupported: false,
      bulkSettingMeterValuesIncreasing: false,
      bulkSetPollMeterValuesState: null,
      bulkSetPeriodicallyRebootState: null,
      bulkSetSmartChargeValue: null,
      bulkSetSmartChargeSupportedValue: null,
      bulkSetMeterValuesIncreasingValue: true,
      bulkOptimizerPinningState: false,
      showBulkOptimizerPinDialog: false,
      showBulkSmartChargeDialog: false,
      showBulkSmartChargeSupportedDialog: false,
      showBulkChargingSiteDialog: false,
      showBulkBrandDialog: false,
      showBulkModelDialog: false,
      showBulkTriggerMeterValuesDialog: false,
      showBulkPeriodicRebootDialog: false,
      showBulkIncreasingDialog: false,
    }
  },

  computed: {
    headers(): Record<string, unknown>[] {
      return rawHeaders.filter(
        (header) => !(this.selectedUser && header.isAppUserHeader)
      )
    },

    selectedAmqpDashboardUrl(): string {
      const ocppIds = _.map(this.selected, (charger) => charger.ocppIdentifier)
      return helpers.bulkAmqpDashboardUrl(ocppIds)
    },
  },

  watch: {
    $route() {
      this.loadData()
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true
      const { appUserId, chargerId } = this.$route.params

      if (appUserId) {
        const appUserResult = await datasource.appUsers.getAppUser(appUserId)
        if (appUserResult instanceof Error || !appUserResult) {
          console.error(appUserResult)
          return
        }
        this.selectedUser = appUserResult

        const chargersResult = await datasource.chargers.listChargersForUser(
          this.selectedUser
        )
        if (chargersResult instanceof Error) {
          console.error(chargersResult)
          return
        }
        this.chargers = chargersResult
        this.loading = false
        return
      }

      if (chargerId) {
        const chargersResult = await datasource.chargers.getCharger(chargerId)
        if (chargersResult instanceof Error) {
          console.error(chargersResult)
          return
        }
        if (chargersResult) {
          this.chargers = [chargersResult]
        } else {
          this.chargers = []
        }

        this.loading = false
        return
      }

      this.selectedUser = null

      let result
      if (this.selectedChargingSite) {
        result = await datasource.chargers.listChargersForChargingSite(
          this.selectedChargingSite
        )
      } else {
        result = await datasource.chargers.listChargers()
      }
      if (result instanceof Error) {
        console.error(result)
        return
      }
      this.chargers = result
      this.loading = false
    },

    async showSnackbar(text: string, color?: string) {
      this.snackbarText = text
      this.snackbarColor = color || "success"
      this.snackbar = true
    },

    async enforceSelectedConfigurationProfiles() {
      this.synchronizingChargers = true

      await this.selected.forEach((c) =>
        datasource.chargers.synchronizeCharger(c)
      )

      this.synchronizingChargers = false
    },

    async bulkSetChargerBrand() {
      this.bulkSettingChargerBrands = true

      Promise.all(
        this.selected.map((c) =>
          datasource.chargers.updateCharger({
            ...c,
            brand: this.multiSetChargerBrand,
          })
        )
      ).then(() => {
        this.selected = []
        this.multiSetChargerBrand = null
        this.bulkSettingChargerBrands = false
        this.showBulkBrandDialog = false
        this.loadData()
      })
    },

    async bulkSetChargerModel() {
      this.bulkSettingChargerModels = true

      await Promise.all(
        this.selected.map((c) => {
          datasource.chargers.updateCharger({
            ...c,
            model: this.multiSetChargerModel,
          })
        })
      ).then(() => {
        this.selected = []
        this.multiSetChargerModel = null
        this.bulkSettingChargerModels = false
        this.showBulkModelDialog = false
        this.loadData()
      })
    },

    async bulkSetPollMeterValues() {
      await Promise.all(
        this.selected.map((c) => {
          datasource.chargers.updateCharger({
            ...c,
            pollMeterValues: !!this.bulkSetPollMeterValuesState,
          })
        })
      ).then(() => {
        this.selected = []
        this.bulkSetPollMeterValuesState = null
        this.bulkSettingPollMeterValues = false
        this.showBulkTriggerMeterValuesDialog = false
        this.loadData()
      })
    },

    async bulkSetPeriodicallyReboot() {
      await Promise.all(
        this.selected.map((c) => {
          datasource.chargers.updateCharger({
            ...c,
            periodicallyReboot: !!this.bulkSetPeriodicallyRebootState,
          })
        })
      ).then(() => {
        this.selected = []
        this.bulkSetPeriodicallyRebootState = null
        this.bulkSettingPeriodicRebooting = false
        this.showBulkPeriodicRebootDialog = false
        this.loadData()
      })
    },

    async bulkSetChargingSite() {
      await Promise.all(
        this.selected.map((c) => {
          datasource.chargers
            .updateCharger({
              ...c,
              chargingSite: this.multiSetChargingSite,
            })
            .then(() => {
              this.selected = []
              this.multiSetChargingSite = null
              this.showBulkChargingSiteDialog = false
              this.bulkSettingChargingSite = false
              this.loadData()
            })
        })
      )
    },

    async updateCharger(charger: Charger) {
      const result = await datasource.chargers.updateCharger(charger)

      this.snackbar = true
      this.loadData()

      if (result instanceof Error) {
        console.error(result)
        this.snackbarText = "Error updating charger"
        this.snackbarColor = "error"
        return
      }

      this.snackbarText = "Charger updated"
      this.snackbarColor = "success"
    },

    async bulkSetSmartCharge() {
      this.bulkSettingSmartCharge = true

      await Promise.all(
        this.selected.map((c) => {
          datasource.chargers.updateCharger({
            ...c,
            smartChargingEnabled: !!this.bulkSetSmartChargeValue,
          })
        })
      ).then(() => {
        this.selected = []
        this.bulkSetSmartChargeValue = null
        this.bulkSettingSmartCharge = false
        this.showBulkSmartChargeDialog = false
      })

      this.loadData()
    },

    async bulkSetSmartChargeSupported() {
      this.bulkSettingSmartChargeSupported = true

      await Promise.all(
        this.selected.map((c) => {
          datasource.chargers.updateCharger({
            ...c,
            smartChargingSupported: !!this.bulkSetSmartChargeSupportedValue,
          })
        })
      ).then(() => {
        this.selected = []
        this.bulkSetSmartChargeSupportedValue = null
        this.bulkSettingSmartChargeSupported = false
        this.showBulkSmartChargeSupportedDialog = false
      })

      this.loadData()
    },

    async bulkSetMeterValuesIncreasing() {
      this.bulkSettingMeterValuesIncreasing = true

      await Promise.allSettled(
        this.selected.map((c) => {
          return datasource.chargers.updateCharger({
            ...c,
            isMeterValuesIncreasing: !!this.bulkSetMeterValuesIncreasingValue,
          })
        })
      ).then(() => {
        this.selected = []
        this.bulkSettingMeterValuesIncreasing = false
        this.bulkSetMeterValuesIncreasingValue = true
        this.showBulkIncreasingDialog = false
        this.loadData()
      })
    },

    async bulkSetOptimizerPinning() {
      this.bulkSettingOptimizerPin = true

      await Promise.allSettled(
        this.selected.map((c) => {
          return datasource.chargers.updateCharger({
            ...c,
            optimizerPinningEnabled: !!this.bulkOptimizerPinningState,
          })
        })
      ).then(() => {
        this.selected = []
        this.bulkSettingOptimizerPin = false
        this.bulkOptimizerPinningState = false
        this.showBulkOptimizerPinDialog = false
        this.loadData()
      })
    },

    configurationProfilesTimestampDisplay(charger: Charger): string {
      return charger.configurationProfilesLastEnforcedAt || ""
    },

    configurationProfilesTimeDiffDisplay(charger: Charger): string {
      if (charger.configurationProfilesLastEnforcedAt) {
        const ts = new Date(charger.configurationProfilesLastEnforcedAt)
        const now = Date.now()
        const deltaMs = now - ts.getTime()
        const elapsedHours = Math.floor(deltaMs / 3600000)
        const remainderMinutes = Math.floor((deltaMs % 3600000) / 60000)

        return `${elapsedHours} hours, ${remainderMinutes} minutes ago`
      } else {
        return ""
      }
    },

    configurationProfileResultJson(
      charger: Charger
    ): string | Record<string, unknown> {
      return charger.configurationProfilesEnforcementLastResult
        ? JSON.parse(charger.configurationProfilesEnforcementLastResult)
        : ""
    },

    historicSessionLinkFromTimestamp(): string {
      let ts = new Date()
      ts.setDate(ts.getDate() - 14)

      return ts.getTime().toString()
    },

    positionDisplay(charger: Charger): string {
      if (charger.positionLatitude && charger.positionLongitude) {
        return `${charger.positionLatitude}, ${charger.positionLongitude}`
      } else {
        return ""
      }
    },
  },
})

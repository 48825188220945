

















import Vue from "vue"
import datasource from "@/datasource"

import EntityTableCard from "@/components/EntityTableCard.vue"
import StringValue from "@/components/StringValue.vue"
import ChargingPlanTemplateStepsValue from "@/components/ChargingPlanTemplateStepsValue.vue"
import Link from "@/components/Link.vue"

export default Vue.extend({
  components: {
    EntityTableCard,
  },

  data() {
    return {
      readFunction: datasource.charging.listChargingPlanTemplates,
      createFunction: datasource.charging.createChargingPlanTemplate,
      updateFunction: datasource.charging.updateChargingPlanTemplate,
      deleteFunction: datasource.charging.deleteChargingPlanTemplate,
      controls: [
        {
          component: Link,
          props: {
            value: true,
            name: "ChargingPlanTemplateRuns",
            params: {},
            label: "Runs",
          },
        },
      ],
      types: {
        id: {
          component: StringValue,
          label: "ID",
          props: {
            editable: false,
          },
        },
        name: {
          component: StringValue,
          label: "Name",
          props: {
            editable: true,
          },
        },
        steps: {
          component: ChargingPlanTemplateStepsValue,
          label: "Steps",
          props: {
            editable: true,
          },
          opts: {
            align: "center",
            sortable: false,
          },
        },
      },
    }
  },
})

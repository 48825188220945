





















import Vue, { PropType } from "vue"
import datasource from "@/datasource"

import type { WebUser } from "@/datasource/webUsers"

export default Vue.extend({
  props: {
    webUser: {
      type: Object as PropType<WebUser>,
      required: true,
    },

    availableRoles: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },

  data() {
    return {
      saving: false,
      modifiedRoles: [...this.webUser.roles],
    }
  },

  methods: {
    isEntryModified(): boolean {
      return (
        this.webUser.roles.concat().sort().toString() !=
        this.modifiedRoles.concat().sort().toString()
      )
    },

    hintText(): string {
      return this.isEntryModified() ? "NOT SAVED" : ""
    },

    async save() {
      this.saving = true

      const result = await datasource.webUsers.updateWebUser(this.webUser, {
        roles: this.modifiedRoles.map((r) => r.toLowerCase()),
      })

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.saving = false
      this.$emit("saved")
    },
  },
})

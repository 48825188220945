var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"overflow-auto",attrs:{"fluid":_vm.fluid}},[_c('VExpansionPanels',{staticClass:"py-4"},[_c('VExpansionPanel',{attrs:{"flat":""}},[_c('VExpansionPanelHeader',[_vm._v("Filters")]),_c('VExpansionPanelContent',[_c('VContainer',{staticClass:"flex-row flex-wrap",attrs:{"fluid":""}},[_vm._l((_vm.usageRows),function(row,index){return [_c('VCheckbox',{staticClass:"px-2",attrs:{"dense":"","label":row.name},model:{value:(_vm.usageRows[index].display),callback:function ($$v) {_vm.$set(_vm.usageRows[index], "display", $$v)},expression:"usageRows[index].display"}})]})],2)],1)],1)],1),_c('VSimpleTable',{staticClass:"zebra-columns"},[_c('thead',[_c('tr',[(_vm.displayChargerInfo)?_c('th',{staticClass:"sticky-column"},[_vm._v("Charger - Connector")]):_vm._e(),_vm._l((_vm.interval),function(hour){return _c('th',{staticClass:"text-center"},[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(hour.toFormat('H')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.title(hour)))])])],1)})],2)]),_c('tbody',_vm._l((_vm.selectedChargingSessionLogs),function(logs){return _c('tr',[(_vm.displayChargerInfo)?_c('td',{staticClass:"sticky-column"},[_c('ChargerLinksMenu',{attrs:{"charger":_vm.findCharger(logs.chargerId)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"small":"","outlined":""}},'VBtn',attrs,false),on),[_vm._v(_vm._s(logs.chargerIdentifier))]),_vm._v(" "+_vm._s(logs.chargerDisplayName)+" "+_vm._s(logs.chargerConnectorId ? ("- " + (logs.chargerConnectorId)) : ''))]}}],null,true)})],1):_vm._e(),_vm._l((_vm.interval),function(hour){return _c('td',[_c('table',{staticClass:"usage-table"},[_c('thead',[(_vm.displayedRows.actual)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('th',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.powerWToKw(datapoint.actual)))])]}}],null,true)},[_c('span',[_vm._v("Actual usage at "+_vm._s(datapoint.timestamp))])])],1)}),0):_vm._e()]),_c('tbody',[(_vm.displayedRows.energyUsageKwh)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('span',[_vm._v(_vm._s(datapoint.energyUsageKwh || '—'))])])]}}],null,true)},[_c('span',[_vm._v("Energy Usage (kwh)")])])],1)}),0):_vm._e(),(_vm.displayedRows.chargerConnectorStatus)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[(datapoint.chargerConnectorStatus == 'Charging')?[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('Flash')],1)]}}],null,true)},[_c('span',[_vm._v("Charging")])])]:(datapoint.chargerConnectorStatus == 'Connected')?[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('ChargingStation')],1)]}}],null,true)},[_c('span',[_vm._v("Connected")])])]:[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('Unplugged')],1)]}}],null,true)},[_c('span',[_vm._v("Not Connected")])])]],2)}),0):_vm._e(),(_vm.displayedRows.currentOfferedA)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(datapoint.currentOfferedA))])]}}],null,true)},[_c('span',[_vm._v("Current Offered (A)")])])],1)}),0):_vm._e(),(_vm.displayedRows.vehicleDisplayName)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('span',[_vm._v(_vm._s(datapoint.vehicleDisplayName.slice(0, 5) || '—'))])])]}}],null,true)},[_c('span',[_vm._v("Vehicle Name")])])],1)}),0):_vm._e(),(_vm.displayedRows.chargingPlanSource)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[(datapoint.chargingPlanSource == 'emergency_power_limiting')?[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('SpeedLimit')],1)]}}],null,true)},[_c('span',[_vm._v("Plan source: Emergency power limiting")])])]:(datapoint.chargingPlanSource == 'manual_start_stop')?[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('Next')],1)]}}],null,true)},[_c('span',[_vm._v("Plan source: Manual Start/Stop")])])]:(datapoint.chargingPlanSource == 'optimizer')?[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('Integration')],1)]}}],null,true)},[_c('span',[_vm._v("Plan Source: Optimizer")])])]:(datapoint.chargingPlanSource == 'optimize_without_smart_charging')?[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('Funnel')],1)]}}],null,true)},[_c('span',[_vm._v("Plan source: Optimize without smart charging")])])]:_vm._e()],2)}),0):_vm._e(),(_vm.displayedRows.elapsedTimeH)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(datapoint.elapsedTimeH))])]}}],null,true)},[_c('span',[_vm._v("Elapsed Time (h)")])])],1)}),0):_vm._e(),(_vm.displayedRows.gridCost)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(datapoint.gridCost || '—'))])]}}],null,true)},[_c('span',[_vm._v("Grid Cost (NOK)")])])],1)}),0):_vm._e(),(_vm.displayedRows.energyCost)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(datapoint.energyCost || '—'))])]}}],null,true)},[_c('span',[_vm._v("Energy Cost (NOK)")])])],1)}),0):_vm._e(),(_vm.displayedRows.routeDepartureTime)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(datapoint.routeDepartureTime || '—'))])]}}],null,true)},[_c('span',[_vm._v("Route Departure Time")])])],1)}),0):_vm._e(),(_vm.displayedRows.planned)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.powerWToKw(datapoint.planned)))])]}}],null,true)},[_c('span',[_vm._v("Planned usage at "+_vm._s(datapoint.timestamp))])])],1)}),0):_vm._e(),(_vm.displayedRows.vehicleSoc)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.dashIfNull(datapoint.vehicleSoc) || '—'))])]}}],null,true)},[_c('span',[_vm._v("Internal State of Charge percentage at "+_vm._s(datapoint.timestamp))])])],1)}),0):_vm._e(),(_vm.displayedRows.ocppSoc)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.dashIfNull(datapoint.ocppSoc)))])]}}],null,true)},[_c('span',[_vm._v("OCPP State of Charge percentage at "+_vm._s(datapoint.timestamp))])])],1)}),0):_vm._e(),(_vm.displayedRows.chargingSavings)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.moneyValue(datapoint.chargingSavings)))])]}}],null,true)},[_c('span',[_vm._v("Charge Savings at "+_vm._s(datapoint.timestamp))])])],1)}),0):_vm._e(),(_vm.displayedRows.authorisationSource)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[(datapoint.authorisationSource == 'AUTH_NOT_REQUIRED')?[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VIcon',_vm._g({},on),[_vm._v("mdi-lock-open-variant-outline")])]}}],null,true)},[_c('span',[_vm._v("Authorisation Source: N/A")])])]:(datapoint.authorisationSource == 'RFID_TAG')?[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VIcon',_vm._g({},on),[_vm._v("mdi-tag")])]}}],null,true)},[_c('span',[_vm._v("Authorisation Source: RFID")])])]:(datapoint.authorisationSource == 'MANUAL_START_STOP')?[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VIcon',_vm._g({},on),[_vm._v("mdi-cellphone-play")])]}}],null,true)},[_c('span',[_vm._v("Authorisation Source: Manual start")])])]:[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',[_vm._v("—")])]}}],null,true)},[_c('span',[_vm._v("Not Authorised")])])]],2)}),0):_vm._e(),(_vm.displayedRows.carSmartChargingEnabled)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[(datapoint.carSmartChargingEnabled)?[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('CarSmartChargingIcon')],1)]}}],null,true)},[_c('span',[_vm._v("Car Smart Charging Enabled")])])]:[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v("—")])]}}],null,true)},[_c('span',[_vm._v("Car Smart Charging Disabled")])])]],2)}),0):_vm._e(),(_vm.displayedRows.chargerSmartChargingEnabled)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[(datapoint.chargerSmartChargingEnabled)?[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('ChargerSmartChargingIcon')],1)]}}],null,true)},[_c('span',[_vm._v("Charger Smart Charging Enabled")])])]:[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_vm._v("—")])]}}],null,true)},[_c('span',[_vm._v("Charger Smart Charging Disabled")])])]],2)}),0):_vm._e(),(_vm.displayedRows.smartChargingEnabled)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('SmartChargingEnabledIcon',{attrs:{"value":datapoint.smartChargingEnabled}})],1)]}}],null,true)},[_c('span',[_vm._v("Smart Charging "+_vm._s(datapoint.smartChargingEnabled ? 'enabled' : 'disabled')+" at "+_vm._s(datapoint.timestamp))])])],1)}),0):_vm._e(),(_vm.displayedRows.isHealthy)?_c('tr',_vm._l((_vm.hourlyUsage(logs.usageTimeseries, hour)),function(datapoint){return _c('td',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({},on),[_c('IsHealthyIcon',{attrs:{"value":datapoint.isHealthy}})],1)]}}],null,true)},[_c('span',[_vm._v("Session is "+_vm._s(datapoint.isHealthy ? 'healthy' : 'unhealthy')+" at "+_vm._s(datapoint.timestamp))])])],1)}),0):_vm._e()])])])})],2)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }


































import Vue from "vue"
import datasource from "@/datasource"

import type { PropType } from "vue"
import { ChargingSite } from "@/datasource/chargers"
import ChargingTimeline from "./ChargingTimeline.vue"

export default Vue.extend({
  components: {
    ChargingTimeline,
  },

  props: {
    chargingSite: {
      type: Object as PropType<ChargingSite>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      fromDate: new Date(),
      toDate: new Date(),
      stepCount: 0,
      stepSizeH: 1,
      pinningTemplate: [] as string[],
      pinningTemplateValueOptions: [] as string[],
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      const model = await datasource.chargers.getChargingSiteMathematicalModel(
        this.chargingSite
      )
      if (model instanceof Error) {
        console.error(model)
        return
      }

      this.stepSizeH = model.optimizationStepSizeH
      this.stepCount = model.optimizationStepCount
      this.pinningTemplate = this.cleanPinningTemplate(
        model.optimizationPinningTemplate,
        this.stepCount
      )

      // The timeline rounds down the fromDate and is inclusive of toDate, but
      // we need the exact number of entries from stepCount in order to match
      // the pinning template.
      this.fromDate = new Date()
      const hourMs = 1000 * 60 * 60
      const stepSizeMs = model.optimizationStepSizeH * hourMs
      const isOnTheStep = this.fromDate.getTime() % stepSizeMs == 0
      const dateStepCountOffset = isOnTheStep ? 0 : -1
      const dateOffsetMs =
        (model.optimizationStepCount + dateStepCountOffset) * stepSizeMs
      this.toDate = new Date(this.fromDate.getTime() + dateOffsetMs)

      this.pinningTemplateValueOptions =
        await datasource.chargers.listPinningTemplateValueOptions()

      this.loading = false
    },

    setPinningTemplate(index: number, value: string) {
      this.pinningTemplate[index] = value
      const result = datasource.chargers.setPinningTemplateForChargingSite(
        this.chargingSite,
        this.pinningTemplate
      )
      if (result instanceof Error) {
        console.error(result)
      }
    },

    pinningTemplateValue(rawValue: string): string {
      switch (rawValue) {
        case "ALL":
          return "A"
        case "NONE":
          return "—"
      }
      return rawValue
    },

    cleanPinningTemplate(
      pinningTemplate: string[],
      stepCount: number
    ): string[] {
      const requiredLength = stepCount
      const cleanPinningTemplate = pinningTemplate.slice(0, requiredLength)
      const currentLength = cleanPinningTemplate.length
      if (currentLength < requiredLength) {
        cleanPinningTemplate[requiredLength - 1] = ""
        cleanPinningTemplate.fill("NONE", currentLength, requiredLength)
      }
      return cleanPinningTemplate
    },
  },
})

import datasource from "@/datasource"
import { Result } from "@/utils"

const externalData = {
  async listPowerMarkets(): Promise<Result<string[]>> {
    type Response = {
      data?: {
        powerMarkets?: string[]
      }
    }
    const response: Result<Response> = await datasource.graphql(`
      query {
        powerMarkets
      }
    `)
    return Result.map(response, (x) => x.data?.powerMarkets || [])
  },
}

export default externalData

import datasource from "@/datasource"
import { Result } from "@/utils"

export type DataOwner = {
  id: string
  name: string
}

const authorization = {
  async listDataOwners(): Promise<Result<DataOwner[]>> {
    type Response = {
      data?: {
        dataOwners?: DataOwner[]
      }
    }
    const response: Result<Response> = await datasource.graphql_flexibility(`
      query {
        dataOwners {
          id
          name
        }
      }
    `)
    return Result.map(response, (x) => x.data?.dataOwners || [])
  },

  async setDataOwner(dataOwner: DataOwner): Promise<Result<boolean>> {
    type Response = {
      data?: {
        currentUser?: {
          setDataOwner?: boolean
        }
      }
    }
    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($id: ID!) {
        currentUser {
          setDataOwner(id: $id)
        }
      }
    `,
      {
        id: dataOwner.id,
      }
    )
    return Result.map(
      response,
      (x) => x.data?.currentUser?.setDataOwner || false
    )
  },
}

export default authorization

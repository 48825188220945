














































































































































import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import { ValidationFailureError } from "@/datasource"
import type { ChargingSite } from "@/datasource/chargers"
import type { InvoiceSettings } from "@/datasource/payments"
import {
  InvoiceType,
  InvoiceSchedule,
  InvoiceDataSource,
} from "@/datasource/payments"

function initialData() {
  return {
    showDialog: false,
    loading: true,
    saving: false,
    showDatePicker: false,
    invoiceTypes: [] as string[],
    invoiceSchedules: [] as string[],
    invoiceDataSources: [] as string[],
    invoiceSettings: {} as InvoiceSettings,
    previousSettings: null as InvoiceSettings | null,
    modifiedInvoiceSettings: {} as InvoiceSettings,
    invoiceSettingsErrors: {},
  }
}

export default Vue.extend({
  components: {},

  props: {
    chargingSite: {
      type: Object as PropType<ChargingSite>,
      required: true,
    },
  },

  data: initialData,

  computed: {
    minDate() {
      if (this.previousSettings && this.previousSettings.activeFrom) {
        return formatters.dateTime.isoDate(this.previousSettings.activeFrom)
      } else {
        return "2000-01-01"
      }
    },

    feeLabel() {
      if (this.modifiedInvoiceSettings.type === InvoiceType.Fixed) {
        return "Fixed Fee (NOK/kWh)"
      } else {
        return "Add-on Fee (NOK/kWh)"
      }
    },

    activeFrom: {
      get() {
        return formatters.dateTime.isoDate(
          this.modifiedInvoiceSettings.activeFrom
        )
      },

      set(newDate: string) {
        this.modifiedInvoiceSettings.activeFrom = new Date(newDate)
      },
    },

    fixedFlexibilityFee: {
      get() {
        return formatters.amount.moneyValue(
          this.modifiedInvoiceSettings.fixedFlexibilityFee
        )
      },

      set(newFee: string) {
        this.modifiedInvoiceSettings.fixedFlexibilityFee.amount = newFee
      },
    },

    fixedProcessingFee: {
      get() {
        return formatters.amount.moneyValue(
          this.modifiedInvoiceSettings.fixedProcessingFee
        )
      },

      set(newFee: string) {
        this.modifiedInvoiceSettings.fixedProcessingFee.amount = newFee
      },
    },

    chargingSiteFeeKwh: {
      get() {
        return formatters.amount.moneyValue(
          this.modifiedInvoiceSettings.chargingSiteFeeKwh
        )
      },

      set(newFee: string) {
        this.modifiedInvoiceSettings.chargingSiteFeeKwh.amount = newFee
      },
    },

    gridFee: {
      get() {
        return formatters.amount.moneyValue(
          this.modifiedInvoiceSettings.gridFee
        )
      },

      set(newFee: string) {
        this.modifiedInvoiceSettings.gridFee.amount = newFee
      },
    },

    excessEnergyFeeKwh: {
      get() {
        return formatters.amount.moneyValue(
          this.modifiedInvoiceSettings.excessEnergyFeeKwh
        )
      },

      set(newFee: string) {
        this.modifiedInvoiceSettings.excessEnergyFeeKwh.amount = newFee
      },
    },
  },

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    ...formatters.amount,

    async loadData() {
      this.loading = true

      this.invoiceTypes = Object.values(InvoiceType)

      this.invoiceDataSources = Object.values(InvoiceDataSource)

      // We don't want to be able to create settings for one-off invoices
      this.invoiceSchedules = Object.values(InvoiceSchedule).filter(
        (s) => s !== InvoiceSchedule.OneOff
      )

      const result =
        await datasource.payments.listInvoiceSettingsForChargingSite(
          this.chargingSite
        )

      if (result instanceof Error) {
        console.error(result)
        return
      }

      const currentSettings = result[result.length - 1]
      this.previousSettings = result[result.length - 2]

      const zeroFee = {
        amount: "0",
        currency: "NOK",
        display: "NOK 0",
      }

      // If we don't have a result, create one with some defaults
      this.modifiedInvoiceSettings =
        currentSettings ||
        ({
          chargingSite: {
            id: this.chargingSite.id,
            name: this.chargingSite.name,
          },
          chargingSiteFeeKwh: { ...zeroFee },
          fixedFlexibilityFee: {
            amount: "32.8",
            currency: "NOK",
            display: "NOK 32.8",
          },
          percentageFlexibilityFee: 0,
          fixedProcessingFee: {
            amount: "0",
            currency: "NOK",
            display: "NOK 0",
          },
          percentageProcessingFee: 1.5,
          gridFee: { ...zeroFee },
          excessEnergyFeeKwh: { ...zeroFee },
          type: InvoiceType.Fixed,
          schedule: InvoiceSchedule.Monthly,
          dayOfSchedule: 1,
          gracePeriodDays: 14,
          taxPercentage: 25,
          activeFrom: new Date(),
          useRebate: false,
          dataSource: InvoiceDataSource.CleanMeterReadings,
          taxSystem: null,
        } as InvoiceSettings)

      this.loading = false
    },

    async save(createNew: boolean) {
      this.saving = true

      const result = await datasource.payments.setInvoiceSettings(
        this.modifiedInvoiceSettings,
        createNew
      )

      if (result instanceof ValidationFailureError) {
        this.invoiceSettingsErrors = result.errors
      } else if (result instanceof Error) {
        console.error(result)
      } else {
        this.$emit("saved")
        this.close()
      }

      this.saving = false
    },

    editing(): boolean {
      return !!this.modifiedInvoiceSettings.id
    },

    close() {
      Object.assign(this.$data, initialData())
    },
  },
})
























import Vue, { PropType } from "vue"
import type { Charger } from "@/datasource/chargers"

import ChargerEdit from "@/components/ChargerEdit.vue"
import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"
import ConfirmedButton from "@/components/ConfirmedButton.vue"
import ChargerActionsMenu from "@/components/ChargerActionsMenu.vue"
import ChargerLinksMenu from "@/components/ChargerLinksMenu.vue"

import ActionRuleInput from "./EditChargerDialog/ActionRuleInput.vue"
import ChargerFields from "./EditChargerDialog/ChargerFields.vue"

export default Vue.extend({
  components: {
    ActionRuleInput,
    ChargerEdit,
    ChargerFields,
    ChargingSiteSelect,
    ConfirmedButton,
    ChargerActionsMenu,
    ChargerLinksMenu,
  },

  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
  },

  data() {
    return { showDialog: false }
  },

  methods: {
    save() {
      console.log("wtf mate")
      this.showDialog = false
      this.$emit("saved")
    },
  },
})

import _ from "underscore"
import datasource from "@/datasource"
import { Result } from "@/utils"

export type EmailTemplate = {
  id: string
  subject: string
  content: string
  type: EmailTemplateType
}

export type EmailTemplateInput = {
  id?: string
  subject: string
  content: string
  type: EmailTemplateType
}

export enum EmailTemplateType {
  Invoice = "INVOICE",
}

const graphQlEmailTemplateFields = `
  id
  subject
  content
  type
`

const emails = {
  async listEmailTemplates(): Promise<Result<Array<EmailTemplate>>> {
    type Response = {
      data?: {
        emailTemplates?: Array<EmailTemplate>
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      query {
        emailTemplates {
          ${graphQlEmailTemplateFields}
        }
      }
      `
    )

    return Result.map(response, (r) => {
      const emailTemplates = r.data?.emailTemplates

      return emailTemplates || []
    })
  },

  async createEmailTemplate(
    emailTemplateInput: EmailTemplateInput
  ): Promise<Result<void>> {
    type Response = {
      data?: {
        createEmailTemplate?: EmailTemplate
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($fields: EmailTemplateInput!) {
        createEmailTemplate(fields: $fields) {
          id
        }
      }
      `,
      {
        fields: emailTemplateInput,
      }
    )

    return Result.toVoid(response)
  },

  async updateEmailTemplate(
    emailTemplate: EmailTemplateInput
  ): Promise<Result<void>> {
    type Response = {
      data?: {
        emailTemplate?: {
          update?: { id: string }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($id: ID!, $fields: EmailTemplateInput!) {
        emailTemplate(id: $id) {
          update(fields: $fields) {
            id
          }
        }
      }
      `,
      {
        id: emailTemplate.id,
        fields: _.omit(emailTemplate, ["id"]),
      }
    )

    return Result.toVoid(response)
  },

  async deleteEmailTemplate(
    emailTemplate: EmailTemplate
  ): Promise<Result<void>> {
    type Response = {
      data?: {
        emailTemplate?: {
          delete?: { id: string }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($id: ID!) {
        emailTemplate(id: $id) {
          delete {
            id
          }
        }
      }
      `,
      {
        id: emailTemplate.id,
      }
    )

    return Result.toVoid(response)
  },
}

export default emails

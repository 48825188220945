




















import Vue from "vue"
import type { ChargingSite } from "@/datasource/chargers"
import type { AppUser } from "@/datasource/appUsers"
import datasource from "@/datasource"

export default Vue.extend({
  props: {
    value: {
      type: [String, Object, Array],
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    returnId: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      chargingSites: [] as ChargingSite[],
      appUsers: [] as AppUser[],
    }
  },

  computed: {
    modified: {
      get(): AppUser | ChargingSite {
        return this.value
      },

      set(
        value:
          | AppUser
          | AppUser[]
          | ChargingSite
          | ChargingSite[]
          | string
          | string[]
      ): void {
        this.$emit("input", value)
      },
    },

    appUserRecipients(): Record<string, string>[] {
      return this.appUsers.map((u: AppUser) => {
        return {
          displayName: u.displayIdentifier,
          id: u.id || "",
          kind: "AppUser",
        }
      })
    },

    chargingSiteRecipients(): Record<string, string>[] {
      return this.chargingSites.map((s: ChargingSite) => {
        return {
          displayName: s.name,
          id: s.id || "",
          kind: "ChargingSite",
        }
      })
    },

    recipients(): Record<string, boolean | string | Record<string, string>>[] {
      return [...this.chargingSiteRecipients, ...this.appUserRecipients]
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      if (this.type === "AppUser") {
        const appUsersResult = await datasource.appUsers.listAppUsers()
        if (appUsersResult instanceof Error) {
          console.error(appUsersResult)
          return
        } else {
          this.appUsers = appUsersResult
        }
      } else if (this.type === "ChargingSite") {
        const chargingSitesResult =
          await datasource.chargers.listChargingSites()
        if (chargingSitesResult instanceof Error) {
          console.error(chargingSitesResult)
          return
        } else {
          this.chargingSites = chargingSitesResult
        }
      }

      this.loading = false
    },
  },
})

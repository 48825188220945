
































































import Vue from "vue"
import datasource from "@/datasource"
import { ApiResponseWithErrors } from "@/datasource"

import VJsonEditor from "v-jsoneditor"
import BuildRequestDialog from "./Optimization/BuildRequestDialog.vue"

export default Vue.extend({
  components: {
    VJsonEditor,
    BuildRequestDialog,
  },

  data() {
    return {
      loading: true,
      sendingRequest: false,
      environments: [] as string[],
      environment: null as string | null,
      sideBySide: true,
      request: { action: "hello" },
      response: null as unknown[] | Record<string, unknown> | null,
      error: false,
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true
      this.environments = await datasource.optimization.listEnvironments()
      this.environment = this.environments[0]
      this.loading = false
    },

    async sendRequest() {
      this.sendingRequest = true
      this.response = null
      this.error = true
      const result = await datasource.optimization.sendRawRequest(
        JSON.stringify(this.request),
        this.environment
      )
      if (result instanceof ApiResponseWithErrors) {
        const errors = result.response.errors || []
        const messages = errors.map((x) => x.message)
        this.response = messages
      } else if (result instanceof Error) {
        console.error(result)
      } else {
        this.response = JSON.parse(result)
        this.error = false
      }
      this.sendingRequest = false
    },
  },
})





































































import Vue from "vue"
import datasource from "@/datasource"
import type { EmailTemplate } from "@/datasource/emails"

import ConfirmedButton from "@/components/ConfirmedButton.vue"
import EditEmailTemplateDialog from "./EditEmailTemplateDialog.vue"
import CreateEmailTemplateDialog from "./CreateEmailTemplateDialog.vue"

export default Vue.extend({
  components: {
    ConfirmedButton,
    CreateEmailTemplateDialog,
    EditEmailTemplateDialog,
  },

  data() {
    return {
      loading: true,
      emailTemplates: [] as Array<EmailTemplate>,
      headers: [
        { text: "ID", value: "id" },
        { text: "Subject", value: "subject" },
        { text: "Content", value: "content" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true
      const templatesResult = await datasource.emails.listEmailTemplates()

      if (templatesResult instanceof Error) {
        console.error(templatesResult)
        return
      }

      this.emailTemplates = templatesResult
      this.loading = false
    },

    async deleteEmailTemplate(emailTemplate: EmailTemplate) {
      const result = await datasource.emails.deleteEmailTemplate(emailTemplate)
      if (result instanceof Error) {
        console.error(result)
        return
      }
      this.loadData()
    },
  },
})





































import Vue from "vue"
import datasource from "@/datasource"
import { ValidationFailureError } from "@/datasource"
import type { Schedule } from "@/datasource/backgroundJobs"

import DynamicScheduleFields from "./DynamicScheduleFields.vue"

function initialData() {
  return {
    showDialog: false,
    loading: false,
    saving: false,
    isFormValid: false,
    modifiedDynamicSchedule: {} as Schedule,
    modifiedDynamicScheduleErrors: {},
  }
}

export default Vue.extend({
  components: {
    DynamicScheduleFields,
  },

  props: {
    availableFunctions: {
      type: Array,
      default: () => [],
    },
  },

  data: initialData,

  methods: {
    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true

      const result = await datasource.backgroundJobs.createDynamicSchedule(
        this.modifiedDynamicSchedule
      )

      if (result instanceof ValidationFailureError) {
        this.modifiedDynamicScheduleErrors = result.errors
      } else if (result instanceof Error) {
        console.error(result)
      } else {
        this.$emit("saved")
        this.close()
      }

      this.saving = false
    },
  },
})

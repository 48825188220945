

















































import Vue from "vue"
import type { PropType } from "vue"
import helpers from "@/utils/helpers"
import type { Charger } from "@/datasource/chargers"

export default Vue.extend({
  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },

    ocppLogFromDate: {
      type: Date as PropType<Date>,
      required: false,
      default: null,
    },

    ocppLogToDate: {
      type: Date as PropType<Date>,
      required: false,
      default: null,
    },
  },

  computed: {
    ocppLogUrl(): string {
      const fromStr = this.ocppLogFromDate
        ? this.ocppLogFromDate.toISOString()
        : "now-7d"

      const toStr = this.ocppLogToDate
        ? this.ocppLogToDate.toISOString()
        : "now"

      const timeStr = `from:'${fromStr}',to:'${toStr}'`

      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_OCPP_LOG_INDEX,
        {
          filters: [
            `match_phrase:(charger_identifier.keyword:${this.charger.ocppIdentifier})`,
          ],
          time: timeStr,
          columns: [
            "charger_identifier",
            "event",
            "direction",
            "message_type",
            "action",
            "payload",
          ],
        }
      )
    },

    ocppStatusLogUrl(): string {
      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_OCPP_STATUS_INDEX,
        {
          filters: [
            `match_phrase:(identifier.keyword:${this.charger.ocppIdentifier})`,
          ],
          time: "from:now-7d,to:now",
          columns: ["identifier", "connector_id", "status", "error_code"],
        }
      )
    },

    userActionLogUrl(): string {
      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_USER_ACTION_LOG_INDEX,
        {
          filters: [
            `match_phrase:(entity_type.keyword:charger)`,
            `match_phrase:(entity_id:${this.charger.id})`,
          ],
          time: "from:now-7d,to:now",
          columns: [
            "user_type",
            "user_email",
            "action",
            "entity_details",
            "from",
            "to",
          ],
        }
      )
    },

    easeeApiLogUrl(): string {
      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_KERNEL_LOG_INDEX,
        {
          filters: [`match_phrase:(label.keyword:easee_api)`],
          time: "from:now-7d,to:now",
          query: this.charger.ocppIdentifier,
        }
      )
    },

    amqpDashboardUrl(): string {
      return helpers.amqpDashboardUrl(this.charger.ocppIdentifier)
    },

    amqpLogUrl(): string {
      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_AMQP_LOG_INDEX,
        {
          filters: [
            `match_phrase:(ocpp_identifier.keyword:${this.charger.ocppIdentifier})`,
          ],
          time: "from:now-7d,to:now",
          columns: [
            "ocpp_identifier",
            "observation_name",
            "value_number",
            "value_boolean",
          ],
        }
      )
    },

    amqpStatusLogUrl(): string {
      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_AMQP_LOG_INDEX,
        {
          filters: [
            `match_phrase:(ocpp_identifier.keyword:${this.charger.ocppIdentifier})`,
            "match_phrase:(observation_name.keyword:ChargerOpMode)",
          ],
          time: "from:now-7d,to:now",
          columns: ["ocpp_identifier", "value_number"],
        }
      )
    },

    chargerApiDataUrl(): string {
      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_CHARGER_API_DATA_INDEX,
        {
          filters: [`match_phrase:(charger_id:${this.charger.id})`],
          time: "from:now-7d,to:now",
          columns: [
            "charger_id",
            "source",
            "is_online",
            "connectors.1.is_connected",
            "connectors.1.is_charging",
            "meter_value_kwh",
            "instantaneous_output_power_kw",
          ],
        }
      )
    },

    chargingSessionLogUrl(): string {
      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_CHARGING_SESSION_LOG_INDEX,
        {
          filters: [`match_phrase:(charger_id:${this.charger.id})`],
          time: "from:now-7d,to:now",
        }
      )
    },
  },
})

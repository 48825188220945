import datasource from "@/datasource"
import { Result } from "@/utils"

export type AdminUser = {
  id: string | null
  email: string
  authSessions: AuthSession[]
}

export type AuthSession = {
  id: string
  insertedAt: string
  updatedAt: string
  expiresAt: string
}

const authentication = {
  async newAdminUser(): Promise<Result<AdminUser>> {
    return {
      id: null,
      email: "",
      authSessions: [],
    }
  },

  async createAdminUser(adminUser: AdminUser): Promise<Result<boolean>> {
    type Response = {
      data?: {
        createAdminUser?: {
          adminUser?: AdminUser
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($fields: AdminUserInput!) {
        createAdminUser(fields: $fields) {
          adminUser {
            id
          }
        }
      }
      `,
      {
        fields: {
          email: adminUser.email,
        },
      }
    )

    return Result.map(response, (x) => !!x.data?.createAdminUser?.adminUser?.id)
  },

  async listAdminUsers(): Promise<Result<AdminUser[]>> {
    type Response = {
      data?: {
        adminUsers?: AdminUser[]
      }
    }
    const response: Result<Response> = await datasource.graphql(`
      query {
        adminUsers {
          id
          email
          authSessions {
            insertedAt
            updatedAt
            expiresAt
          }
        }
      }
    `)
    return Result.map(response, (x) => x.data?.adminUsers || [])
  },

  async deleteAdminUser(adminUser: AdminUser): Promise<Result<boolean>> {
    type Response = {
      data?: {
        adminUser?: {
          delete?: {
            success: boolean
          }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        adminUser(id: $id) {
          delete {
            success
          }
        }
      }
      `,
      {
        id: adminUser.id,
      }
    )
    return Result.map(
      response,
      (x) => x.data?.adminUser?.delete?.success || false
    )
  },
}

export default authentication

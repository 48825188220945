



































import Vue, { PropType } from "vue"
import type { Car } from "@/datasource/cars"
import type { Charger } from "@/datasource/chargers"

export default Vue.extend({
  props: {
    value: {
      type: String,
      required: true,
    },
    modelList: {
      type: [] as PropType<string[]>,
      required: true,
    },
    useMl: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object as PropType<Car | Charger>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      modifiedValue: this.value,
    }
  },

  methods: {
    emit() {
      this.$emit("input", this.modifiedValue)
      this.$emit("save")
    },

    retrainModel() {
      this.loading = true
      this.$root.$emit("trainFdt", this.object)
    },
  },
})

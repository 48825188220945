


















































































import Vue, { PropType } from "vue"
import DateTimePicker from "@/components/DateTimePicker.vue"
import BillingPeriodVerifier from "@/components/BillingPeriodVerifier.vue"
import InvoiceGenerationResult from "@/components/InvoiceGenerationResult.vue"
import type { ChargingSite } from "@/datasource/chargers"

function initialData() {
  return {
    showDialog: false,
    billingPeriodLoading: true,
    step: 1,
    date: new Date(),
  }
}

export default Vue.extend({
  components: {
    DateTimePicker,
    BillingPeriodVerifier,
    InvoiceGenerationResult,
  },

  props: {
    chargingSites: {
      type: [] as PropType<ChargingSite[]>,
      required: true,
    },
  },

  data: initialData,

  watch: {
    showDialog(value) {
      if (!value) {
        this.close()
      }
    },
  },

  methods: {
    close() {
      Object.assign(this.$data, initialData())
      this.$emit("close")
    },
  },
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"flex-fh flex-col flex-grow flex-clip",attrs:{"fluid":""}},[_c('VCard',{staticClass:"flex-grow flex-col flex-clip",attrs:{"loading":_vm.loading}},[_c('VCardTitle',[_c('span',[_vm._v("Cars: Overview")]),_c('VSpacer'),_c('VBtn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.loadData()}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1),_c('CreateVehicleDialog',{on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1 white--text",attrs:{"icon":"","color":"green"}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-plus")])],1)]}}])})],1),_c('VCardSubtitle',[(_vm.selectedUser)?[_c('span',[_vm._v("User:"),_c('RouterLink',{attrs:{"to":{ name: 'AppUsers', params: { appUserId: _vm.selectedUser.id } }}},[_vm._v(" "+_vm._s(_vm.selectedUser.displayIdentifier))])],1)]:_vm._e(),_c('VContainer',{staticClass:"flex-grow-0",attrs:{"fluid":""}},[_c('VTextField',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","label":"Filter: Name or VIN","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],2),_c('VCardText',{staticClass:"flex-grow flex-clip"},[(_vm.loading && this.cars.length == 0)?[_c('VContainer',{staticClass:"flex-col",attrs:{"fluid":""}},_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}),1)]:[_c('VContainer',{staticClass:"flex-grow flex-col",attrs:{"fluid":""}},[_c('div',{staticClass:"py-2"},[_c('VMenu',_vm._b({attrs:{"disabled":!_vm.selected.length,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"disabled":!_vm.selected.length}},'VBtn',attrs,false),on),[_vm._v("Edit "+_vm._s(_vm.selected.length)+" Selected"),_c('VIcon',{staticClass:"right"},[_vm._v("mdi-menu-down")])],1)]}}])},'VMenu',_vm.$attrs,false),[_c('VList',[_c('VListItem',{on:{"click":function($event){}}},[_c('SetPercentageEnergyForecastDialog',{attrs:{"cars":_vm.selected},on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g({attrs:{"v-bind":attrs}},on),[_vm._v("Set Percentage Energy Forecast for selected vehicles")])]}}])})],1),_c('VListItem',[_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Set Onboard Charger Capacity")])]}}]),model:{value:(_vm.showDepartureTimeDialog),callback:function ($$v) {_vm.showDepartureTimeDialog=$$v},expression:"showDepartureTimeDialog"}},[_c('VCard',{attrs:{"loading":_vm.bulkSettingOnboardChargerCapacityDialogue}},[_c('VCardTitle',[_vm._v("Set Onboard Charger Capacity Phases Kw")]),_c('VCardText',[_c('VTextField',{attrs:{"label":"Charger capacity phases","hint":"Accepts a comma separated string of numbers, e.g. '7.4,7.4,7.4'"},model:{value:(_vm.bulkOnboardChargerCapacityPhases),callback:function ($$v) {_vm.bulkOnboardChargerCapacityPhases=$$v},expression:"bulkOnboardChargerCapacityPhases"}}),_c('VBtn',{on:{"click":function($event){return _vm.bulkSetOnboardChargerCapacity()}}},[_vm._v("Apply To Selected")])],1)],1)],1)],1),_c('VListItem',[_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Set Battery Size")])]}}]),model:{value:(_vm.showBatterySizeDialog),callback:function ($$v) {_vm.showBatterySizeDialog=$$v},expression:"showBatterySizeDialog"}},[_c('VCard',{attrs:{"loading":_vm.settingBatterySize}},[_c('VCardTitle',[_vm._v("Set Battery Size")]),_c('VCardText',[_c('VTextField',{attrs:{"label":"Battery size Kwh","hint":"e.g. 70"},model:{value:(_vm.bulkEditBatterySize),callback:function ($$v) {_vm.bulkEditBatterySize=$$v},expression:"bulkEditBatterySize"}}),_c('VBtn',{on:{"click":function($event){return _vm.bulkUpdateBatterySizeKwh()}}},[_vm._v("Apply To Selected")])],1)],1)],1)],1),_c('VListItem',[_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Set Preferred Departure Time")])]}}]),model:{value:(_vm.showOnboardChargerCapacityDialogue),callback:function ($$v) {_vm.showOnboardChargerCapacityDialogue=$$v},expression:"showOnboardChargerCapacityDialogue"}},[_c('VCard',{attrs:{"loading":_vm.bulkSettingDepartureTimes}},[_c('VCardTitle',[_vm._v("Set Preferred Departure Time")]),_c('VCardSubtitle',[_vm._v("Expires after 16 hours")]),_c('VCardText',[_c('VTextField',{attrs:{"label":"Departure time (hh:mm)"},model:{value:(_vm.bulkDepartureTime),callback:function ($$v) {_vm.bulkDepartureTime=$$v},expression:"bulkDepartureTime"}}),_c('VBtn',{on:{"click":function($event){return _vm.bulkSetPreferredDepartureTime()}}},[_vm._v("Apply To Selected")])],1)],1)],1)],1),_c('VListItem',[_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Toggle Smart Charging")])]}}]),model:{value:(_vm.showBulkSmartChargeDialog),callback:function ($$v) {_vm.showBulkSmartChargeDialog=$$v},expression:"showBulkSmartChargeDialog"}},[_c('VCard',{attrs:{"loading":_vm.bulkSettingSmartCharge}},[_c('VCardTitle',[_vm._v("Toggle Smart Charging")]),_c('VCardText',[_c('VSwitch',{model:{value:(_vm.bulkSetSmartChargeValue),callback:function ($$v) {_vm.bulkSetSmartChargeValue=$$v},expression:"bulkSetSmartChargeValue"}}),_c('VBtn',{on:{"click":function($event){return _vm.bulkSetSmartCharge()}}},[_vm._v("Apply To Selected ("+_vm._s(_vm.selected.length)+")")])],1)],1)],1)],1),_c('VListItem',[_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Set App Users")])]}}]),model:{value:(_vm.bulkAppUserDialog),callback:function ($$v) {_vm.bulkAppUserDialog=$$v},expression:"bulkAppUserDialog"}},[_c('SetCarsAppUsers',{attrs:{"cars":_vm.selected},on:{"saved":function($event){return _vm.onBulkAppUserSave()}}})],1)],1),_c('VListItem',{attrs:{"inactive":""},on:{"click":function($event){}}},[_c('ConfirmedButton',{attrs:{"color":"red","text-color":"white"},on:{"click":function($event){return _vm.deleteVehicles()}}},[_vm._v("Delete selected vehicles ("+_vm._s(_vm.selected.length)+")")])],1)],1)],1)],1),_c('CollapsibleDataTable',{attrs:{"headers":_vm.headers,"items":_vm.cars,"search":_vm.search,"namespace":"cars","sort-by":"displayName","fixed-header":"","show-select":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.displayName",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: 'DetailedCar', params: { carId: item.id } }}},[_vm._v(_vm._s(item.displayName))])]}},{key:"item.batterySizeWh",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.batterySizeDisplay(item)))]}},{key:"item.chargers",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.chargingSiteDisplay(item)))]}},{key:"item.preferenceDepartureTime",fn:function(ref){
var item = ref.item;
return [(item.preferenceDepartureTime && item.preferenceDepartureTimeExpiresAt)?[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(_vm.preferenceDepartureTimeDisplay(item.preferenceDepartureTime)))])]}}],null,true)},[_vm._v(_vm._s(_vm.expiryTextForDepartureTime(item)))])]:[_vm._v(_vm._s(_vm.preferenceDepartureTimeDisplay(item.preferenceDepartureTime)))]]}},{key:"item.appUsers",fn:function(ref){
var item = ref.item;
return [(item.appUsers.length === 0)?_c('span',[_vm._v("–")]):_vm._l((item.appUsers),function(user){return _c('div',[(!_vm.selectedUser || _vm.selectedUser.id !== user.id)?_c('RouterLink',{attrs:{"to":{ name: 'AppUserCars', params: { appUserId: user.id } }}},[_vm._v(_vm._s(user.displayIdentifier))]):_c('span',[_vm._v(_vm._s(user.displayIdentifier))])],1)})]}},{key:"item.chargePreference",fn:function(ref){
var item = ref.item;
return [_c('ChargePreferenceFields',{on:{"input":function($event){return _vm.saveChargePreference(item)}},model:{value:(item),callback:function ($$v) {item=$$v},expression:"item"}})]}},{key:"item.smartChargingEnabled",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.smartChargingEnabled}})]}},{key:"item.smartSocInferenceEnabled",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.smartSocInferenceEnabled}})]}},{key:"item.onboardChargerCapacityPhasesW",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.onboardChargerCapacityDisplay(item.onboardChargerCapacityPhasesW)))]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('EditCarDialog',{attrs:{"car":item},on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-pen")])],1)]}}],null,true)})],1)]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('VMenu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('VList',[_c('VListItem',[_c('ConfirmedButton',{attrs:{"color":"red","text-color":"white"},on:{"click":function($event){return _vm.deleteCar(item)}}},[_vm._v("Delete")])],1)],1)],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]],2),_c('VSnackbar',{attrs:{"timeout":3000,"color":"error"},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(_vm._s(_vm.error))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
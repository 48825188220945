














































import Vue, { PropType } from "vue"
import _ from "underscore"
import Big from "big.js"
import { DateTime } from "luxon"
import datasource from "@/datasource"
import type { Charger } from "@/datasource/chargers"
import type { FinishedChargingSession } from "@/datasource/charging"
import BooleanIcon from "@/components/BooleanIcon.vue"
import CollapsibleDataTable from "@/components/CollapsibleDataTable.vue"

type Row = {
  timestamps: string
  isSmart: boolean
  authSource: string
  authorizedAt: string
  energyTransferred: string
  maximumObservedPowerOutputKw: Big | null
  currentOutputPhasesAtMaximumPowerOutputW: number[] | null
  isHealthy: boolean
  instantCostEstimate: string
  smartCostEstimate: string
  savingsEstimate: string
  session: FinishedChargingSession
  connector: number
  ocppSoc: number | null
  ocppVehicleId: string | null
  currentOfferedA: number | null
  ocppTransactionIds: string[]
  vehicleName: string
  routeId: string | null
  driverId: string | null
  routeDepartureTime: string | null
}

export default Vue.extend({
  components: { BooleanIcon, CollapsibleDataTable },

  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
  },

  data() {
    return {
      showShortSessions: false,
      chargingSessions: [] as FinishedChargingSession[],
      loading: false,
      headers: [
        {
          text: "",
          value: "redirectButton",
          width: 0,
        },
        {
          text: "",
          value: "timestamps",
          width: 0,
          align: "center",
          name: "Timestamps",
        },
        { text: "Smart?", value: "isSmart", width: 0 },
        { text: "Auth Source", value: "authSource", width: 0 },
        { text: "Authd at", value: "authorizedAt", width: 0 },
        { text: "Connector", value: "connector", width: 0, align: "right" },
        { text: "OCPP SoC", value: "ocppSoc", width: 0, align: "right" },
        {
          text: "Current Offered (A)",
          value: "currentOfferedA",
          width: 0,
          align: "right",
        },
        {
          text: "Energy (kWh)",
          value: "energyTransferred",
          width: 0,
          align: "right",
        },
        {
          text: "Max Power (kw)",
          value: "maximumObservedPowerOutputKw",
          width: 0,
          align: "right",
        },
        {
          text: "Output at Max Power (kw)",
          value: "currentOutputPhasesAtMaximumPowerOutputW",
          width: 0,
        },
        { text: "Health", value: "isHealthy", width: 0 },
        { text: "Vehicle Name", value: "vehicleName", align: "left" },
        { text: "OCPP VID", value: "ocppVehicleId", width: 0 },
        {
          text: "OCPP Transactions",
          value: "ocppTransactionIds",
          width: 0,
          align: "right",
        },
        {
          text: "Route ID",
          value: "routeId",
          align: "left",
        },
        {
          text: "Driver ID",
          value: "driverId",
          align: "left",
        },
        {
          text: "Route Departure Time",
          value: "routeDepartureTime",
          align: "left",
        },
        {
          text: "Instant Cost Estimate",
          value: "instantCostEstimate",
          width: 0,
        },
        { text: "Smart Cost Estimate", value: "smartCostEstimate", width: 0 },
        { text: "Savings Estimate", value: "savingsEstimate", width: 0 },
        { text: "", value: "spacing" },
      ],
    }
  },

  computed: {
    instantCostHeader(): string {
      let currencyStr = ""

      if (
        this.chargingSessions.length &&
        this.chargingSessions[0].chargingSessionPricings.length
      ) {
        currencyStr = ` (${this.chargingSessions[0].chargingSessionPricings[0].cost.currency})`
      }

      return `Instant Cost Estimate${currencyStr}`
    },

    smartCostHeader(): string {
      let currencyStr = ""

      if (
        this.chargingSessions.length &&
        this.chargingSessions[0].chargingSessionPricings.length
      ) {
        currencyStr = ` (${this.chargingSessions[0].chargingSessionPricings[0].cost.currency})`
      }

      return `Smart Cost Estimate${currencyStr}`
    },

    rows(): Row[] {
      if (this.showShortSessions) {
        return this.chargingSessions.map((s) => this.toRow(s))
      } else {
        return this.chargingSessions
          .filter((s) => !this.isShortLived(s))
          .map((s) => this.toRow(s))
      }
    },

    filteredChargingSessions(): FinishedChargingSession[] {
      if (this.showShortSessions) {
        return this.chargingSessions
      } else {
        return this.chargingSessions.filter(
          (session) => !this.isShortLived(session)
        )
      }
    },
  },

  watch: {
    charger() {
      this.loadData()
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true
      const fromDate = new Date()
      fromDate.setDate(fromDate.getDate() - 14)

      const result = await datasource.charging.listFinishedChargingSessions(
        null,
        1000,
        {
          fromDate: fromDate,
          toDate: new Date(),
          ocppIds: [this.charger.ocppIdentifier],
        }
      )

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.chargingSessions = _.sortBy(result.items, (session) => {
        const dt = new Date(session.startedAt)
        return dt.getTime() * -1
      })

      this.loading = false
    },

    toRow(session: FinishedChargingSession): Row {
      return {
        timestamps: this.timestampDisplay(session),
        isSmart: session.smartChargingEnabled,
        authSource: this.authSourceDisplay(session),
        authorizedAt: this.dateDisplay(session.authorizedAt),
        energyTransferred: this.energyUsageKWHDisplay(session),
        maximumObservedPowerOutputKw: session.maximumObservedPowerOutputW
          ? Big(session.maximumObservedPowerOutputW).div(1000).round(2)
          : null,
        currentOutputPhasesAtMaximumPowerOutputW:
          session.currentOutputPhasesAAtMaximumPowerOutputW,
        isHealthy: session.isHealthy,
        instantCostEstimate: this.instantChargingSessionPricing(session),
        smartCostEstimate: this.smartChargingSessionPricing(session),
        savingsEstimate: this.estimatedChargingSavings(session),
        connector: session.chargerConnectorId,
        ocppSoc: session.ocppSoc,
        currentOfferedA: session.currentOfferedA,
        ocppVehicleId: session.ocppVehicleId,
        ocppTransactionIds: session.ocppTransactionIds,
        session: session,
        vehicleName: session.car?.displayName || "",
        routeId: session.routeId,
        driverId: session.routeDriverId,
        routeDepartureTime:
          session.routeDepartureTime &&
          DateTime.fromISO(session.routeDepartureTime).toLocaleString(
            DateTime.TIME_24_SIMPLE
          ),
      }
    },

    energyUsageKWHDisplay(session: FinishedChargingSession): string {
      return Big(session.energyUsageWh).div(1000).round(1).toString()
    },

    instantChargingSessionPricing(session: FinishedChargingSession): string {
      const instantPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "instant"
      )
      return instantPricing?.cost?.amount || ""
    },

    smartChargingSessionPricing(session: FinishedChargingSession): string {
      const smartPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "smart"
      )
      return smartPricing?.cost?.amount || ""
    },

    estimatedChargingSavings(session: FinishedChargingSession): string {
      const instantPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "instant"
      )

      const smartPricing = _.find(
        session.chargingSessionPricings,
        (p) => p.type == "smart"
      )

      const savings =
        instantPricing && smartPricing
          ? Big(instantPricing.cost.amount)
              .minus(Big(smartPricing.cost.amount))
              .toString()
          : ""

      return savings ? `${savings}` : ""
    },

    authSourceDisplay(session: FinishedChargingSession): string {
      switch (session.authorizationSource) {
        case "AUTH_NOT_REQUIRED":
          return "N/A"
        default:
          return session.authorizationSource || ""
      }
    },

    displayRfidToken(session: FinishedChargingSession): string {
      return (
        session.rfidToken?.secret || session.rfidToken?.externalReference || ""
      )
    },

    timestampDisplay(session: FinishedChargingSession): string {
      const finishedAt = DateTime.fromISO(session.finishedAt)
      const delta = Math.floor(finishedAt.diffNow("days").days * -1)

      return `${this.dateDisplay(session.startedAt)} - ${this.dateDisplay(
        session.finishedAt
      )} (${delta} days ago)`
    },

    dateDisplay(dtString: string | null): string {
      if (dtString) {
        return DateTime.fromISO(dtString).toFormat("d LLL T")
      } else {
        return ""
      }
    },

    isShortLived(session: FinishedChargingSession): boolean {
      const durationSeconds =
        DateTime.fromISO(session.finishedAt).toSeconds() -
        DateTime.fromISO(session.startedAt).toSeconds()

      return durationSeconds < 60
    },

    emitSessionTimestamps(row: { session: FinishedChargingSession }) {
      const startedAt = row.session.startedAt
      const finishedAt = row.session.finishedAt

      this.$emit("recentSessionRowClick", {
        startedAt: startedAt,
        finishedAt: finishedAt,
      })
    },
  },
})

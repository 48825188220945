import datasource from "@/datasource"
import { Result } from "@/utils"
import type { ChargingSite } from "@/datasource/chargers"

export type AlertPhoneNumber = {
  id: string
  phoneNumber: string
}

const alerts = {
  async addAlertPhoneNumber(
    chargingSite: ChargingSite,
    phoneNumber: string
  ): Promise<Result<AlertPhoneNumber | undefined>> {
    type Response = {
      data?: {
        chargingSite?: {
          addAlertPhoneNumber?: AlertPhoneNumber
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!, $phoneNumber: String!) {
        chargingSite(id: $id) {
          addAlertPhoneNumber(phoneNumber: $phoneNumber) {
            id
            phoneNumber
          }
        }
      }
      `,
      {
        id: chargingSite.id,
        phoneNumber: phoneNumber,
      }
    )
    return Result.map(
      response,
      (x) => x.data?.chargingSite?.addAlertPhoneNumber
    )
  },

  async deleteAlertPhoneNumber(
    chargingSite: ChargingSite,
    alertPhoneNumber: AlertPhoneNumber
  ): Promise<Result<void>> {
    type Response = {
      data?: {
        chargingSite?: {
          deleteAlertPhoneNumber?: { id: string }
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($siteId: ID!, $numberId: ID!) {
        chargingSite(id: $siteId) {
          deleteAlertPhoneNumber(id: $numberId) {
            id
          }
        }
      }
      `,
      {
        numberId: alertPhoneNumber.id,
        siteId: chargingSite.id,
      }
    )
    return Result.toVoid(response)
  },
}

export default alerts

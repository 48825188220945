





























































import _ from "underscore"
import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type { RebateRate, RebateRateInput } from "@/datasource/payments"

import DateTimePicker from "@/components/DateTimePicker.vue"

function toInput(rebateRate: RebateRate): RebateRateInput {
  return {
    id: rebateRate.id,
    month: rebateRate.month,
    no1RatePerKwh:
      rebateRate.no1RatePerKwh && _.omit(rebateRate.no1RatePerKwh, ["display"]),
    no2RatePerKwh:
      rebateRate.no1RatePerKwh && _.omit(rebateRate.no1RatePerKwh, ["display"]),
    no3RatePerKwh:
      rebateRate.no1RatePerKwh && _.omit(rebateRate.no3RatePerKwh, ["display"]),
    no4RatePerKwh:
      rebateRate.no1RatePerKwh && _.omit(rebateRate.no4RatePerKwh, ["display"]),
    no5RatePerKwh:
      rebateRate.no1RatePerKwh && _.omit(rebateRate.no5RatePerKwh, ["display"]),
    rebatePercentage: rebateRate.rebatePercentage,
    rebateThreshold: rebateRate.rebateThreshold,
  }
}

function initialData(this: { energyRebate: RebateRate }) {
  return {
    showDialog: false,
    saving: false,
    modified: toInput({ ...this.energyRebate }) as RebateRateInput,
    rebatePercentage: this.energyRebate.rebatePercentage,
    rebateThreshold: this.energyRebate.rebateThreshold,
    no1RateAmount: this.energyRebate.no1RatePerKwh?.amount,
    no2RateAmount: this.energyRebate.no2RatePerKwh?.amount,
    no3RateAmount: this.energyRebate.no3RatePerKwh?.amount,
    no4RateAmount: this.energyRebate.no4RatePerKwh?.amount,
    no5RateAmount: this.energyRebate.no5RatePerKwh?.amount,
  }
}

export default Vue.extend({
  components: { DateTimePicker },
  props: {
    energyRebate: {
      type: Object as PropType<RebateRate>,
      required: true,
    },
  },

  data: initialData,

  watch: {
    showDialog(value) {
      if (!value) {
        this.close()
      }
    },

    no1RateAmount(newVal) {
      this.modified.no1RatePerKwh = newVal
        ? { amount: newVal, currency: "NOK" }
        : null
    },

    no2RateAmount(newVal) {
      this.modified.no2RatePerKwh = newVal
        ? { amount: newVal, currency: "NOK" }
        : null
    },

    no3RateAmount(newVal) {
      this.modified.no3RatePerKwh = newVal
        ? { amount: newVal, currency: "NOK" }
        : null
    },

    no4RateAmount(newVal) {
      this.modified.no4RatePerKwh = newVal
        ? { amount: newVal, currency: "NOK" }
        : null
    },

    no5RateAmount(newVal) {
      this.modified.no5RatePerKwh = newVal
        ? { amount: newVal, currency: "NOK" }
        : null
    },

    rebatePercentage(newVal) {
      this.modified.rebatePercentage = newVal
    },

    rebateThreshold(newVal) {
      this.modified.rebateThreshold = newVal
    },
  },

  methods: {
    close() {
      this.showDialog = false
    },

    async save() {
      this.saving = true

      try {
        {
          const result = await datasource.payments.updateRebateRate(
            this.modified
          )
          if (result instanceof Error) {
            throw result
          }
        }

        this.$emit("saved")
        this.close()
      } catch (e) {
        console.error(e)
      }

      this.saving = false
    },
  },
})

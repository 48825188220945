import datasource from "@/datasource"
import Big from "big.js"
import { DateTime } from "luxon"
import type { Car } from "@/datasource/cars"
import type {
  Charger,
  ChargingSite,
  ChargerManagerSettings,
} from "@/datasource/chargers"
import type { Money } from "@/datasource/payments"
import type { PageInfo } from "@/utils"
import { Result, pageInfoFields, emptyPageInfo } from "@/utils"
import { graphQlChargingSiteFields } from "@/datasource/chargers"

export type ChargingPlanStep = {
  startTime: Date
  chargingState: ChargingState
  maxChargingPowerW: number
  maxChargingPowerPhase1W: number
  maxChargingPowerPhase2W: number
  maxChargingPowerPhase3W: number
}

type RawChargingPlanStep = {
  startTime: string
  chargingState: ChargingState
  maxChargingPowerW: number
  maxChargingPowerPhase1W: number
  maxChargingPowerPhase2W: number
  maxChargingPowerPhase3W: number
}

export type ChargingPlan = {
  id: number
  chargingSessionId?: number
  source: string
  sourceMetadata: Record<string, string>
  steps: ChargingPlanStep[]
}

type RawChargingPlan = {
  id: number
  chargingSessionId?: number
  source: string
  sourceMetadata: string
  steps: RawChargingPlanStep[]
}

export type ChargingPlanTemplate = {
  id: number
  name: string
  steps: ChargingPlanTemplateStep[]
}

export type ChargingPlanTemplateStep = {
  durationS: number
  chargingState: ChargingState
  maxChargingPowerPhase1W: number
  maxChargingPowerPhase2W: number
  maxChargingPowerPhase3W: number
}

export type ChargingSession = {
  id?: number | null
  createdAt: string
  car: {
    id: number
    displayName: string
    stateOfChargeTomorrow: number
  } | null
  charger: {
    id: number
    displayName: string
    ocppIdentifier: string
    status: string
    appUser: {
      id: number
    } | null
    chargingSite: {
      id: number
      name: string
    } | null
    apiData: {
      connectors: { order: number; status: string }[]
      instantaneousOutputPowerW: number
    } | null
    managerSettings: ChargerManagerSettings
    managementModule: string
  } | null
  chargerConnectorId: number
  smartChargingEnabled: boolean
  rfidToken: {
    ownerId: string | null
    secret: string | null
    externalReference: string | null
  }
  managementStrategy: string
  authorized: boolean
  authorizedAt: string | null
  authorizationSource: string | null
  isHealthy: boolean
  sessionStatus: string
  currentChargingPlanStep: {
    maxChargingPowerW: number
  } | null
  chargingPlan: {
    id?: number | null
    chargingSavings: {
      display: string
    }
  } | null
  instantaneousChargingPowerW: string | null
  maximumObservedPowerOutputW: number | null
  currentOutputPhasesAAtMaximumPowerOutputW: number[] | null
  energyUsageWh: string
  cleanedEnergyUsageWh: string | null
  ocppSoc: number | null
  ocppVehicleId: string | null
  ocppTransactionIds: string[]
  currentOfferedA: number | null
  vehicleSoc: number | null
  chargingPlanLastEnforcedAt: string | null
  chargingPlanLastEnforcementFailureAt: string | null
  chargingPlanEnforcementFailureCount: number
  energyCost: Money | null
  gridCost: Money | null
  chargingSessionPricings: ChargingSessionPricing[]
  routeDepartureTime: string | null
  routeId: string | null
  routeDriverId: string | null
}

type RawChargingSession = {
  id?: number | null
  createdAt: string
  car: {
    id: number
    displayName: string
    stateOfChargeTomorrow: number
  } | null
  charger: {
    id: number
    displayName: string
    ocppIdentifier: string
    status: string
    appUser: {
      id: number
    } | null
    chargingSite: {
      id: number
      name: string
    } | null
    apiData: {
      connectors: { order: number; status: string }[]
      instantaneousOutputPowerW: number
    } | null
    managerSettings: ChargerManagerSettings
    managementModule: string
  } | null
  chargerConnectorId: number
  smartChargingEnabled: boolean
  rfidToken: {
    ownerId: string | null
    secret: string | null
    externalReference: string | null
  }
  managementStrategy: string
  authorized: boolean
  authorizedAt: string | null
  authorizationSource: string | null
  isHealthy: boolean
  sessionStatus: string
  currentChargingPlanStep: {
    maxChargingPowerW: number
  } | null
  chargingPlan: {
    id?: number | null
    chargingSavings: {
      display: string
    }
  } | null
  instantaneousChargingPowerW: string | null
  energyUsageWh: string
  maximumObservedPowerOutputW: number | null
  currentOutputPhasesAAtMaximumPowerOutputW: number[] | null
  cleanedEnergyUsageWh: string | null
  currentOfferedA: number | null
  ocppSoc: number | null
  ocppVehicleId: string | null
  ocppTransactionIds: string[]
  vehicleSoc: number | null
  chargingPlanLastEnforcedAt: string | null
  chargingPlanLastEnforcementFailureAt: string | null
  chargingPlanEnforcementFailureCount: number
  energyCost: Money | null
  gridCost: Money | null
  chargingSessionPricings: RawChargingSessionPricing[]
  routeDepartureTime: string | null
  routeId: string | null
  routeDriverId: string | null
}

export type ChargingSessionPricing = {
  externalChargingSessionId: number
  type: string
  cost: Money
  createdAt: Date
  updatedAt: Date
  initialSoc: number | null
  targetSoc: number | null
  batteryCapacityKwh: Big | null
  totalEnergyTransferredKwh: Big | null
  breakdownLines: SessionPricingBreakdownLine[]
}

type RawChargingSessionPricing = {
  externalChargingSessionId: number
  type: string
  cost: Money
  createdAt: string
  updatedAt: string
  initialSoc: number | null
  targetSoc: number | null
  batteryCapacityKwh: string
  totalEnergyTransferredKwh: string
  breakdownLines: RawSessionPricingBreakdownLine[]
}

export type EstimatedSavingsBreakdown = {
  hourlyBreakdown: {
    startTime: Date
    stopTime: Date
    actualUsageKwh: Big
    planUsageKwh: Big
    actualCost: Money
    planCost: Money
    energyPriceKwh: Money
  }[]
  totalActualEnergyKwh: Big
  totalActualCost: Money
  actualCostPerKwh: Money
  totalPlanUsageKwh: Big
  totalPlanCost: Money
  planCostPerKwh: Money
  savings: Money
  scaledSavings: Money
}

type RawEstimatedSavingsBreakdown = {
  hourlyBreakdown: {
    startTime: string
    stopTime: string
    actualUsageKwh: string
    planUsageKwh: string
    actualCost: Money
    planCost: Money
    energyPriceKwh: Money
  }[]
  totalActualEnergyKwh: string
  totalActualCost: Money
  actualCostPerKwh: Money
  totalPlanUsageKwh: string
  totalPlanCost: Money
  planCostPerKwh: Money
  savings: Money
  scaledSavings: Money
}

export type SessionPricingBreakdownLine = {
  maxChargingPowerPhase1Kw: Big | null
  maxChargingPowerPhase2Kw: Big | null
  maxChargingPowerPhase3Kw: Big | null
  energyTransferredKwh: Big | null
  chargingState: string
  energyPriceKwh: Money
  startTime: Date
  stopTime: Date
  cost: Money
}

type RawSessionPricingBreakdownLine = {
  maxChargingPowerPhase1Kw: string | null
  maxChargingPowerPhase2Kw: string | null
  maxChargingPowerPhase3Kw: string | null
  energyTransferredKwh: string
  chargingState: string
  energyPriceKwh: Money
  startTime: string
  stopTime: string
  cost: Money
}

export type PostFactSavings = {
  id: number
  optimizationRequestId: string | null
  finishedChargingSessionId: number
  totalAggregatedCost: Money
  totalActualEnergyUsageKwh: Big
  actualAggregatedCostPerKwh: Money
  postFactPlanEnergyUsageKwh: Big
  postFactPlanAggregatedCost: Money
  postFactPlanAggregatedCostPerKwh: Money
  postFactSavings: Money
  breakdownLines: PostFactSavingsBreakdownLine[]
  insertedAt: Date
}

export type PostFactSavingsBreakdownLine = {
  startTime: Date
  stopTime: Date
  energyPricePerKwh: Money
  actualEnergyUsageKwh: Big
  actualAggregatedCost: Money
  postFactPlanEnergyUsageKwh: Big
  postFactPlanAggregatedCost: Money
}

type RawPostFactSavings = {
  id: number
  optimizationRequestId: string | null
  finishedChargingSessionId: number
  totalAggregatedCost: Money
  totalActualEnergyUsageKwh: string
  actualAggregatedCostPerKwh: Money
  postFactPlanEnergyUsageKwh: string
  postFactPlanAggregatedCost: Money
  postFactPlanAggregatedCostPerKwh: Money
  postFactSavings: Money
  breakdownLines: RawPostFactSavingsBreakdownLine[]
  insertedAt: string
}

type RawPostFactSavingsBreakdownLine = {
  startTime: string
  stopTime: string
  energyPricePerKwh: Money
  actualEnergyUsageKwh: string
  actualAggregatedCost: Money
  postFactPlanEnergyUsageKwh: string
  postFactPlanAggregatedCost: Money
}

export type ChargingSessionLogEntry = {
  chargerIdentifier: string | null
  chargerDisplayName: string | null
  chargerConnectorId: number | null
  chargerConnectorStatus: string | null
  energyUsageWh: number | null
  elapsedTimeS: number | null
  instantaneousChargingPowerW: number | null
  plannedChargingPowerW: number | null
  loggedAt: DateTime
  isHealthy: boolean | null
  smartChargingEnabled: boolean | null
  vehicleSoc: number | null
  ocppSoc: number | null
  chargingPlan: {
    id: string
    source: string
  } | null
  chargingSavings: Money | null
  energyCost: Money | null
  gridCost: Money | null
  sessionStatus: string
  vehicleDisplayName: string | null
  routeDepartureTime: string | null
  authorizationSource: string | null
  carSmartChargingEnabled: boolean | null
  chargerSmartChargingEnabled: boolean | null
  currentOfferedA: number | null
}

export type FinishedChargingSession = {
  id?: number | null
  startedAt: string
  finishedAt: string
  authorized: boolean
  authorizedAt: string | null
  authorizationSource: string | null
  car: {
    id: number
    displayName: string
    stateOfChargeTomorrow: number
  } | null
  charger: {
    id: number
    displayName: string
    ocppIdentifier: string
    status: string
    appUser: {
      id: number
    } | null
    chargingSite: {
      id: number
      name: string
    } | null
    apiData: {
      instantaneousOutputPowerW: number
    } | null
  } | null
  chargerConnectorId: number
  chargingPlan: {
    id?: number | null
    chargingSavings: {
      display: string
    }
  } | null
  smartChargingEnabled: boolean
  rfidToken: {
    secret: string | null
    externalReference: string | null
  }
  energyUsageWh: string
  cleanedEnergyUsageWh: string | null
  maximumObservedPowerOutputW: number | null
  currentOutputPhasesAAtMaximumPowerOutputW: number[] | null
  currentOfferedA: number | null
  ocppSoc: number | null
  ocppVehicleId: string | null
  ocppTransactionIds: string[]
  vehicleSoc: number | null
  isHealthy: boolean
  sessionStatus: string
  chargingPlanEnforcementFailureCount: number
  energyCost: Money | null
  gridCost: Money | null
  chargingSessionPricings: {
    cost: Money
    type: string
    updatedAt: string
  }[]
  routeDepartureTime: string | null
  routeId: string | null
  routeDriverId: string | null
  postFactSavingsAmount: number | null
  postFactSavingsCurrency: number | null
}

export type FinishedChargingSessionPage = {
  pageInfo: PageInfo
  items: FinishedChargingSession[]
}

export enum ChargingState {
  Charging = "CHARGING",
  Stopped = "STOPPED",
}

export const graphQlChargingSessionPricingFields = `
  externalChargingSessionId
  type
  cost {
    amount
    currency
    display
  }
  createdAt
  updatedAt
  initialSoc
  targetSoc
  batteryCapacityKwh
  totalEnergyTransferredKwh
  breakdownLines {
    maxChargingPowerPhase1Kw
    maxChargingPowerPhase2Kw
    maxChargingPowerPhase3Kw
    energyTransferredKwh
    chargingState
    energyPriceKwh {
      amount
      currency
      display
    }
    cost {
      amount
      currency
      display
    }
    startTime
    stopTime
  }
`

export const graphqlPostFactSavingsFields = `
  id
  finishedChargingSessionId
  optimizationRequestId
  totalAggregatedCost {
    amount
    currency
  }
  totalActualEnergyUsageKwh
  actualAggregatedCostPerKwh {
    amount
    currency
  }
  postFactPlanEnergyUsageKwh
  postFactPlanAggregatedCost {
    amount
    currency
  }
  postFactPlanAggregatedCostPerKwh {
    amount
    currency
  }
  postFactSavings {
    amount
    currency
  }
  breakdownLines {
    startTime
    stopTime
    energyPricePerKwh {
      amount
      currency
    }
    actualEnergyUsageKwh
    actualAggregatedCost {
      amount
      currency
    }
    postFactPlanEnergyUsageKwh
    postFactPlanAggregatedCost {
      amount
      currency
    }
  }
  insertedAt
`

export const graphQlChargingSessionFields = `
  id
  createdAt
  car {
    id
    displayName
    stateOfChargeTomorrow
  }
  charger {
    id
    displayName
    ocppIdentifier
    status
    appUser {
      id
    }
    chargingSite {
      id
      name
    }
    managerSettings {
      commandsApi
      observationsApi
    }
    managementModule
    apiData {
      connectors {
        status
        order
      }
    }
  }
  chargerConnectorId
  smartChargingEnabled
  rfidToken {
    ownerId
    secret
    externalReference
  }
  managementStrategy
  authorized
  authorizedAt
  authorizationSource
  isHealthy
  sessionStatus
  currentChargingPlanStep {
    maxChargingPowerW
  }
  chargingPlan {
    id
    chargingSavings {
      display
    }
  }
  instantaneousChargingPowerW
  maximumObservedPowerOutputW
  currentOutputPhasesAAtMaximumPowerOutputW
  energyUsageWh
  cleanedEnergyUsageWh
  currentOfferedA
  ocppSoc
  ocppVehicleId
  ocppTransactionIds
  vehicleSoc
  chargingPlanLastEnforcedAt
  chargingPlanLastEnforcementFailureAt
  chargingPlanEnforcementFailureCount
  energyCost {
    display
  }
  gridCost {
    display
  }
  chargingSessionPricings {
    ${graphQlChargingSessionPricingFields}
  }
  routeDepartureTime
  routeId
  routeDriverId
`

const graphQlChargingSessionLogEntryFields = `
  authorizationSource
  chargerIdentifier
  chargerDisplayName
  chargerConnectorId
  chargerConnectorStatus
  elapsedTimeS
  instantaneousChargingPowerW
  plannedChargingPowerW
  energyUsageWh
  loggedAt
  isHealthy
  smartChargingEnabled
  vehicleSoc
  vehicleDisplayName
  ocppSoc
  sessionStatus
  chargingPlan {
    id
    source
  }
  chargingSavings {
    amount
    display
    currency
  }
  energyCost {
    display
    amount
  }
  gridCost {
    display
    amount
  }
  routeDepartureTime
  carSmartChargingEnabled
  chargerSmartChargingEnabled
  currentOfferedA
`

const graphQlFinishedChargingSessionPageFields = `
  pageInfo {
    ${pageInfoFields}
  }
  items {
    id
    startedAt
    finishedAt
    authorized
    authorizedAt
    authorizationSource
    car {
      id
      displayName
    }
    charger {
      id
      displayName
      ocppIdentifier
      status
      appUser {
        id
      }
      chargingSite {
        id
        name
      }
    }
    chargingPlan {
      id
      chargingSavings {
        display
      }
    }
    chargerConnectorId
    smartChargingEnabled
    rfidToken {
      secret
      externalReference
    }
    energyUsageWh
    cleanedEnergyUsageWh
    maximumObservedPowerOutputW
    currentOutputPhasesAAtMaximumPowerOutputW
    currentOfferedA
    ocppSoc
    ocppVehicleId
    ocppTransactionIds
    vehicleSoc
    isHealthy
    sessionStatus
    chargingPlanEnforcementFailureCount
    energyCost {
      display
      amount
      currency
    }
    gridCost {
      display
      amount
      currency
    }
    chargingSessionPricings {
      cost {
        amount
        currency
        display
      }
      type
      insertedAt
    }
    routeDepartureTime
    routeId
    routeDriverId
    postFactSavingsAmount
    postFactSavingsCurrency
  }
`

const graphQlChargingPlanFields = `
  id
  source
  sourceMetadata
  steps {
    startTime
    chargingState
    maxChargingPowerW
    maxChargingPowerPhase1W
    maxChargingPowerPhase2W
    maxChargingPowerPhase3W
  }
`

function mapChargingSessionLogEntry(
  logEntry: Record<string, unknown>
): ChargingSessionLogEntry {
  const loggedAt = DateTime.fromISO(logEntry.loggedAt as string)

  return {
    ...(logEntry as ChargingSessionLogEntry),
    loggedAt,
  }
}

function mapChargingPlan(rawPlan: RawChargingPlan): ChargingPlan {
  let sourceMetadata = {}
  try {
    sourceMetadata = JSON.parse(rawPlan.sourceMetadata)
  } finally {
    null
  }

  const rawSteps: RawChargingPlanStep[] = rawPlan?.steps || []
  const steps: ChargingPlanStep[] = rawSteps.map(function (
    step: RawChargingPlanStep
  ): ChargingPlanStep {
    return {
      ...step,
      startTime: new Date(step.startTime),
    }
  })

  return {
    ...rawPlan,
    sourceMetadata,
    steps,
  }
}

function castChargingSessionPricingFromResponse(
  pricing: RawChargingSessionPricing
): ChargingSessionPricing {
  const createdAt = new Date(pricing.createdAt)
  const updatedAt = new Date(pricing.updatedAt)

  const batteryCapacityKwh = pricing.batteryCapacityKwh
    ? Big(pricing.batteryCapacityKwh)
    : null

  const totalEnergyTransferredKwh = pricing.totalEnergyTransferredKwh
    ? Big(pricing.totalEnergyTransferredKwh)
    : null

  const breakdownLines: SessionPricingBreakdownLine[] =
    pricing.breakdownLines.map((line) => {
      const maxChargingPowerPhase1Kw = line.maxChargingPowerPhase1Kw
        ? Big(line.maxChargingPowerPhase1Kw)
        : null

      const maxChargingPowerPhase2Kw = line.maxChargingPowerPhase2Kw
        ? Big(line.maxChargingPowerPhase2Kw)
        : null

      const maxChargingPowerPhase3Kw = line.maxChargingPowerPhase3Kw
        ? Big(line.maxChargingPowerPhase3Kw)
        : null

      const energyTransferredKwh = line.energyTransferredKwh
        ? Big(line.energyTransferredKwh)
        : null

      const startTime = new Date(line.startTime)
      const stopTime = new Date(line.stopTime)

      return {
        ...line,
        maxChargingPowerPhase1Kw,
        maxChargingPowerPhase2Kw,
        maxChargingPowerPhase3Kw,
        energyTransferredKwh,
        startTime,
        stopTime,
        breakdownLines,
      }
    })

  return {
    ...pricing,
    createdAt,
    updatedAt,
    batteryCapacityKwh,
    totalEnergyTransferredKwh,
    breakdownLines,
  }
}

function castRawPostFactSavingsToPostFactSavings(
  rawSavings: RawPostFactSavings
): PostFactSavings {
  return {
    ...rawSavings,
    totalActualEnergyUsageKwh: Big(rawSavings.totalActualEnergyUsageKwh),
    postFactPlanEnergyUsageKwh: Big(rawSavings.postFactPlanEnergyUsageKwh),
    insertedAt: new Date(rawSavings.insertedAt),
    breakdownLines: rawSavings.breakdownLines.map((line) => {
      return {
        ...line,
        startTime: new Date(line.startTime),
        stopTime: new Date(line.stopTime),
        actualEnergyUsageKwh: Big(line.actualEnergyUsageKwh),
        postFactPlanEnergyUsageKwh: Big(line.postFactPlanEnergyUsageKwh),
      }
    }),
  }
}

const charging = {
  async listChargingSessions(opts?: {
    withChargingSite?: boolean
  }): Promise<Result<Array<ChargingSession>>> {
    type Response = {
      data?: {
        chargingSessions?: Array<RawChargingSession>
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $withChargingSite: Boolean
      ) {
        chargingSessions(
          withChargingSite: $withChargingSite
        ) {
          ${graphQlChargingSessionFields}
        }
      }
    `,
      {
        withChargingSite: opts?.withChargingSite,
      }
    )
    return Result.map(response, (x) => {
      const chargingSessions = x.data?.chargingSessions

      if (chargingSessions) {
        return chargingSessions.map((session) => {
          return {
            ...session,
            chargingSessionPricings: session.chargingSessionPricings.map((p) =>
              castChargingSessionPricingFromResponse(p)
            ),
          }
        })
      } else {
        return []
      }
    })
  },

  async listChargingSessionsForChargingSite(
    chargingSite: ChargingSite
  ): Promise<Result<Array<ChargingSession>>> {
    type Response = {
      data?: {
        chargingSite?: {
          chargingSessions?: Array<ChargingSession>
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $chargingSiteId: ID!
      ) {
        chargingSite(id: $chargingSiteId) {
          chargingSessions {
            ${graphQlChargingSessionFields}
          }
        }
      }
    `,
      {
        chargingSiteId: chargingSite.id,
      }
    )
    return Result.map(
      response,
      (x) => x.data?.chargingSite?.chargingSessions || []
    )
  },

  async listPricingsForFinishedChargingSession(
    session: FinishedChargingSession
  ): Promise<Result<ChargingSessionPricing[]>> {
    type Response = {
      data?: {
        chargingSessionPricings?: RawChargingSessionPricing[]
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $chargingSessionId: ID!
      ) {
        chargingSessionPricings(chargingSessionId: $chargingSessionId) {
          ${graphQlChargingSessionPricingFields}
        }
      }
      `,
      {
        chargingSessionId: session.id,
      }
    )

    return Result.map(response, (r) => {
      return r.data?.chargingSessionPricings
        ? r.data?.chargingSessionPricings.map((p) =>
            castChargingSessionPricingFromResponse(p)
          )
        : []
    })
  },

  async listPostFactPricingsForFinishedChargingSession(
    session: FinishedChargingSession
  ): Promise<Result<PostFactSavings[]>> {
    type Response = {
      data?: {
        postFactSaving?: RawPostFactSavings[]
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $chargingSessionId: ID!
      ) {
        postFactSaving(chargingSessionId: $chargingSessionId) {
          ${graphqlPostFactSavingsFields}
        }
      }
      `,
      {
        chargingSessionId: session.id,
      }
    )

    return Result.map(response, (r) => {
      const savings = r.data?.postFactSaving

      if (savings) {
        return savings.map((s) => castRawPostFactSavingsToPostFactSavings(s))
      } else {
        return []
      }
    })
  },

  async getEstimatedSavingsForFinishedChargingSession(
    session: FinishedChargingSession
  ): Promise<Result<EstimatedSavingsBreakdown | null>> {
    type Response = {
      data?: {
        finishedChargingSession?: {
          estimatedSavings?: RawEstimatedSavingsBreakdown
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        finishedChargingSession(id: $id) {
          estimatedSavings {
            hourlyBreakdown {
              startTime
              stopTime
              actualUsageKwh
              planUsageKwh
              actualCost {
                amount
                currency
              }
              planCost {
                amount
                currency
              }
              energyPriceKwh {
                amount
                currency
              }
            }
            totalActualEnergyKwh
            totalActualCost {
              amount
              currency
            }
            actualCostPerKwh {
              amount
              currency
            }
            planCostPerKwh {
              amount
              currency
            }
            totalPlanUsageKwh
            totalPlanCost {
              amount
              currency
            }
            planCostPerKwh {
              amount
              currency
            }
            savings {
              amount
              currency
            }
            scaledSavings {
              amount
              currency
            }
          }
        }
      }
      `,
      { id: session.id }
    )

    return Result.map(response, (r) => {
      const estimatedSavings = r.data?.finishedChargingSession?.estimatedSavings

      if (estimatedSavings) {
        return {
          ...estimatedSavings,
          hourlyBreakdown: estimatedSavings.hourlyBreakdown.map((b) => {
            return {
              ...b,
              startTime: new Date(b.startTime),
              stopTime: new Date(b.stopTime),
              actualUsageKwh: Big(b.actualUsageKwh).round(1),
              planUsageKwh: Big(b.planUsageKwh).round(1),
            }
          }),
          totalActualEnergyKwh: Big(
            estimatedSavings.totalActualEnergyKwh
          ).round(1),
          totalPlanUsageKwh: Big(estimatedSavings.totalPlanUsageKwh).round(1),
        }
      } else {
        return null
      }
    })
  },

  async listFinishedChargingSessions(
    cursor: string | null,
    limit: number,
    filters: {
      fromDate: Date
      toDate: Date
      ocppIds?: string[] | null
      chargingSessionIds?: string[] | null
      chargingSiteIds?: string[] | null
      smartChargingEnabled?: boolean | null
      minimumDuration?: number | null
      maximumDuration?: number | null
      minimumEnergyTransferred?: number | null
      maximumEnergyTransferred?: number | null
      vehicleDisplayName?: string | null
      rfidTokenIds?: string[] | null
    }
  ): Promise<Result<FinishedChargingSessionPage>> {
    type Response = {
      data?: {
        finishedChargingSessionsList?: FinishedChargingSessionPage
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $cursor: String,
        $limit: Int,
        $fromDate: DateTime!,
        $toDate: DateTime!,
        $ocppIdentifiers: [String],
        $chargingSessionIds: [ID],
        $chargingSiteIds: [ID],
        $smartChargingEnabled: Boolean,
        $minimumEnergyTransferredWh: Float,
        $maximumEnergyTransferredWh: Float,
        $minimumDurationS: Int,
        $maximumDurationS: Int,
        $vehicleDisplayName: String,
        $rfidTokenIds: [ID]
      ) {
        finishedChargingSessionsList(
          cursor: $cursor,
          limit: $limit,
          from: $fromDate,
          until: $toDate,
          ocppIdentifiers: $ocppIdentifiers,
          chargingSessionIds: $chargingSessionIds,
          chargingSiteIds: $chargingSiteIds,
          smartChargingEnabled: $smartChargingEnabled,
          minimumEnergyTransferredWh: $minimumEnergyTransferredWh,
          maximumEnergyTransferredWh: $maximumEnergyTransferredWh,
          minimumDurationS: $minimumDurationS,
          maximumDurationS: $maximumDurationS,
          vehicleDisplayName: $vehicleDisplayName,
          rfidTokenIds: $rfidTokenIds
        ) {
          ${graphQlFinishedChargingSessionPageFields}
        }
      }
      `,
      {
        cursor,
        limit,
        fromDate: filters.fromDate,
        toDate: filters.toDate,
        ocppIdentifiers: filters.ocppIds,
        chargingSessionIds: filters.chargingSessionIds,
        chargingSiteIds: filters.chargingSiteIds,
        smartChargingEnabled: filters.smartChargingEnabled,
        minimumEnergyTransferredWh: filters.minimumEnergyTransferred,
        maximumEnergyTransferredWh: filters.maximumEnergyTransferred,
        minimumDurationS: filters.minimumDuration,
        maximumDurationS: filters.maximumDuration,
        vehicleDisplayName: filters.vehicleDisplayName,
        rfidTokenIds: filters.rfidTokenIds,
      }
    )
    return Result.map(
      response,
      (x) =>
        x.data?.finishedChargingSessionsList || {
          pageInfo: emptyPageInfo,
          items: [],
        }
    )
  },

  async listLogEntriesForChargingSession(
    chargingSession: ChargingSession
  ): Promise<Result<ChargingSessionLogEntry[]>> {
    type Response = {
      data?: {
        chargingSession?: {
          logEntries?: ChargingSessionLogEntry[]
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $id: ID!
      ) {
        chargingSession(id: $id) {
          logEntries {
            ${graphQlChargingSessionLogEntryFields}
          }
        }
      }
      `,
      {
        id: chargingSession.id,
      }
    )
    return Result.map(response, function (response: Response) {
      const rawLog = response.data?.chargingSession?.logEntries || []
      return rawLog.map((logEntry) => mapChargingSessionLogEntry(logEntry))
    })
  },

  async listLogEntriesForFinishedChargingSession(
    chargingSession: FinishedChargingSession
  ): Promise<Result<ChargingSessionLogEntry[]>> {
    type Response = {
      data?: {
        finishedChargingSession?: {
          logEntries?: ChargingSessionLogEntry[]
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $id: ID!
      ) {
        finishedChargingSession(id: $id) {
          logEntries {
            ${graphQlChargingSessionLogEntryFields}
          }
        }
      }
      `,
      {
        id: chargingSession.id,
      }
    )
    return Result.map(response, function (response: Response) {
      const rawLog = response.data?.finishedChargingSession?.logEntries || []
      return rawLog.map((logEntry) => mapChargingSessionLogEntry(logEntry))
    })
  },

  async registerChargingSession({
    car,
    charger,
    chargerConnectorId,
  }: {
    car?: Car
    charger?: Charger
    chargerConnectorId?: number
  }): Promise<Result<ChargingSession | null>> {
    type Response = {
      data?: {
        registerChargingSession?: ChargingSession
      }
    }
    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($chargingSession: ChargingSessionInput!) {
        registerChargingSession(chargingSession: $chargingSession) {
          ${graphQlChargingSessionFields}
        }
      }
    `,
      {
        chargingSession: {
          carId: car?.id,
          chargerId: charger?.id,
          chargerConnectorId,
        },
      }
    )
    return Result.map(response, (x) => x.data?.registerChargingSession || null)
  },

  async clearChargingSession(
    chargingSession: ChargingSession
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        chargingSession?: {
          clear?: boolean
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        chargingSession(id: $id) {
          clear
        }
      }
    `,
      {
        id: chargingSession.id,
      }
    )
    return Result.map(response, (x) => x.data?.chargingSession?.clear || false)
  },

  async fetchChargingSessionLog(
    charger: Charger
  ): Promise<Result<Array<ChargingSessionLogEntry>>> {
    type Response = {
      data?: {
        chargingSessionLog?: Array<ChargingSessionLogEntry>
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        chargingSessionLog(chargerId: $id) {
          ${graphQlChargingSessionLogEntryFields}
        }
      }
    `,
      {
        id: charger.id,
      }
    )

    return Result.map(response, function (response: Response) {
      const rawLog = response.data?.chargingSessionLog || []
      return rawLog.map((logEntry) => mapChargingSessionLogEntry(logEntry))
    })
  },

  async fetchChargingSessionLogRange(
    charger: Charger,
    fromDate: Date,
    toDate: Date
  ): Promise<Result<Array<ChargingSessionLogEntry>>> {
    type Response = {
      data?: {
        charger?: {
          chargingSessionLogRange?: Array<ChargingSessionLogEntry>
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query(
        $id: ID!,
        $fromDate: DateTime!,
        $toDate: DateTime!,
      ) {
        charger(id: $id) {
          chargingSessionLogRange(from: $fromDate, until: $toDate) {
            ${graphQlChargingSessionLogEntryFields}
          }
        }
      }
    `,
      {
        id: charger.id,
        fromDate: fromDate,
        toDate: toDate,
      }
    )

    return Result.map(response, function (response: Response) {
      const rawLog = response.data?.charger?.chargingSessionLogRange || []
      return rawLog.map((logEntry) => mapChargingSessionLogEntry(logEntry))
    })
  },

  async setChargingState(
    chargingSession: ChargingSession,
    chargingState: ChargingState
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        chargingSession?: {
          setChargingState?: {
            id: number
          }
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!, $chargingState: ChargingState!) {
        chargingSession(id: $id) {
          setChargingState(chargingState: $chargingState) {
            id
          }
        }
      }
    `,
      {
        id: chargingSession.id,
        chargingState: chargingState,
      }
    )
    return Result.map(
      response,
      (x) => typeof x.data?.chargingSession?.setChargingState?.id == "number"
    )
  },

  async setChargingSessionManagementStrategy(
    chargingSession: ChargingSession
  ): Promise<Result<ChargingSession>> {
    type Response = {
      data?: {
        chargingSession?: {
          setManagementStrategy?: ChargingSession
        }
      }
    }
    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation(
        $id: ID!,
        $managementStrategy: ChargingSessionManagementStrategy!
      ) {
        chargingSession(id: $id) {
          setManagementStrategy(managementStrategy: $managementStrategy) {
            ${graphQlChargingSessionFields}
          }
        }
      }
    `,
      {
        id: chargingSession.id,
        managementStrategy: chargingSession.managementStrategy,
      }
    )
    return Result.map(
      response,
      (x) => x.data?.chargingSession?.setManagementStrategy || chargingSession
    )
  },

  async optimizeChargingSession(
    chargingSession: ChargingSession
  ): Promise<Result<ChargingSession | null>> {
    type Response = {
      data?: {
        chargingSession?: {
          optimize?: ChargingSession
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        chargingSession(id: $id) {
          optimize {
            ${graphQlChargingSessionFields}
          }
        }
      }
    `,
      {
        id: chargingSession.id,
      }
    )
    return Result.map(
      response,
      (x) => x.data?.chargingSession?.optimize || null
    )
  },

  async optimizeChargingSite(
    chargingSite: ChargingSite
  ): Promise<Result<ChargingSite | null>> {
    type Response = {
      data?: {
        chargingSite?: {
          optimize?: ChargingSite
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        chargingSite(id: $id) {
          optimize {
            ${graphQlChargingSiteFields}
          }
        }
      }
    `,
      {
        id: chargingSite.id,
      }
    )
    return Result.map(response, (x) => x.data?.chargingSite?.optimize || null)
  },

  async getChargingPlan(
    chargingSession: ChargingSession
  ): Promise<Result<ChargingPlan | null>> {
    type Response = {
      data?: {
        chargingSession?: {
          chargingPlan?: RawChargingPlan
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query($id: ID!) {
        chargingSession(id: $id) {
          chargingPlan {
            ${graphQlChargingPlanFields}
          }
        }
      }
    `,
      {
        id: chargingSession.id,
      }
    )
    return Result.map(response, function (response: Response) {
      const chargingPlan = response.data?.chargingSession?.chargingPlan
      if (!chargingPlan) {
        return null
      }

      return mapChargingPlan(chargingPlan)
    })
  },

  async listChargingSessionManagementStrategies(): Promise<string[]> {
    return await datasource.schema.listEnumOptions(
      "ChargingSessionManagementStrategy"
    )
  },

  async listAuthorizationSettings(): Promise<string[]> {
    return await datasource.schema.listEnumOptions("AuthorizationSetting")
  },

  async listChargingPlanTemplates(): Promise<Result<ChargingPlanTemplate[]>> {
    type Response = {
      data?: {
        chargingPlanTemplates?: ChargingPlanTemplate[]
      }
    }
    const response: Result<Response> = await datasource.graphql_flexibility(`
      query {
        chargingPlanTemplates {
          id
          name
          steps {
            durationS
            chargingState
            maxChargingPowerPhase1W
            maxChargingPowerPhase2W
            maxChargingPowerPhase3W
          }
        }
      }
    `)
    return Result.map(response, (x) => x.data?.chargingPlanTemplates || [])
  },

  async createChargingPlanTemplate(
    template: ChargingPlanTemplate
  ): Promise<Result<void>> {
    type Response = {
      data?: {
        createChargingPlanTemplate?: ChargingPlanTemplate
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($fields: ChargingPlanTemplateInput!) {
        createChargingPlanTemplate(fields: $fields) {
          id
        }
      }
      `,
      {
        fields: {
          name: template.name,
          steps: template.steps,
        },
      }
    )

    return Result.toVoid(response)
  },

  async updateChargingPlanTemplate(
    template: ChargingPlanTemplate
  ): Promise<Result<void>> {
    type Response = {
      data?: {
        updateChargingPlanTemplate?: ChargingPlanTemplate
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($id: ID!, $fields: ChargingPlanTemplateInput!) {
        updateChargingPlanTemplate(id: $id, fields: $fields) {
          id
        }
      }
      `,
      {
        id: template.id,
        fields: {
          name: template.name,
          steps: template.steps,
        },
      }
    )

    return Result.toVoid(response)
  },

  async deleteChargingPlanTemplate(
    template: ChargingPlanTemplate
  ): Promise<Result<void>> {
    type Response = {
      data?: {
        deleteChargingPlanTemplate?: ChargingPlanTemplate
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($id: ID!) {
        deleteChargingPlanTemplate(id: $id) {
          id
        }
      }
      `,
      {
        id: template.id,
      }
    )

    return Result.toVoid(response)
  },

  async triggerChargingPlanTemplate(
    template: ChargingPlanTemplate,
    charger: Charger,
    connectorId: number
  ): Promise<Result<void>> {
    type Response = {
      data?: {
        charger?: {
          triggerChargingPlanTemplate?: string
        }
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation($id: ID!, $chargingPlanTemplateId: ID!, $connectorId: Int) {
        charger(id: $id) {
          triggerChargingPlanTemplate(chargingPlanTemplateId: $chargingPlanTemplateId, connectorId: $connectorId)
        }
      }
      `,
      {
        id: charger.id,
        chargingPlanTemplateId: template.id,
        connectorId: connectorId,
      }
    )

    return Result.toVoid(response)
  },

  async listChargingPlanTemplateRuns(): Promise<Result<ChargingPlan[]>> {
    type Response = {
      data?: {
        chargingPlanTemplateRuns?: RawChargingPlan[]
      }
    }

    const response: Result<Response> = await datasource.graphql_flexibility(`
      query {
        chargingPlanTemplateRuns {
          chargingSessionId
          ${graphQlChargingPlanFields}
        }
      }
    `)

    return Result.map(response, (x) => {
      const rawPlans = x.data?.chargingPlanTemplateRuns || []
      return rawPlans.map((rawPlan) => mapChargingPlan(rawPlan))
    })
  },

  async listChargingSiteOptimizationModes(): Promise<string[]> {
    return await datasource.schema.listEnumOptions(
      "ChargingSiteMathematicalModelOptimizationMode"
    )
  },

  async generatePostFactSavingsForFinishedChargingSessionId(
    sessionId: number | string
  ): Promise<Result<PostFactSavings | null>> {
    type Response = {
      data?: {
        generatePostFactSavings?: RawPostFactSavings
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($chargingSessionId: ID!) {
        generatePostFactSavings(chargingSessionId: $chargingSessionId) {
          ${graphqlPostFactSavingsFields}
        }
      }
      `,
      { chargingSessionId: sessionId }
    )

    return Result.map(response, (r) => {
      const savings = r.data?.generatePostFactSavings

      if (savings) {
        return castRawPostFactSavingsToPostFactSavings(savings)
      } else {
        return null
      }
    })
  },
}

export default charging

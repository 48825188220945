



























































































import Vue from "vue"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import type { JobStatistics, Schedule } from "@/datasource/backgroundJobs"

import ConfirmedButton from "@/components/ConfirmedButton.vue"
import NewDynamicScheduleDialog from "./NewDynamicScheduleDialog.vue"
import EditDynamicScheduleDialog from "./EditDynamicScheduleDialog.vue"

export default Vue.extend({
  components: {
    ConfirmedButton,
    NewDynamicScheduleDialog,
    EditDynamicScheduleDialog,
  },

  data() {
    return {
      loading: true,
      search: "",
      expanded: [],
      jobStatistics: {} as Record<number, JobStatistics[]>,
      schedules: [] as Schedule[],
      availableFunctions: [] as string[],
      headers: [
        { text: "Name", value: "name" },
        { text: "Function", value: "function" },
        { text: "arguments", value: "arguments" },
        { text: "schedule", value: "schedule", align: "right" },
        { text: "state", value: "state" },
        {
          text: "Actions",
          value: "actions",
          width: 0,
          align: "right",
          sortable: false,
        },
        { text: "", value: "menu", width: 0, align: "right", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    ...formatters.dateTime,

    getJobStatistics(schedule: Schedule) {
      var attempts = [] as Date[]

      if (schedule.id && this.jobStatistics[schedule.id]) {
        // Map over the statistics for this ID. We ultimately want:
        // - The last attempt time
        // - A summary of end states, e.g. `completed: 61, retryable: 2`
        const summary = this.jobStatistics[schedule.id]
          .map((item: JobStatistics) => {
            if (item.lastAttemptedAt) {
              attempts.push(item.lastAttemptedAt)
            }

            return `${item.state}: ${item.count}`
          })
          .join(", ")

        const lastAttempt = attempts.sort()[0]

        return `Last attempt: ${this.dateTime(lastAttempt)}. ${summary}`
      } else {
        return "No statistics found."
      }
    },

    async loadData() {
      this.loading = true

      const schedulesResult = await datasource.backgroundJobs.listSchedules()
      if (schedulesResult instanceof Error) {
        console.error(schedulesResult)
        return
      }

      const functionsResult = await datasource.backgroundJobs.listJobFunctions()
      if (functionsResult instanceof Error) {
        console.error(functionsResult)
        return
      }

      const jobStatisticsResult =
        await datasource.backgroundJobs.jobStatistics()
      if (jobStatisticsResult instanceof Error) {
        console.error(jobStatisticsResult)
        return
      }

      // The statistics are `JobStatistics[]`, but we want `{dynamicJobId: JobStatistics[]}`,
      // so reduce over them and group by dynamicJobId.
      this.jobStatistics = jobStatisticsResult.reduce((result, item) => {
        const scheduleId = item.dynamicJobId

        // The ID is nullable, so only add to the group if it exists
        if (scheduleId) {
          // Make sure the group for this ID exists
          result[scheduleId] = result[scheduleId] || []

          // Then add the item.
          result[scheduleId].push(item)
        }

        return result
      }, {} as Record<number, JobStatistics[]>)
      this.schedules = schedulesResult
      this.availableFunctions = functionsResult
      this.loading = false
    },

    async deleteSchedule(index: number) {
      const schedule = this.schedules[index]
      await datasource.backgroundJobs.deleteDynamicSchedule(schedule)
      this.loadData()
    },
  },
})

import datasource from "@/datasource"
import { Result } from "@/utils"
import type { RfidToken, Charger } from "@/datasource/chargers"

export class AppUser {
  id?: string
  email: string | null = ""
  externalReference: string | null = null
  displayIdentifier = ""
  firstName = ""
  lastName = ""
  phoneNumber = ""
  addressStreet = ""
  addressCity = ""
  addressPostcode = ""
  addressCountry = ""
  notes: string | null = null
  smartChargingEnabled: boolean | null = false
  unknownCarBatterySizeWh = 30000
  unhealthyChargingSessionAlertsEnabled: boolean | null = false
  emailIsVerified = false
  carsCount = 0
  chargers: Charger[] = []
  chargersCount = 0
  chargingSiteCount = 0
  rfidTokens: RfidToken[] = []
  freeCharging: boolean | null = false
  subscriptionStartDate: string | null = null
  subscriptionEndDate: string | null = null
}

export class AppUserSummary {
  id?: string
  displayIdentifier = ""
}

const graphQlAppUserFields = `
  id
  email
  externalReference
  displayIdentifier
  firstName
  lastName
  phoneNumber
  addressStreet
  addressCity
  addressPostcode
  addressCountry
  notes
  smartChargingEnabled
  unknownCarBatterySizeWh
  unhealthyChargingSessionAlertsEnabled
  emailIsVerified
  carsCount
  chargers {
    ocppIdentifier
    chargingSite {
      id
    }
  }
  chargersCount
  chargingSiteCount
  rfidTokens {
    id
    secret
  }
  freeCharging
  subscriptionStartDate
  subscriptionEndDate
`

const graphQlAppUserSummaryFields = `
  id
  displayIdentifier
`

const appUsers = {
  async newAppUser(): Promise<Result<AppUser>> {
    return new AppUser()
  },

  async getAppUser(id: string): Promise<Result<AppUser | null>> {
    type Response = {
      data?: {
        appUser?: AppUser
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query ($id: ID!) {
        appUser(id: $id) {
          ${graphQlAppUserFields}
        }
      }
    `,
      {
        id: id,
      }
    )
    return Result.map(response, function (x) {
      return x.data?.appUser || null
    })
  },

  async listAppUsers(): Promise<Result<Array<AppUser>>> {
    type Response = {
      data?: {
        appUsers?: Array<AppUser>
      }
    }
    const response: Result<Response> = await datasource.graphql(`
      query {
        appUsers {
          ${graphQlAppUserFields}
        }
      }
    `)
    return Result.map(response, function (x) {
      return x.data?.appUsers || []
    })
  },

  async listAppUsersByIds(ids: string[]): Promise<Result<Array<AppUser>>> {
    type Response = {
      data?: {
        appUsers?: Array<AppUser>
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      query($ids: [ID!]) {
        appUsers(ids: $ids) {
          ${graphQlAppUserFields}
        }
      }
    `,
      { ids }
    )
    return Result.map(response, function (x) {
      return x.data?.appUsers || []
    })
  },

  async listAppUserSummaries(): Promise<Result<Array<AppUserSummary>>> {
    type Response = {
      data?: {
        appUsers?: Array<AppUserSummary>
      }
    }

    const response: Result<Response> = await datasource.graphql(`
      query {
        appUsers {
          ${graphQlAppUserSummaryFields}
        }
      }
      `)

    return Result.map(response, (r) => r.data?.appUsers || [])
  },
  async deleteAppUser(appUser: AppUser): Promise<Result<boolean>> {
    type Response = {
      data?: {
        appUser?: {
          delete: boolean
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        appUser(id: $id) {
          delete
        }
      }
    `,
      {
        id: appUser.id,
      }
    )
    return Result.map(response, (x) => x.data?.appUser?.delete || false)
  },

  async getLoginQrCode(appUser: AppUser): Promise<Result<string>> {
    type Response = {
      data?: {
        appUser?: {
          loginQrCodeSvg?: string
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      query ($id: ID!) {
        appUser(id: $id) {
          loginQrCodeSvg
        }
      }
    `,
      {
        id: appUser.id,
      }
    )
    return Result.map(response, (x) => x.data?.appUser?.loginQrCodeSvg || "")
  },

  async sendPasswordResetEmail(appUser: AppUser): Promise<Result<boolean>> {
    type Response = {
      data?: {
        appUser?: {
          sendPasswordResetEmail?: {
            success?: boolean
          }
        }
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation ($id: ID!) {
        appUser(id: $id) {
          sendPasswordResetEmail {
            success
          }
        }
      }
    `,
      {
        id: appUser.id,
      }
    )
    return Result.map(
      response,
      (x) => x.data?.appUser?.sendPasswordResetEmail?.success || false
    )
  },

  async sendVerificationEmail(appUser: AppUser): Promise<Result<boolean>> {
    type Response = {
      data?: {
        appUser?: {
          sendVerificationEmail?: boolean
        }
      }
    }
    const response: Result<Response> = await datasource.graphql_flexibility(
      `
      mutation ($id: ID!) {
        appUser(id: $id) {
          sendVerificationEmail
        }
      }
    `,
      {
        id: appUser.id,
      }
    )
    return Result.map(
      response,
      (x) => x.data?.appUser?.sendVerificationEmail || false
    )
  },

  async createAppUser(appUser: AppUser): Promise<Result<void>> {
    type Response = {
      data?: {
        createAppUser?: AppUser
      }
    }
    const response: Result<Response> = await datasource.graphql(
      `
      mutation (
        $fields: AppUserInput!
      ) {
        createAppUser(fields: $fields) {
          id
        }
      }
    `,
      {
        id: appUser.id,
        fields: {
          email: appUser.email,
          firstName: appUser.firstName,
          lastName: appUser.lastName,
          phoneNumber: appUser.phoneNumber,
          addressStreet: appUser.addressStreet,
          addressCity: appUser.addressCity,
          addressPostcode: appUser.addressPostcode,
          addressCountry: appUser.addressCountry,
          notes: appUser.notes,
        },
      }
    )
    return Result.toVoid(response)
  },

  async updateAppUser(appUser: AppUser): Promise<Result<void>> {
    type Response = {
      data?: {
        appUser?: {
          update?: AppUser
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation (
        $id: ID!,
        $fields: AppUserInput!
      ) {
        appUser(id: $id) {
          update(fields: $fields) {
            id
          }
        }
      }
    `,
      {
        id: appUser.id,
        fields: {
          email: appUser.email,
          firstName: appUser.firstName,
          lastName: appUser.lastName,
          phoneNumber: appUser.phoneNumber,
          addressStreet: appUser.addressStreet,
          addressCity: appUser.addressCity,
          addressPostcode: appUser.addressPostcode,
          addressCountry: appUser.addressCountry,
          notes: appUser.notes,
          unknownCarBatterySizeWh: appUser.unknownCarBatterySizeWh,
          unhealthyChargingSessionAlertsEnabled:
            appUser.unhealthyChargingSessionAlertsEnabled,
          smartChargingEnabled: appUser.smartChargingEnabled,
          freeCharging: appUser.freeCharging,
          subscriptionStartDate: appUser.subscriptionStartDate,
          subscriptionEndDate: appUser.subscriptionEndDate,
        },
      }
    )
    return Result.toVoid(response)
  },

  async clearAppUserSubscriptionStartDate(
    appUser: AppUser
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        appUser?: {
          clearSubscriptionStartDate?: AppUser
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        appUser(id: $id) {
          clearSubscriptionStartDate {
            id
          }
        }
      }
      `,
      { id: appUser.id }
    )

    return Result.map(
      response,
      (r) => !!r.data?.appUser?.clearSubscriptionStartDate?.id
    )
  },

  async clearAppUserSubscriptionEndDate(
    appUser: AppUser
  ): Promise<Result<boolean>> {
    type Response = {
      data?: {
        appUser?: {
          clearSubscriptionEndDate?: AppUser
        }
      }
    }

    const response: Result<Response> = await datasource.graphql(
      `
      mutation($id: ID!) {
        appUser(id: $id) {
          clearSubscriptionEndDate {
            id
          }
        }
      }
      `,
      { id: appUser.id }
    )

    return Result.map(
      response,
      (r) => !!r.data?.appUser?.clearSubscriptionEndDate?.id
    )
  },
}

export default appUsers

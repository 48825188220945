




































import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type { Charger } from "@/datasource/chargers"
import VJsonEditor from "v-jsoneditor"

function initialData() {
  return {
    showDialog: false,
    loading: true,
    loadingFailed: false,
    debugData: {},
  }
}

export default Vue.extend({
  components: {
    VJsonEditor,
  },

  props: {
    charger: {
      required: true,
      type: Object as PropType<Charger>,
    },
  },

  data() {
    return initialData()
  },

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    async loadData() {
      this.loading = true
      this.loadingFailed = false

      const result = await datasource.chargers.getChargerDebugData(this.charger)
      if (result instanceof Error) {
        this.loadingFailed = true
        console.error(result)
      } else {
        this.debugData = result
      }

      this.loading = false
    },

    close() {
      Object.assign(this.$data, initialData())
    },
  },
})



































import Vue from "vue"
import datasource from "@/datasource"
import { ValidationFailureError } from "@/datasource"
import type { InvoiceInput } from "@/datasource/payments"

import InvoiceFields from "@/views/Invoices/InvoiceFields.vue"

function initialData() {
  return {
    showDialog: false,
    loading: false,
    saving: false,
    isFormValid: false,
    modifiedInvoice: {
      startDate: new Date(),
      endDate: new Date(),
    } as InvoiceInput,
    modifiedInvoiceErrors: {},
  }
}

export default Vue.extend({
  components: {
    InvoiceFields,
  },

  data: initialData,

  methods: {
    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true

      const result = await datasource.payments.createManualInvoice(
        this.modifiedInvoice
      )

      this.saving = false

      if (result instanceof ValidationFailureError) {
        this.modifiedInvoiceErrors = result.errors.lines[0]
      } else if (result instanceof Error) {
        console.error(result)
      } else {
        this.$emit("saved")
        this.close()
      }
    },
  },
})

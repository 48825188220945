







































































































































import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type { ChargingSiteMathematicalModel } from "@/datasource/chargers"
import SubFuseButton from "./SubFuseButton.vue"

function fieldsFromValue(value: ChargingSiteMathematicalModel) {
  const fields = {
    ...value,
    optimizationStepCount: value.optimizationStepCount.toString(),
    unknownCarBatterySizeKwh: value.unknownCarBatterySizeWh / 1000,
    unknownCarBatterySizeWh: undefined,
    unknownCarBatteryLevel: value.unknownCarBatteryLevel.toString(),
    unknownCarOnboardChargerMaxPowerKw:
      value.unknownCarOnboardChargerMaxPowerW.map((x) => (x / 1000).toString()),
    unknownCarOnboardChargerMaxPowerW: undefined,
  }

  delete fields.unknownCarBatterySizeWh
  delete fields.unknownCarOnboardChargerMaxPowerW

  fields.location = fields.location || {
    latitude: "",
    longitude: "",
  }

  fields.mainFuse = fields.mainFuse || {
    name: "",
    phase1CapacityAmps: "",
    phase2CapacityAmps: "",
    phase3CapacityAmps: "",
    subFuses: [],
  }

  return fields
}

export default Vue.extend({
  components: {
    SubFuseButton,
  },

  props: {
    value: {
      type: Object as PropType<ChargingSiteMathematicalModel>,
      required: true,
    },
    errors: {
      type: Object as PropType<Record<string, string[]>>,
      default: () => [],
    },
  },

  data() {
    return {
      fields: fieldsFromValue(this.value),
      optimizationModes: [] as string[],
      allEnvironmentTags: [] as string[],
      voltages: [
        { value: "VOLTAGE_230", text: "230" },
        { value: "VOLTAGE_400", text: "400" },
      ],
    }
  },

  watch: {
    value() {
      this.fields = fieldsFromValue(this.value)
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.allEnvironmentTags = await datasource.chargers.listEnvironmentTags()
      this.optimizationModes =
        await datasource.charging.listChargingSiteOptimizationModes()
    },

    isValidFloat(value: string): boolean {
      return !isNaN(parseFloat(value))
    },

    emit() {
      let unknownCarBatterySizeWh: number | null =
        this.fields.unknownCarBatterySizeKwh * 1000
      if (unknownCarBatterySizeWh == 0) {
        unknownCarBatterySizeWh = null
      }

      let unknownCarBatteryLevel: number | null = parseInt(
        this.fields.unknownCarBatteryLevel
      )
      if (isNaN(unknownCarBatteryLevel)) {
        unknownCarBatteryLevel = null
      }

      let unknownCarOnboardChargerMaxPowerW: number[] | null =
        this.fields.unknownCarOnboardChargerMaxPowerKw.map(
          (x) => parseFloat(x) * 1000
        )

      const fields = {
        ...this.fields,
        optimizationStepCount: parseInt(this.fields.optimizationStepCount),
        unknownCarBatterySizeWh,
        unknownCarBatterySizeKwh: undefined,
        unknownCarBatteryLevel,
        unknownCarOnboardChargerMaxPowerW,
        unknownCarOnboardChargerMaxPowerKw: undefined,
      }
      delete fields.unknownCarBatterySizeKwh
      delete fields.unknownCarOnboardChargerMaxPowerKw

      this.$emit("input", fields)
    },
  },
})

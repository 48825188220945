




































import Vue from "vue"
import datasource from "@/datasource"
import type { Setting } from "@/datasource/system"

export default Vue.extend({
  data() {
    return {
      loading: true,
      search: "",
      settingsValues: [] as Array<Setting>,
      headers: [
        { text: "Key", value: "key" },
        { text: "Value", value: "value" },
        { text: "Source", value: "source" },
        { text: "Created", value: "insertedAt" },
        { text: "Last Updated", value: "updatedAt" },
      ],
    }
  },

  watch: {
    $route() {
      this.loadData()
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      const result = await datasource.system.listSystemSettings()

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.settingsValues = result
      this.loading = false
    },
  },
})































import Vue from "vue"
import datasource from "@/datasource"
import VJsonEditor from "v-jsoneditor"
import type { AdminTask } from "@/datasource/system"

export default Vue.extend({
  components: { VJsonEditor },

  data() {
    return {
      loading: false,
      adminTasks: [] as AdminTask[],
      selectedAdminTask: null as null | AdminTask,
      taskInput: null as null | string,
      executingTask: false,
      taskResult: null as null | { success: boolean; message: string },
    }
  },

  computed: {
    readableTaskResult(): null | string {
      if (this.taskResult) {
        return `Succeeded: ${this.taskResult.success}, Message: ${this.taskResult.message}`
      } else {
        return null
      }
    },
  },

  watch: {
    selectedAdminTask(task) {
      if (task) {
        this.taskInput = JSON.parse(task.exampleInput)
      }
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      const result = await datasource.system.listAdminTasks()

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.adminTasks = result
      this.loading = false
    },

    async executeAdminTask() {
      if (this.selectedAdminTask) {
        this.executingTask = true

        const result = await datasource.system.executeAdminTask(
          this.selectedAdminTask.name,
          JSON.stringify(this.taskInput)
        )

        if (result instanceof Error) {
          console.error(result)
          return
        }

        this.taskResult = result
        this.executingTask = false
      }
    },
  },
})

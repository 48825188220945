


















import Vue, { PropType } from "vue"
import type { Car } from "@/datasource/cars"

export default Vue.extend({
  props: {
    value: {
      type: Object as PropType<Car>,
      required: true,
    },
  },

  methods: {
    emit() {
      this.$emit("input", this.value)
    },
  },
})























































































































































































































import Vue from "vue"
import { mapState } from "vuex"
import store from "@/store"
import datasource from "@/datasource"
import type { DataOwner } from "@/datasource/authorization"

export default Vue.extend({
  data() {
    return {
      showDrawer: true,
      availableDataOwners: [] as DataOwner[],
    }
  },

  computed: {
    ...mapState(["loginAccountEmail", "loginAccountDataOwnerName"]),

    title() {
      let ret = process.env.VUE_APP_NAME
      if (process.env.NODE_ENV != "production") {
        ret += ` (${process.env.NODE_ENV})`
      }
      return ret
    },

    canSwitchDataOwner(): boolean {
      return store.state.hasElevatedPrivileges
    },

    authRequired(): boolean {
      return !store.state.loggedIn && !store.state.refreshingToken
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      const result = await datasource.authorization.listDataOwners()
      if (result instanceof Error) {
        console.error(result)
      } else {
        this.availableDataOwners = result
      }
    },

    async setDataOwner(dataOwner: DataOwner) {
      const result = await datasource.authorization.setDataOwner(dataOwner)
      if (result instanceof Error) {
        console.error(result)
      } else {
        await datasource.fetchCurrentUserDetails()
      }
    },

    async logIn() {
      datasource.startAuthFlow()
    },

    async logOut() {
      datasource.logOut()
    },
  },
})

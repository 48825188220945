








































import Vue from "vue"

export default Vue.extend({
  data() {
    return {
      loading: true,
      obanDashboardLink: "https://app.flexibility.ai/monitoring/oban",
      systemDashboardLink: "https://app.flexibility.ai/monitoring/system",
      obanConfigurationLink:
        "https://github.com/flexibility-org/kernel/blob/master/config/oban_retries.exs",
      docsLink: "https://privdocs.flexibility.ai/",
      graphqlVoyagerLink: "https://docs.flexibility.ai/graphql-voyager/",
    }
  },
})

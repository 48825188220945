










import Vue from "vue"
import type { PropType } from "vue"
import type { Charger } from "@/datasource/chargers"
import ChargerLinks from "@/components/ChargerLinks.vue"

export default Vue.extend({
  components: {
    ChargerLinks,
  },

  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
  },
})


















import Vue from "vue"
import datasource from "@/datasource"
import type { ChargingPlanTemplate } from "@/datasource/charging"

export default Vue.extend({
  props: {
    value: {
      type: [String, Object, Array],
      default: null,
    },

    returnId: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: true,
      chargingPlanTemplates: [] as ChargingPlanTemplate[],
    }
  },

  computed: {
    modified: {
      get(): ChargingPlanTemplate {
        return this.value
      },

      set(
        value: ChargingPlanTemplate | ChargingPlanTemplate[] | string | string[]
      ): void {
        this.$emit("input", value)
      },
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      const result = await datasource.charging.listChargingPlanTemplates()

      if (result instanceof Error) {
        console.error(result)
        return
      } else {
        this.chargingPlanTemplates = result
        if (!this.clearable) {
          this.modified ||= this.chargingPlanTemplates[0]
        }
      }

      this.loading = false
    },
  },
})













import Vue from "vue"
import datasource from "@/datasource"

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: null,
    },

    clearable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: true,
      values: [] as string[],
    }
  },

  computed: {
    modified: {
      get(): string {
        return this.value
      },

      set(value: string): void {
        this.$emit("input", value)
      },
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      const result =
        await datasource.charging.listChargingSessionManagementStrategies()

      if (result instanceof Error) {
        throw result
      }

      this.values = result
      if (!this.clearable) {
        this.modified ||= this.values[0]
      }

      this.loading = false
    },
  },
})

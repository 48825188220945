
















import Vue, { PropType } from "vue"
import type { Charger } from "@/datasource/chargers"
import datasource from "@/datasource"

export default Vue.extend({
  props: {
    value: {
      type: [String, Object, Array],
      default: null,
    },

    preloadedChargers: {
      type: Array as PropType<Charger[]>,
      required: false,
      default() {
        return []
      },
    },

    loadChargers: {
      type: Boolean,
      default: true,
    },

    returnId: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: false,
      chargers: [] as Charger[],
    }
  },

  computed: {
    modified: {
      get(): Charger {
        return this.value
      },

      set(value: Charger | Charger[] | string | string[]): void {
        this.$emit("input", value)
      },
    },
  },

  watch: {
    preloadedChargers(value: Charger[]) {
      if (!this.loadChargers) {
        this.chargers = value
      }
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      if (this.loadChargers) {
        this.loading = true
        const result = await datasource.chargers.listChargers()
        if (result instanceof Error) {
          console.error(result)
          return
        } else {
          this.chargers = result
          if (!this.clearable) {
            this.modified ||= this.chargers[0]
          }
        }
        this.loading = false
      } else {
        this.chargers = this.preloadedChargers
      }
    },
  },
})

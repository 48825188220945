


































import Vue from "vue"
import datasource from "@/datasource"
import { ValidationFailureError } from "@/datasource"
import type { Charger } from "@/datasource/chargers"

import ChargerFields from "@/components/EditChargerDialog/ChargerFields.vue"

function initialData() {
  return {
    showDialog: false,
    loading: true,
    saving: false,
    isFormValid: false,
    modifiedCharger: null as Charger | null,
    modifiedChargerErrors: {},
  }
}

export default Vue.extend({
  components: {
    ChargerFields,
  },

  data: initialData,

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    async loadData() {
      this.loading = true

      const result = await datasource.chargers.newCharger()
      if (result instanceof Error) {
        console.error(result)
        return
      }
      this.modifiedCharger = result

      this.loading = false
    },

    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      if (!this.modifiedCharger) {
        return
      }

      this.saving = true

      const result = await datasource.chargers.createCharger(
        this.modifiedCharger
      )

      if (result instanceof ValidationFailureError) {
        this.modifiedChargerErrors = result.errors
      } else if (result instanceof Error) {
        console.error(result)
      } else {
        this.$emit("saved")
        this.close()
      }

      this.saving = false
    },
  },
})

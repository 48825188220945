


























































































































































import Vue from "vue"
import datasource from "@/datasource"
import Big from "big.js"
import helpers from "@/utils/helpers"
import type { AppUser } from "@/datasource/appUsers"
import type {
  ChargingSite,
  ChargingSiteMathematicalModel,
  GeoPoint,
  ChargingSiteMathematicalModelFuse,
} from "@/datasource/chargers"

import AlertPhoneNumbers from "@/components/AlertPhoneNumbers.vue"
import RfidAuthorizedAppUsers from "@/components/RfidAuthorizedAppUsers.vue"
import CollapsibleDataTable from "@/components/CollapsibleDataTable.vue"
import ConfirmedButton from "@/components/ConfirmedButton.vue"
import BooleanIcon from "@/components/BooleanIcon.vue"
import GenericSelect from "@/components/GenericSelect.vue"
import StringValue from "@/components/StringValue.vue"
import NewChargingSiteDialog from "./ChargingSites/NewChargingSiteDialog.vue"
import EditChargingSiteDialog from "./ChargingSites/EditChargingSiteDialog.vue"
import InvoiceSettingsDialog from "./Invoices/InvoiceSettingsDialog.vue"
import InlineSaveInputField from "@/components/InlineSaveInputField.vue"

export default Vue.extend({
  components: {
    AlertPhoneNumbers,
    BooleanIcon,
    CollapsibleDataTable,
    ConfirmedButton,
    GenericSelect,
    StringValue,
    NewChargingSiteDialog,
    EditChargingSiteDialog,
    InvoiceSettingsDialog,
    InlineSaveInputField,
    RfidAuthorizedAppUsers,
  },

  data() {
    return {
      loading: true,
      authorizationSettings: [] as Array<string>,
      selectedUser: null as AppUser | null,
      snack: false,
      snackText: "",
      snackColour: "",
      chargingSites: [] as Array<ChargingSite>,
      headers: [
        { text: "ID", width: 0, value: "id", align: "right" },
        { text: "Name", width: 0, value: "name" },
        {
          text: "Optimization Enabled",
          width: 0,
          value: "optimizationEnabled",
          align: "center",
        },
        {
          text: "Optimization Mode",
          value: "mathematicalModel.optimizationMode",
          width: 0,
        },
        {
          text: "Offline Alerts?",
          width: 0,
          value: "alertsWhenAllChargersOffline",
        },
        { text: "Location", width: 0, value: "location" },
        { text: "Grid Operator", width: 0, value: "gridOperator.name" },
        {
          text: "Voltage (Fuse)",
          width: 0,
          value: "fuse_voltage",
          align: "center",
        },
        {
          text: "Voltage (Charger)",
          value: "charger_voltage",
          align: "center",
          width: 0,
        },
        { text: "Fuse", width: 0, value: "mainFuse", align: "center" },
        { text: "Power Market", width: 0, value: "powerMarket" },
        {
          text: "Environment Tags",
          width: 0,
          value: "environmentTags",
          align: "center",
        },
        {
          text: "Authorization Setting",
          width: 0,
          value: "authorizationSetting",
        },
        {
          text: "Tariff Free Peak Load Limit (kw)",
          width: 100,
          value: "tariffFreePeakLoadLimitW",
        },
        {
          text: "Peak Load Tariff Rate (NOK/kw)",
          width: 100,
          value: "peakLoadTariffRatePerKw",
        },
        { text: "Alert Phone Numbers", value: "alertPhoneNumbers" },
        {
          text: "Authorized App Users",
          value: "rfidAuthorizedAppUsers",
          width: 500,
        },
        {
          text: "Actions",
          value: "actions",
          width: 0,
          align: "right",
          sortable: false,
        },
        {
          text: "",
          name: "Menu",
          value: "menu",
          width: 0,
          align: "right",
          sortable: false,
        },
        { text: "", value: "spacing" },
      ],
    }
  },

  watch: {
    $route() {
      this.loadData()
    },
  },

  created() {
    this.loadData()
    this.loadAuthSettings()
  },

  methods: {
    Big,

    async loadData() {
      this.loading = true
      this.selectedUser = null
      const { appUserId } = this.$route.params

      let result
      if (appUserId) {
        const appUserResult = await datasource.appUsers.getAppUser(appUserId)
        if (appUserResult instanceof Error || !appUserResult) {
          console.error(appUserResult)
          return
        }
        this.selectedUser = appUserResult

        result = await datasource.chargers.listChargingSitesForUser(
          this.selectedUser
        )
      } else {
        result = await datasource.chargers.listChargingSites()
      }

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.chargingSites = result
      let mathematicalModelsResult

      if (this.selectedUser) {
        mathematicalModelsResult =
          await datasource.chargers.listChargingSitesMathematicalModelsForUserById(
            this.selectedUser
          )
      } else {
        mathematicalModelsResult =
          await datasource.chargers.listChargingSiteMathematicalModelsById()
      }

      if (mathematicalModelsResult instanceof Error) {
        console.error(mathematicalModelsResult)
        return
      }

      const mathematicalModels = mathematicalModelsResult as Record<
        string,
        ChargingSiteMathematicalModel
      >

      this.chargingSites.forEach((chargingSite) => {
        if (chargingSite.id) {
          chargingSite.mathematicalModel = mathematicalModels[chargingSite.id]
        }
      })
      this.loading = false
    },

    async deleteChargingSite(index: number) {
      const chargingSite = this.chargingSites[index]
      await datasource.chargers.deleteChargingSite(chargingSite)
      this.loadData()
    },

    async runOptimization(chargingSite: ChargingSite) {
      const result = await datasource.charging.optimizeChargingSite(
        chargingSite
      )
      if (result instanceof Error) {
        console.error(result)
      }
    },

    async loadAuthSettings() {
      const result = await datasource.charging.listAuthorizationSettings()

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.authorizationSettings = result
    },

    async updateChargingSite(item: ChargingSite) {
      const result = await datasource.chargers.updateChargingSite(item)

      if (result instanceof Error) {
        this.snackText = "Could not update charging site"
        this.snackColour = "error"
        this.snack = true
        console.error(result)
      } else {
        this.loadData()
      }
    },

    tariffLimitDisplayKw(chargingSite: ChargingSite): string {
      return chargingSite.tariffFreePeakLoadLimitW
        ? Big(chargingSite.tariffFreePeakLoadLimitW).div(1000).toString()
        : ""
    },

    peakLoadTariffRateDisplay(chargingSite: ChargingSite): string {
      return chargingSite.peakLoadTariffRatePerKw
        ? chargingSite.peakLoadTariffRatePerKw.amount
        : ""
    },

    async savePeakLoadTariffRate(
      valueNokPerKw: string,
      chargingSite: ChargingSite
    ) {
      const inputValue = {
        currency: "NOK",
        amount: valueNokPerKw.trim(),
        display: "",
      }

      const result = await datasource.chargers.updateChargingSite({
        ...chargingSite,
        peakLoadTariffRatePerKw: inputValue,
      })

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.loadData()
    },

    async saveTariffLimitW(valueKw: string, chargingSite: ChargingSite) {
      const tariffLimitW = Big(valueKw).times(1000).toNumber()

      const result = await datasource.chargers.updateChargingSite({
        ...chargingSite,
        tariffFreePeakLoadLimitW: tariffLimitW,
      })

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.loadData()
    },

    voltage(input: string) {
      switch (input) {
        case "VOLTAGE_230":
          return "230"
        case "VOLTAGE_400":
          return "400"
        default:
          return input
      }
    },

    geoPoint(input?: GeoPoint): string {
      if (input) {
        return `(${input.latitude}, ${input.longitude})`
      } else {
        return ""
      }
    },

    fuse(fuse?: ChargingSiteMathematicalModelFuse): string {
      if (!fuse) {
        return ""
      }
      return [
        fuse.phase1CapacityAmps,
        fuse.phase2CapacityAmps,
        fuse.phase3CapacityAmps,
      ].join(", ")
    },

    userActionLogUrl(chargingSite: ChargingSite): string {
      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_USER_ACTION_LOG_INDEX,
        {
          filters: [
            `match_phrase:(entity_type.keyword:charging_site)`,
            `match_phrase:(entity_id:${chargingSite.id})`,
          ],
          time: "from:now-7d,to:now",
          columns: [
            "user_type",
            "user_email",
            "action",
            "entity_details",
            "from",
            "to",
          ],
        }
      )
    },
  },
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.value,"sort-by":"id","dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: 'Invoice', params: { id: item.id } }}},[_vm._v(_vm._s(item.name))])]}},{key:"item.chargingSite",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.chargingSite.name))]}},{key:"item.recipient",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.recipient(item)))]}},{key:"item.energyCost",fn:function(ref){
var item = ref.item;
return [_c('MoneyValue',{model:{value:(item.energyCost),callback:function ($$v) {_vm.$set(item, "energyCost", $$v)},expression:"item.energyCost"}})]}},{key:"item.flexibilityFee",fn:function(ref){
var item = ref.item;
return [_c('MoneyValue',{attrs:{"editable":_vm.draft},on:{"save":function($event){return _vm.updateInvoiceLine(item)}},model:{value:(item.flexibilityFee),callback:function ($$v) {_vm.$set(item, "flexibilityFee", $$v)},expression:"item.flexibilityFee"}})]}},{key:"item.flexibilityFeeTax",fn:function(ref){
var item = ref.item;
return [_c('MoneyValue',{model:{value:(item.flexibilityFeeTax),callback:function ($$v) {_vm.$set(item, "flexibilityFeeTax", $$v)},expression:"item.flexibilityFeeTax"}})]}},{key:"item.gridFee",fn:function(ref){
var item = ref.item;
return [_c('MoneyValue',{attrs:{"editable":_vm.draft},on:{"save":function($event){return _vm.updateInvoiceLine(item)}},model:{value:(item.gridFee),callback:function ($$v) {_vm.$set(item, "gridFee", $$v)},expression:"item.gridFee"}})]}},{key:"item.offlineChargingFee",fn:function(ref){
var item = ref.item;
return [_c('MoneyValue',{attrs:{"editable":_vm.draft},on:{"save":function($event){return _vm.updateInvoiceLine(item)}},model:{value:(item.offlineChargingFee),callback:function ($$v) {_vm.$set(item, "offlineChargingFee", $$v)},expression:"item.offlineChargingFee"}})]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.date(item.startDate)))]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.date(item.endDate)))]}},{key:"item.excessEnergyUsageKwh",fn:function(ref){
var item = ref.item;
return [_c('NumberValue',{attrs:{"editable":_vm.draft},on:{"input":function($event){return _vm.updateInvoiceLine(item)}},model:{value:(item.excessEnergyUsageKwh),callback:function ($$v) {_vm.$set(item, "excessEnergyUsageKwh", $$v)},expression:"item.excessEnergyUsageKwh"}})]}},{key:"item.energyUsed",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.fixedFloat(item.endMeterReadingKwh - item.startMeterReadingKwh + item.excessEnergyUsageKwh, 1)))]}},{key:"item.energyRebate",fn:function(ref){
var item = ref.item;
return [_c('MoneyValue',{attrs:{"editable":false},model:{value:(item.energyRebate),callback:function ($$v) {_vm.$set(item, "energyRebate", $$v)},expression:"item.energyRebate"}})]}},{key:"item.tax",fn:function(ref){
var item = ref.item;
return [_c('MoneyValue',{attrs:{"editable":false},model:{value:(item.tax),callback:function ($$v) {_vm.$set(item, "tax", $$v)},expression:"item.tax"}})]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('MoneyValue',{attrs:{"editable":false},model:{value:(item.total),callback:function ($$v) {_vm.$set(item, "total", $$v)},expression:"item.total"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
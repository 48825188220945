import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import datasource from "@/datasource"
import store from "@/store"

import Home from "@/views/Home.vue"
import AdminUsers from "@/views/AdminUsers.vue"
import AdminTasks from "@/views/AdminTasks.vue"
import AppUsers from "@/views/AppUsers.vue"
import WebUsers from "@/views/WebUsers.vue"
import CarDetails from "@/views/Cars/Details.vue"
import CarOverview from "@/views/Cars/Overview.vue"
import Chargers from "@/views/Chargers.vue"
import ChargersDetailedOverview from "@/views/Chargers/DetailedOverview.vue"
import ChargerDetailedSummary from "@/views/Chargers/ChargerDetailedSummary.vue"
import ChargerUsage from "@/views/Chargers/Usage.vue"
import ChargerConnectors from "@/views/ChargerConnectors.vue"
import ChargerConfigurationProfiles from "@/views/ChargerConfigurationProfiles.vue"
import EnergyRebates from "@/views/Invoices/EnergyRebates.vue"
import EmailTemplates from "@/views/Emails/EmailTemplates.vue"
import ActiveChargingSessions from "@/views/ChargingSessions/ActiveChargingSessions.vue"
import HistoricChargingSessions from "@/views/ChargingSessions/HistoricChargingSessions.vue"
import ChargingSite from "@/views/ChargingSite.vue"
import ChargingSites from "@/views/ChargingSites.vue"
import ChargingSiteClusters from "@/views/ChargingSiteClusters.vue"
import Optimization from "@/views/Optimization.vue"
import AmsMeters from "@/views/AmsMeters.vue"
import DynamicSchedules from "@/views/BackgroundJobs/DynamicSchedules.vue"
import JobStatistics from "@/views/BackgroundJobs/JobStatistics.vue"
import Invoices from "@/views/Invoices.vue"
import InvoiceSettings from "@/views/Invoices/SettingsList.vue"
import ChargingPlanTemplates from "@/views/ChargingPlanTemplates.vue"
import ChargingPlanTemplateRuns from "@/views/ChargingPlanTemplates/Runs.vue"
import ChargingSiteInvoiceSettings from "@/views/ChargingSite/InvoiceSettings.vue"
import VariableGridCosts from "@/views/GridCosts/VariableGridCosts.vue"
import GridOperators from "@/views/GridCosts/GridOperators.vue"
import SystemSettingsDisplay from "@/views/SystemSettingsDisplay.vue"
import SystemLinks from "@/views/SystemLinks.vue"
import Fuses from "@/views/Fuses.vue"
import UnregisteredConnections from "@/views/UnregisteredConnections.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/user/:appUserId",
    name: "AppUserHome",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    async beforeEnter(_to, _from, next) {
      const paramsString = document.location.search.substring(1)
      if (paramsString == "") {
        datasource.setAuthTokenUsingRefreshToken()
      } else {
        const params = new URLSearchParams(paramsString)
        const code = params.get("code")
        if (code) {
          if (await datasource.setAuthTokenUsingGrantCode(code)) {
            await datasource.fetchGlobalState()
          }
        }
        if (store.state.loggedIn) {
          next({ name: "Home" })
        } else {
          next(false)
          console.error("Login failure: " + params)
        }
      }
    },
  },
  {
    path: "/admin-users",
    name: "AdminUsers",
    component: AdminUsers,
  },
  {
    path: "/app-users/:appUserId?",
    name: "AppUsers",
    component: AppUsers,
  },
  {
    path: "/web-users",
    name: "WebUsers",
    component: WebUsers,
  },
  {
    path: "/cars/overview",
    name: "CarOverview",
    component: CarOverview,
  },
  {
    path: "/cars/details",
    name: "CarDetails",
    component: CarDetails,
  },
  {
    path: "/cars/details/:carId",
    name: "DetailedCar",
    component: CarDetails,
  },
  {
    path: "/cars/details/user/:appUserId",
    name: "AppUserCars",
    component: CarDetails,
  },
  {
    path: "/chargers/overview",
    name: "Chargers",
    component: Chargers,
  },
  {
    path: "/chargers/detailed-overview",
    name: "ChargersDetailedOverview",
    component: ChargersDetailedOverview,
  },
  {
    path: "/chargers/user/:appUserId",
    name: "AppUserChargers",
    component: Chargers,
  },
  {
    path: "/chargers/usage/:charger?",
    name: "ChargerUsage",
    component: ChargerUsage,
  },
  {
    path: "/chargers/configuration_profiles",
    name: "ChargerConfigurationProfiles",
    component: ChargerConfigurationProfiles,
  },
  {
    path: "/chargers/:chargerId?/connectors",
    name: "ChargerConnectors",
    component: ChargerConnectors,
  },
  {
    path: "/chargers/:chargerId",
    name: "Charger",
    component: Chargers,
  },
  {
    path: "/charger/summary",
    name: "ChargerSummary",
    component: ChargerDetailedSummary,
  },
  {
    path: "/chargers/:chargerId/charging_sessions",
    name: "ChargerChargingSessions",
    component: ActiveChargingSessions,
  },
  {
    path: "/charging_sessions/historic",
    name: "HistoricChargingSessions",
    component: HistoricChargingSessions,
  },
  {
    path: "/charging_sessions/active",
    name: "ActiveChargingSessions",
    component: ActiveChargingSessions,
  },
  {
    path: "/charging-sites",
    name: "ChargingSites",
    component: ChargingSites,
  },
  {
    path: "/charging-site/:id",
    name: "ChargingSite",
    component: ChargingSite,
  },
  {
    path: "/charging-sites/user/:appUserId",
    name: "AppUserChargingSites",
    component: ChargingSites,
  },
  {
    path: "/charging-site/:id/invoice-settings",
    name: "ChargingSiteInvoiceSettings",
    component: ChargingSiteInvoiceSettings,
  },
  {
    path: "/charging-site-clusters",
    name: "ChargingSiteClusters",
    component: ChargingSiteClusters,
  },
  {
    path: "/fuses",
    name: "Fuses",
    component: Fuses,
  },
  {
    path: "/optimization",
    name: "Optimization",
    component: Optimization,
  },
  {
    path: "/ams-meters",
    name: "AMS Meters",
    component: AmsMeters,
  },
  {
    path: "/background-jobs/dynamic-schedules",
    name: "Dynamic Schedules",
    component: DynamicSchedules,
  },
  {
    path: "/background-jobs/job-statistics",
    name: "Job Statistics",
    component: JobStatistics,
  },
  {
    path: "/invoices/:state/app-user/:appUserId",
    name: "AppUserInvoices",
    component: Invoices,
  },
  {
    path: "/invoice-settings",
    name: "InvoiceSettings",
    component: InvoiceSettings,
  },
  {
    path: "/invoices/energy-rebates",
    name: "EnergyRebates",
    component: EnergyRebates,
  },
  {
    path: "/invoices/email-templates",
    name: "EmailTemplates",
    component: EmailTemplates,
  },
  {
    path: "/invoices/:state",
    name: "Invoices",
    component: Invoices,
  },
  {
    path: "/grid-costs/variable-grid-costs",
    name: "VariableGridCosts",
    component: VariableGridCosts,
  },
  {
    path: "/grid-costs/grid-operators",
    name: "GridOperators",
    component: GridOperators,
  },
  {
    path: "/charging/plan-templates",
    name: "ChargingPlanTemplates",
    component: ChargingPlanTemplates,
  },
  {
    path: "/charging/plan-templates/runs",
    name: "ChargingPlanTemplateRuns",
    component: ChargingPlanTemplateRuns,
  },
  {
    path: "/system/settings",
    name: "SystemSettingsDisplay",
    component: SystemSettingsDisplay,
  },
  {
    path: "/system/links",
    name: "SystemLinks",
    component: SystemLinks,
  },
  {
    path: "/system/adminTasks",
    name: "AdminTasks",
    component: AdminTasks,
  },
  {
    path: "/unregistered-connections",
    name: "UnregisteredWebsocketConnections",
    component: UnregisteredConnections,
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, _from, next) => {
  if (store.state.loggedIn) {
    next()
  } else {
    if (to.name == "Login") {
      next()
    } else {
      next({ name: "Login" })
    }
  }
})

export default router

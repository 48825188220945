var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"flex-fh flex-col flex-grow flex-clip",attrs:{"fluid":""}},[_c('VCard',{staticClass:"flex-grow flex-col flex-clip",attrs:{"loading":_vm.loading}},[_c('VCardTitle',[_c('span',[_vm._v("App Users")]),_c('VSpacer'),_c('NewAppUserDialog',{on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"white--text mr-1",attrs:{"icon":"","color":"green"}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-plus")])],1)]}}])}),_c('VBtn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.loadData()}}},[_c('VIcon',[_vm._v("mdi-refresh")])],1)],1),_c('VCardSubtitle',[(_vm.selectedUser)?[_vm._v("User: "+_vm._s(_vm.selectedUser.displayIdentifier))]:_vm._e(),_c('VContainer',{staticClass:"flex-grow-0",attrs:{"fluid":""}},[_c('VTextField',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","label":"Filter: Identifier or Name","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],2),_c('VCardText',{staticClass:"flex-grow flex-clip"},[(_vm.loading && this.appUsers.length == 0)?[_c('VContainer',{staticClass:"flex-col",attrs:{"fluid":""}},_vm._l((5),function(index){return _c('VSkeletonLoader',{key:index,attrs:{"type":"table-row"}})}),1)]:[_c('VContainer',{staticClass:"flex-grow flex-col",attrs:{"fluid":""}},[_c('VContainer',{attrs:{"fluid":""}},[(_vm.selected.length)?_c('VMenu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"d-inline-flex mx-2"},'VBtn',attrs,false),on),[_vm._v("Edit Selected ("+_vm._s(_vm.selected.length)+")")])]}}],null,false,3656617409)},[_c('VList',[_c('VListItem',_vm._g({directives:[{name:"attrs",rawName:"v-attrs",value:(_vm.attrs),expression:"attrs"}]},_vm.on),[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-currency-usd-off")])],1),_c('VDialog',{attrs:{"width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItemContent',_vm._g(_vm._b({},'VListItemContent',attrs,false),on),[_vm._v("Set Free Charging")])]}}],null,false,2100520182),model:{value:(_vm.showFreeChargingDialog),callback:function ($$v) {_vm.showFreeChargingDialog=$$v},expression:"showFreeChargingDialog"}},[_c('VCard',[_c('VCardTitle',[_vm._v("Set Free Charging")]),_c('VCardText',[_c('VSwitch',{model:{value:(_vm.bulkSetFreeChargingState),callback:function ($$v) {_vm.bulkSetFreeChargingState=$$v},expression:"bulkSetFreeChargingState"}}),_c('VBtn',{on:{"click":function($event){return _vm.bulkSetFreeCharging()}}},[_vm._v("Apply To Selected ("+_vm._s(_vm.selected.length)+")")])],1)],1)],1)],1)],1)],1):_vm._e()],1),_c('CollapsibleDataTable',{staticClass:"flex-grow overflow-auto",attrs:{"headers":_vm.headers,"items":_vm.appUsers,"search":_vm.search,"footer-props":{ 'items-per-page-options': [30, 60, -1] },"sort-by":"displayIdentifier","namespace":"appusers","fixed-header":"","show-select":"","dense":""},scopedSlots:_vm._u([{key:"item.emailIsVerified",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.emailIsVerified}})]}},{key:"item.subscriptionStartDate",fn:function(ref){
var item = ref.item;
return [_c('InlineDateInput',{on:{"saved":function (start) { return _vm.saveSubscriptionStart(start, item); },"clear":function($event){return _vm.clearSubscriptionStartDate(item)}},model:{value:(item.subscriptionStartDate),callback:function ($$v) {_vm.$set(item, "subscriptionStartDate", $$v)},expression:"item.subscriptionStartDate"}})]}},{key:"item.subscriptionEndDate",fn:function(ref){
var item = ref.item;
return [_c('InlineDateInput',{on:{"saved":function (end) { return _vm.saveSubscriptionEnd(end, item); },"clear":function($event){return _vm.clearSubscriptionEndDate(item)}},model:{value:(item.subscriptionEndDate),callback:function ($$v) {_vm.$set(item, "subscriptionEndDate", $$v)},expression:"item.subscriptionEndDate"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.name(item)))]}},{key:"item.carsCount",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: 'AppUserCars', params: { appUserId: item.id } }}},[_vm._v(_vm._s(item.carsCount))])]}},{key:"item.chargersCount",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: 'AppUserChargers', params: { appUserId: item.id } }}},[_vm._v(_vm._s(item.chargersCount))])]}},{key:"item.chargingSiteCount",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{ name: 'AppUserChargingSites', params: { appUserId: item.id } }}},[_vm._v(_vm._s(_vm.getChargingSiteCountFromAppUser(item)))])]}},{key:"item.optimizationChargingSiteCount",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('RouterLink',{attrs:{"to":{ name: 'AppUserHome', params: { appUserId: item.id } }}},[_vm._v(_vm._s(_vm.getChargingSiteCountFromAppUser(item)))])],1)]}}],null,true)},[_vm._v("See optimizations for this AppUser")])]}},{key:"item.links",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('span',[_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('RouterLink',{staticClass:"pr-2 no-underline",attrs:{"to":{ name: 'HistoricChargingSessions', query: { ocppIds: _vm.ocppIdsStringFromUser(item), from: _vm.weeksAgo(2) } }}},[_c('VBtn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-lightning-bolt")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("See historic charging sessions for this user's chargers")])])],1),_c('span',[_c('VTooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('RouterLink',{staticClass:"pr-2 no-underline",attrs:{"to":{ name: 'HistoricChargingSessions', query: { siteIds: _vm.siteIdsStringFromUser(item), from: _vm.weeksAgo(2) } }}},[_c('VBtn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-map-marker")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("See historic charging sessions for this user's charging sites")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('RouterLink',{staticClass:"pr-2 no-underline",attrs:{"to":{ name: 'ChargerUsage', query: { appUser: item.id } }}},[_c('VBtn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-chart-box-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Compare usage for this user's chargers")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('RouterLink',{staticClass:"pr-2 no-underline",attrs:{"to":{ name: 'AppUserInvoices', params: { state: 'paid', appUserId: item.id } }}},[_c('VBtn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-receipt-text-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("See this user's invoices")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openRfidAuthAttempts(item)}}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-credit-card-wireless")])],1)]}}],null,true)},[_c('span',[_vm._v("Show RFID authentication attempts")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"small":"","icon":"","href":_vm.userActionLogUrl(item),"target":"blank"}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-playlist-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Show User action logs")])])],1)])]}},{key:"item.unknownCarBatterySizeWh",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.powerWToKw(item.unknownCarBatterySizeWh)))]}},{key:"item.smartChargingEnabled",fn:function(ref){
var item = ref.item;
return [(item.carsCount > 0 || item.chargersCount > 0)?[_c('BooleanIcon',{attrs:{"value":item.smartChargingEnabled}})]:_vm._e()]}},{key:"item.rfidTokens",fn:function(ref){
var item = ref.item;
return [_c('RfidTokens',{attrs:{"appUser":item},model:{value:(item.rfidTokens),callback:function ($$v) {_vm.$set(item, "rfidTokens", $$v)},expression:"item.rfidTokens"}})]}},{key:"item.foobar",fn:function(ref){
var item = ref.item;
return [_c('VCombobox',{attrs:{"multiple":"","chips":""},model:{value:(_vm.dummyCarIds),callback:function ($$v) {_vm.dummyCarIds=$$v},expression:"dummyCarIds"}})]}},{key:"item.unhealthyChargingSessionAlertsEnabled",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.unhealthyChargingSessionAlertsEnabled}})]}},{key:"item.freeCharging",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.freeCharging}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('EditAppUserDialog',{attrs:{"app-user":item},on:{"saved":function($event){return _vm.loadData()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-pen")])],1)]}}],null,true)})]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
return [_c('VMenu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('VList',[_c('LoginQrCodeDialog',{attrs:{"appUser":item},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItem',_vm._g(_vm._b({},'VListItem',attrs,false),on),[_vm._v("Login QR Code...")])]}}],null,true)}),_c('PasswordResetDialog',{attrs:{"appUser":item},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItem',_vm._g(_vm._b({},'VListItem',attrs,false),on),[_vm._v("Reset Password...")])]}}],null,true)}),(!item.emailIsVerified)?_c('ResendVerificationDialog',{attrs:{"appUser":item},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VListItem',_vm._g(_vm._b({},'VListItem',attrs,false),on),[_vm._v("Resend Email Verification...")])]}}],null,true)}):_vm._e(),_c('VListItem',{on:{"click":function($event){return _vm.deleteAppUser(item)}}},[_c('VListItemContent',{staticClass:"red--text"},[_vm._v("Delete")])],1)],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]],2),_c('VSnackbar',{attrs:{"timeout":3000,"color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VBtn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'VBtn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
























































import Vue from "vue"
import datasource from "@/datasource"
import type { LogEntry, Reports } from "@/datasource/machineLearning"

import { SelfBuildingSquareSpinner } from "epic-spinners"

export default Vue.extend({
  components: {
    SelfBuildingSquareSpinner,
  },

  data() {
    return {
      tab: null,
      reports: {} as Reports,
      reportIds: [] as string[],
    }
  },

  computed: {
    hasReports(): boolean {
      return this.reportIds.length > 0 && Object.keys(this.reports).length > 0
    },

    allowedReports(): Reports {
      return Object.keys(this.reports)
        .filter((key) => this.reportIds.includes(key))
        .reduce(
          (res, key) => ((res[key] = this.reports[key]), res),
          {} as Record<string, string[]>
        )
    },
  },

  mounted() {
    this.streamLogs()
  },

  methods: {
    async streamLogs() {
      const withCallback = (entry: LogEntry) => {
        if (!this.reports[entry.runId]) {
          Vue.set(this.reports, entry.runId, [])
        }

        this.reports[entry.runId].push(entry.data)
      }

      datasource.machineLearning.waitForNextLogEntry(withCallback)
    },
  },
})

















import Vue from "vue"

export default Vue.extend({
  props: {
    input: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      edited: false,
      modifiedInput: this.input,
    }
  },

  methods: {
    save() {
      this.$emit("saved", this.modifiedInput)
      this.edited = false
    },

    markEdited() {
      this.edited = true
    },
  },
})



































import Vue from "vue"
import datasource from "@/datasource"

function initialData() {
  return {
    showDialog: false,
    saving: false,
    email: "",
  }
}

export default Vue.extend({
  data: initialData,

  methods: {
    subtitleText(): string {
      return "Creates a new user with a strong initial password, then sends a password reset email so they can set their own"
    },

    close() {
      Object.assign(this.$data, initialData())
    },

    async save() {
      this.saving = true
      const result = await datasource.webUsers.signUpWebUser({
        email: this.email,
      })

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.saving = false
      this.$emit("saved")
      this.close()
    },
  },
})

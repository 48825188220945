var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',[_c('CollapsibleDataTable',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.rows,"namespace":"summary-active-sessions","fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.started",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('div',[_vm._v(_vm._s(item.started))])])]}},{key:"item.isSmart",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.isSmart}})]}},{key:"item.isHealthy",fn:function(ref){
var item = ref.item;
return [_c('BooleanIcon',{attrs:{"value":item.isHealthy}})]}},{key:"item.authSource",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.authSourceDisplay(item.session)))]),_c('div',[_vm._v(_vm._s(_vm.displayRfidToken(item.session)))])]}},{key:"item.authdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.authdAt))])]}},{key:"item.ocppTransactionIds",fn:function(ref){
var item = ref.item;
return _vm._l((item.ocppTransactionIds),function(transactionId){return _c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(transactionId))])})}},{key:"item.vehicleName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.vehicleName))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
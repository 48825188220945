


























































































































































import Vue from "vue"
import _ from "underscore"
import datasource from "@/datasource"

import BooleanIcon from "@/components/BooleanIcon.vue"
import ChargingSiteSelect from "@/components/ChargingSiteSelect.vue"
import ConfirmedButton from "@/components/ConfirmedButton.vue"

import type { ChargingSiteCluster, ChargingSite } from "@/datasource/chargers"

export default Vue.extend({
  components: { BooleanIcon, ChargingSiteSelect, ConfirmedButton },
  data() {
    return {
      loading: true,
      showCreateDialog: false,
      showEditDialog: false,
      savingChargingSiteCluster: false,
      chargingSiteClusters: [] as ChargingSiteCluster[],
      newClusterSites: [] as ChargingSite[],
      newClusterCurrentLimiting: false,
      newClusterName: "",
      newClusterCurrentThresholdPhase1: null as null | string,
      newClusterCurrentThresholdPhase2: null as null | string,
      newClusterCurrentThresholdPhase3: null as null | string,
      modifiedChargingSiteCluster: {} as
        | Record<string, never>
        | ChargingSiteCluster,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        {
          text: "Instantaneous Output Power Kw",
          value: "instantaneousOutputPowerW",
          align: "center",
        },
        {
          text: "Instantaneous Output Current Phases A",
          value: "instantaneousOutputCurrentPhasesA",
          align: "center",
        },
        {
          text: "Auto limit high current",
          value: "autoCurrentLimitingEnabled",
          align: "center",
        },
        {
          text: "Output Current Warning Thresholds A",
          value: "outputCurrentWarningThresholdsA",
          align: "center",
        },
        { text: "Charging Sites", value: "chargingSites" },
        { value: "actions", sortable: false },
      ],
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true
      const result = await datasource.chargers.listChargingSiteClusters()

      if (result instanceof Error) {
        console.error(result)
        return
      } else {
        this.chargingSiteClusters = result
        this.loading = false
      }
    },

    async saveChargingSiteCluster() {
      const chargingSiteClusterInput = {
        chargingSiteIds: _.filter(
          this.newClusterSites.map((s) => s.id),
          (id) => !!id
        ) as string[],
        name: this.newClusterName,
        autoCurrentLimitingEnabled: this.newClusterCurrentLimiting,
        phaseOneCurrentWarningThresholdA:
          this.newClusterCurrentThresholdPhase1 &&
          parseInt(this.newClusterCurrentThresholdPhase1),
        phaseTwoCurrentWarningThresholdA:
          this.newClusterCurrentThresholdPhase2 &&
          parseInt(this.newClusterCurrentThresholdPhase2),
        phaseThreeCurrentWarningThresholdA:
          this.newClusterCurrentThresholdPhase3 &&
          parseInt(this.newClusterCurrentThresholdPhase3),
      }

      const result = await datasource.chargers.createChargingSiteCluster(
        chargingSiteClusterInput
      )

      if (result instanceof Error) {
        console.error(result)
      } else {
        this.closeCreateDialog()
        this.newClusterCurrentLimiting = false
        this.newClusterSites = []
        this.newClusterName = ""
        this.newClusterCurrentThresholdPhase1 = null
        this.newClusterCurrentThresholdPhase2 = null
        this.newClusterCurrentThresholdPhase3 = null
        this.loadData()
      }
    },

    async updateChargingSiteCluster() {
      const result = await datasource.chargers.updateChargingSiteCluster(
        this.modifiedChargingSiteCluster as ChargingSiteCluster
      )

      if (result instanceof Error) {
        console.error(result)
      } else {
        this.closeEditDialog()
        this.modifiedChargingSiteCluster = {}
        this.loadData()
      }
    },

    async deleteChargingSiteCluster() {
      const result = await datasource.chargers.deleteChargingSiteCluster(
        this.modifiedChargingSiteCluster as ChargingSiteCluster
      )

      if (result instanceof Error) {
        console.error(result)
      } else {
        this.closeEditDialog()
        this.modifiedChargingSiteCluster = {}
        this.loadData()
      }
    },

    openCreateDialog() {
      this.showCreateDialog = true
    },

    closeCreateDialog() {
      this.showCreateDialog = false
      this.newClusterSites = []
    },

    openEditDialog(chargingSiteCluster: ChargingSiteCluster) {
      this.modifiedChargingSiteCluster = { ...chargingSiteCluster }
      this.showEditDialog = true
    },

    closeEditDialog() {
      this.modifiedChargingSiteCluster = {}
      this.showEditDialog = false
    },

    outputCurrentWarningDisplayString(cluster: ChargingSiteCluster): string {
      const phaseThresholds = [
        cluster.phaseOneCurrentWarningThresholdA,
        cluster.phaseTwoCurrentWarningThresholdA,
        cluster.phaseThreeCurrentWarningThresholdA,
      ].map((x) => x || "")

      return phaseThresholds.some((p) => p) ? phaseThresholds.join(", ") : ""
    },

    chargingSitesDisplay(chargingSites: ChargingSite[]): string {
      const display = chargingSites.map((c) => c.name).join(", ")
      return display.length < 35 ? display : `${display.slice(0, 35)}...`
    },
  },
})

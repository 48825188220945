




































































































import Vue from "vue"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import type { InvoiceSettings } from "@/datasource/payments"

import InvoiceSettingsDialog from "./InvoiceSettingsDialog.vue"
import MoneyValue from "@/components/MoneyValue.vue"

export default Vue.extend({
  components: {
    InvoiceSettingsDialog,
    MoneyValue,
  },

  data() {
    return {
      loading: true,
      showPaid: true,
      snack: false,
      snackText: "",
      snackColour: "",
      search: "",
      invoiceSettings: [] as InvoiceSettings[],
      headers: [
        { text: "ID", width: 0, value: "id" },
        { text: "Charging Site", value: "chargingSite" },
        { text: "Active From", value: "activeFrom" },
        { text: "Type", value: "type" },
        { text: "Fee (kWh)", value: "chargingSiteFeeKwh", align: "right" },
        { text: "Schedule", value: "schedule" },
        { text: "Day of Schedule", value: "dayOfSchedule" },
        { text: "Payment Grace Period", value: "gracePeriodDays" },
        { text: "Tax", value: "taxPercentage" },
        {
          text: "Grid Fee/month",
          value: "gridFee",
          align: "right",
        },
        {
          text: "Fixed Flexiblity Fee",
          value: "fixedFlexibilityFee",
          align: "right",
        },
        {
          text: "Percentage Flexiblity Fee",
          value: "percentageFlexibilityFee",
          align: "right",
        },
        {
          text: "Fixed Processing Fee",
          value: "fixedProcessingFee",
          align: "right",
        },
        {
          text: "Percentage Processing Fee",
          value: "percentageProcessingFee",
          align: "right",
        },
        {
          text: "Excess Energy Fee/kWh",
          value: "excessEnergyFeeKwh",
          align: "right",
        },
        {
          text: "Use Rebate",
          value: "useRebate",
        },
        {
          text: "Data Source",
          value: "dataSource",
        },
        {
          text: "Tax System",
          value: "taxSystem",
        },
        {
          text: "Display Tax",
          value: "dummyTax",
        },
        { text: "", value: "menu", width: 0, align: "right", sortable: false },
      ],
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    ...formatters.amount,
    ...formatters.dateTime,
    ...formatters.generic,

    async loadData() {
      this.loading = true

      const result = await datasource.payments.listInvoiceSettings()
      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.invoiceSettings = result

      this.loading = false
    },

    async updateInvoiceSettings(settings: InvoiceSettings) {
      const result = await datasource.payments.setInvoiceSettings(
        settings,
        true
      )

      this.snack = true
      this.loadData()

      if (result instanceof Error || !result) {
        console.error(result)
        this.snackText = "Error updating invoice settings"
        this.snackColour = "error"
        return
      }

      this.snackText = "Invoice settings updated"
      this.snackColour = "success"
    },
  },
})











import Vue, { PropType } from "vue"
import type { AppUser } from "@/datasource/appUsers"

export default Vue.extend({
  props: {
    value: {
      type: String,
      required: true,
    },
    currentState: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    selectedUser: {
      type: Object as PropType<AppUser>,
      default: null,
    },
  },

  computed: {
    linkParams(): Record<string, unknown> {
      if (this.selectedUser) {
        return {
          name: "AppUserInvoices",
          params: { state: this.value, appUserId: this.selectedUser.id },
          query: this.$route.query,
        }
      } else {
        return {
          name: "Invoices",
          params: { state: this.value },
          query: this.$route.query,
        }
      }
    },
  },
})

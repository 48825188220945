














































import Vue, { PropType } from "vue"
import datasource from "@/datasource"
import type { ValidationError } from "@/datasource"
import type { Car } from "@/datasource/cars"

function initialData() {
  return {
    showDialog: false,
    loading: true,
    saving: false,
    savingFailed: false,
    newPercentage: 0 as number,
    errors: {} as ValidationError,
    rules: {
      percentage: (value: number) =>
        (value >= 0 && value <= 100) || "Not valid",
    },
  }
}

export default Vue.extend({
  props: {
    cars: {
      required: true,
      type: [] as PropType<Array<Car>>,
    },
  },

  data: initialData,

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    close() {
      Object.assign(this.$data, initialData())
    },

    async loadData() {
      this.loading = false
    },

    async save() {
      this.saving = true
      this.savingFailed = false

      const result = await datasource.cars.setPercentageEnergyForecast(
        this.cars,
        Number(this.newPercentage)
      )

      if (result instanceof Error) {
        console.error(result)
        this.savingFailed = true
        this.saving = false
      } else {
        this.saving = false
        this.$emit("saved")
        this.close()
      }
    },
  },
})

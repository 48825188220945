

















































































































import Vue from "vue"
import datasource from "@/datasource"
import formatters from "@/utils/formatters"
import BooleanIcon from "@/components/BooleanIcon.vue"
import helpers from "@/utils/helpers"

import type {
  Charger,
  ChargerMathematicalModelConnector,
} from "@/datasource/chargers"

import EditChargerConnectors from "./ChargerConnectors/EditChargerConnectors.vue"

type DataRow = {
  rowIndex: number
  charger: Charger
  connector: ChargerMathematicalModelConnector
  modifiedConnector: {
    modified: boolean
    phaseOrder: string
    phase1MaxPowerkW: number
    phase2MaxPowerkW: number
    phase3MaxPowerkW: number
    id: number
    longitude: number | null
    latitude: number | null
  }
}

const rawHeaders = [
  {
    text: "Charger Name",
    value: "charger.displayName",
    isChargerHeader: true,
    width: 0,
  },
  { text: "Connector", value: "connector.id", align: "right", width: 0 },
  {
    text: "Connected?",
    value: "connector.isConnected",
    align: "center",
    width: 0,
  },
  { text: "Locked?", value: "connector.isLocked", align: "center", width: 0 },
  {
    text: "Charging?",
    value: "connector.isCharging",
    align: "center",
    width: 0,
  },
  { text: "Phase Order", value: "phaseOrder", align: "center", width: 0 },
  { text: "Max kW (L1)", value: "phase1MaxPower", align: "right", width: 70 },
  { text: "Max kW (L2)", value: "phase2MaxPower", align: "right", width: 70 },
  { text: "Max kW (L3)", value: "phase3MaxPower", align: "right", width: 70 },
  { text: "Geolocation (Lat/Lng)", value: "geolocation", width: 500 },
  { text: "", value: "logLink" },
  {
    text: "",
    value: "update",
    width: 0,
  },
  { text: "", value: "spacing" },
]

export default Vue.extend({
  components: {
    BooleanIcon,
    EditChargerConnectors,
  },

  data() {
    return {
      loading: true,
      charger: null as Charger | null,
      dataRows: [] as DataRow[],
      selected: [] as DataRow[],
    }
  },

  computed: {
    headers(): Record<string, unknown>[] {
      return rawHeaders.filter(
        (header) => !(this.charger && header.isChargerHeader)
      )
    },
  },

  watch: {
    $route() {
      this.loadData()
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    ...formatters.charging,

    async loadData() {
      this.loading = true

      const { chargerId } = this.$route.params
      let chargers: Charger[] = []
      if (chargerId) {
        const result = await datasource.chargers.getCharger(chargerId)
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.charger = result
        if (this.charger) {
          chargers = [this.charger]
        }
      } else {
        this.charger = null
        const result = await datasource.chargers.listChargers()
        if (result instanceof Error) {
          console.error(result)
          return
        }
        chargers = result
      }

      let rowId = 0

      this.dataRows = chargers.flatMap((charger) =>
        charger.mathematicalModel.connectors.map((connector, index) => {
          rowId += 1

          const apiData = charger.apiData?.connectors.find(
            (connector) => connector.order == index + 1
          )
          return {
            rowIndex: rowId,
            charger,
            connector: {
              ...connector,
              ...apiData,
              id: index + 1,
            },
            modifiedConnector: this.initialModifiedConnectorFromConnector(
              connector,
              index + 1
            ),
          }
        })
      )

      this.loading = false
    },

    initialModifiedConnectorFromConnector(
      connector: ChargerMathematicalModelConnector,
      order: number
    ) {
      return {
        modified: false,
        phaseOrder: connector.phaseOrder,
        phase1MaxPowerkW: parseFloat(
          (connector.phase1MaxPowerW / 1000).toFixed(1)
        ),
        phase2MaxPowerkW: parseFloat(
          (connector.phase2MaxPowerW / 1000).toFixed(1)
        ),
        phase3MaxPowerkW: parseFloat(
          (connector.phase3MaxPowerW / 1000).toFixed(1)
        ),
        longitude: connector.longitude,
        latitude: connector.latitude,
        id: order,
      }
    },

    geolocationDisplay(dataRow: DataRow): string {
      if (dataRow.connector?.latitude && dataRow.connector?.longitude) {
        return `${dataRow.connector.latitude}, ${dataRow.connector.longitude}`
      } else {
        return ""
      }
    },

    setRowModified(row: DataRow) {
      const index = this.dataRows.indexOf(row)

      Vue.set(this.dataRows, index, {
        ...row,
        modifiedConnector: { ...row.modifiedConnector, modified: true },
      })
    },

    async saveRow(row: DataRow) {
      const charger: Charger = row.charger
      const initModel = charger.mathematicalModel
      const order = row.modifiedConnector.id

      const newConnector = {
        phaseOrder: `${row.modifiedConnector.phaseOrder.replaceAll("-", "_")}`,
        phase1MaxPowerW: row.modifiedConnector.phase1MaxPowerkW * 1000,
        phase2MaxPowerW: row.modifiedConnector.phase2MaxPowerkW * 1000,
        phase3MaxPowerW: row.modifiedConnector.phase3MaxPowerkW * 1000,
        longitude: row.modifiedConnector.longitude || null,
        latitude: row.modifiedConnector.latitude || null,
      }

      const newConnectors = [...charger.mathematicalModel.connectors]
      // eslint-disable-next-line
      // @ts-ignore
      newConnectors.splice(order - 1, 1, newConnector)

      const result = await datasource.chargers.setMathematicalModelForCharger(
        charger,
        {
          ...initModel,
          connectors: newConnectors,
        }
      )

      if (result instanceof Error) {
        console.error(result)
        return
      }

      this.loadData()
    },

    restoreRow(row: DataRow) {
      const index = this.dataRows.indexOf(row)

      Vue.set(this.dataRows, index, {
        ...row,
        modifiedConnector: this.initialModifiedConnectorFromConnector(
          row.connector,
          row.modifiedConnector.id
        ),
      })
    },

    userActionLogUrl(row: DataRow): string {
      return helpers.kibanaDiscoverUrl(
        process.env.VUE_APP_ELASTICSEARCH_USER_ACTION_LOG_INDEX,
        {
          filters: [
            `match_phrase:(entity_type.keyword:charger_mathematical_model)`,
            `match_phrase:(entity_id:${row.charger.mathematicalModel.id})`,
          ],
          time: "from:now-7d,to:now",
          columns: [
            "user_type",
            "user_email",
            "action",
            "entity_details",
            "entity_type",
            "entity_id",
          ],
        }
      )
    },
  },
})

import Vue from "vue"
import _ from "underscore"
import { DateTime, Interval } from "luxon"

function focusSelect(vue: Vue, ref: string): void {
  // We have to use `nextTick` as the select is conditionally rendered
  // (so doesn't exist in `$refs` in this tick).
  vue.$nextTick(function () {
    const select = vue.$refs[ref]

    if (select && typeof select === "object") {
      // No typescript, this really is fine
      const typedSelect = select as Vue & {
        focus: () => boolean
        activateMenu: () => boolean
      }
      typedSelect.focus()
      typedSelect.activateMenu()
    }
  })
}

function* hoursInInterval(
  interval: Interval
): Generator<DateTime, void, undefined> {
  let cursor = interval.start.startOf("hour")
  while (cursor < interval.end) {
    yield cursor
    cursor = cursor.plus({ hours: 1 })
  }
}

function kibanaDiscoverUrl(
  index?: string,
  options?: {
    filters?: string[]
    time?: string
    query?: string
    columns?: string[]
  }
): string {
  if (!index) {
    return ""
  }

  const timeParam = options?.time ? `time:(${options.time})` : ""
  const indexParam = `index:'${index}'`
  const filterParams = (options?.filters || []).map(
    (query) => `(query:(${query}))`
  )
  const filtersParam = `
    filters:!(${filterParams.join(",")})
  `
  const queryParam = options?.query
    ? `query:(language:kuery,query:'${encodeURIComponent(options.query)}')`
    : ""
  const columnsParam = options?.columns
    ? `columns:!(${options.columns.join(",")})`
    : ""
  const aParam = [indexParam, filtersParam, queryParam, columnsParam]
    .filter((x) => x)
    .join(",")

  return `
    ${process.env.VUE_APP_KIBANA_BASE_URL}
    /app/discover#/
    ?_g=(${timeParam})
    &_a=(${aParam})
  `.replaceAll(/\s+/g, "")
}

function kibanaDashboardUrl(
  id?: string,
  options?: {
    filters?: string[]
    time?: string
  }
): string {
  if (!id) {
    return ""
  }

  const timeParam = options?.time
    ? `time:(${options.time})`
    : "time:(from:now-4h,to:now)"
  const filterParams = (options?.filters || []).map(
    (query) => `(query:(${query}))`
  )
  const filtersParam = `
    filters:!(${filterParams.join(",")})
  `
  return `
    ${process.env.VUE_APP_KIBANA_BASE_URL}
    /app/dashboards#/view/${id}
    ?_g=(${filtersParam},${timeParam})
  `.replaceAll(/\s+/g, "")
}

function bulkAmqpDashboardUrl(
  ocppIds: Array<string>,
  timestamps?: { fromDate: Date; toDate: Date }
): string {
  const timeOption = timestamps
    ? `from:'${timestamps.fromDate.toISOString()}',to:'${timestamps.toDate.toISOString()}'`
    : undefined

  const brand = ocppIds[0].charAt(0).toLowerCase() == "e" ? "easee" : "zaptec"

  const matchPhrases = _.map(ocppIds, (ocppId) => {
    const ocppIdString = brand == "easee" ? ocppId : ocppId.toUpperCase()
    return `(match_phrase:(ocpp_identifier.keyword:${ocppIdString}))`
  })
  const query = `bool:(minimum_should_match:1,should:!(${matchPhrases.join(
    ","
  )}))`

  switch (brand) {
    case "easee":
      return kibanaDashboardUrl("829ba060-415b-11ed-acd1-a987bcb00336", {
        filters: [query],
        time: timeOption,
      })

    case "zaptec":
      return kibanaDashboardUrl("e7d6b8a0-6c91-11ed-acd1-a987bcb00336", {
        filters: [query],
        time: timeOption,
      })

    default:
      return ""
  }
}

function amqpDashboardUrl(
  ocppId: string,
  timestamps?: { fromDate: Date; toDate: Date }
): string {
  const timeOption = timestamps
    ? `from:'${timestamps.fromDate.toISOString()}',to:'${timestamps.toDate.toISOString()}'`
    : undefined

  switch (ocppId.charAt(0).toLowerCase()) {
    case "e":
      return kibanaDashboardUrl("829ba060-415b-11ed-acd1-a987bcb00336", {
        filters: [`match_phrase:(ocpp_identifier.keyword:${ocppId})`],
        time: timeOption,
      })

    case "z":
      return kibanaDashboardUrl("e7d6b8a0-6c91-11ed-acd1-a987bcb00336", {
        filters: [
          `match_phrase:(ocpp_identifier.keyword:${ocppId?.toUpperCase()})`,
        ],
        time: timeOption,
      })

    default:
      return ""
  }
}

function rejectEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined
}

export default {
  amqpDashboardUrl,
  bulkAmqpDashboardUrl,
  focusSelect,
  hoursInInterval,
  kibanaDiscoverUrl,
  kibanaDashboardUrl,
  rejectEmpty,
}























import Vue from "vue"
import type { PropType } from "vue"
import type { Charger, TimelineDatapoint } from "@/datasource/chargers"
import datasource from "@/datasource"
import ChargerLink from "@/components/ChargerLink.vue"

export default Vue.extend({
  components: {
    ChargerLink,
  },
  props: {
    charger: {
      type: Object as PropType<Charger>,
      required: true,
    },
    fromDate: {
      type: Date as PropType<Date>,
      required: true,
    },
    toDate: {
      type: Date as PropType<Date>,
      required: true,
    },
    stepSizeH: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      datapoints: [] as TimelineDatapoint[],
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true

      const result = await datasource.chargers.getChargingTimelineForCharger(
        this.charger,
        this.fromDate,
        this.toDate,
        this.stepSizeH * 60 * 60
      )
      if (result instanceof Error) {
        console.error(result)
        return
      } else {
        this.datapoints = result
        this.$emit("loaded", { key: this.charger.id, result: result })
      }

      this.loading = false
    },

    displayValue(datapoint: TimelineDatapoint): string {
      return datapoint.value > 0
        ? (datapoint.value / 1000).toFixed(1).toString()
        : ""
    },
  },
})

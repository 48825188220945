
















import Vue from "vue"

export default Vue.extend({
  model: {
    prop: "inputValue",
    event: "change",
  },

  props: {
    inputValue: {
      type: Boolean,
      default: false,
    },

    filled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    color(): string | undefined {
      if (this.filled) {
        return "grey lighten-3"
      }
      return undefined
    },
  },

  methods: {
    toggle() {
      this.$emit("change", !this.inputValue)
    },
  },
})

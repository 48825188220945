

































































import Vue, { PropType } from "vue"
import type { Schedule } from "@/datasource/backgroundJobs"
import VueCronEditorVuetify, {
  Component,
} from "@charbytex/vue-cron-editor-vuetify"

export default Vue.extend({
  components: {
    VueCronEditorVuetify,
  },

  props: {
    availableFunctions: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object as PropType<Schedule>,
      required: true,
    },
    errors: {
      type: Object as PropType<Record<string, string[]>>,
      default: () => ({}),
    },
  },

  data() {
    return {
      required: [(v: string) => !!v || "Required"],
      fields: { ...this.value },
      original: { ...this.value },
    }
  },

  methods: {
    emit(field: string) {
      this.errors[field] = []
      this.$emit("input", this.fields)
    },

    resetSchedule() {
      this.fields.schedule = this.original.schedule
    },

    loadSchedule() {
      const schedule = this.fields.schedule

      // This is needed twice so the cron editor loads
      this.$nextTick(() => {
        this.$nextTick(() => {
          if (this.$refs.cronEditor) {
            let cronEditor = this.$refs.cronEditor as Component

            cronEditor.value = schedule
            cronEditor.__loadDataFromExpression()
          }
        })
      })
    },
  },
})

































import Vue from "vue"
import datasource, { ValidationFailureError } from "@/datasource"
import type { AppUser } from "@/datasource/appUsers"
import type { ValidationError } from "@/datasource"
import AppUserFields from "./AppUserFields.vue"

function initialData() {
  return {
    showDialog: false,
    loading: false,
    saving: false,
    modified: null as AppUser | null,
    errors: {} as ValidationError,
  }
}

export default Vue.extend({
  components: {
    AppUserFields,
  },

  data: initialData,

  watch: {
    showDialog(value) {
      if (value) {
        this.loadData()
      } else {
        this.close()
      }
    },
  },

  methods: {
    close() {
      Object.assign(this.$data, initialData())
    },

    async loadData() {
      this.loading = true

      {
        const result = await datasource.appUsers.newAppUser()
        if (result instanceof Error) {
          console.error(result)
          return
        }
        this.modified = result
      }

      this.loading = false
    },

    async save() {
      if (!this.modified) {
        return
      }

      this.saving = true
      let isOk = true

      try {
        {
          const result = await datasource.appUsers.createAppUser(this.modified)
          if (result instanceof ValidationFailureError) {
            this.errors = result.errors
            isOk = false
          } else if (result instanceof Error) {
            throw result
          }
        }
      } catch (e) {
        console.error(e)
        isOk = false
      }

      if (isOk) {
        this.$emit("saved")
        this.close()
      }

      this.saving = false
    },
  },
})
